import React from 'react';
import { Card, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import ChangePasswordForm from '../../components/auth/ChangePasswordForm';

const backgroundStyle = {
  backgroundImage: 'url(/img/login-background.jpg)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover'
};

const FirstTimeLoginChangePassword = () => {
  const { t } = useTranslation();

  return (
    <div className="auth-container">
      <div className="h-100" style={backgroundStyle}>
        <div className="container d-flex flex-column justify-content-center h-100">
          <Row justify="center">
            <Col xs={20} sm={20} md={20} lg={7}>
              <Card bordered={false}>
                <div className="my-4">
                  <div className="text-center">
                    <h2 className="mb-4">{t('navigation_profile.change_password')}</h2>
                  </div>
                  <Row justify="center">
                    <Col xs={24} sm={24} md={20} lg={20}>
                      <ChangePasswordForm />
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default FirstTimeLoginChangePassword;
