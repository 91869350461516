import {
  FETCH_ROLES,
  FETCH_ROLES_FAIL,
  FETCH_ROLES_SUCCESS,
  IRolesState,
  RoleActionTypes
} from '../constants/roles';

const initialState: IRolesState = {
  roles: [],
  fetchingRoles: false,
  errorsFetchingRoles: null
};

const roleReducer = (state = initialState, action: RoleActionTypes): IRolesState => {
  switch (action.type) {
    case FETCH_ROLES:
      return {
        ...state,
        fetchingRoles: true
      };
    case FETCH_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload.roles,
        errorsFetchingRoles: null,
        fetchingRoles: false
      };
    case FETCH_ROLES_FAIL:
      return {
        ...state,
        roles: [],
        errorsFetchingRoles: action.payload.errorsFetchingRoles,
        fetchingRoles: false
      };
    default:
      return state;
  }
};

export default roleReducer;
