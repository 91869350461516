import { IError } from '../../api/models/error';
import { IMunicipality } from '../../api/models/municipality';

export const FETCH_MUNICIPALITIES = 'FETCH_MUNICIPALITIES';
export const FETCH_MUNICIPALITIES_SUCCESS = 'FETCH_MUNICIPALITIES_SUCCESS';
export const FETCH_MUNICIPALITIES_FAIL = 'FETCH_MUNICIPALITIES_FAIL';

interface IFetchMunicipalities {
  type: typeof FETCH_MUNICIPALITIES;
}

interface IFetchMunicipalitiesSuccess {
  type: typeof FETCH_MUNICIPALITIES_SUCCESS;
  payload: {
    municipalities: IMunicipality[];
  };
}

interface IFetchMunicipalitiesFail {
  type: typeof FETCH_MUNICIPALITIES_FAIL;
  payload: {
    errors: IError;
  };
}

export interface IMunicipalitiesState {
  municipalities: IMunicipality[];
  fetchingMunicipalities: boolean;
  errors: IError | null;
}

export type MunicipalityActionTypes =
  | IFetchMunicipalities
  | IFetchMunicipalitiesSuccess
  | IFetchMunicipalitiesFail;
