import {
  TOGGLE_COLLAPSED_NAV,
  TOGGLE_MOBILE_NAV,
  SELECT_LANGUAGE,
  IThemeState,
  ThemeActionTypes
} from '../constants/theme';

const initialState: IThemeState = {
  navCollapsed: false,
  mobileNav: false,
  language: 'al'
};

const themeReducer = (state = initialState, action: ThemeActionTypes): IThemeState => {
  switch (action.type) {
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.payload.navCollapsed
      };
    case TOGGLE_MOBILE_NAV:
      return {
        ...state,
        mobileNav: action.payload.mobileNav
      };
    case SELECT_LANGUAGE:
      return {
        ...state,
        language: action.payload.language
      };
    default:
      return state;
  }
};

export default themeReducer;
