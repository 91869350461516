import React, { useState, ReactNode } from 'react';
import { Grid, Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import utils from '../../utils';

const { useBreakpoint } = Grid;

interface SideContentProps {
  sideContentWidth?: number;
  sideContent: ReactNode;
  border?: boolean;
}

const SideContent: React.FC<SideContentProps> = ({
  sideContent,
  sideContentWidth = 250,
  border = false
}) => (
  <div
    className={`side-content ${border ? 'with-border' : ''}`}
    style={{ width: `${sideContentWidth}px` }}
  >
    {sideContent}
  </div>
);

interface SideContentMobileProps {
  sideContent: ReactNode;
  visible: boolean;
  onSideContentClose?: () => void;
}

const SideContentMobile: React.FC<SideContentMobileProps> = ({
  sideContent,
  visible,
  onSideContentClose
}) => {
  return (
    <Drawer
      width={320}
      placement="left"
      closable={false}
      onClose={onSideContentClose}
      visible={visible}
      bodyStyle={{ paddingLeft: 0, paddingRight: 0 }}
    >
      <div className="h-100">{sideContent}</div>
    </Drawer>
  );
};

interface InnerAppLayoutProps {
  mainContent: ReactNode;
  sideContent: ReactNode;
  pageHeader: boolean;
  sideContentGutter?: boolean;
}

export const InnerAppLayout: React.FC<InnerAppLayoutProps> = ({
  mainContent,
  sideContent,
  pageHeader,
  sideContentGutter = true
}) => {
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
  const [visible, setVisible] = useState(false);

  const close = () => {
    setVisible(false);
  };

  const openSideContentMobile = () => {
    setVisible(true);
  };

  return (
    <div className="inner-app-layout">
      {isMobile ? (
        <SideContentMobile visible={visible} onSideContentClose={close} sideContent={sideContent} />
      ) : (
        <SideContent sideContent={sideContent} />
      )}
      <div
        className={`main-content ${pageHeader ? 'has-page-header' : ''} ${
          sideContentGutter ? 'gutter' : 'no-gutter'
        }`}
      >
        {isMobile ? (
          <div className={`font-size-lg mb-3 ${!sideContentGutter ? 'pt-3 px-3' : ''}`}>
            <MenuOutlined onClick={() => openSideContentMobile()} />
          </div>
        ) : null}
        {mainContent}
      </div>
    </div>
  );
};

export default InnerAppLayout;
