import React from 'react';
import { Form, Button, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IChangePasswordRequest } from '../../api/models/auth';
import { changePassword, changePasswordFirstTime } from '../../redux/actions/auth';
import { RootState } from '../../redux/reducers';
import Alert from '../layout/shared/Alert';

const ChangePasswordForm = () => {
  const { t } = useTranslation();
  const email = useSelector((state: RootState) => state.auth.email);
  const firstTimeLogin = useSelector((state: RootState) => state.auth.firstLogin);
  const changePasswordErrors = useSelector((state: RootState) => state.auth.errors);

  const dispatch = useDispatch();
  const history = useHistory();

  const onFinish = (values: IChangePasswordRequest) => {
    const data: IChangePasswordRequest = {
      ...values,
      email: email ?? ''
    };

    if (firstTimeLogin) {
      dispatch(changePasswordFirstTime(data, history));
    } else {
      dispatch(changePassword(data, t('feedback_message.password_successfully_changed'), history));
    }
  };

  return (
    <>
      <Form name="changePasswordForm" layout="vertical" onFinish={onFinish}>
        <Form.Item
          label={t('general.current_password')}
          name="old_password"
          rules={[
            {
              required: true,
              message: t('validation_messages.enter_current_password')
            }
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label={t('general.new_password')}
          name="new_password1"
          rules={[
            {
              required: true,
              message: t('validation_messages.enter_new_password')
            }
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label={t('general.confirm_password')}
          name="new_password2"
          rules={[
            {
              required: true,
              message: t('validation_messages.confirm_new_password')
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('new_password1') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(t('validation_messages.password_not_matched'));
              }
            })
          ]}
        >
          <Input.Password />
        </Form.Item>
        {changePasswordErrors && <Alert message={changePasswordErrors.message} />}
        <Button type="primary" htmlType="submit" className="w-100">
          {t('navigation_profile.change_password')}
        </Button>
      </Form>
    </>
  );
};

export default ChangePasswordForm;
