import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div>{t('general.footer')}</div>
      <div className="footerLogo">
        <img
          // style={{ position: 'absolute', top: 0, maxWidth: '50%' }}
          src="/img/undp_logo.png"
          alt="UNDP Logo"
        />
      </div>
    </footer>
  );
};

export default Footer;
