import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FIRST_TIME,
  FETCH_USER_FAIL,
  AuthActionTypes
} from './constants/auth';

const tokenMiddleware = () => {
  return (next: (arg: any) => void) => (action: AuthActionTypes) => {
    switch (action.type) {
      case LOGIN_SUCCESS:
        localStorage.setItem('token', action.payload.token);
        break;
      case LOGIN_FAIL:
      case LOGOUT:
      case CHANGE_PASSWORD:
      case FETCH_USER_FAIL:
      case CHANGE_PASSWORD_FIRST_TIME:
        localStorage.removeItem('token');
        break;
      default:
        break;
    }

    next(action);
  };
};

export default tokenMiddleware;
