import React from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from '../redux/reducers';
import { UserRole } from '../constants/theme';

const ServerError = () => {
  const userRole = useSelector((state: RootState) => state.auth.role);
  const history = useHistory();
  const { t } = useTranslation();

  const destination =
    userRole === UserRole.Director || userRole === UserRole.Admin
      ? '/platforma/admin/kreu'
      : '/platforma/kreu';

  return (
    <div className="h-100 bg-white">
      <div className="container-fluid d-flex flex-column justify-content-between h-100 px-md-4 pb-md-4 pt-md-1">
        <div>
          <img className="img-fluid" src="/img/logo-akafpk.png" alt="" />
        </div>
        <div className="container">
          <div className="text-center mb-5">
            <img className="img-fluid" src="/img/img-21.png" alt="" />
            <h1 className="font-weight-bold mb-4">{t('general.something_went_wrong')}</h1>
            <Button type="primary" onClick={() => history.push(destination)}>
              {t('general.home_page')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServerError;
