import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_FIELDS,
  FETCH_FIELDS_SUCCESS,
  FETCH_FIELDS_FAIL,
  FETCH_SUBFIELDS,
  FETCH_SUBFIELDS_SUCCESS,
  FETCH_SUBFIELDS_FAIL
} from '../constants/field';
import agent from '../../api/agent';
import { RootState } from '../reducers/index';
import { IError } from '../../api/models/error';

export const fetchFields = (): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch
) => {
  dispatch({
    type: FETCH_FIELDS
  });

  try {
    const fields = await agent.Fields.list();

    dispatch({
      type: FETCH_FIELDS_SUCCESS,
      payload: {
        fields
      }
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: FETCH_FIELDS_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const fetchFieldSubfields = (
  id: number
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: FETCH_SUBFIELDS
  });

  try {
    const subFields = await agent.Fields.listFieldSubfields(id);

    dispatch({
      type: FETCH_SUBFIELDS_SUCCESS,
      payload: {
        subFields
      }
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: FETCH_SUBFIELDS_FAIL,
      payload: {
        errors
      }
    });
  }
};
