import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_PROPERTIES,
  FETCH_PROPERTIES_SUCCESS,
  FETCH_PROPERTIES_FAIL
} from '../constants/property';
import agent from '../../api/agent';
import { RootState } from '../reducers/index';
import { IError } from '../../api/models/error';

const fetchProperties = (): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch
) => {
  dispatch({
    type: FETCH_PROPERTIES
  });

  try {
    const properties = await agent.Property.list();

    dispatch({
      type: FETCH_PROPERTIES_SUCCESS,
      payload: {
        properties
      }
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: FETCH_PROPERTIES_FAIL,
      payload: {
        errors
      }
    });
  }
};

export default fetchProperties;
