import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import NotFound from '../404';
import ChangePassword from '../auth/ChangePassword';
import EditProfile from '../auth/EditProfile';
import Home from './home';
import Institutions from './institutions';
import GraduationStatisticsReport from './reports/statistics/graduation';
import EvaluationPerformanceSummaryReport from './reports/evaluation-performance/summary';
import EvaluationPerformanceByFieldsReport from './reports/evaluation-performance/performance-by-fields';
import EvaluationPerformanceByYearsReport from './reports/evaluation-performance/performance-by-years';
import EvaluationProgressReport from './reports/evaluation-progress';
import EvaluationPeriods from './evaluation-periods/index';
import Users from './users';
import AddUser from './users/add-user';
import EditUser from './users/edit-user';
import AddInstitution from './institutions/add-institution';
import EditInstitution from './institutions/edit-institution';
import AdminPrivateRoute from '../../routing/AdminPrivateRoute';
import Subjects from './subjects';
import AddSubject from './subjects/add-subjects';
import EditSubject from './subjects/edit-subjects';
import AddEvaluationPeriod from './evaluation-periods/add-evaluation-period';
import EditEvaluationPeriod from './evaluation-periods/edit-evaluation-period';
import InstitutionOverview from './institutions/overview';
import EvidenceTemplate from './documents/evidence-template';
import AddEvidenceTemplate from './documents/evidence-template/add-evidence-template';
import EditEvidenceTemplate from './documents/evidence-template/edit-evidence-template';
import SummaryTemplate from './documents/summary-template';
import RegistrationStatisticsReport from './reports/statistics/registration';

const AdminRoutes: React.FC = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}`}>
        <Redirect to={`${match.url}/admin/kreu`} />
      </Route>
      <Route exact path={`${match.url}/admin/kreu`} component={Home} />
      <AdminPrivateRoute exact path={`${match.url}/admin/perdorues`} component={Users} />
      <AdminPrivateRoute
        exact
        path={`${match.url}/admin/perdorues/shto-perdorues`}
        component={AddUser}
      />
      <AdminPrivateRoute
        exact
        path={`${match.url}/admin/perdorues/ndrysho-perdorues/:id`}
        component={EditUser}
      />
      <Route exact path={`${match.url}/admin/institucione`} component={Institutions} />
      <Route
        path={`${match.url}/admin/institucione/shiko-institucion/:id`}
        component={InstitutionOverview}
      />
      <AdminPrivateRoute
        exact
        path={`${match.url}/admin/institucione/shto-institucion`}
        component={AddInstitution}
      />
      <AdminPrivateRoute
        exact
        path={`${match.url}/admin/institucione/ndrysho-institucion/:id`}
        component={EditInstitution}
      />
      <AdminPrivateRoute exact path={`${match.url}/admin/drejtime`} component={Subjects} />
      <AdminPrivateRoute
        exact
        path={`${match.url}/admin/drejtime/shto-drejtime`}
        component={AddSubject}
      />
      <AdminPrivateRoute
        exact
        path={`${match.url}/admin/drejtime/ndrysho-drejtime/:id`}
        component={EditSubject}
      />
      <AdminPrivateRoute exact path={`${match.url}/admin/periudha`} component={EvaluationPeriods} />
      <AdminPrivateRoute
        exact
        path={`${match.url}/admin/periudha/shto-periudhe`}
        component={AddEvaluationPeriod}
      />
      <AdminPrivateRoute
        exact
        path={`${match.url}/admin/periudha/ndrysho-periudhe/:id`}
        component={EditEvaluationPeriod}
      />
      <AdminPrivateRoute
        exact
        path={`${match.url}/admin/dokumenta/evidenca`}
        component={EvidenceTemplate}
      />
      <AdminPrivateRoute
        exact
        path={`${match.url}/admin/dokumenta/raporti-vetevleresimit`}
        component={SummaryTemplate}
      />
      <AdminPrivateRoute
        exact
        path={`${match.url}/admin/dokumenta/evidenca/shto-evidence`}
        component={AddEvidenceTemplate}
      />
      <AdminPrivateRoute
        exact
        path={`${match.url}/admin/dokumenta/evidenca/ndrysho-evidence/:id`}
        component={EditEvidenceTemplate}
      />
      <Route
        exact
        path={`${match.url}/admin/raporte/statistika/regjistrime`}
        component={RegistrationStatisticsReport}
      />
      <Route
        exact
        path={`${match.url}/admin/raporte/statistika/diplomime`}
        component={GraduationStatisticsReport}
      />
      <Route
        exact
        path={`${match.url}/admin/raporte/progresi-vleresimit`}
        component={EvaluationProgressReport}
      />
      <Route
        exact
        path={`${match.url}/admin/raporte/rezultate/permbledhje`}
        component={EvaluationPerformanceSummaryReport}
      />
      <Route
        exact
        path={`${match.url}/admin/raporte/rezultate/fusha`}
        component={EvaluationPerformanceByFieldsReport}
      />
      <Route
        exact
        path={`${match.url}/admin/raporte/rezultate/vite`}
        component={EvaluationPerformanceByYearsReport}
      />
      <Route exact path={`${match.url}/admin/modifiko-profilin`} component={EditProfile} />
      <Route exact path={`${match.url}/admin/ndrysho-fjalekalimin`} component={ChangePassword} />
      <Route component={() => <NotFound showContainer={false} />} />
    </Switch>
  );
};

export default AdminRoutes;
