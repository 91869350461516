import React, { useState } from 'react';
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { Button, Card, Input, Popconfirm, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { ISubject, ISubjectsDisplay } from '../../../api/models/subject';
import { deleteSubject } from '../../../redux/actions/subjects';
import { RootState } from '../../../redux/reducers';
import utils from '../../../utils/index';
import SearchTable from '../../layout/SearchTable';
import Alert from '../../layout/shared/Alert';
import Flex from '../../layout/shared/Flex';
import Spinner from '../../layout/shared/Spinner';
import { UserRole } from '../../../constants/theme';

const SubjectsList = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const userRole = useSelector((state: RootState) => state.auth.role);
  const subjects = useSelector((state: RootState) => state.subject.subjects);
  const institutionTypes = useSelector((state: RootState) => state.institution.institutionTypes);
  const deletingSubject = useSelector((state: RootState) => state.subject.deletingSubject);
  const error = useSelector((state: RootState) => state.subject.errorsDeletingSubject);

  const subjectsDisplay: ISubjectsDisplay[] = subjects.map((subject) => {
    return {
      ...subject,
      institutionType: institutionTypes.filter(
        (institutionType) => institutionType.id === subject.institutionTypeId
      )[0]?.description
    };
  });

  const [search, setSearch] = useState<string | null>(null);

  const addSubject = () => {
    history.push(`${match.url}/shto-drejtime`);
  };

  const onHandleDelete = (id: number) => {
    dispatch(deleteSubject(id, t('feedback_message.subject_successfully_deleted')));
  };

  const tableColumns: any = [
    {
      title: t('general.subject'),
      dataIndex: 'name',
      sorter: (a: string, b: string) => utils.antdTableSorter(a, b, 'name')
    },
    {
      title: t('general.institution_type'),
      dataIndex: 'institutionType',
      sorter: (a: string, b: string) => utils.antdTableSorter(a, b, 'institutionType')
    },
    {
      title: '',
      dataIndex: 'actions',
      render: (_: any, row: ISubject) => {
        if (userRole !== UserRole.Admin) return null;

        return (
          <Flex justifyContent="center">
            <Tooltip title={t('general.edit')} placement="bottom">
              <Link to={`${match.url}/ndrysho-drejtime/${row.id}`}>
                <Button type="primary" className="mr-2" icon={<EditOutlined />} size="small" />
              </Link>
            </Tooltip>

            <Tooltip title={t('general.delete')} placement="bottom">
              <Popconfirm
                title={t('feedback_message.are_you_sure')}
                okText={t('general.yes')}
                cancelText={t('general.no')}
                onConfirm={() => onHandleDelete(row.id)}
              >
                <Button danger icon={<DeleteOutlined />} size="small" />
              </Popconfirm>
            </Tooltip>
          </Flex>
        );
      }
    }
  ];

  if (deletingSubject) return <Spinner />;

  return (
    <Card>
      <Flex justifyContent="between" mobileFlex={false}>
        <Flex className="mb-1" mobileFlex={false}>
          <Flex className="mr-md-3 mb-3">
            <Input
              placeholder={t('general.search')}
              prefix={<SearchOutlined />}
              onChange={(e) => setSearch(e.currentTarget.value)}
            />
          </Flex>
        </Flex>
        <Flex>
          {userRole === UserRole.Admin && (
            <Button onClick={addSubject} type="primary" icon={<PlusCircleOutlined />} block>
              {t('general.add_subject')}
            </Button>
          )}
        </Flex>
      </Flex>

      {error && <Alert message={error.message} />}
      <SearchTable tableColumns={tableColumns} dataSource={subjectsDisplay} search={search} />
    </Card>
  );
};

export default SubjectsList;
