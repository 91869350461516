import React from 'react';
import { Menu, Dropdown, Avatar } from 'antd';
import { EditOutlined, SettingOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Icon from './Icon';
import { logout } from '../../redux/actions/auth';
import { RootState } from '../../redux/reducers';
import { UserRole } from '../../constants/theme';

interface IMenuItem {
  title: string;
  icon: any;
  path: string;
}

const getMenuItems = (role: string): IMenuItem[] => {
  return [
    {
      title: 'navigation_profile.edit_profile',
      icon: EditOutlined,
      path:
        role === UserRole.Director || role === UserRole.Admin
          ? '/platforma/admin/modifiko-profilin'
          : '/platforma/modifiko-profilin'
    },
    {
      title: 'navigation_profile.change_password',
      icon: SettingOutlined,
      path:
        role === UserRole.Director || role === UserRole.Admin
          ? '/platforma/admin/ndrysho-fjalekalimin'
          : '/platforma/ndrysho-fjalekalimin'
    }
  ];
};

const NavProfile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: RootState) => state.auth.user);

  const menuItem: IMenuItem[] = getMenuItems(user?.role.name ?? '');

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} icon={<UserOutlined />} style={{ backgroundColor: 'black' }} />
          <div className="pl-3">
            <h4 className="mb-0">{`${user?.firstName} ${user?.lastName}`}</h4>
            <span className="text-muted">{user?.role.description}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el: IMenuItem, i: number) => {
            return (
              <Menu.Item key={i.toString()}>
                <Link to={`${el.path}`}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{t(el.title)}</span>
                </Link>
              </Menu.Item>
            );
          })}
          <Menu.Item
            key={menuItem.length + 1}
            onClick={() => {
              dispatch(logout());
              history.replace('/');
            }}
          >
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">{t('navigation_profile.logout')}</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={['click']}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <Avatar style={{ backgroundColor: 'black' }} icon={<UserOutlined />} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default NavProfile;
