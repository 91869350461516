import React, { useState } from 'react';
import {
  EditOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  FileTextOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { Button, Card, Input, Popconfirm, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { IEvidenceTemplateDisplay } from '../../../api/models/evidenceTemplate';
import { deleteEvidenceTemplate } from '../../../redux/actions/evidenceTemplate';
import { RootState } from '../../../redux/reducers';
import utils from '../../../utils/index';
import SearchTable from '../../layout/SearchTable';
import Flex from '../../layout/shared/Flex';
import { UserRole } from '../../../constants/theme';

const EvidenceTemplateList = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userRole = useSelector((state: RootState) => state.auth.role);
  const evidenceTemplates = useSelector(
    (state: RootState) => state.evidenceTemplate.evidenceTemplates
  );

  const evidenceTemplatesDisplay: IEvidenceTemplateDisplay[] = evidenceTemplates.map(
    (evidenceTemplate) => ({
      ...evidenceTemplate,
      fieldName: evidenceTemplate.field.name
    })
  );

  const [search, setSearch] = useState<string | null>(null);

  const addEvidence = () => {
    history.push(`${match.url}/shto-evidence`);
  };

  const onHandleEdit = (id: number) => {
    history.push(`${match.url}/ndrysho-evidence/${id}`);
  };

  const onHandleDelete = (id: number) => {
    dispatch(
      deleteEvidenceTemplate(id, t('feedback_message.evidence_template_successfully_deleted'))
    );
  };

  const tableColumns: any = [
    {
      title: t('general.document'),
      dataIndex: 'name',
      width: '35%',
      sorter: (a: string, b: string) => utils.antdTableSorter(a, b, 'name')
    },
    {
      title: t('general.field'),
      dataIndex: 'fieldName',
      width: '35%',
      sorter: (a: number, b: number) => utils.antdTableSorter(a, b, 'fieldName')
    },
    {
      title: '',
      dataIndex: 'document',
      align: 'center',
      render: (_: any, row: IEvidenceTemplateDisplay) => {
        const { document } = row;
        if (document?.id) {
          return (
            <FileTextOutlined
              style={{ fontSize: '25px', color: '#3e82f7' }}
              onClick={() => utils.downloadDocument(document.id)}
            />
          );
        }

        return null;
      }
    },
    {
      title: '',
      dataIndex: 'actions',
      render: (_: any, row: IEvidenceTemplateDisplay) => {
        if (userRole !== UserRole.Admin) return null;

        return (
          <Flex justifyContent="center">
            <Tooltip title={t('general.edit')} placement="bottom" className="mr-2">
              <Button
                type="primary"
                icon={<EditOutlined />}
                size="small"
                onClick={() => onHandleEdit(row.id)}
              />
            </Tooltip>
            <Tooltip title={t('general.delete')} placement="bottom">
              <Popconfirm
                title={t('feedback_message.are_you_sure')}
                okText={t('general.yes')}
                cancelText={t('general.no')}
                onConfirm={() => onHandleDelete(row.id)}
              >
                <Button danger icon={<DeleteOutlined />} size="small" />
              </Popconfirm>
            </Tooltip>
          </Flex>
        );
      }
    }
  ];

  return (
    <Card>
      <Flex justifyContent="between" mobileFlex={false}>
        <Flex className="mb-1" mobileFlex={false}>
          <Flex className="mr-md-3 mb-3">
            <Input
              placeholder={t('general.search')}
              prefix={<SearchOutlined />}
              onChange={(e) => setSearch(e.currentTarget.value)}
            />
          </Flex>
        </Flex>
        <Flex>
          {userRole === UserRole.Admin && (
            <Button onClick={addEvidence} type="primary" icon={<PlusCircleOutlined />} block>
              {t('general.add_evidence')}
            </Button>
          )}
        </Flex>
      </Flex>

      <SearchTable
        tableColumns={tableColumns}
        dataSource={evidenceTemplatesDisplay}
        search={search}
      />
    </Card>
  );
};

export default EvidenceTemplateList;
