import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import jwtDecode from 'jwt-decode';
import { message } from 'antd';
import {
  LOGIN_SUCCESS,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAIL,
  LOGIN_FAIL,
  AuthActionTypes,
  LOGOUT,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAIL,
  UPDATE_CURRENT_USER_FAIL,
  UPDATE_CURRENT_USER,
  UPDATE_CURRENT_USER_SUCCESS,
  CHANGE_PASSWORD_FIRST_TIME,
  CHANGE_PASSWORD_FIRST_TIME_FAIL,
  TOGGLE_APP_LOADING,
  RESET_AUTH_ERRORS
} from '../constants/auth';
import agent from '../../api/agent';
import { RootState } from '../reducers/index';
import { IChangePasswordRequest, IDecodedToken, ILoginRequest } from '../../api/models/auth';
import { IError } from '../../api/models/error';
import { IUserSendRequest } from '../../api/models/user';

export const toggleAppLoading = (loading: boolean): AuthActionTypes => {
  return {
    type: TOGGLE_APP_LOADING,
    payload: {
      loading
    }
  };
};

export const fetchUser = (
  userId: number,
  userRole: string,
  email: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    dispatch(toggleAppLoading(true));

    const user = await agent.User.current(userId);

    dispatch({
      type: FETCH_USER_SUCCESS,
      payload: {
        user,
        role: userRole,
        email
      }
    });

    dispatch(toggleAppLoading(false));
  } catch (error) {
    const errors: IError = error?.data;

    dispatch(toggleAppLoading(false));

    dispatch({
      type: FETCH_USER_FAIL,
      payload: {
        fetchingCurrentUserErrors: errors
      }
    });
  }
};

export const login = (
  values: ILoginRequest
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    const { token } = await agent.Auth.login(values);

    if (token) {
      const result: IDecodedToken = jwtDecode(token);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          token,
          role: result.role,
          firstLogin: result.firstLogin,
          email: result.email
        }
      });

      if (!result.firstLogin) dispatch(fetchUser(result.id, result.role, result.email));
    }
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: LOGIN_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const logout = (): AuthActionTypes => {
  return {
    type: LOGOUT
  };
};

export const changePasswordFirstTime = (
  values: IChangePasswordRequest,
  history: any
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    await agent.Auth.changePassword(values);

    dispatch({
      type: CHANGE_PASSWORD_FIRST_TIME
    });

    history.replace('/');
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: CHANGE_PASSWORD_FIRST_TIME_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const changePassword = (
  values: IChangePasswordRequest,
  successMessage: string,
  history: any
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  try {
    await agent.Auth.changePassword(values);

    message.success({ content: successMessage, duration: 3 });

    dispatch({
      type: CHANGE_PASSWORD
    });

    history.replace('/');
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: CHANGE_PASSWORD_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const updateCurrentUser = (
  id: number,
  values: IUserSendRequest,
  successMessage: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: UPDATE_CURRENT_USER
  });

  try {
    const user = await agent.Auth.updateCurrentUser(id, values);

    dispatch({
      type: UPDATE_CURRENT_USER_SUCCESS,
      payload: {
        user
      }
    });

    message.success({ content: successMessage, duration: 4 });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: UPDATE_CURRENT_USER_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const resetAuthErrors = (): AuthActionTypes => {
  return {
    type: RESET_AUTH_ERRORS
  };
};
