import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, message, Row, Select, Table } from 'antd';
import Title from 'antd/lib/typography/Title';
import { FileExcelOutlined, FileOutlined, FilePdfOutlined } from '@ant-design/icons';
import html2canvas from 'html2canvas';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Header from '../../../../../components/admin/reports/StatisticsReportHeader';
import { RootState } from '../../../../../redux/reducers';
import StatisticalData from '../../../../../components/admin/reports/StatisticalData';
import {
  IChartSeries,
  IFieldExtractReport,
  IReportImage,
  IResultSummaryReport,
  IStatisticalDataWidget
} from '../../../../../api/models/report';
import VerticalBarChart from '../../../../../components/admin/charts/VerticalBarChart';
import HorizontalBarChart from '../../../../../components/admin/charts/HorizontalBarChart';
import agent from '../../../../../api/agent';
import { IEvaluationPeriod } from '../../../../../api/models/evaluationPeriod';
import { IError } from '../../../../../api/models/error';
import Spinner from '../../../../../components/layout/shared/Spinner';
import utils from '../../../../../utils';
import generateSelfEvaluationPdfReport from '../../../../../components/institutions/self-evaluation/fields-overview/report_template/selfEvaluationReport_pdf';
import { IInstitutionSelfEvaluation } from '../../../../../api/models/selfEvaluation';
import { IInstitution } from '../../../../../api/models/institution';
import { IField } from '../../../../../api/models/field';
import generateSelfEvaluationExcelReport from '../../../../../components/institutions/self-evaluation/fields-overview/report_template/selfEvaluationReport_excel';
import generateExcelReport from '../../excelReport';
import generatePDFReport from '../../pdfReport';

const TextItemsList: React.FC<{ items: string[]; itemIds: string[] }> = ({ items, itemIds }) => {
  const [...copy] = items;

  return (
    <Row align="middle" justify="center">
      {copy.map((item, index) => (
        <Col key={itemIds[index]} xs={24}>
          {item}
        </Col>
      ))}
    </Row>
  );
};

const IconsItemsList: React.FC<{
  institutionId: number;
  items: string[];
  itemIds: string[];
  icon: React.ComponentType<any>;
  reportGenerator: (institutionId: number, selfEvaluationId: number) => Promise<void>;
}> = ({ institutionId, items, itemIds, icon, reportGenerator }) => {
  const [...copy] = items;

  return (
    <Row justify="center" align="middle">
      {copy.map((item, index) => (
        <Col key={itemIds[index]} xs={24}>
          {React.createElement(icon, {
            // eslint-disable-next-line radix
            onClick: () => reportGenerator(institutionId, parseInt(item))
          })}
        </Col>
      ))}
    </Row>
  );
};

const EvaluationPerformanceSummaryReport = () => {
  const { t } = useTranslation();
  const { Option } = Select;

  const evaluationPeriods = useSelector(
    (state: RootState) => state.evaluationPeriod.evaluationPeriods
  );

  const fields = useSelector((state: RootState) => state.field.fields);
  const [evaluationPeriod, setEvaluationPeriod] = useState<null | IEvaluationPeriod>(null);
  const [loadingSummary, setLoadingSummary] = useState<boolean>(true);
  const [summary, setSummary] = useState<IResultSummaryReport | null>(null);
  const [fieldsSummaryDataSource, setFieldsSummaryDataSource] = useState<any>(null);
  const [selfEvaluationSummaryDataSource, setSelfEvaluationSummaryDataSource] = useState<any>(null);
  const [institutionPoints, setInstitutionPoints] = useState<IChartSeries[]>([]);
  const [statistics, setStatistics] = useState<IStatisticalDataWidget[]>([]);
  const [countyPoints, setCountyPoints] = useState<IChartSeries[]>([]);
  const [generatingPDF, setGeneratingPDF] = useState<boolean>(false);
  const [generatingExcel, setGeneratingExcel] = useState<boolean>(false);

  const resultSummaryImage = useRef<HTMLDivElement>(null);

  const getSummary = useCallback(async () => {
    let result = null;

    if (evaluationPeriod) {
      setLoadingSummary(true);

      result = await agent.Report.getSummary(evaluationPeriod.id);

      setLoadingSummary(false);
    }

    setSummary(result);
  }, [evaluationPeriod]);

  const generateChartsDataSource = useCallback(() => {
    if (summary) {
      setFieldsSummaryDataSource(
        summary.summaryProgress.map((el, index) => ({ ...el, id: index.toString() }))
      );

      setSelfEvaluationSummaryDataSource(summary.selfEvaluationSummary);

      setInstitutionPoints([
        {
          name: 'Institucioni',
          data: summary.institutionPoints.map(({ institution, rating }) => ({
            x: institution,
            y: rating
          }))
        }
      ]);

      setCountyPoints([
        {
          name: 'Qarku',
          data: summary.countiesPoints.map(({ county, rating }) => ({
            x: county,
            y: rating
          }))
        }
      ]);

      setStatistics(summary.statistics);
    }
  }, [summary]);

  const selectEvaluationPeriod = useCallback(() => {
    if (evaluationPeriods.length > 0) {
      const currentPeriod = evaluationPeriods.filter(
        (el) => el.period === new Date().getFullYear()
      )[0];

      if (currentPeriod) return currentPeriod;

      return evaluationPeriods[0];
    }

    return null;
  }, [evaluationPeriods]);

  useEffect(() => {
    setEvaluationPeriod(selectEvaluationPeriod());
  }, [evaluationPeriods, selectEvaluationPeriod]);

  useEffect(() => {
    try {
      getSummary();
    } catch (error) {
      const errors: IError = error?.data;
      setLoadingSummary(false);
      message.error({ content: errors?.message, duration: 4 });
    }
  }, [evaluationPeriod, getSummary]);

  useEffect(() => {
    generateChartsDataSource();
  }, [generateChartsDataSource, summary]);

  const generateSelfEvaluationReport = (
    reportGenerator: (
      data: IFieldExtractReport[],
      selfEvaluation: IInstitutionSelfEvaluation,
      institution: IInstitution,
      fields: IField[]
    ) => void
  ) => {
    return async (institutionId: number, selfEvaluationId: number) => {
      try {
        const data = await agent.Report.listFieldsData(institutionId, selfEvaluationId);
        const selfEvaluationList = await agent.InstitutionSelfEvaluation.list(institutionId);
        const selfEvaluation = selfEvaluationList.filter(
          (item) => item.id === selfEvaluationId
        )?.[0];
        const institution = await agent.Institution.getById(institutionId);

        if (selfEvaluation) {
          reportGenerator(data, selfEvaluation, institution, fields);
        }
      } catch (error) {
        const err: IError = error?.data ?? error;

        message.error({ content: err?.message ?? error, duration: 4 });
      }
    };
  };

  const renderHeaderFilter = () => {
    if (evaluationPeriod) {
      return (
        <Select
          className="w-100"
          placeholder={t('general.evaluation_periods_ptc')}
          style={{ border: '1px solid #3e82f7', borderRadius: '11px' }}
          defaultValue={evaluationPeriod.id}
          onChange={(e) => {
            setEvaluationPeriod(evaluationPeriods.filter((el) => el.id === e)[0]);
          }}
        >
          {evaluationPeriods.map((period) => (
            <Option key={period.id} value={period.id}>
              {period.period}
            </Option>
          ))}
        </Select>
      );
    }

    return null;
  };

  const renderFieldsProgress = (items: string[], row: any) => {
    const fieldsProgress = items.map((item) => {
      if (parseInt(item, 10) === 100) {
        return '100';
      }

      return item;
    });

    return <TextItemsList items={fieldsProgress} itemIds={row.fieldIds} />;
  };

  const renderTextItemsList = (items: string[], row: any) => (
    <TextItemsList items={items} itemIds={row.fieldIds} />
  );

  const fieldsSummaryTableColumns: any = [
    {
      title: t('general.field'),
      dataIndex: 'field',
      key: 'field',
      sorter: (a: string, b: string) => utils.antdTableSorter(a, b, 'field')
    },
    {
      title: t('general.institution'),
      dataIndex: 'institution',
      key: 'institution',
      sorter: (a: string, b: string) => utils.antdTableSorter(a, b, 'institution')
    },
    {
      title: t('general.result_points'),
      dataIndex: 'rating',
      key: 'rating',
      align: 'right'
    }
  ];

  const selfEvaluationSummaryTableColumns: any = [
    {
      title: t('general.county'),
      dataIndex: 'county',
      width: '5%',
      key: 'county',
      align: 'center',
      sorter: (a: string, b: string) => utils.antdTableSorter(a, b, 'county')
    },
    {
      title: t('general.institution'),
      dataIndex: 'institution',
      key: 'institution',
      width: '20%',
      sorter: (a: string, b: string) => utils.antdTableSorter(a, b, 'institution')
    },
    {
      title: t('general.result_points'),
      dataIndex: 'averageFieldRating',
      key: 'averageFieldRating',
      width: '5%',
      align: 'center',
      sorter: (a: string, b: string) => utils.antdTableSorter(a, b, 'averageFieldRating'),
      render: (averageFieldRating: string) => <Title level={5}>{averageFieldRating}</Title>
    },
    {
      title: t('general.report'),
      dataIndex: 'documentId',
      key: 'documentId',
      align: 'center',
      width: '5%',
      render: (documentId: number | null) => {
        if (!documentId) {
          return null;
        }

        return (
          <FileOutlined
            style={{ fontSize: '25px', color: '#3e82f7' }}
            onClick={() => {
              utils.downloadDocument(documentId);
            }}
          />
        );
      }
    },
    {
      title: t('general.evaluated_field'),
      dataIndex: 'fields',
      key: 'fields',
      align: 'right',
      width: '25%',
      render: renderTextItemsList
    },
    {
      title: t('general.progress'),
      dataIndex: 'fieldsProgress',
      key: 'fieldsProgress',
      width: '5%',
      align: 'center',
      render: renderFieldsProgress
    },
    {
      title: t('general.result_points'),
      dataIndex: 'ratings',
      key: 'ratings',
      align: 'center',
      width: '5%',
      render: renderTextItemsList
    },
    {
      title: t('general.pdf'),
      width: '5%',
      key: 'pdfReport',
      dataIndex: 'selfEvaluationIds',
      align: 'center',
      render: (selfEvaluationIds: string[], row: any) => (
        <IconsItemsList
          institutionId={row.institutionId}
          items={selfEvaluationIds}
          itemIds={selfEvaluationIds}
          icon={FilePdfOutlined}
          reportGenerator={generateSelfEvaluationReport(generateSelfEvaluationPdfReport)}
        />
      )
    },
    {
      title: t('general.excel'),
      width: '5%',
      key: 'excelReport',
      dataIndex: 'selfEvaluationIds',
      align: 'center',
      render: (selfEvaluationIds: string[], row: any) => (
        <IconsItemsList
          institutionId={row.institutionId}
          items={selfEvaluationIds}
          itemIds={selfEvaluationIds}
          icon={FileExcelOutlined}
          reportGenerator={generateSelfEvaluationReport(generateSelfEvaluationExcelReport)}
        />
      )
    },
    {
      title: t('general.total_criterias'),
      dataIndex: 'totalCriterias',
      key: 'totalCriterias',
      align: 'center',
      width: '5%',
      render: renderTextItemsList
    },
    {
      title: t('general.criterias_without_evaluation'),
      dataIndex: 'responsesWithoutEvaluation',
      key: 'responsesWithoutEvaluation',
      align: 'center',
      width: '5%',
      render: renderTextItemsList
    },
    {
      title: t('general.criterias_with_comments'),
      width: '5%',
      dataIndex: 'responsesWithComments',
      key: 'responsesWithComments',
      align: 'center',
      render: renderTextItemsList
    },
    {
      title: t('general.criterias_with_evidence'),
      dataIndex: 'responsesWithEvidence',
      key: 'responsesWithEvidence',
      align: 'center',
      width: '5%',
      render: renderTextItemsList
    }
  ];

  const generateExcel = async () => {
    try {
      if (evaluationPeriod) {
        setGeneratingExcel(true);
        const result = await agent.Report.getProgressExcel();

        const datasource: any[] = result.data
          .filter((el) => el.year === evaluationPeriod.period)
          .map(
            ({
              county,
              institution,
              year,
              startdate,
              enddate,
              field,
              subfield,
              indicator,
              criteria,
              rating,
              description,
              evidence
            }) => {
              return [
                county,
                institution,
                year,
                `${moment(startdate).format('DD/MM/YYYY')} - ${moment(enddate).format(
                  'DD/MM/YYYY'
                )}`,
                field,
                subfield,
                indicator,
                criteria,
                rating,
                description,
                evidence
              ];
            }
          );

        generateExcelReport(
          'raport_rezultatet_vetevleresimit',
          datasource,
          'evaluation_progress_data_table',
          [
            { name: 'Qark' },
            { name: 'Institucioni Arsimor' },
            { name: 'Viti i vlerësimit' },
            { name: 'Periudha e vlerësimit' },
            { name: 'Fusha' },
            { name: 'Nënfusha' },
            { name: 'Treguesi' },
            { name: 'Kriteri' },
            { name: 'Vlerësimi me pikë' },
            { name: 'Komente' },
            { name: 'Evidenca' }
          ]
        );

        setGeneratingExcel(false);
      }
    } catch (error) {
      const errors: IError = error?.data;
      setGeneratingExcel(false);
      message.error({ content: errors?.message, duration: 4 });
    }
  };

  const generatePDF = async () => {
    const images: IReportImage[] = [];
    const reportTitle = `${t('general.self_evaluation_result')} ${evaluationPeriod?.period}`;

    const image = {
      element: resultSummaryImage.current
    };

    try {
      setGeneratingPDF(true);
      window.scrollTo(0, 0);

      if (image && image.element) {
        const canvas = await html2canvas(image.element, { scrollX: -7 });

        images.push({ url: canvas.toDataURL('image/png') });

        generatePDFReport(images, reportTitle, 'raport_permbledhje_vetevleresimi');
        setGeneratingPDF(false);
      }
    } catch (error) {
      setGeneratingPDF(false);
    }
  };

  if (!evaluationPeriod) return <h3>{t('general.no_evaluation_period')}</h3>;

  if (loadingSummary) return <Spinner />;

  return (
    <>
      <Header
        element={renderHeaderFilter()}
        generateExcel={generateExcel}
        generatePDF={generatePDF}
        generatingPDF={generatingPDF}
        generatingExcel={generatingExcel}
        title={t('general.self_evaluation_result')}
      />

      <Row gutter={16} ref={resultSummaryImage} justify="space-between" align="middle">
        <StatisticalData data={statistics} />

        <Col xs={24}>
          <Card className="ant-card-default-border">
            <Table
              className="no-border-last"
              columns={selfEvaluationSummaryTableColumns}
              dataSource={selfEvaluationSummaryDataSource}
              rowKey="institutionId"
              size="middle"
              scroll={{ x: true }}
              pagination={{
                pageSize: 10,
                showSizeChanger: false
              }}
            />
          </Card>
        </Col>

        <Col xs={24}>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Card title={t('general.self_evaluation_result')} className="ant-card-default-border">
                <Table
                  className="no-border-last"
                  columns={fieldsSummaryTableColumns}
                  dataSource={fieldsSummaryDataSource}
                  rowKey="id"
                  pagination={{
                    pageSize: 10,
                    showSizeChanger: false
                  }}
                />
              </Card>
            </Col>

            <Col xs={24} sm={12}>
              <Card
                title={t('general.progress_by_institutions_points')}
                className="ant-card-default-border"
              >
                <VerticalBarChart height={620} series={institutionPoints} />
              </Card>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24}>
              <Card
                title={t('general.progress_by_counties_in_points')}
                className="ant-card-default-border"
              >
                <HorizontalBarChart series={countyPoints} />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default EvaluationPerformanceSummaryReport;
