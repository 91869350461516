import React from 'react';
import { Alert as AntAlert } from 'antd';

interface IProps {
  message: string;
  type?: 'error' | 'success' | 'info' | 'warning' | undefined;
}

const Alert: React.FC<IProps> = ({ message, type = 'error' }) => {
  return <AntAlert message={message} style={{ marginBottom: '20px' }} type={type} />;
};

export default Alert;
