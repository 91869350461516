import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { message } from 'antd';
import {
  FETCH_MY_INSTITUTION,
  FETCH_MY_INSTITUTION_SUCCESS,
  FETCH_MY_INSTITUTION_FAIL,
  FETCH_MY_INSTITUTION_USERS,
  FETCH_MY_INSTITUTION_USERS_SUCCESS,
  FETCH_MY_INSTITUTION_USERS_FAIL,
  FETCH_MY_INSTITUTION_SUBJECTS,
  FETCH_MY_INSTITUTION_SUBJECTS_SUCCESS,
  FETCH_MY_INSTITUTION_SUBJECTS_FAIL,
  FETCH_MY_INSTITUTION_STATISTICS,
  FETCH_MY_INSTITUTION_STATISTICS_SUCCESS,
  FETCH_MY_INSTITUTION_STATISTICS_FAIL,
  UPDATE_MY_INSTITUTION_STATISTICS,
  UPDATE_MY_INSTITUTION_STATISTICS_SUCCESS,
  UPDATE_MY_INSTITUTION_STATISTICS_FAIL,
  FETCH_MY_INSTITUTION_SELF_EVALUATION,
  FETCH_MY_INSTITITUTION_SELF_EVALUATION_SUCCESS,
  FETCH_MY_INSTITITUTION_SELF_EVALUATION_FAIL,
  UPDATE_MY_INSTITUTION_SELF_EVALUATION,
  UPDATE_MY_INSTITUTION_SELF_EVALUATION_SUCCESS,
  UPDATE_MY_INSTITUTION_SELF_EVALUATION_FAIL,
  DELETE_MY_INSTITUTION_SELF_EVALUATION,
  DELETE_MY_INSTITUTION_SELF_EVALUATION_SUCCESS,
  DELETE_MY_INSTITUTION_SELF_EVALUATION_FAIL,
  SHOW_MY_INSTITUTION_SELF_EVALUATION_FORM,
  CLOSE_MY_INSTITUTION_SELF_EVALUATION_FORM,
  UPDATE_MY_INSTITUTION_SELF_EVALUATION_RESPONSE,
  UPDATE_MY_INSTITUTION_SELF_EVALUATION_RESPONSE_SUCCESS,
  UPDATE_MY_INSTITUTION_SELF_EVALUATION_RESPONSE_FAIL,
  MyInstitutionActionTypes,
  FETCH_MY_INSTITUTION_CURRENT_RESPONSE,
  FETCH_MY_INSTITUTION_CURRENT_RESPONSE_SUCCESS,
  FETCH_MY_INSTITUTION_CURRENT_RESPONSE_FAIL,
  RESET_MY_INSTITUTION_ERRORS
} from '../constants/myInstitution';
import agent from '../../api/agent';
import { RootState } from '../reducers/index';
import { IError } from '../../api/models/error';
import { IInstitutionStatistics } from '../../api/models/statistic';
import { IInstitutionSelfEvaluationRequest } from '../../api/models/selfEvaluation';
import { ISendRequestSelfEvaluationResponse } from '../../api/models/field';
import fetchSubfieldPercentageCompleted from './graphicalData';

export const fetchMyInstitution = (
  id: number
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: FETCH_MY_INSTITUTION
  });

  try {
    const institution = await agent.Institution.getById(id);

    dispatch({
      type: FETCH_MY_INSTITUTION_SUCCESS,
      payload: {
        institution
      }
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: FETCH_MY_INSTITUTION_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const fetchMyInstitutionUsers = (
  id: number
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: FETCH_MY_INSTITUTION_USERS
  });

  try {
    const users = await agent.Institution.listUsers(id);

    dispatch({
      type: FETCH_MY_INSTITUTION_USERS_SUCCESS,
      payload: {
        users
      }
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: FETCH_MY_INSTITUTION_USERS_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const fetchMyInstitutionSubjects = (
  id: number
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: FETCH_MY_INSTITUTION_SUBJECTS
  });

  try {
    const subjects = await agent.Institution.listSubjects(id);

    dispatch({
      type: FETCH_MY_INSTITUTION_SUBJECTS_SUCCESS,
      payload: {
        subjects
      }
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: FETCH_MY_INSTITUTION_SUBJECTS_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const fetchMyInstitutionStatistics = (
  institutionId: number
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: FETCH_MY_INSTITUTION_STATISTICS
  });

  try {
    const statistics = await agent.Statistic.fetchInstitutionStatistics(institutionId);

    dispatch({
      type: FETCH_MY_INSTITUTION_STATISTICS_SUCCESS,
      payload: {
        statistics
      }
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: FETCH_MY_INSTITUTION_STATISTICS_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const updateMyInstitutionStatistics = (
  id: number,
  data: IInstitutionStatistics,
  successMessage: string,
  history: any
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: UPDATE_MY_INSTITUTION_STATISTICS
  });

  try {
    const statistics = await agent.Statistic.updateInstitutionStatistics(id, data);

    dispatch({
      type: UPDATE_MY_INSTITUTION_STATISTICS_SUCCESS,
      payload: {
        statistics
      }
    });

    message.success({
      content: successMessage,
      duration: 4
    });

    history.goBack();
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: UPDATE_MY_INSTITUTION_STATISTICS_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const fetchMyInstitutionSelfEvaluation = (
  id: number
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: FETCH_MY_INSTITUTION_SELF_EVALUATION
  });

  try {
    const selfEvaluation = await agent.InstitutionSelfEvaluation.list(id);

    dispatch({
      type: FETCH_MY_INSTITITUTION_SELF_EVALUATION_SUCCESS,
      payload: {
        selfEvaluation
      }
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: FETCH_MY_INSTITITUTION_SELF_EVALUATION_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const showInstitutionSelfEvaluationForm = (): MyInstitutionActionTypes => {
  return {
    type: SHOW_MY_INSTITUTION_SELF_EVALUATION_FORM
  };
};

export const closeInstitutionSelfEvaluationForm = (): MyInstitutionActionTypes => {
  return {
    type: CLOSE_MY_INSTITUTION_SELF_EVALUATION_FORM
  };
};

export const updateMyInstitutionSelfEvaluation = (
  id: number,
  data: IInstitutionSelfEvaluationRequest,
  successMessage: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: UPDATE_MY_INSTITUTION_SELF_EVALUATION
  });

  try {
    const selfEvaluation = await agent.InstitutionSelfEvaluation.update(id, data);

    dispatch({
      type: UPDATE_MY_INSTITUTION_SELF_EVALUATION_SUCCESS,
      payload: {
        selfEvaluation
      }
    });

    dispatch(closeInstitutionSelfEvaluationForm());

    message.success({
      content: successMessage,
      duration: 4
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: UPDATE_MY_INSTITUTION_SELF_EVALUATION_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const deleteMyInstitutionSelfEvaluation = (
  institutionId: number,
  id: number,
  successMessage: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: DELETE_MY_INSTITUTION_SELF_EVALUATION
  });

  try {
    await agent.InstitutionSelfEvaluation.delete(institutionId, id);

    dispatch({
      type: DELETE_MY_INSTITUTION_SELF_EVALUATION_SUCCESS,
      payload: {
        id
      }
    });

    message.success({ content: successMessage, duration: 4 });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: DELETE_MY_INSTITUTION_SELF_EVALUATION_FAIL,
      payload: {
        errors
      }
    });

    message.error({ content: errors?.message, duration: 4 });
  }
};

export const updateMyInstitutionSelfEvaluationResponse = (
  id: number,
  data: ISendRequestSelfEvaluationResponse,
  successMessage: string,
  history: any
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: UPDATE_MY_INSTITUTION_SELF_EVALUATION_RESPONSE
  });

  try {
    const currentResponse = await agent.InstitutionSelfEvaluation.saveResponse(id, data);

    dispatch({
      type: UPDATE_MY_INSTITUTION_SELF_EVALUATION_RESPONSE_SUCCESS,
      payload: {
        currentResponse
      }
    });

    dispatch(fetchSubfieldPercentageCompleted(id));
    dispatch(fetchMyInstitutionSelfEvaluation(id));

    message.success({
      content: successMessage,
      duration: 4
    });

    history.goBack();
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: UPDATE_MY_INSTITUTION_SELF_EVALUATION_RESPONSE_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const fetchMyInstitutionCurrentResponse = (
  insitutionId: number,
  selfEvaluationId: number,
  subFieldId: number
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: FETCH_MY_INSTITUTION_CURRENT_RESPONSE
  });

  try {
    const currentResponse = await agent.InstitutionSelfEvaluation.listResponse(
      insitutionId,
      selfEvaluationId,
      subFieldId
    );

    dispatch({
      type: FETCH_MY_INSTITUTION_CURRENT_RESPONSE_SUCCESS,
      payload: {
        currentResponse
      }
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: FETCH_MY_INSTITUTION_CURRENT_RESPONSE_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const resetMyInstitutionErrors = (): MyInstitutionActionTypes => {
  return {
    type: RESET_MY_INSTITUTION_ERRORS
  };
};
