export const ROW_GUTTER = 16;
export const SIDE_NAV_WIDTH = 250;
export const SIDE_NAV_COLLAPSED_WIDTH = 80;
export const ALBANIAN_LANGUAGE = 'al';
export const ENGLISH_LANGUAGE = 'en';

export enum InstitutionTypeName {
  QENDER_FORMIMI_PROFESIONAL = 'qfp',
  SHKOLLE = 'shkolle',
  AGJENSIA_KOMBETARE_ARSIMIT = 'akafp',
  AGJENSIA_KOMBETARE_PUNESIMIT_AFTESIVE = 'akpa'
}

export enum UserRole {
  Admin = 'admin',
  Director = 'director',
  InstitutionAdmin = 'institution_admin',
  InstitutionDirector = 'institution_director'
}
