import React from 'react';
import Chart from 'react-apexcharts';
import { IChartSeries } from '../../../api/models/report';

interface IProps {
  series: IChartSeries[];
  height?: number;
  percentage?: boolean;
}

const HorizontalBarChart: React.FC<IProps> = ({ series, height = 350, percentage = false }) => {
  const options = {
    chart: {
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false
        // dataLabels: {
        //   position: percentage ? 'top' : 'center'
        // }
      }
    },
    dataLabels: {
      enabled: true,
      formatter: (value: number) => {
        if (value === 0) {
          return '';
        }

        if (percentage) {
          return value < 5 ? '' : value;
        }

        return value;
      }
    },
    tooltip: {
      y: {
        formatter: (value: number) => {
          return percentage ? `${value} %` : value;
        }
      }
    },
    xaxis: {
      tickPlacement: 'on'
    },
    yaxis: {
      tickAmount: percentage ? 5 : undefined,
      min: percentage ? 0 : undefined,
      max: percentage ? 100 : undefined
    }
  };

  return <Chart options={options} series={series} type="bar" height={height} />;
};

export default HorizontalBarChart;
