import {
  FETCH_DOCUMENTS,
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENTS_FAIL,
  UPLOAD_DOCUMENT,
  RESET_DOCUMENT_ERRORS,
  IDocumentsState,
  DocumentsActionTypes
} from '../constants/documents';

const initialState: IDocumentsState = {
  documents: [],
  fetchingDocuments: false,
  errors: null
};

const documentReducer = (state = initialState, action: DocumentsActionTypes): IDocumentsState => {
  switch (action.type) {
    case FETCH_DOCUMENTS:
      return {
        ...state,
        fetchingDocuments: true
      };
    case FETCH_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: action.payload.documents,
        errors: null,
        fetchingDocuments: false
      };
    case FETCH_DOCUMENTS_FAIL:
      return {
        ...state,
        documents: [],
        errors: action.payload.errors,
        fetchingDocuments: false
      };
    case UPLOAD_DOCUMENT:
      return {
        ...state,
        documents: [...state.documents, action.payload.document]
      };
    case RESET_DOCUMENT_ERRORS:
      return {
        ...state,
        errors: null
      };
    default:
      return state;
  }
};

export default documentReducer;
