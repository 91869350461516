import { message } from 'antd';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import agent from '../../api/agent';
import { IError } from '../../api/models/error';
import { ISummaryTemplateSendRequest } from '../../api/models/summaryTemplate';
import {
  FETCH_SUMMARY_TEMPLATE,
  ADD_OR_UPDATE_SUMMARY_TEMPLATE,
  ADD_OR_UPDATE_SUMMARY_TEMPLATE_FAIL,
  ADD_OR_UPDATE_SUMMARY_TEMPLATE_SUCCESS,
  FETCH_SUMMARY_TEMPLATE_FAIL,
  FETCH_SUMMARY_TEMPLATE_SUCCESS
} from '../constants/summaryTemplate';
import { RootState } from '../reducers';

export const fetchSummaryTemplate = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async (dispatch) => {
  dispatch({
    type: FETCH_SUMMARY_TEMPLATE
  });

  try {
    const summaryTemplates = await agent.SummaryTemplate.list();

    dispatch({
      type: FETCH_SUMMARY_TEMPLATE_SUCCESS,
      payload: {
        summaryTemplates
      }
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: FETCH_SUMMARY_TEMPLATE_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const addOrUpdateSummaryTemplate = (
  summaryTemplate: ISummaryTemplateSendRequest,
  successMessage: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: ADD_OR_UPDATE_SUMMARY_TEMPLATE
  });

  try {
    const addedSummaryTemplate = await agent.SummaryTemplate.addOrUpdateSummaryTemplate(
      summaryTemplate
    );

    dispatch({
      type: ADD_OR_UPDATE_SUMMARY_TEMPLATE_SUCCESS,
      payload: {
        summaryTemplate: addedSummaryTemplate
      }
    });

    message.success({
      content: successMessage,
      duration: 4
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: ADD_OR_UPDATE_SUMMARY_TEMPLATE_FAIL,
      payload: {
        errors
      }
    });
  }
};
