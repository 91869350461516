import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

interface IProps {
  fontSize?: number;
}

const Spinner: React.FC<IProps> = ({ fontSize = 50 }) => {
  const antIcon = (
    <LoadingOutlined
      className="custom-spinner"
      // style={{ fontSize: `${fontSize}px`, minHeight: `calc(100vh - ${130 + fontSize}px)` }}
      style={{ fontSize: `${fontSize}px` }}
      spin
    />
  );

  return (
    <div>
      <Spin indicator={antIcon} />
    </div>
  );
};

export default Spinner;
