import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Button, Card, Col, Row, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import PageHeaderAlt from '../../layout/PageHeaderAlt';
import Flex from '../../layout/shared/Flex';
import { IDocument } from '../../../api/models/document';

import { RootState } from '../../../redux/reducers';
import UploadDocument from '../../layout/UploadDocument';
import Alert from '../../layout/shared/Alert';
import { ISummaryTemplate, SUMMARY_TEMPLATE_NAME } from '../../../api/models/summaryTemplate';
import { addOrUpdateSummaryTemplate } from '../../../redux/actions/summaryTemplate';

interface IProps {
  summaryTemplate?: ISummaryTemplate;
}

const SummaryTemplateForm: React.FC<IProps> = ({ summaryTemplate }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [document, setDocument] = useState<IDocument | null>(summaryTemplate?.document ?? null);
  const [uploading, setUploading] = useState<boolean>(false);

  const addingOrUpdatingSummaryTemplate = useSelector(
    (state: RootState) => state.summaryTemplate.addingOrUpdatingSummaryTemplate
  );

  const errors = useSelector((state: RootState) => state.evidenceTemplate.errors);

  const onFinish = () => {
    dispatch(
      addOrUpdateSummaryTemplate(
        {
          documentId: document!.id
        },
        t('feedback_message.summary_successfully_updated')
      )
    );
  };

  const onSuccessDocumentUpload = (doc: IDocument | null) => {
    setDocument(doc);
  };

  const onDocumentUploading = (flag: boolean) => {
    setUploading(flag);
  };

  const addOrEditButtonDisabled = addingOrUpdatingSummaryTemplate || uploading || document === null;

  return (
    <Form
      layout="vertical"
      form={form}
      className="ant-advanced-search-form"
      onFinish={onFinish}
      initialValues={{
        name: SUMMARY_TEMPLATE_NAME
      }}
    >
      <PageHeaderAlt className="bg-white border-bottom">
        <Flex className="py-2" mobileFlex={false} justifyContent="between" alignItems="center">
          <h2>
            {summaryTemplate
              ? t('general.edit_summary_template')
              : t('general.add_summary_template')}
          </h2>
          <Button
            type="primary"
            htmlType="submit"
            disabled={addOrEditButtonDisabled}
            loading={uploading}
          >
            {summaryTemplate ? t('general.edit') : t('general.add')}
          </Button>
        </Flex>
      </PageHeaderAlt>

      <Row gutter={16}>
        <Col xs={24} xl={12}>
          <Card style={{ marginTop: '25px' }}>
            <Form.Item
              label={t('general.document_name')}
              name="name"
              rules={[{ required: true, message: t('validation_messages.enter_document_name') }]}
            >
              <Input disabled />
            </Form.Item>
          </Card>
        </Col>
        <Col xs={24} xl={12}>
          <Card style={{ marginTop: '25px' }}>
            <Form.Item label={t('general.document')} name="document">
              <UploadDocument
                documentId={summaryTemplate?.document?.id ?? null}
                upload={onSuccessDocumentUpload}
                uploading={onDocumentUploading}
              />
            </Form.Item>
          </Card>
        </Col>
        {errors && (
          <Col span={24}>
            <Alert message={errors.message} />
          </Col>
        )}
      </Row>
    </Form>
  );
};

export default SummaryTemplateForm;
