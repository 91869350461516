import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { message } from 'antd';
import {
  FETCH_INSTITUTION_EVIDENCE,
  FETCH_INSTITUTION_EVIDENCE_SUCCESS,
  FETCH_INSTITUTION_EVIDENCE_FAIL,
  ADD_INSTITUTION_EVIDENCE,
  ADD_INSTITUTION_EVIDENCE_SUCCESS,
  ADD_INSTITUTION_EVIDENCE_FAIL,
  UPDATE_INSTITUTION_EVIDENCE,
  UPDATE_INSTITUTION_EVIDENCE_SUCCESS,
  UPDATE_INSTITUTION_EVIDENCE_FAIL,
  DELETE_INSTITUTION_EVIDENCE,
  DELETE_INSTITUTION_EVIDENCE_SUCCESS,
  DELETE_INSTITUTION_EVIDENCE_FAIL
} from '../constants/evidence';
import agent from '../../api/agent';
import { RootState } from '../reducers/index';
import { IError } from '../../api/models/error';
import { IEvidenceSendRequest } from '../../api/models/evidence';

export const fetchEvidences = (
  institutionId: number
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: FETCH_INSTITUTION_EVIDENCE
  });

  try {
    const evidences = await agent.Evidence.list(institutionId);

    dispatch({
      type: FETCH_INSTITUTION_EVIDENCE_SUCCESS,
      payload: {
        evidences
      }
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: FETCH_INSTITUTION_EVIDENCE_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const addEvidence = (
  institutionId: number,
  evidence: IEvidenceSendRequest,
  successMessage: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: ADD_INSTITUTION_EVIDENCE
  });

  try {
    const addedEvidence = await agent.Evidence.add(institutionId, evidence);

    dispatch({
      type: ADD_INSTITUTION_EVIDENCE_SUCCESS,
      payload: {
        evidence: addedEvidence
      }
    });

    message.success({
      content: successMessage,
      duration: 4
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: ADD_INSTITUTION_EVIDENCE_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const updateEvidence = (
  institutionId: number,
  evidenceId: number,
  evidence: IEvidenceSendRequest,
  successMessage: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: UPDATE_INSTITUTION_EVIDENCE
  });

  try {
    const updatedEvidence = await agent.Evidence.update(institutionId, evidenceId, evidence);

    dispatch({
      type: UPDATE_INSTITUTION_EVIDENCE_SUCCESS,
      payload: {
        evidence: updatedEvidence
      }
    });

    message.success({
      content: successMessage,
      duration: 4
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: UPDATE_INSTITUTION_EVIDENCE_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const deleteEvidence = (
  institutionId: number,
  evidenceId: number,
  successMessage: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: DELETE_INSTITUTION_EVIDENCE
  });

  try {
    await agent.Evidence.delete(institutionId, evidenceId);

    dispatch({
      type: DELETE_INSTITUTION_EVIDENCE_SUCCESS,
      payload: {
        id: evidenceId
      }
    });

    message.success({
      content: successMessage,
      duration: 4
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: DELETE_INSTITUTION_EVIDENCE_FAIL,
      payload: {
        errors
      }
    });
  }
};
