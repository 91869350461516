import React, { useEffect } from 'react';
import { Layout, Grid } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { SIDE_NAV_WIDTH, SIDE_NAV_COLLAPSED_WIDTH, UserRole } from '../constants/theme';
import utils from '../utils/index';
import HeaderNav from '../components/layout/HeaderNav';
import SideNav from '../components/layout/SideNav';
import navigationConfig from '../config/NavigationConfig';
import Footer from '../components/layout/Footer';
import MobileNav from '../components/layout/mobile/MobileNav';

import { RootState } from '../redux/reducers/index';
import PageHeader from '../components/layout/shared/PageHeader';
import AdminRoutes from './admin';
import InstitutionRoutes from './institution';
import { fetchInstitutions, fetchInstitutionTypes } from '../redux/actions/institutions';
import fetchRoles from '../redux/actions/roles';
import { fetchUsers } from '../redux/actions/users';
import Spinner from '../components/layout/shared/Spinner';
import fetchSectors from '../redux/actions/sectors';
import fetchMunicipalities from '../redux/actions/municipalities';
import fetchCounties from '../redux/actions/counties';
import { fetchSubjects } from '../redux/actions/subjects';
import { fetchEvaluationPeriods } from '../redux/actions/evaluationPeriods';
import { fetchDocuments } from '../redux/actions/documents';
import {
  fetchMyInstitution,
  fetchMyInstitutionSelfEvaluation,
  fetchMyInstitutionStatistics,
  fetchMyInstitutionSubjects,
  fetchMyInstitutionUsers
} from '../redux/actions/myInstitution';
import { fetchFields } from '../redux/actions/field';
import fetchProperties from '../redux/actions/property';
import fetchSubfieldPercentageCompleted from '../redux/actions/graphicalData';
import { fetchEvidenceTemplates } from '../redux/actions/evidenceTemplate';
import { fetchSummaries } from '../redux/actions/summary';
import { fetchSummaryTemplate } from '../redux/actions/summaryTemplate';
import { fetchEvidences } from '../redux/actions/evidence';

const { Content } = Layout;
const { useBreakpoint } = Grid;

interface IProps extends RouteComponentProps {}

const MainView: React.FC<IProps> = ({ location }) => {
  const navCollapsed = useSelector((state: RootState) => state.theme.navCollapsed);
  const userRole = useSelector((state: RootState) => state.auth.role);
  const user = useSelector((state: RootState) => state.auth.user);
  const screens = utils.getBreakPoint(useBreakpoint());
  const dispatch = useDispatch();

  const fetchingCurrentUser = useSelector((state: RootState) => state.auth.fetchingCurrentUser);
  const fetchingRoles = useSelector((state: RootState) => state.role.fetchingRoles);
  const fetchingUsers = useSelector((state: RootState) => state.user.fetchingUsers);
  const fetchingInstitutions = useSelector(
    (state: RootState) => state.institution.fetchingInstitutions
  );
  const fetchingInstitutionTypes = useSelector(
    (state: RootState) => state.institution.fetchingInstitutionTypes
  );
  const fetchingSectors = useSelector((state: RootState) => state.sector.fetchingSectors);
  const fetchingMunicipalities = useSelector(
    (state: RootState) => state.municipality.fetchingMunicipalities
  );
  const fetchingCounties = useSelector((state: RootState) => state.county.fetchingCounties);
  const fetchingSubjects = useSelector((state: RootState) => state.subject.fetchingSubjects);
  const fetchingEvaluationPeriods = useSelector(
    (state: RootState) => state.evaluationPeriod.fetchingEvaluationPeriods
  );
  const fetchingDocuments = useSelector((state: RootState) => state.document.fetchingDocuments);
  const fetchingMyInstitution = useSelector(
    (state: RootState) => state.myInstitution.fetchingMyInstitution
  );
  const fetchingMyInstitutionUsers = useSelector(
    (state: RootState) => state.myInstitution.fetchingMyInstitutionUsers
  );
  const fetchingMyInstitutionSubjects = useSelector(
    (state: RootState) => state.myInstitution.fetchingMyInstitutionSubjects
  );
  const fetchingProperties = useSelector((state: RootState) => state.property.fetchingProperties);
  const fetchingInstitutionStatistics = useSelector(
    (state: RootState) => state.myInstitution.fetchingMyInstitutionStatistics
  );
  const fetchingFields = useSelector((state: RootState) => state.field.fetchingFields);
  const fetchingMyInstitutionSelfEvaluation = useSelector(
    (state: RootState) => state.myInstitution.fetchingMyInstitutionSelfEvaluation
  );
  const fetchingSubFieldPercentageCompleted = useSelector(
    (state: RootState) => state.graphs.fetchingSubFieldPercentageCompleted
  );
  const fetchingSummaries = useSelector((state: RootState) => state.summary.fetchingSummaries);
  const fetchingEvidences = useSelector((state: RootState) => state.evidence.fetchingEvidences);

  useEffect(() => {
    if (userRole === UserRole.Admin || userRole === UserRole.Director) {
      dispatch(fetchUsers());
      dispatch(fetchRoles());
      dispatch(fetchInstitutions());
      dispatch(fetchInstitutionTypes());
      dispatch(fetchSectors());
      dispatch(fetchMunicipalities());
      dispatch(fetchCounties());
      dispatch(fetchDocuments());
    }

    if (
      (userRole === UserRole.InstitutionAdmin || userRole === UserRole.InstitutionDirector) &&
      user
    ) {
      dispatch(fetchMyInstitution(user.institution.id));
      dispatch(fetchProperties());
      dispatch(fetchMyInstitutionUsers(user.institution.id));
      dispatch(fetchMyInstitutionSubjects(user.institution.id));
      dispatch(fetchMyInstitutionStatistics(user.institution.id));
      dispatch(fetchMyInstitutionSelfEvaluation(user.institution.id));
      dispatch(fetchSubfieldPercentageCompleted(user.institution.id));
      dispatch(fetchSummaries(user.institution.id));
      dispatch(fetchEvidences(user.institution.id));
    }

    dispatch(fetchSubjects());
    dispatch(fetchEvidenceTemplates());
    dispatch(fetchSummaryTemplate());
    dispatch(fetchEvaluationPeriods());
    dispatch(fetchFields());
  }, [dispatch, userRole, user]);

  const currentRouteInfo = utils.getRouteInfo(navigationConfig(userRole ?? ''), location.pathname);
  const isMobile = !screens.includes('lg');
  const isNavSide = true;
  const getLayoutGutter = () => {
    if (isMobile) return 0;

    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  if (
    (userRole === UserRole.Admin || userRole === UserRole.Director) &&
    (fetchingUsers ||
      fetchingRoles ||
      fetchingInstitutions ||
      fetchingInstitutionTypes ||
      fetchingCurrentUser ||
      fetchingSectors ||
      fetchingMunicipalities ||
      fetchingCounties ||
      fetchingSubjects ||
      fetchingDocuments ||
      fetchingEvaluationPeriods ||
      fetchingFields)
  ) {
    return <Spinner />;
  }

  if (
    user &&
    (userRole === UserRole.InstitutionAdmin || userRole === UserRole.InstitutionDirector) &&
    (fetchingMyInstitution ||
      fetchingCurrentUser ||
      fetchingMyInstitutionUsers ||
      fetchingProperties ||
      fetchingSubjects ||
      fetchingMyInstitutionSubjects ||
      fetchingInstitutionStatistics ||
      fetchingEvaluationPeriods ||
      fetchingFields ||
      fetchingMyInstitutionSelfEvaluation ||
      fetchingSubFieldPercentageCompleted ||
      fetchingEvidences ||
      fetchingSummaries)
  ) {
    return <Spinner />;
  }

  return (
    <Layout>
      <HeaderNav isMobile={isMobile} />
      <Layout className="app-container">
        {isNavSide && !isMobile ? <SideNav routeInfo={currentRouteInfo} /> : null}
        <Layout className="app-layout" style={{ paddingLeft: getLayoutGutter() }}>
          <div className="app-content">
            <PageHeader display={currentRouteInfo?.header} title={currentRouteInfo?.title} />
            <Content>
              {userRole === UserRole.Admin || userRole === UserRole.Director ? (
                <AdminRoutes />
              ) : (
                <InstitutionRoutes />
              )}
            </Content>
          </div>
          <Footer />
        </Layout>
      </Layout>
      {isMobile && <MobileNav routeInfo={currentRouteInfo} />}
    </Layout>
  );
};

export default withRouter(MainView);
