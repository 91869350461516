import { IError } from '../../api/models/error';
import { IRole } from '../../api/models/role';

export const FETCH_ROLES = 'FETCH_ROLES';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_FAIL = 'FETCH_ROLES_FAIL';

interface IFetchRoles {
  type: typeof FETCH_ROLES;
}

interface IFetchRolesSuccess {
  type: typeof FETCH_ROLES_SUCCESS;
  payload: {
    roles: IRole[];
  };
}

interface IFetchRolesFail {
  type: typeof FETCH_ROLES_FAIL;
  payload: {
    errorsFetchingRoles: IError;
  };
}

export interface IRolesState {
  roles: IRole[];
  fetchingRoles: boolean;
  errorsFetchingRoles: IError | null;
}

export type RoleActionTypes = IFetchRoles | IFetchRolesSuccess | IFetchRolesFail;
