import React from 'react';
import { Button, Row, Col } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IProps {
  showContainer?: boolean;
}

const NotFound: React.FC<IProps> = ({ showContainer = true }) => {
  const { t } = useTranslation();
  const history: any = useHistory();

  return (
    <div className={showContainer ? 'auth-container' : ''}>
      <div className="h-100 bg-white">
        <div className="container-fluid d-flex flex-column justify-content-between h-100 px-md-4 pb-md-4 pt-md-1">
          <div>
            <img className="img-fluid" src="/img/logo-akafpk.png" alt="" />
          </div>
          <div className="container">
            <Row align="middle">
              <Col xs={24} sm={24} md={8}>
                <h1 className="font-weight-bold mb-4 display-4">{t('general.page_not_found')}</h1>
                <p className="font-size-md mb-4">{t('general.page_does_not_exist')}</p>
                <Button
                  type="primary"
                  onClick={() => {
                    history.goBack();
                  }}
                  icon={<ArrowLeftOutlined />}
                >
                  {t('general.go_back')}
                </Button>
              </Col>
              <Col xs={24} sm={24} md={{ span: 14, offset: 2 }}>
                <img className="img-fluid mt-md-0 mt-4" src="/img/img-20.png" alt="" />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
