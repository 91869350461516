import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { message } from 'antd';
import { FormInstance } from 'antd/lib/form';
import {
  FETCH_EVALUATION_PERIODS,
  FETCH_EVALUATION_PERIODS_SUCCESS,
  FETCH_EVALUATION_PERIODS_FAIL,
  ADD_EVALUATION_PERIOD,
  ADD_EVALUATION_PERIOD_SUCCESS,
  ADD_EVALUATION_PERIOD_FAIL,
  UPDATE_EVALUATION_PERIOD,
  UPDATE_EVALUATION_PERIOD_SUCCESS,
  UPDATE_EVALUATION_PERIOD_FAIL,
  DELETE_EVALUATION_PERIOD,
  DELETE_EVALUATION_PERIOD_SUCCESS,
  DELETE_EVALUATION_PERIOD_FAIL,
  RESET_EVALUATION_PERIOD_ERRORS,
  EvaluationPeriodsActionTypes
} from '../constants/evaluationPeriods';
import agent from '../../api/agent';
import { RootState } from '../reducers/index';
import { IError } from '../../api/models/error';
import { IEvaluationPeriodSendRequest } from '../../api/models/evaluationPeriod';

export const fetchEvaluationPeriods = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async (dispatch) => {
  dispatch({
    type: FETCH_EVALUATION_PERIODS
  });

  try {
    const evaluationPeriods = await agent.EvaluationPeriod.list();

    dispatch({
      type: FETCH_EVALUATION_PERIODS_SUCCESS,
      payload: {
        evaluationPeriods
      }
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: FETCH_EVALUATION_PERIODS_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const addEvaluationPeriod = (
  values: IEvaluationPeriodSendRequest,
  form: FormInstance<any>,
  successMessage: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: ADD_EVALUATION_PERIOD
  });

  try {
    const evaluationPeriod = await agent.EvaluationPeriod.add({ ...values });

    dispatch({
      type: ADD_EVALUATION_PERIOD_SUCCESS,
      payload: {
        evaluationPeriod
      }
    });

    form.resetFields();

    message.success({ content: successMessage, duration: 4 });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: ADD_EVALUATION_PERIOD_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const updateEvaluationPeriod = (
  id: number,
  values: IEvaluationPeriodSendRequest,
  successMessage: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: UPDATE_EVALUATION_PERIOD
  });

  try {
    const evaluationPeriod = await agent.EvaluationPeriod.update(id, values);

    dispatch({
      type: UPDATE_EVALUATION_PERIOD_SUCCESS,
      payload: {
        evaluationPeriod
      }
    });

    message.success({ content: successMessage, duration: 4 });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: UPDATE_EVALUATION_PERIOD_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const deleteEvaluationPeriod = (
  id: number,
  successMessage: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: DELETE_EVALUATION_PERIOD
  });

  try {
    await agent.EvaluationPeriod.delete(id);

    dispatch({
      type: DELETE_EVALUATION_PERIOD_SUCCESS,
      payload: {
        id
      }
    });

    message.success({ content: successMessage, duration: 4 });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: DELETE_EVALUATION_PERIOD_FAIL,
      payload: {
        errors
      }
    });

    message.error({ content: errors?.message, duration: 4 });
  }
};

export const resetEvaluationPeriodErrors = (): EvaluationPeriodsActionTypes => {
  return {
    type: RESET_EVALUATION_PERIOD_ERRORS
  };
};
