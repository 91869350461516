/* eslint-disable no-param-reassign */
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { getEvaluationPeriodBasedOnInstitutionType } from '../../../../../api/models/evaluationPeriod';
import { IField } from '../../../../../api/models/field';
import { IInstitution } from '../../../../../api/models/institution';
import { IFieldExtractReport } from '../../../../../api/models/report';
import { IInstitutionSelfEvaluation } from '../../../../../api/models/selfEvaluation';
import {
  evaluateSelfEvaluationRating,
  generateSelfEvaluationReportResult,
  getSelEvalutationAverageRating,
  ISelfEvaluationReportResult
} from './selfEvaluationReport_helper';

interface IRow {
  current: number;
}

const startingRow = 1;
const startingColumn = 'A';

const headerCell = (worksheet: ExcelJS.Worksheet, row: IRow, value: string, bold = false) => {
  const fontHeader = { name: 'Arial', size: 14, bold };

  const cell = worksheet.getCell(`${startingColumn}${row.current}`);
  cell.font = fontHeader;
  cell.value = value;

  // worksheet.mergeCells(`${startingColumn}${row.current}:${numberToChar(5)}${row.current}`);

  row.current += 1;

  return cell;
};

const createSubFieldEvaluationTable = (
  worksheet: ExcelJS.Worksheet,
  row: IRow,
  result: ISelfEvaluationReportResult
) => {
  row.current += 2;

  headerCell(worksheet, row, 'Vetëvlerësimi sipas nënfushave', true);

  let count = 1; // also count table header

  const table = worksheet.addTable({
    name: 'vetevleresimi_sipas_nenfushave',
    ref: `${startingColumn}${row.current}`,
    columns: [{ name: 'Nënfusha' }, { name: 'Nivelet e vlerësimit' }, { name: 'Pikët' }],
    rows: [
      ...result.subFields.map(({ name, rating }, index) => {
        count += 1;

        return [
          `${index + 1}. ${name}`,
          evaluateSelfEvaluationRating(rating),
          rating?.toFixed(1) ?? ''
        ];
      })
    ]
  });

  row.current += count;

  return table;
};

const createIndicatordEvaluationTable = (
  worksheet: ExcelJS.Worksheet,
  row: IRow,
  result: ISelfEvaluationReportResult
) => {
  row.current += 2;

  headerCell(worksheet, row, 'Vetëvlerësimi sipas treguesve', true);

  let count = 1; // also count table header

  const rows: any[] = [];

  result.subFields.forEach((subField, indexOfSubField) => {
    subField.indicators.forEach((indicator, indexOfIndicator) => {
      count += 1;

      rows.push([
        `${indexOfSubField + 1}. ${subField.name}`,
        `${indexOfSubField + 1}.${indexOfIndicator + 1}. ${indicator.name}`,
        evaluateSelfEvaluationRating(indicator.rating),
        indicator?.rating?.toFixed(1) ?? ''
      ]);
    });
  });

  const table = worksheet.addTable({
    name: 'vetevleresimi_sipas_treguesve',
    ref: `${startingColumn}${row.current}`,
    columns: [
      { name: 'Nënfusha' },
      { name: 'Treguesi' },
      { name: 'Nivelet e vlerësimit' },
      { name: 'Pikët' }
    ],
    rows
  });

  row.current += count;

  return table;
};

const createCriteriaEvaluationTable = (
  worksheet: ExcelJS.Worksheet,
  row: IRow,
  result: ISelfEvaluationReportResult
) => {
  row.current += 2;

  headerCell(worksheet, row, 'Vetëvlerësimi sipas kritereve', true);

  let count = 1; // also count table header

  const rows: any[] = [];

  result.subFields.forEach((subField, indexOfSubField) => {
    subField.indicators.forEach((indicator, indexOfIndicator) => {
      indicator.criteria.forEach((criteria, indexOfCriteria) => {
        count += 1;

        rows.push([
          `${indexOfSubField + 1}. ${subField.name}`,
          `${indexOfSubField + 1}.${indexOfIndicator + 1}. ${indicator.name}`,
          `${indexOfSubField + 1}.${indexOfIndicator + 1}.${indexOfCriteria + 1}. ${criteria.name}`,
          evaluateSelfEvaluationRating(criteria.rating),
          criteria.rating?.toFixed(1) ?? '',
          criteria.description,
          criteria.evidence
        ]);
      });
    });
  });

  const table = worksheet.addTable({
    name: 'vetevleresimi_sipas_kritereve',
    ref: `${startingColumn}${row.current}`,
    columns: [
      { name: 'Nënfusha' },
      { name: 'Treguesi' },
      { name: 'Kritere' },
      { name: 'Nivelet e vlerësimit' },
      { name: 'Pikët' },
      { name: 'Komente' },
      { name: 'Evidenca' }
    ],
    rows
  });

  row.current += count;

  return table;
};

const generateSelfEvaluationExcelReport = (
  // fileName: string,
  data: IFieldExtractReport[],
  selfEvaluation: IInstitutionSelfEvaluation,
  institution: IInstitution,
  fields: IField[]
) => {
  const currentField = fields.filter((field) => field.id === data[0].fieldId)[0];
  const result = generateSelfEvaluationReportResult(currentField, data);
  const totalRating = getSelEvalutationAverageRating(data);

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet');
  const row = { current: startingRow };

  headerCell(worksheet, row, `Vetëvlerësimi ${selfEvaluation.evaluationPeriod.period}`);
  headerCell(worksheet, row, `${institution.name}`);
  headerCell(worksheet, row, `Fusha: ${data[0].fieldName}`);
  headerCell(
    worksheet,
    row,
    `${getEvaluationPeriodBasedOnInstitutionType(
      selfEvaluation.evaluationPeriod.period,
      institution.institutionType.name
    )}`
  );

  row.current += 2;

  headerCell(worksheet, row, 'Performanca e vetëvlerësimit', true);
  headerCell(
    worksheet,
    row,
    `${evaluateSelfEvaluationRating(totalRating)} ${totalRating.toFixed(1)}`,
    true
  );

  createSubFieldEvaluationTable(worksheet, row, result);

  createIndicatordEvaluationTable(worksheet, row, result);

  createCriteriaEvaluationTable(worksheet, row, result);

  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'raporti_vetëvlerësimit.xlsx');
  });
};

export default generateSelfEvaluationExcelReport;
