import {
  TeamOutlined,
  HomeOutlined,
  LineChartOutlined,
  BankOutlined,
  CalendarOutlined,
  ClusterOutlined,
  BarChartOutlined,
  PieChartOutlined,
  ContainerOutlined,
  FileTextOutlined,
  ProfileOutlined,
  ContactsOutlined,
  TrophyOutlined,
  SnippetsOutlined,
  OneToOneOutlined
} from '@ant-design/icons';
import { UserRole } from '../constants/theme';

export interface IMenuItem {
  key: string;
  path: string;
  title: string;
  icon: any;
  header: boolean;
  submenu: IMenuItem[];
}

const adminNavTree: IMenuItem[] = [
  {
    key: 'panel',
    path: '/platforma/admin',
    title: 'sidemenu.dashboard',
    icon: HomeOutlined,
    header: false,
    submenu: [
      {
        key: 'kreu',
        path: '/platforma/admin/kreu',
        title: 'sidemenu.home',
        icon: HomeOutlined,
        header: false,
        submenu: []
      },
      {
        key: 'perdorues',
        path: '/platforma/admin/perdorues',
        title: 'sidemenu.users',
        icon: TeamOutlined,
        header: true,
        submenu: []
      },
      {
        key: 'institucione',
        path: '/platforma/admin/institucione',
        title: 'sidemenu.institutions',
        icon: BankOutlined,
        header: true,
        submenu: []
      },
      {
        key: 'drejtime',
        path: '/platforma/admin/drejtime',
        title: 'sidemenu.subjects',
        icon: ClusterOutlined,
        header: true,
        submenu: []
      },
      {
        key: 'periudha',
        path: '/platforma/admin/periudha',
        title: 'sidemenu.evaluation_periods',
        icon: CalendarOutlined,
        header: true,
        submenu: []
      },
      {
        key: 'dokumenta',
        path: '/platforma/admin/dokumenta',
        title: 'sidemenu.documents',
        icon: FileTextOutlined,
        header: true,
        submenu: [
          {
            key: 'evidenca',
            path: '/platforma/admin/dokumenta/evidenca',
            title: 'sidemenu.evidence',
            icon: ContainerOutlined,
            header: true,
            submenu: []
          },
          {
            key: 'raportiVetevleresimit',
            path: '/platforma/admin/dokumenta/raporti-vetevleresimit',
            title: 'sidemenu.self_evaluation_report',
            icon: ProfileOutlined,
            header: false,
            submenu: []
          }
        ]
      }
    ]
  }
];

const directorNavTree: IMenuItem[] = [
  {
    key: 'panel',
    path: '/platforma/admin',
    title: 'sidemenu.dashboard',
    icon: HomeOutlined,
    header: false,
    submenu: [
      {
        key: 'kreu',
        path: '/platforma/admin/kreu',
        title: 'sidemenu.home',
        icon: HomeOutlined,
        header: false,
        submenu: []
      },
      {
        key: 'institucione',
        path: '/platforma/admin/institucione',
        title: 'sidemenu.institutions',
        icon: BankOutlined,
        header: true,
        submenu: []
      }
    ]
  }
];

const institutionNavTree: IMenuItem[] = [
  {
    key: 'panel',
    path: '/platforma',
    title: 'sidemenu.dashboard',
    icon: HomeOutlined,
    header: false,
    submenu: [
      {
        key: 'kreu',
        path: '/platforma/kreu',
        title: 'sidemenu.home',
        icon: HomeOutlined,
        header: false,
        submenu: []
      },
      {
        key: 'vetevleresimi',
        path: '/platforma/vetevleresimi',
        title: 'sidemenu.selfevaluation',
        icon: TeamOutlined,
        header: false,
        submenu: []
      },
      {
        key: 'institucioniim',
        path: '/platforma/institucioni-im',
        title: 'sidemenu.myinstitution',
        icon: BankOutlined,
        header: true,
        submenu: []
      }
    ]
  }
];

const reportsNavTree: IMenuItem[] = [
  {
    key: 'reports',
    path: '',
    title: 'sidemenu.reports',
    icon: HomeOutlined,
    header: false,
    submenu: [
      {
        key: 'adminStatisticsReport',
        path: '',
        title: 'sidemenu.statistics',
        icon: LineChartOutlined,
        header: false,
        submenu: [
          {
            key: 'adminStatisticsReportRegistration',
            path: '/platforma/admin/raporte/statistika/regjistrime',
            title: 'general.registration',
            icon: ContactsOutlined,
            header: false,
            submenu: []
          },
          {
            key: 'adminStatisticsReportGraduation',
            path: '/platforma/admin/raporte/statistika/diplomime',
            title: 'general.graduation',
            icon: TrophyOutlined,
            header: false,
            submenu: []
          }
        ]
      },
      {
        key: 'adminEvaluationProgressReport',
        path: '/platforma/admin/raporte/progresi-vleresimit',
        title: 'sidemenu.evaluation_progress',
        icon: BarChartOutlined,
        header: false,
        submenu: []
      },
      {
        key: 'adminEvaluationPerformanceReport',
        path: '',
        title: 'sidemenu.self_evaluation_results',
        icon: PieChartOutlined,
        header: false,
        submenu: [
          {
            key: 'adminEvaluationPerformanceSummaryReport',
            path: '/platforma/admin/raporte/rezultate/permbledhje',
            title: 'sidemenu.summary',
            icon: SnippetsOutlined,
            header: false,
            submenu: []
          },
          {
            key: 'adminEvaluationPerformanceFieldsReport',
            path: '/platforma/admin/raporte/rezultate/fusha',
            title: 'sidemenu.performance_by_fields',
            icon: OneToOneOutlined,
            header: false,
            submenu: []
          },
          {
            key: 'adminEvaluationPerformanceYearsReport',
            path: '/platforma/admin/raporte/rezultate/vite',
            title: 'sidemenu.performance_over_years',
            icon: CalendarOutlined,
            header: false,
            submenu: []
          }
        ]
      }
    ]
  }
];

const navigationConfig = (userRole: string): IMenuItem[] => {
  if (userRole === UserRole.Admin) {
    return [...adminNavTree, ...reportsNavTree];
  }

  if (userRole === UserRole.Director) {
    return [...directorNavTree, ...reportsNavTree];
  }

  return institutionNavTree;
};

export default navigationConfig;
