import React from 'react';

interface IProps {
  className?: string;
  alignItems?: string;
  flexDirection?: string;
  justifyContent?: string;
  mobileFlex?: boolean;
}

const Flex: React.FC<IProps> = ({
  children,
  className = '',
  alignItems,
  justifyContent,
  mobileFlex = true,
  flexDirection = 'row'
}) => {
  const getFlexResponsive = () => (mobileFlex ? 'd-flex' : 'd-md-flex');
  const getFlexDirection = () => (flexDirection ? ` flex-${flexDirection} ` : '');
  const getAlignItems = () => (alignItems ? ` align-items-${alignItems} ` : '');
  const getJustifyContent = () => (justifyContent ? ` justify-content-${justifyContent} ` : '');

  const computedClassName = `${getFlexResponsive()} ${className} ${getFlexDirection()}${getAlignItems()}${getJustifyContent()}`;

  return <div className={computedClassName}>{children}</div>;
};

export default Flex;
