import React from 'react';
import { Button, Card, List } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { RootState } from '../../../../../redux/reducers';
import { IInstitutionSelfEvaluation } from '../../../../../api/models/selfEvaluation';
import Utils from '../../../../../utils';
import { ISubField } from '../../../../../api/models/field';
import { UserRole } from '../../../../../constants/theme';

interface IProps {
  selfEvaluation: IInstitutionSelfEvaluation;
}

const SubFieldsList: React.FC<IProps> = ({ selfEvaluation }) => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const userRole = useSelector((state: RootState) => state.auth.role);
  const { subFields } = useSelector((state: RootState) => state.field.fields).filter(
    (field) => field.id === selfEvaluation.field.id
  )[0];

  const subfieldsPercentageCompleted = useSelector(
    (state: RootState) => state.graphs.subFieldsPercentageCompleted
  ).filter((item) => item.selfEvaluationId === selfEvaluation.id)[0];

  const renderSubFieldTitle = (index: number, subField: ISubField) => {
    let percentage = 0;

    if (subfieldsPercentageCompleted) {
      const filteredPercentage = subfieldsPercentageCompleted.percentageResponse.filter(
        (item) => item.subFieldId === subField.id
      );

      if (filteredPercentage.length > 0)
        percentage = Math.floor(+filteredPercentage[0]?.percentage);
    }

    return <List.Item.Meta title={`${index + 1}. ${subField.name} (${percentage}%)`} />;
  };

  const canEdit =
    userRole === UserRole.InstitutionAdmin &&
    Utils.isDateInRange(
      moment(selfEvaluation.evaluationPeriod.startDate),
      moment(selfEvaluation.evaluationPeriod.endDate)
    );

  return (
    <Card title={t('general.subfields')}>
      <List
        itemLayout="horizontal"
        dataSource={subFields}
        // pagination={{
        //   pageSize: 10
        // }}
        renderItem={(subField, index) => (
          <List.Item
            actions={[
              <Link to={`${match.url}/nenfusha/${subField.id}`}>
                <Button
                  type="primary"
                  className="mr-2"
                  icon={canEdit ? <EditOutlined /> : <EyeOutlined />}
                  size="small"
                >
                  {canEdit ? t('general.evaluate') : t('general.view')}
                </Button>
              </Link>
            ]}
          >
            {renderSubFieldTitle(index, subField)}
          </List.Item>
        )}
      />
    </Card>
  );
};

export default SubFieldsList;
