import React, { useState } from 'react';
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  FileTextOutlined
} from '@ant-design/icons';
import { Button, Card, Input, Popconfirm, Tooltip } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { IEvaluationPeriodDisplay } from '../../../api/models/evaluationPeriod';
import { deleteEvaluationPeriod } from '../../../redux/actions/evaluationPeriods';
import { RootState } from '../../../redux/reducers';
import utils from '../../../utils/index';
import SearchTable from '../../layout/SearchTable';
import Flex from '../../layout/shared/Flex';
import Spinner from '../../layout/shared/Spinner';
import { UserRole } from '../../../constants/theme';

const EvaluationPeriodsList = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const userRole = useSelector((state: RootState) => state.auth.role);
  const evaluationPeriods = useSelector(
    (state: RootState) => state.evaluationPeriod.evaluationPeriods
  );
  const deletingEvaluationPeriod = useSelector(
    (state: RootState) => state.evaluationPeriod.deletingEvaluationPeriod
  );

  const [search, setSearch] = useState<string | null>(null);

  const evaluationPeriodsDisplay: IEvaluationPeriodDisplay[] = evaluationPeriods.map(
    (evaluationPeriod) => {
      return {
        ...evaluationPeriod,
        documentId: evaluationPeriod?.documentId ?? null,
        startDate: moment(evaluationPeriod.startDate).format('LL'),
        endDate: moment(evaluationPeriod.endDate).format('LL'),
        schoolPeriod: `${evaluationPeriod.period - 2} - ${evaluationPeriod.period - 1}`,
        coursePeriod: `${evaluationPeriod.period - 2}`
      };
    }
  );

  const addEvaluationPeriod = () => {
    history.push(`${match.url}/shto-periudhe`);
  };

  const onHandleDelete = (id: number) => {
    dispatch(
      deleteEvaluationPeriod(id, t('feedback_message.evaluation_period_successfully_deleted'))
    );
  };

  const tableColumns: any = [
    {
      title: t('general.evaluation_period'),
      dataIndex: 'period',
      sorter: (a: number, b: number) => utils.antdTableSorter(a, b, 'period')
    },
    {
      title: t('general.school_period'),
      dataIndex: 'schoolPeriod',
      sorter: (a: string, b: string) => utils.antdTableSorter(a, b, 'schoolPeriod')
    },
    {
      title: t('general.course_period'),
      dataIndex: 'coursePeriod',
      sorter: (a: string, b: string) => utils.antdTableSorter(a, b, 'coursePeriod')
    },
    {
      title: t('general.calendar'),
      dataIndex: 'documentLink',
      align: 'center',
      render: (_: any, row: IEvaluationPeriodDisplay) => {
        const { documentId } = row;

        if (documentId) {
          return (
            <FileTextOutlined
              style={{ fontSize: '25px', color: '#3e82f7' }}
              onClick={() => utils.downloadDocument(documentId)}
            />
          );
        }

        return null;
      }
    },
    {
      title: t('general.start_date'),
      dataIndex: 'startDate'
    },
    {
      title: t('general.end_date'),
      dataIndex: 'endDate'
    },
    {
      title: '',
      dataIndex: 'actions',
      render: (_: any, row: IEvaluationPeriodDisplay) => {
        if (userRole !== UserRole.Admin) return null;

        return (
          <Flex justifyContent="center">
            <Tooltip title={t('general.edit')} placement="bottom">
              <Link to={`${match.url}/ndrysho-periudhe/${row.id}`}>
                <Button type="primary" className="mr-2" icon={<EditOutlined />} size="small" />
              </Link>
            </Tooltip>

            <Tooltip title={t('general.delete')} placement="bottom">
              <Popconfirm
                title={t('feedback_message.are_you_sure')}
                okText={t('general.yes')}
                cancelText={t('general.no')}
                onConfirm={() => onHandleDelete(row.id)}
              >
                <Button danger icon={<DeleteOutlined />} size="small" />
              </Popconfirm>
            </Tooltip>
          </Flex>
        );
      }
    }
  ];

  if (deletingEvaluationPeriod) return <Spinner />;

  return (
    <Card>
      <Flex justifyContent="between" mobileFlex={false}>
        <Flex className="mb-1" mobileFlex={false}>
          <Flex className="mr-md-3 mb-3">
            <Input
              placeholder={t('general.search')}
              prefix={<SearchOutlined />}
              onChange={(e) => setSearch(e.currentTarget.value)}
            />
          </Flex>
        </Flex>
        <Flex>
          {userRole === UserRole.Admin && (
            <Button
              onClick={addEvaluationPeriod}
              type="primary"
              icon={<PlusCircleOutlined />}
              block
            >
              {t('general.add_evaluation_period')}
            </Button>
          )}
        </Flex>
      </Flex>

      <SearchTable
        tableColumns={tableColumns}
        dataSource={evaluationPeriodsDisplay}
        search={search}
      />
    </Card>
  );
};

export default EvaluationPeriodsList;
