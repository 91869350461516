import {
  FETCH_FIELDS,
  FETCH_FIELDS_SUCCESS,
  FETCH_FIELDS_FAIL,
  FETCH_SUBFIELDS,
  FETCH_SUBFIELDS_SUCCESS,
  FETCH_SUBFIELDS_FAIL,
  IFieldsState,
  FieldActionTypes
} from '../constants/field';

const initialState: IFieldsState = {
  fields: [],
  subFields: [],
  fetchingFields: false,
  fetchingSubFields: false,
  errors: null
};

const fieldReducer = (state = initialState, action: FieldActionTypes): IFieldsState => {
  switch (action.type) {
    case FETCH_FIELDS:
      return {
        ...state,
        fetchingFields: true
      };
    case FETCH_FIELDS_SUCCESS:
      return {
        ...state,
        fields: action.payload.fields,
        errors: null,
        fetchingFields: false
      };
    case FETCH_FIELDS_FAIL:
      return {
        ...state,
        fields: [],
        errors: action.payload.errors,
        fetchingFields: false
      };
    case FETCH_SUBFIELDS:
      return {
        ...state,
        fetchingSubFields: true
      };
    case FETCH_SUBFIELDS_SUCCESS:
      return {
        ...state,
        subFields: action.payload.subFields,
        errors: null,
        fetchingSubFields: false
      };
    case FETCH_SUBFIELDS_FAIL:
      return {
        ...state,
        fields: [],
        errors: action.payload.errors,
        fetchingSubFields: false
      };
    default:
      return state;
  }
};

export default fieldReducer;
