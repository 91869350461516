import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Input, Popconfirm, Row, Tooltip } from 'antd';
import {
  DeleteOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  FileTextOutlined,
  ArrowLeftOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../redux/reducers';
import utils from '../../../utils';
import Flex from '../../layout/shared/Flex';
import SearchTable from '../../layout/SearchTable';
import AppBreadCrumb from '../../layout/AppBreadCrumb';
import SelfEvaluationPeriodForm from './SelfEvaluationPeriodForm';
import { IInstitutionSelfEvaluationDisplay } from '../../../api/models/selfEvaluation';
import {
  deleteMyInstitutionSelfEvaluation,
  showInstitutionSelfEvaluationForm
} from '../../../redux/actions/myInstitution';
import Spinner from '../../layout/shared/Spinner';
import { getEvaluationPeriodBasedOnInstitutionType } from '../../../api/models/evaluationPeriod';
import { InstitutionTypeName, UserRole } from '../../../constants/theme';

const SelfEvaluationPeriodList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const userRole = useSelector((state: RootState) => state.auth.role);
  const selfEvaluations = useSelector((state: RootState) => state.myInstitution.selfEvaluation);
  const institution = useSelector((state: RootState) => state.myInstitution.institution);
  const modalVisibility = useSelector(
    (state: RootState) => state.myInstitution.showMyInstitutionSelfEvaluationForm
  );
  const deletingSelfEvaluation = useSelector(
    (state: RootState) => state.myInstitution.deletingMyInstitutionSelfEvaluation
  );

  const institutionSelfEvaluationDisplay: IInstitutionSelfEvaluationDisplay[] = selfEvaluations.map(
    (selfEvaluation) => {
      return {
        id: selfEvaluation.id,
        fieldDescription: selfEvaluation.field.name,
        evaluationPeriodDescription: getEvaluationPeriodBasedOnInstitutionType(
          selfEvaluation.evaluationPeriod.period,
          institution?.institutionType.name ?? ''
        ).toString(),
        evaluationPeriodStartDate: moment(selfEvaluation.evaluationPeriod.startDate).format('LL'),
        evaluationPeriodEndDate: moment(selfEvaluation.evaluationPeriod.endDate).format('LL'),
        documentId: selfEvaluation.evaluationPeriod.documentId,
        hasResponses: selfEvaluation.hasResponses
      };
    }
  );

  const tableColumns = [
    {
      title: t('general.field'),
      dataIndex: 'fieldDescription',
      width: '25%',
      sorter: (a: string, b: string) => utils.antdTableSorter(a, b, 'fieldDescription')
    },
    {
      title: t('general.evaluation_period'),
      dataIndex: 'evaluationPeriodDescription',
      width: '25%',
      sorter: (a: string, b: string) => utils.antdTableSorter(a, b, 'evaluationPeriodDescription')
    },
    {
      title: t('general.calendar'),
      dataIndex: 'documentLink',
      align: 'center',
      render: (_: any, row: IInstitutionSelfEvaluationDisplay) => {
        if (row.documentId) {
          return (
            <FileTextOutlined
              style={{ fontSize: '25px', color: '#3e82f7' }}
              onClick={() => {
                const { documentId } = row;

                if (documentId) utils.downloadDocument(documentId);
              }}
            />
          );
        }

        return null;
      }
    },
    {
      title: t('general.start_date'),
      dataIndex: 'evaluationPeriodStartDate',
      width: '25%',
      sorter: (a: string, b: string) => utils.antdTableSorter(a, b, 'evaluationPeriodDescription')
    },
    {
      title: t('general.end_date'),
      dataIndex: 'evaluationPeriodEndDate',
      width: '25%',
      sorter: (a: string, b: string) => utils.antdTableSorter(a, b, 'evaluationPeriodDescription')
    },
    {
      title: '',
      dataIndex: 'actions',
      width: '25%',
      render: (_: any, row: IInstitutionSelfEvaluationDisplay) => {
        // Only admin can delete & only self evaluation without data can be deleted
        if (userRole !== UserRole.InstitutionAdmin || row.hasResponses) return null;

        return (
          <Flex justifyContent="center">
            <Tooltip title={t('general.delete')} placement="bottom">
              <Popconfirm
                title={t('feedback_message.are_you_sure')}
                okText={t('general.yes')}
                cancelText={t('general.no')}
                onConfirm={() => {
                  if (institution)
                    dispatch(
                      deleteMyInstitutionSelfEvaluation(
                        institution?.id,
                        row.id,
                        t(
                          'feedback_message.institution_self_evaluation_period_successfully_deleted'
                        )
                      )
                    );
                }}
              >
                <Button danger icon={<DeleteOutlined />} size="small" />
              </Popconfirm>
            </Tooltip>
          </Flex>
        );
      }
    }
  ];

  const [search, setSearch] = useState<string | null>(null);

  if (deletingSelfEvaluation) return <Spinner />;

  return (
    <Row>
      <AppBreadCrumb
        items={[
          { title: t('sidemenu.selfevaluation'), path: '../vetevleresimi' },
          {
            title:
              institution?.institutionType.name === InstitutionTypeName.SHKOLLE
                ? t('general.academic_year')
                : t('general.calendar_year'),
            path: null
          }
        ]}
      />
      <Col xs={24}>
        <Card style={{ marginTop: '25px' }}>
          <Flex justifyContent="between" mobileFlex={false}>
            <Flex className="mb-1" mobileFlex={false}>
              <Flex className="mr-md-3 mb-3">
                <Input
                  placeholder={t('general.search')}
                  prefix={<SearchOutlined />}
                  onChange={(e) => setSearch(e.currentTarget.value)}
                />
              </Flex>
            </Flex>
            <Flex>
              {userRole === UserRole.InstitutionAdmin && (
                <Button
                  onClick={() => dispatch(showInstitutionSelfEvaluationForm())}
                  type="primary"
                  icon={<PlusCircleOutlined />}
                  block
                >
                  {t('general.add_field')}
                </Button>
              )}
            </Flex>
          </Flex>

          <SearchTable
            tableColumns={tableColumns}
            dataSource={institutionSelfEvaluationDisplay}
            search={search}
          />
        </Card>
      </Col>

      <SelfEvaluationPeriodForm visible={modalVisibility} />

      <Col>
        <Button
          type="primary"
          htmlType="submit"
          icon={<ArrowLeftOutlined />}
          onClick={() => history.goBack()}
        >
          {t('general.go_back')}
        </Button>
      </Col>
    </Row>
  );
};

export default SelfEvaluationPeriodList;
