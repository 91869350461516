import React from 'react';
import { Layout } from 'antd';
import { useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { SIDE_NAV_WIDTH } from '../../constants/theme';
import MenuContent from './MenuContent';

import { RootState } from '../../redux/reducers/index';

const { Sider } = Layout;

interface IProps {
  routeInfo: any;
}

const SideNav: React.FC<IProps> = ({ routeInfo }) => {
  const navCollapsed = useSelector((state: RootState) => state.theme.navCollapsed);

  return (
    <Sider className="side-nav" width={SIDE_NAV_WIDTH} collapsed={navCollapsed}>
      <Scrollbars autoHide>
        <MenuContent routeInfo={routeInfo} />
      </Scrollbars>
    </Sider>
  );
};

export default SideNav;
