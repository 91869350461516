import {
  FETCH_INSTITUTION_EVIDENCE,
  FETCH_INSTITUTION_EVIDENCE_SUCCESS,
  FETCH_INSTITUTION_EVIDENCE_FAIL,
  ADD_INSTITUTION_EVIDENCE,
  ADD_INSTITUTION_EVIDENCE_SUCCESS,
  ADD_INSTITUTION_EVIDENCE_FAIL,
  UPDATE_INSTITUTION_EVIDENCE,
  UPDATE_INSTITUTION_EVIDENCE_SUCCESS,
  UPDATE_INSTITUTION_EVIDENCE_FAIL,
  DELETE_INSTITUTION_EVIDENCE,
  DELETE_INSTITUTION_EVIDENCE_SUCCESS,
  DELETE_INSTITUTION_EVIDENCE_FAIL,
  IEvidenceState,
  EvidenceActionTypes
} from '../constants/evidence';

const initialState: IEvidenceState = {
  evidences: [],
  fetchingEvidences: false,
  addingEvidence: false,
  updatingEvidence: false,
  deletingEvidence: false,
  errors: null
};

const evidenceReducer = (state = initialState, action: EvidenceActionTypes): IEvidenceState => {
  switch (action.type) {
    case FETCH_INSTITUTION_EVIDENCE:
      return {
        ...state,
        fetchingEvidences: true
      };
    case FETCH_INSTITUTION_EVIDENCE_SUCCESS:
      return {
        ...state,
        evidences: action.payload.evidences,
        errors: null,
        fetchingEvidences: false
      };
    case FETCH_INSTITUTION_EVIDENCE_FAIL:
      return {
        ...state,
        evidences: [],
        fetchingEvidences: false,
        errors: action.payload.errors
      };
    case ADD_INSTITUTION_EVIDENCE:
      return {
        ...state,
        addingEvidence: true
      };
    case ADD_INSTITUTION_EVIDENCE_SUCCESS:
      return {
        ...state,
        evidences: [
          ...state.evidences.filter((evidence) => evidence.id !== action.payload.evidence.id),
          action.payload.evidence
        ],
        addingEvidence: false,
        errors: null
      };
    case ADD_INSTITUTION_EVIDENCE_FAIL:
      return {
        ...state,
        addingEvidence: false,
        errors: action.payload.errors
      };
    case UPDATE_INSTITUTION_EVIDENCE:
      return {
        ...state,
        updatingEvidence: true
      };
    case UPDATE_INSTITUTION_EVIDENCE_SUCCESS:
      return {
        ...state,
        updatingEvidence: false,
        evidences: state.evidences.map((evidence) =>
          evidence.id === action.payload.evidence.id ? action.payload.evidence : evidence
        ),
        errors: null
      };
    case UPDATE_INSTITUTION_EVIDENCE_FAIL:
      return {
        ...state,
        updatingEvidence: false,
        errors: action.payload.errors
      };
    case DELETE_INSTITUTION_EVIDENCE:
      return {
        ...state,
        deletingEvidence: true
      };
    case DELETE_INSTITUTION_EVIDENCE_SUCCESS:
      return {
        ...state,
        deletingEvidence: false,
        evidences: state.evidences.filter((evidence) => evidence.id !== action.payload.id),
        errors: null
      };
    case DELETE_INSTITUTION_EVIDENCE_FAIL:
      return {
        ...state,
        deletingEvidence: false,
        errors: action.payload.errors
      };
    default:
      return state;
  }
};

export default evidenceReducer;
