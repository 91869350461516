import { AxiosResponse } from 'axios';
import { message } from 'antd';
import moment from 'moment';
import agent from '../api/agent';
import { IError } from '../api/models/error';

class Utils {
  static getBreakPoint(screens: any) {
    const breakpoints: any[] = [];

    Object.keys(screens).forEach((key) => {
      const element = screens[key];

      if (element) {
        breakpoints.push(key);
      }
    });

    return breakpoints;
  }

  static getRouteInfo(navTree: any, path: string): any {
    if (navTree.path === path) {
      return navTree;
    }

    let route;

    Object.keys(navTree).forEach((p) => {
      if (typeof navTree[p] === 'object' && this.getRouteInfo(navTree[p], path)) {
        route = this.getRouteInfo(navTree[p], path);
      }
    });

    return route;
  }

  static deleteArrayRow(list: any, key: string, value: any) {
    let data = list;
    if (list) {
      data = list.filter((item: any) => item[key] !== value);
    }
    return data;
  }

  static antdTableSorter(a: any, b: any, key: string) {
    if (typeof a[key] === 'number' && typeof b[key] === 'number') {
      return a[key] - b[key];
    }

    if (typeof a[key] === 'string' && typeof b[key] === 'string') {
      const value1 = a[key].toLowerCase();
      const value2 = b[key].toLowerCase();

      if (value1 > value2) return -1;
      if (value2 > value1) return 1;
      return 0;
    }

    if (typeof a[key] === 'boolean' && typeof b[key] === 'boolean') {
      if (a > b) return -1;
      if (b > a) return 1;
      return 0;
    }

    return null;
  }

  static wildCardSearch(list: any, input: number | string) {
    const searchText = (item: any) => {
      let flag = false;

      Object.keys(item).forEach((key) => {
        if (item[key] == null) {
          return;
        }

        if (item[key].toString().toUpperCase().indexOf(input.toString().toUpperCase()) !== -1) {
          flag = true;
        }
      });

      return flag;
    };

    return list.filter((value: any) => searchText(value));
  }

  static downloadDocument = async (id: number) => {
    try {
      const response: AxiosResponse = await agent.Document.download(id);

      const contentDisposition = response.headers['content-disposition'];
      const filename: string = contentDisposition
        .split(';')[1]
        .split('filename')[1]
        .split('=')[1]
        .trim()
        .split('"')
        .join('');

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      const errors: IError = error?.data ?? error;

      message.error({ content: errors?.message ?? error, duration: 4 });
    }
  };

  static getProperty = (obj: any, prop: any) => {
    return obj[prop];
  };

  static setProperty = (obj: any, prop: any, value: any) => {
    const object = obj;

    object[prop] = value;
  };

  static isDateInRange = (startDate: moment.Moment, endDate: moment.Moment) => {
    const today = moment();

    if (today.isBetween(startDate, endDate, 'days', '[]')) return true;

    return false;
  };

  static isDateBefore = (endDate: moment.Moment) => {
    const today = moment();

    if (today.isBefore(endDate, 'day')) return true;

    return false;
  };
}

export default Utils;
