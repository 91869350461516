import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetAuthErrors } from '../../redux/actions/auth';
import { resetDocumentErrors } from '../../redux/actions/documents';
import { resetEvaluationPeriodErrors } from '../../redux/actions/evaluationPeriods';
import { resetEvidenceTemplateErrors } from '../../redux/actions/evidenceTemplate';
import { resetInstitutionErrors } from '../../redux/actions/institutions';
import { resetMyInstitutionErrors } from '../../redux/actions/myInstitution';
import { resetSubjectErrors } from '../../redux/actions/subjects';
import { resetInstitutionSummaryErrors } from '../../redux/actions/summary';
import { resetUserErrors } from '../../redux/actions/users';
import { RootState } from '../../redux/reducers';

const HistoryListener = ({ children }: any) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const myInstitutionErrors = useSelector((state: RootState) => state.myInstitution.errors);
  const documentErrors = useSelector((state: RootState) => state.document.errors);
  const evaluationPeriodErrors = useSelector((state: RootState) => state.evaluationPeriod.errors);
  const evidenceTemplateErrors = useSelector((state: RootState) => state.evidenceTemplate.errors);
  const addInstitutionErrors = useSelector(
    (state: RootState) => state.institution.errorsAddingInstitution
  );
  const updateInstitutionErrors = useSelector(
    (state: RootState) => state.institution.errorsUpdatingInstitution
  );
  const addInstitutionSubjectErrors = useSelector(
    (state: RootState) => state.institution.errorsAddingInstitutionSubject
  );
  const updateInstitutionSubjectErrors = useSelector(
    (state: RootState) => state.institution.errorsUpdatingInstitutionSubject
  );

  const addSubjectErrors = useSelector((state: RootState) => state.subject.errorsAddingSubject);
  const updateSubjectErrors = useSelector(
    (state: RootState) => state.subject.errorsUpdatingSubject
  );
  const institutionSummaryErrors = useSelector((state: RootState) => state.summary.errors);

  const addUserErrors = useSelector((state: RootState) => state.user.errorsAddingUser);
  const updateUserErrors = useSelector((state: RootState) => state.user.errorsUpdatingUser);
  const updateCurrentUserErrors = useSelector(
    (state: RootState) => state.auth.updateCurrentUserErrors
  );

  useEffect(() => {
    return history.listen(() => {
      // console.log('New location: ', location.pathname);

      if (myInstitutionErrors) dispatch(resetMyInstitutionErrors());
      if (documentErrors) dispatch(resetDocumentErrors());
      if (evaluationPeriodErrors) dispatch(resetEvaluationPeriodErrors());
      if (evidenceTemplateErrors) dispatch(resetEvidenceTemplateErrors());
      if (
        addInstitutionErrors ||
        updateInstitutionErrors ||
        addInstitutionSubjectErrors ||
        updateInstitutionSubjectErrors
      )
        dispatch(resetInstitutionErrors());

      if (addSubjectErrors || updateSubjectErrors) dispatch(resetSubjectErrors());
      if (institutionSummaryErrors) dispatch(resetInstitutionSummaryErrors());
      if (addUserErrors || updateUserErrors) dispatch(resetUserErrors());
      if (updateCurrentUserErrors) dispatch(resetAuthErrors());
    });
  }, [
    history,
    myInstitutionErrors,
    documentErrors,
    evaluationPeriodErrors,
    evidenceTemplateErrors,
    addInstitutionErrors,
    updateInstitutionErrors,
    addInstitutionSubjectErrors,
    updateInstitutionSubjectErrors,
    addSubjectErrors,
    updateSubjectErrors,
    institutionSummaryErrors,
    addUserErrors,
    updateUserErrors,
    updateCurrentUserErrors,
    dispatch
  ]);

  return children;
};

export default HistoryListener;
