import React from 'react';
import { Card, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
  HomeOutlined,
  ForkOutlined,
  ApartmentOutlined,
  BankOutlined
} from '@ant-design/icons';
import Icon from '../../layout/Icon';
import { RootState } from '../../../redux/reducers';
import { UserRole } from '../../../constants/theme';

interface IUserDataProps {
  iconType: any;
  dataKey: string;
  dataValue?: string | null;
}

const UserDataInfo: React.FC<IUserDataProps> = ({ iconType, dataKey, dataValue = '' }) => {
  return (
    <Row className="mb-4">
      <Col xs={12} sm={12} md={9}>
        <Icon type={iconType} className="text-primary font-size-md" />
        <span className="text-muted ml-2">{`${dataKey}:`}</span>
      </Col>
      <Col xs={12} sm={12} md={15}>
        <span className="font-weight-semibold">{dataValue}</span>
      </Col>
    </Row>
  );
};

const HomeInfo: React.FC = () => {
  const { t } = useTranslation();
  const institution = useSelector((state: RootState) => state.myInstitution.institution);
  const institutionMembers = useSelector((state: RootState) => state.myInstitution.users);

  const director = institutionMembers.filter(
    (member) => member.role.name === UserRole.InstitutionDirector
  )[0];

  return (
    <Row gutter={16}>
      <Col xs={24} xl={12}>
        <Card title={t('general.myinstitution')}>
          <UserDataInfo
            iconType={HomeOutlined}
            dataKey={t('general.institution')}
            dataValue={institution?.name ?? ''}
          />

          <UserDataInfo
            iconType={ApartmentOutlined}
            dataKey={t('general.type')}
            dataValue={institution?.institutionType.description ?? ''}
          />

          {/* <UserDataInfo
            iconType={ShopOutlined}
            dataKey={t('general.county')}
            dataValue={institution?.municipality.name}
          /> */}

          <UserDataInfo
            iconType={BankOutlined}
            dataKey={t('general.municipality')}
            dataValue={institution?.municipality.name}
          />

          <UserDataInfo
            iconType={ForkOutlined}
            dataKey={t('general.sector')}
            dataValue={institution?.sector.description ?? ''}
          />

          <UserDataInfo
            iconType={UserOutlined}
            dataKey={t('general.director')}
            dataValue={`${director?.firstName ?? ''} ${director?.lastName ?? ''}`}
          />

          <UserDataInfo
            iconType={MailOutlined}
            dataKey={t('general.email')}
            dataValue={institution?.email ?? ''}
          />

          <UserDataInfo
            iconType={PhoneOutlined}
            dataKey={t('general.phone')}
            dataValue={institution?.phone ?? ''}
          />
        </Card>
      </Col>
      <Col xs={24} xl={12}>
        <Card title={t('general.product_description')}>
          <p>
            Sigurimi i cilësisë së shërbimit arsimor mbetet një përparësi e institucioneve ofruese
            të arsimit dhe formimit profesional (IoAFP-të). Përvojat e suksesshme bashkëkohore,
            dëshmojnë se përmbushja e këtij qëllimi realizohet atëherë kur IoAFP-të zhvillojnë
            vetëvlerësimin e tyre, i cili konsiderohet i suksesshëm kur përputhet plotësisht me të
            dhënat e vlerësimit të jashtëm, që gjithnjë kryhet nga institucione të specializuara dhe
            të akredituara shtetërore ose joshtetërore. Në këtë kontekst, zhvillimi i proceseve të
            vetëvlerësimit nga IoAFP-të e bën më cilësor shërbimin që ato ofrojnë. Ndaj, procesi i
            vetëvlerësimit/vlerësimit të brendshëm të IoAFP-ve kërkon përdorimin e një metodologjie
            shkencore, të zbatueshme, të besueshme dhe bashkëkohore, e cila siguron të dhëna reale
            dhe mundësi për të pasqyruar pikat e forta, pikat e dobëta, mundësitë që IoAFP-ja ka për
            të ofruar kualifikime profesionale cilësore, por veçanarisht, nevojat për përmirësimin e
            saj në të ardhmen. Përhapja e kulturës së vetëvlerësimit është një rrugë profesionale
            dhe sfiduese që kërkon njohje dhe zotërim të standardeve profesionale dhe etike të
            vlerësimit, angazhim dhe përgjegjësi të të gjithë personave të përfshirë në vlerësim, të
            mësuesve/instruktorëve, të nxënësve/kursantëve, të ekipeve të vlerësimit, por dhe të
            autoriteteve drejtues dhe organizmave të IoAFP-së.
          </p>
        </Card>
      </Col>
    </Row>
  );
};

export default HomeInfo;
