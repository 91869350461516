import _ from 'lodash';
import { IField } from '../../../../../api/models/field';
import { IFieldExtractReport } from '../../../../../api/models/report';

interface ICustomSubfield {
  id: number;
  name: string;
  rating: number | null;
}

interface ICustomIndicator {
  id: number;
  subField: {
    id: number;
    name: string;
  };
  name: string;
  rating: number | null;
}

interface ICriteria {
  // id: number;
  name: string;
  rating: number;
  description: string;
  evidence: string;
}

interface IIndicator {
  id: number;
  name: string;
  rating: number | null;
  criteria: ICriteria[];
}

interface ISubField {
  id: number;
  name: string;
  rating: number | null;
  indicators: IIndicator[];
}

export interface ISelfEvaluationReportResult {
  fieldId: number;
  fieldName: string;
  subFields: ISubField[];
}

export const evaluateSelfEvaluationRating = (data: number | null) => {
  if (data === null) return '';

  const value = Math.round(data);

  switch (value) {
    case 1:
      return 'Shumë mirë';
    case 2:
      return 'Mirë';
    case 3:
      return 'Mjaftueshëm';
    case 4:
      return 'Dobët';
    default:
      return 'Pa vlerësim';
  }
};

export const getSelEvalutationAverageRating = (data: IFieldExtractReport[]) => {
  const responsesWithValue = data.filter(({ rating }) => rating !== null && rating !== 0).length;

  const totalRating =
    responsesWithValue === 0
      ? 0
      : data.reduce((acc, curr) => {
          return acc + (curr.rating ?? 0);
        }, 0) / responsesWithValue;

  return totalRating;
};

export const generateSelfEvaluationReportResult = (
  currentField: IField,
  data: IFieldExtractReport[]
) => {
  const subFields: ICustomSubfield[] = [];
  const indicators: ICustomIndicator[] = [];

  const groupedIndicators = _.groupBy(data, 'indicatorId');

  // Group and calculate rating on indicator level
  Object.keys(groupedIndicators).forEach((property) => {
    const count = groupedIndicators[property].filter(
      ({ rating }) => rating !== null && rating !== 0
    ).length;

    const { subFieldId, subFieldName, indicatorId, indicator } = groupedIndicators[property][0];
    let totalPoints = 0;

    groupedIndicators[property].forEach((item) => {
      totalPoints += item.rating ?? 0;
    });

    const rating = count === 0 ? null : totalPoints / count;

    indicators.push({
      id: indicatorId,
      name: indicator,
      subField: {
        id: subFieldId,
        name: subFieldName
      },
      rating
    });
  });

  // Group and calculate rating on sub field level
  currentField.subFields.forEach((subField) => {
    let count = 0;
    let totalPoints = 0;

    data.forEach((item) => {
      if (item.subFieldId === subField.id) {
        if (item.rating !== null && item.rating !== 0) {
          count += 1;
        }

        totalPoints += item.rating ?? 0;
      }
    });

    const rating = count === 0 ? null : totalPoints / count;

    subFields.push({
      id: subField.id,
      name: subField.name,
      rating
    });
  });

  const result: ISelfEvaluationReportResult = {
    fieldId: currentField.id,
    fieldName: currentField.name,
    subFields: []
  };

  // Generate final model used to generate report
  subFields.forEach((subField) => {
    const tempIndicators: IIndicator[] = [];

    indicators.forEach((indicator) => {
      const tempCriteria: ICriteria[] = [];

      data.forEach((criteria) => {
        if (criteria.indicatorId === indicator.id) {
          tempCriteria.push({
            name: criteria.question,
            rating: criteria.rating,
            description: criteria?.description ?? '',
            evidence: criteria?.evidence ?? ''
          });
        }
      });

      if (indicator.subField.id === subField.id) {
        tempIndicators.push({
          id: indicator.id,
          name: indicator.name,
          rating: indicator.rating,
          criteria: tempCriteria
        });
      }
    });

    result.subFields.push({
      id: subField.id,
      name: subField.name,
      rating: subField.rating,
      indicators: tempIndicators
    });
  });

  return result;
};
