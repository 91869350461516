import React, { useState } from 'react';
import { Form, Card, List, InputNumber, Row, Col, Collapse, Popconfirm, Button } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ISubject } from '../../../../api/models/subject';
import { RootState } from '../../../../redux/reducers';
import { IInstitutionStatistics } from '../../../../api/models/statistic';
import { InstitutionTypeName, UserRole } from '../../../../constants/theme';
import Utils from '../../../../utils';
import {
  addInstitutionSubject,
  deleteInstitutionSubject
} from '../../../../redux/actions/institutions';
import SubjectsDrawer from './subjects/AddSubjectsDrawer';
import { IInstitutionSubject } from '../../../../api/models/institution';

interface IProps {
  evaluationPeriodId: number;
  allSubjects: ISubject[];
  institutionSubjects: IInstitutionSubject[];
  institutionStatistics: IInstitutionStatistics | null;
}

const hasStatistics = (
  institutionSubject: IInstitutionSubject,
  institutionStatistics: IInstitutionStatistics | null
) => {
  if (!institutionStatistics) {
    return false;
  }

  const subject = institutionStatistics.subjects.filter(
    (item) => item.id === institutionSubject.id
  )[0];

  if (subject) {
    return true;
  }

  return false;
};

const YearlyStatisticalSubjectDataCard: React.FC<IProps> = ({
  evaluationPeriodId,
  allSubjects,
  institutionSubjects,
  institutionStatistics
}) => {
  const { Panel } = Collapse;
  const { t } = useTranslation();
  const institution = useSelector((state: RootState) => state.myInstitution.institution);
  const userRole = useSelector((state: RootState) => state.auth.role);
  const isMobile = !Utils.getBreakPoint(useBreakpoint()).includes('sm');
  const isUpdatingMyInstitutionSubjects = useSelector(
    (state: RootState) => state.myInstitution.updatingMyInstitutionSubjects
  );
  const dispatch = useDispatch();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const availableSubjects = allSubjects.filter(
    (subject) =>
      !institutionSubjects.find(
        (institutionSubject) => institutionSubject.subject.id === subject.id
      )
  );

  const title =
    institution?.institutionType.name === InstitutionTypeName.QENDER_FORMIMI_PROFESIONAL
      ? t('general.courses')
      : t('sidemenu.subjects');

  const addSubjectTitle =
    institution?.institutionType.name === InstitutionTypeName.QENDER_FORMIMI_PROFESIONAL
      ? t('general.add_institution_course')
      : t('general.add_institution_subject');

  const canModifyInstitutionSubjects = () => {
    return userRole === UserRole.InstitutionAdmin || userRole === UserRole.Admin;
  };

  const canDeleteInstitutionSubject = (item: IInstitutionSubject) => {
    return canModifyInstitutionSubjects() && !hasStatistics(item, institutionStatistics);
  };

  const onDeleteInstitutionSubject = (item: IInstitutionSubject) => {
    dispatch(
      deleteInstitutionSubject(
        institution!!.id,
        item.id,
        t('feedback_message.institution_subject_successfully_deleted')
      )
    );
  };

  const renderAddInstitutionSubjectButton = () => {
    if (canModifyInstitutionSubjects()) {
      return (
        <Button
          onClick={(event) => {
            event.stopPropagation();
            setDrawerVisible(true);
          }}
          loading={isUpdatingMyInstitutionSubjects}
          disabled={availableSubjects.length === 0}
          type="primary"
          icon={<PlusCircleOutlined />}
          block
        >
          {addSubjectTitle}
        </Button>
      );
    }

    return null;
  };

  const renderDeleteInstitutionSubject = (item: IInstitutionSubject) => {
    return (
      <Col
        xs={24}
        md={4}
        style={{
          display: isMobile ? '' : 'flex',
          justifyContent: isMobile ? 'normal' : 'flex-end'
        }}
      >
        <Popconfirm
          title={t('feedback_message.are_you_sure')}
          okText={t('general.yes')}
          cancelText={t('general.no')}
          onConfirm={() => onDeleteInstitutionSubject(item)}
        >
          <Button danger icon={<DeleteOutlined />} size="small" />
        </Popconfirm>
      </Col>
    );
  };

  const renderDataCard = (item: IInstitutionSubject) => {
    return (
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            label={t('general.total')}
            name={`${item.id}_subject_registration`}
            style={{ marginBottom: 0 }}
          >
            <InputNumber
              size="small"
              className="w-100"
              min={0}
              style={{ border: '1px solid #3e82f7' }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            label={t('general.graduation')}
            name={`${item.id}_subject_graduation`}
            // rules={[
            //   ({ getFieldValue }) => ({
            //     validator(_, value) {
            //       if (!value || getFieldValue(`${item.id}_subject_registration`) >= value) {
            //         return Promise.resolve();
            //       }
            //       return Promise.reject(
            //         t('validation_messages.graduation_greater_than_registration')
            //       );
            //     }
            //   })
            // ]}
            // style={{ marginBottom: 0 }}
          >
            <InputNumber
              size="small"
              className="w-100"
              min={0}
              style={{ border: '1px solid #3e82f7' }}
            />
          </Form.Item>
        </Col>
      </Row>
    );
  };

  const renderReadOnlyDataCard = (item: IInstitutionSubject) => {
    if (institutionStatistics) {
      const result = institutionStatistics.subjects.filter((data) => data.id === item.id)[0];

      // TODO: Fix styles by allowing word-wrapping and centering vertically
      return (
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <div style={{ display: 'flex' }}>
              <span className="ant-descriptions-item-label">{t('general.total')}</span>
              <span className="ant-descriptions-item-content">{result?.registration ?? 0}</span>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div style={{ display: 'flex' }}>
              <span className="ant-descriptions-item-label text-wrap">
                {t('general.graduation')}
              </span>
              <span className="ant-descriptions-item-content">{result?.graduation ?? 0}</span>
            </div>
          </Col>
        </Row>
      );
    }

    return null;
  };

  return (
    <>
      <Card style={{ marginTop: '25px' }}>
        <Collapse bordered={false}>
          <Panel header={title} key="1" extra={renderAddInstitutionSubjectButton()}>
            <List
              dataSource={institutionSubjects}
              renderItem={(item) => (
                <List.Item style={{ paddingLeft: 24, paddingRight: 24 }}>
                  <Row align="middle" className="w-100">
                    <Col xs={24} md={6}>
                      {item.subject.name}
                    </Col>
                    <Col xs={24} md={{ span: 10, offset: 4 }}>
                      {userRole === UserRole.InstitutionAdmin
                        ? renderDataCard(item)
                        : renderReadOnlyDataCard(item)}
                    </Col>
                    {canDeleteInstitutionSubject(item)
                      ? renderDeleteInstitutionSubject(item)
                      : null}
                  </Row>
                </List.Item>
              )}
            />
          </Panel>
        </Collapse>
      </Card>
      <SubjectsDrawer
        onAddSubject={(subjectId) => {
          dispatch(
            addInstitutionSubject(
              institution!!.id,
              {
                evaluationPeriodId: [evaluationPeriodId],
                subjectId
              },
              t('feedback_message.institution_subject_successfully_added')
            )
          );
        }}
        title={addSubjectTitle}
        visible={drawerVisible}
        loading={isUpdatingMyInstitutionSubjects}
        onClose={() => setDrawerVisible(false)}
        availableSubjects={availableSubjects}
      />
    </>
  );
};

export default YearlyStatisticalSubjectDataCard;
