import React from 'react';
import { Drawer } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { ArrowLeftOutlined } from '@ant-design/icons';
import MenuContent from '../MenuContent';
import { onMobileNavToggle } from '../../../redux/actions/theme';
import Logo from '../Logo';
import Flex from '../shared/Flex';

import { RootState } from '../../../redux/reducers/index';

interface IProps {
  routeInfo: any;
}

const MobileNav: React.FC<IProps> = ({ routeInfo }) => {
  const mobileNav = useSelector((state: RootState) => state.theme.mobileNav);
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(onMobileNavToggle(false));
  };

  return (
    <Drawer
      placement="left"
      closable={false}
      onClose={onClose}
      visible={mobileNav}
      bodyStyle={{ padding: 5 }}
    >
      <Flex flexDirection="column" className="h-100">
        <Flex justifyContent="between" alignItems="center">
          <Logo mobileLogo />
          <div
            className="nav-close"
            onClick={() => onClose()}
            role="button"
            onKeyDown={() => {}}
            tabIndex={0}
          >
            <ArrowLeftOutlined aria-label="Close Navigation" />
          </div>
        </Flex>
        <div className="h-100">
          <Scrollbars autoHide>
            <MenuContent routeInfo={routeInfo} />
          </Scrollbars>
        </div>
      </Flex>
    </Drawer>
  );
};

export default MobileNav;
