import React from 'react';
import { Form, Card, List, InputNumber, Row, Col, Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IProperty, ISubProperty } from '../../../../api/models/property';
import { RootState } from '../../../../redux/reducers';
import { IInstitutionStatistics } from '../../../../api/models/statistic';
import { UserRole } from '../../../../constants/theme';

interface IProps {
  property: IProperty;
  institutionStatistics: IInstitutionStatistics | null;
}

const YearlyStatisticalPropertyDataCard: React.FC<IProps> = ({
  property,
  institutionStatistics
}) => {
  const { t } = useTranslation();
  const { Panel } = Collapse;
  const userRole = useSelector((state: RootState) => state.auth.role);

  const renderDataCard = (subProperty: ISubProperty) => {
    return (
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            label={t('general.total')}
            name={`${subProperty.id}_property_registration`}
            style={{ marginBottom: 0 }}
          >
            <InputNumber
              size="small"
              className="w-100"
              min={0}
              style={{ border: '1px solid #3e82f7' }}
            />
          </Form.Item>
        </Col>
        {subProperty.hasGraduation && (
          <Col xs={24} md={12}>
            <Form.Item
              label={t('general.graduation')}
              name={`${subProperty.id}_property_graduation`}
              style={{ marginBottom: 0 }}
            >
              <InputNumber
                size="small"
                className="w-100"
                min={0}
                style={{ border: '1px solid #3e82f7' }}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
    );
  };

  const renderReadOnlyDataCard = (subProperty: ISubProperty) => {
    if (institutionStatistics) {
      const result = institutionStatistics.properties.filter(
        (data) => data.subPropertyId === subProperty.id
      )[0];

      return (
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <div style={{ display: 'flex' }}>
              <span className="ant-descriptions-item-label">{t('general.total')}</span>
              <span className="ant-descriptions-item-content">{result?.registration ?? 0}</span>
            </div>
          </Col>
          {subProperty.hasGraduation && (
            <Col xs={24} md={12}>
              <div style={{ display: 'flex' }}>
                <span className="ant-descriptions-item-label">{t('general.graduation')}</span>
                <span className="ant-descriptions-item-content">{result?.graduation ?? 0}</span>
              </div>
            </Col>
          )}
        </Row>
      );
    }

    return null;
  };

  return (
    <Card style={{ marginTop: '25px' }}>
      <Collapse bordered={false}>
        <Panel header={property.description} key={property.id}>
          <List
            dataSource={property.subProperties}
            renderItem={(subProperty) => (
              <List.Item>
                <Row className="w-100" align="middle" style={{ paddingLeft: 24 }}>
                  <Col xs={24} md={6}>
                    {subProperty.description}
                  </Col>
                  <Col xs={24} md={{ span: 10, offset: 4 }}>
                    {userRole === UserRole.InstitutionAdmin
                      ? renderDataCard(subProperty)
                      : renderReadOnlyDataCard(subProperty)}
                  </Col>
                </Row>
              </List.Item>
            )}
          />
        </Panel>
      </Collapse>
    </Card>
  );
};

export default YearlyStatisticalPropertyDataCard;
