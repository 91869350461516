import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import html2canvas from 'html2canvas';
import moment from 'moment';
import { Card, Col, message, Row, Table } from 'antd';
import Header from '../../../../components/admin/reports/StatisticsReportHeader';
import { RootState } from '../../../../redux/reducers';
import StatisticalData from '../../../../components/admin/reports/StatisticalData';
import {
  IChartSeries,
  IProgressReport,
  IReportImage,
  IStatisticalDataWidget
} from '../../../../api/models/report';
import HorizontalBarChart from '../../../../components/admin/charts/HorizontalBarChart';
import VerticalBarChart from '../../../../components/admin/charts/VerticalBarChart';
import { IEvaluationPeriod } from '../../../../api/models/evaluationPeriod';
import { IError } from '../../../../api/models/error';
import Spinner from '../../../../components/layout/shared/Spinner';
import agent from '../../../../api/agent';
import generatePDFReport from '../pdfReport';
import generateExcelReport from '../excelReport';
import utils from '../../../../utils';

const EvaluationProgressReport = () => {
  const { t } = useTranslation();

  const [evaluationPeriod, setEvaluationPeriod] = useState<null | IEvaluationPeriod>(null);
  const [loadingResults, setLoadingResults] = useState<boolean>(true);
  const [results, setResults] = useState<IProgressReport | null>(null);
  const [resultForInstitutionByFields, setResultForInstitutionByFields] = useState<any[]>([]);
  const [resultForInstitutions, setResultForInstitutions] = useState<IChartSeries[]>([]);
  const [resultForCounties, setResultForCounties] = useState<IChartSeries[]>([]);
  const [resultForFields, setResultForFields] = useState<IChartSeries[]>([]);
  const [statistics, setStatistics] = useState<IStatisticalDataWidget[]>([]);
  const [generatingPDF, setGeneratingPDF] = useState<boolean>(false);
  const [generatingExcel, setGeneratingExcel] = useState<boolean>(false);

  const evaluationProgressImage1 = useRef<HTMLDivElement>(null);
  const evaluationProgressImage2 = useRef<HTMLDivElement>(null);

  const evaluationPeriods = useSelector(
    (state: RootState) => state.evaluationPeriod.evaluationPeriods
  );

  const getResults = useCallback(async () => {
    let result = null;

    if (evaluationPeriod) {
      setLoadingResults(true);

      result = await agent.Report.getProgress(evaluationPeriod.id);

      setLoadingResults(false);
    }

    setResults(result);
  }, [evaluationPeriod]);

  const generateChartsDataSource = useCallback(async () => {
    if (results) {
      setStatistics(results.statistics);

      setResultForInstitutionByFields(
        results.resultForInstitutionByFields.map(({ field, institution, completed }, index) => ({
          id: index.toString(),
          field,
          institution,
          completed: Math.floor(parseFloat(completed.toString()))
        }))
      );

      setResultForInstitutions([
        {
          name: 'Institucioni',
          data: results.resultForInstitutions.map(({ institution, completed }) => ({
            x: institution,
            y: Math.floor(parseFloat(completed.toString()))
          }))
        }
      ]);

      setResultForCounties([
        {
          name: 'Qarku',
          data: results.resultForCounties.map(({ name, completed }) => ({
            x: name,
            y: Math.floor(parseFloat(completed.toString()))
          }))
        }
      ]);

      setResultForFields([
        {
          name: 'Fusha',
          data: results.resultForFields.map(({ name, completed }) => ({
            x: name,
            y: Math.floor(parseFloat(completed.toString()))
          }))
        }
      ]);
    }
  }, [results]);

  const selectEvaluationPeriod = useCallback(() => {
    if (evaluationPeriods.length > 0) {
      const currentPeriod = evaluationPeriods.filter(
        (el) => el.period === new Date().getFullYear()
      )[0];

      if (currentPeriod) return currentPeriod;

      return evaluationPeriods[0];
    }

    return null;
  }, [evaluationPeriods]);

  useEffect(() => {
    setEvaluationPeriod(selectEvaluationPeriod());
  }, [evaluationPeriods, selectEvaluationPeriod]);

  useEffect(() => {
    try {
      getResults();
    } catch (error) {
      const errors: IError = error?.data;
      setLoadingResults(false);
      message.error({ content: errors?.message, duration: 4 });
    }
  }, [evaluationPeriod, getResults]);

  useEffect(() => {
    generateChartsDataSource();
  }, [generateChartsDataSource, results]);

  const tableColumns: any = [
    {
      title: t('general.field'),
      dataIndex: 'field',
      key: 'field',
      sorter: (a: string, b: string) => utils.antdTableSorter(a, b, 'field')
    },
    {
      title: t('general.institution'),
      dataIndex: 'institution',
      key: 'institution',
      sorter: (a: string, b: string) => utils.antdTableSorter(a, b, 'institution')
    },
    {
      title: `${t('general.progress')} %`,
      dataIndex: 'completed',
      key: 'completed',
      align: 'right'
    }
  ];

  const generateExcel = async () => {
    try {
      if (evaluationPeriod) {
        setGeneratingExcel(true);
        const result = await agent.Report.getProgressExcel();

        const datasource: any[] = result.data
          .filter((el) => el.year === evaluationPeriod.period)
          .map(
            ({
              county,
              institution,
              year,
              startdate,
              enddate,
              field,
              subfield,
              indicator,
              criteria,
              completed,
              rating,
              description,
              evidence
            }) => {
              return [
                county,
                institution,
                year,
                `${moment(startdate).format('DD/MM/YYYY')} - ${moment(enddate).format(
                  'DD/MM/YYYY'
                )}`,
                field,
                subfield,
                indicator,
                criteria,
                `${completed} %`,
                rating,
                description,
                evidence
              ];
            }
          );

        generateExcelReport(
          'raport_ecuria_vetevleresimit',
          datasource,
          'evaluation_progress_data_table',
          [
            { name: 'Qark' },
            { name: 'Institucioni Arsimor' },
            { name: 'Viti i vlerësimit' },
            { name: 'Periudha e vlerësimit' },
            { name: 'Fusha' },
            { name: 'Nënfusha' },
            { name: 'Treguesi' },
            { name: 'Kriteri' },
            { name: 'Plotësuar' },
            { name: 'Vlerësimi me pikë' },
            { name: 'Komente' },
            { name: 'Evidenca' }
          ]
        );

        setGeneratingExcel(false);
      }
    } catch (error) {
      const errors: IError = error?.data;
      setGeneratingExcel(false);
      message.error({ content: errors?.message, duration: 4 });
    }
  };

  const generatePDF = async () => {
    const images: IReportImage[] = [];
    const reportTitle = `${t('general.progress_evaluation_report')} ${evaluationPeriod?.period}`;

    const firstImage = {
      element: evaluationProgressImage1.current
    };

    const secondImage = {
      element: evaluationProgressImage2.current
    };

    try {
      setGeneratingPDF(true);
      window.scrollTo(0, 0);

      if (firstImage && firstImage.element) {
        const canvas = await html2canvas(firstImage.element, { scrollX: -7 });

        images.push({ url: canvas.toDataURL('image/png') });
      }

      if (secondImage && secondImage.element) {
        const canvas = await html2canvas(secondImage.element, { scrollX: -7 });

        images.push({ url: canvas.toDataURL('image/png') });
      }

      generatePDFReport(images, reportTitle, 'raport_ecuria_vetevleresimit');
      setGeneratingPDF(false);
    } catch (error) {
      setGeneratingPDF(false);
    }
  };

  if (!evaluationPeriod) return <h3>{t('general.no_evaluation_period')}</h3>;

  if (loadingResults) return <Spinner />;

  return (
    <>
      <Header
        title={`${t('general.progress_evaluation_report')} ${new Date().getFullYear()}`}
        generatePDF={generatePDF}
        generateExcel={generateExcel}
        generatingPDF={generatingPDF}
        generatingExcel={generatingExcel}
        subtitle={`${t('general.period')} ${moment(evaluationPeriod?.startDate).format(
          'DD/MM/YYYY'
        )} - ${moment(evaluationPeriod?.endDate).format('DD/MM/YYYY')}`}
      />

      <Row gutter={16}>
        <Col xs={24} ref={evaluationProgressImage1}>
          <Row gutter={16} justify="space-between" align="middle">
            <StatisticalData data={statistics} />

            <Col xs={24}>
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Card
                    title={t('general.self_evaluation_progress_by_institution')}
                    className="ant-card-default-border"
                  >
                    <Table
                      className="no-border-last"
                      columns={tableColumns}
                      dataSource={resultForInstitutionByFields}
                      rowKey="id"
                      pagination={{
                        pageSize: 10,
                        showSizeChanger: false
                      }}
                    />
                  </Card>
                </Col>
                <Col xs={24} sm={12}>
                  <Card title={`${t('general.progress_in')} %`} className="ant-card-default-border">
                    <VerticalBarChart percentage height={620} series={resultForInstitutions} />
                  </Card>
                </Col>
              </Row>
              <Card
                title={`${t('general.progress_by_counties')} (%)`}
                className="ant-card-default-border"
              >
                <HorizontalBarChart percentage series={resultForCounties} />
              </Card>
            </Col>
          </Row>
        </Col>

        <Col xs={24} ref={evaluationProgressImage2}>
          <Card
            title={`${t('general.institutions_by_selected_fields')}`}
            className="ant-card-default-border"
          >
            <HorizontalBarChart series={resultForFields} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default EvaluationProgressReport;
