import { IError } from '../../api/models/error';
import { IEvaluationPeriod } from '../../api/models/evaluationPeriod';

export const FETCH_EVALUATION_PERIODS = 'FETCH_EVALUATION_PERIODS';
export const FETCH_EVALUATION_PERIODS_SUCCESS = 'FETCH_EVALUATION_PERIODS_SUCCESS';
export const FETCH_EVALUATION_PERIODS_FAIL = 'FETCH_EVALUATION_PERIODS_FAIL';
export const ADD_EVALUATION_PERIOD = 'ADD_EVALUATION_PERIOD';
export const ADD_EVALUATION_PERIOD_SUCCESS = 'ADD_EVALUATION_PERIOD_SUCCESS';
export const ADD_EVALUATION_PERIOD_FAIL = 'ADD_EVALUATION_PERIOD_FAIL';
export const UPDATE_EVALUATION_PERIOD = 'UPDATE_EVALUATION_PERIOD';
export const UPDATE_EVALUATION_PERIOD_SUCCESS = 'UPDATE_EVALUATION_PERIOD_SUCCESS';
export const UPDATE_EVALUATION_PERIOD_FAIL = 'UPDATE_EVALUATION_PERIOD_FAIL';
export const DELETE_EVALUATION_PERIOD = 'DELETE_EVALUATION_PERIOD';
export const DELETE_EVALUATION_PERIOD_SUCCESS = 'DELETE_EVALUATION_PERIOD_SUCCESS';
export const DELETE_EVALUATION_PERIOD_FAIL = 'DELETE_EVALUATION_PERIOD_FAIL';
export const RESET_EVALUATION_PERIOD_ERRORS = 'RESET_EVALUATION_PERIOD_ERRORS';

export interface IEvaluationPeriods {
  evaluationPeriods: IEvaluationPeriod[];
  fetchingEvaluationPeriods: boolean;
  addingEvaluationPeriod: boolean;
  updatingEvaluationPeriod: boolean;
  deletingEvaluationPeriod: boolean;
  errors: IError | null;
}

/** ************************** Fetch evaluation periods *************************** */
interface IFetchEvaluationPeriods {
  type: typeof FETCH_EVALUATION_PERIODS;
}

interface IFetchEvaluationPeriodsSuccess {
  type: typeof FETCH_EVALUATION_PERIODS_SUCCESS;
  payload: {
    evaluationPeriods: IEvaluationPeriod[];
  };
}

interface IFetchEvaluationPeriodsFail {
  type: typeof FETCH_EVALUATION_PERIODS_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Add evaluation period *************************** */
interface IAddEvaluationPeriod {
  type: typeof ADD_EVALUATION_PERIOD;
}

interface IAddEvaluationPeriodSuccess {
  type: typeof ADD_EVALUATION_PERIOD_SUCCESS;
  payload: {
    evaluationPeriod: IEvaluationPeriod;
  };
}

interface IAddEvaluationPeriodFail {
  type: typeof ADD_EVALUATION_PERIOD_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Update evaluation period *************************** */
interface IUpdateEvaluationPeriod {
  type: typeof UPDATE_EVALUATION_PERIOD;
}

interface IUpdateEvaluationPeriodSuccess {
  type: typeof UPDATE_EVALUATION_PERIOD_SUCCESS;
  payload: {
    evaluationPeriod: IEvaluationPeriod;
  };
}

interface IUpdateEvaluationPeriodFail {
  type: typeof UPDATE_EVALUATION_PERIOD_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Delete evaluation period *************************** */
interface IDeleteEvaluationPeriod {
  type: typeof DELETE_EVALUATION_PERIOD;
}

interface IDeleteEvaluationPeriodSuccess {
  type: typeof DELETE_EVALUATION_PERIOD_SUCCESS;
  payload: {
    id: number;
  };
}

interface IDeleteEvaluationPeriodFail {
  type: typeof DELETE_EVALUATION_PERIOD_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Reset evaluation period errors *************************** */
interface IResetEvaluationPeriodErrors {
  type: typeof RESET_EVALUATION_PERIOD_ERRORS;
}

export type EvaluationPeriodsActionTypes =
  | IFetchEvaluationPeriods
  | IFetchEvaluationPeriodsSuccess
  | IFetchEvaluationPeriodsFail
  | IAddEvaluationPeriod
  | IAddEvaluationPeriodSuccess
  | IAddEvaluationPeriodFail
  | IUpdateEvaluationPeriod
  | IUpdateEvaluationPeriodSuccess
  | IUpdateEvaluationPeriodFail
  | IDeleteEvaluationPeriod
  | IDeleteEvaluationPeriodSuccess
  | IDeleteEvaluationPeriodFail
  | IResetEvaluationPeriodErrors;
