import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { TFunction } from 'i18next';
import { message } from 'antd';
import { Store } from 'redux';
import {
  IChangePasswordRequest,
  ILoginRequest,
  ILoginResponse,
  IResetPasswordEmail,
  IResetPasswordRequest
} from './models/auth';
import { ICounty } from './models/county';
import {
  IInstitution,
  IInstitutionSendRequest,
  IInstitutionType,
  IInstitutionSubject,
  IInstitutionSubjectSendRequest,
  IInstitutionSubjectResponse
} from './models/institution';
import { IMunicipality } from './models/municipality';
import { IRole } from './models/role';
import { ISector } from './models/sector';
import { IUser, IUserSendRequest } from './models/user';
import {
  IField,
  ISelfEvaluationResponse,
  ISendRequestSelfEvaluationResponse,
  ISubFieldComplete
} from './models/field';
import { ISubject, ISubjectSendRequest } from './models/subject';
import { IEvaluationPeriod, IEvaluationPeriodSendRequest } from './models/evaluationPeriod';
import { IDocument } from './models/document';
import { IProperty } from './models/property';
import { IInstitutionStatistics } from './models/statistic';
import {
  IInstitutionSelfEvaluation,
  IInstitutionSelfEvaluationRequest
} from './models/selfEvaluation';
import { ISubfieldPercentageCompleted, ISubfieldQuantityCompleted } from './models/graphicalData';
import {
  IFieldExtractReport,
  IProgressReport,
  IProgressReportExcel,
  IResultByFieldsReport,
  IResultByYearsReport,
  IResultSummaryReport,
  IStatisticsDataReport,
  IStatisticsReportExcel
} from './models/report';
import {
  IEvidenceTemplateSendRequest,
  IEvidenceTemplate,
  IEvidenceTemplateUpdateRequest
} from './models/evidenceTemplate';
import { IEvidence, IEvidenceSendRequest } from './models/evidence';
import { ISummary, ISummarySendRequest, ISummaryUpdateRequest } from './models/summary';
import { ISummaryTemplate, ISummaryTemplateSendRequest } from './models/summaryTemplate';
import { logout } from '../redux/actions/auth';

const { REACT_APP_URL } = process.env;

const AxiosInitiation = (store: Store<any, any>, history: any, t: TFunction) => {
  axios.defaults.baseURL = REACT_APP_URL;
  axios.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const configuration = config;

      const token = window.localStorage.getItem('token');
      if (token) configuration.headers.Authorization = `Bearer ${token}`;
      return configuration;
    },
    (error: AxiosError) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      if (error.message === 'Network Error' && !error.response) {
        message.error({ content: t('general.network_error'), duration: 8 });

        store.dispatch(logout());
      }

      const status = error.response?.status;

      if (status === 404) {
        history.push('/404');
      }

      if (status === 500) {
        history.push('/500');
      }

      if (status === 403 && error.response?.data.code === 'token_invalid') {
        message.error({ content: t('general.expired_session'), duration: 8 });

        store.dispatch(logout());
      }

      throw error.response;
    }
  );
};

const responseData = (response: AxiosResponse) => response?.data;

const request = {
  get: (url: string, params: any = undefined) => axios.get(url, { params }).then(responseData),
  post: (url: string, body: {}) => axios.post(url, body).then(responseData),
  put: (url: string, body: {}) => axios.put(url, body).then(responseData),
  delete: (url: string) => axios.delete(url).then(responseData),
  downloadDocument: (url: string) =>
    axios.get(url, {
      responseType: 'blob'
    }),
  uploadDocument: async (url: string, file: string | Blob, config: AxiosRequestConfig) => {
    const formData = new FormData();
    formData.append('file', file);

    const response = await axios.post(url, formData, {
      ...config,
      headers: { 'content-type': 'multipart/form-data' }
    });

    return responseData(response);
  }
};

const Auth = {
  login: (userData: ILoginRequest): Promise<ILoginResponse> => request.post('auth/login', userData),
  changePassword: (data: IChangePasswordRequest) => request.post('auth/password/change/', data),
  resetPasswordRequest: (email: IResetPasswordEmail) => request.post('auth/password/reset/', email),
  resetPasswordConfirm: (data: IResetPasswordRequest) =>
    request.post('auth/password/reset/confirm/', data),
  updateCurrentUser: (id: number, user: IUserSendRequest): Promise<IUser> =>
    request.put(`/user/${id}`, user)
};

const User = {
  current: (id: number): Promise<IUser> => request.get(`/user/${id}`),
  update: (id: number, user: IUserSendRequest): Promise<IUser> => request.put(`/user/${id}`, user),
  add: (user: IUserSendRequest): Promise<IUser> => request.post('/user/', user),
  delete: (id: number) => request.delete(`/user/${id}`),
  list: (): Promise<IUser[]> => request.get('/user/')
};

const Role = {
  list: (): Promise<IRole[]> => request.get('/role/')
};

const Sector = {
  list: (): Promise<ISector[]> => request.get('/sector/')
};

const Institution = {
  list: (): Promise<IInstitution[]> => request.get('/institution/'),
  add: (institution: IInstitutionSendRequest): Promise<IInstitution> =>
    request.post('/institution/', institution),
  update: (id: number, institution: IInstitutionSendRequest): Promise<IInstitution> =>
    request.put(`/institution/${id}`, institution),
  delete: (id: number): Promise<any> => request.delete(`/institution/${id}`),
  listTypes: (): Promise<IInstitutionType[]> => request.get('/institutionType/'),
  getById: (id: number): Promise<IInstitution> => request.get(`/institution/${id}`),
  listUsers: (id: number): Promise<IUser[]> => request.get(`/institution/${id}/users/`),
  listSubjects: (id: number): Promise<IInstitutionSubject[]> =>
    request.get(`/institution/${id}/subjects/`),
  getInstitutionSubjectById: (
    institutionId: number,
    institutionSubjectId: number
  ): Promise<IInstitutionSubjectResponse> =>
    request.get(`institution/${institutionId}/subjects/${institutionSubjectId}`),
  listSubjectsForInstitution: (id: number): Promise<IInstitutionSubjectResponse[]> =>
    request.get(`/institution/${id}/subjects/`),
  addSubjects: (
    institutionId: number,
    institutionSubject: IInstitutionSubjectSendRequest
  ): Promise<IInstitutionSubjectResponse[]> =>
    request.post(`/institution/${institutionId}/subjects/`, institutionSubject),
  updateSubject: (
    institutionId: number,
    institutionSubjectId: number,
    institutionSubject: IInstitutionSubjectSendRequest
  ): Promise<IInstitutionSubjectResponse> =>
    request.put(
      `/institution/${institutionId}/subjects/${institutionSubjectId}`,
      institutionSubject
    ),
  deleteSubject: (institutionId: number, institutionSubjectId: number): Promise<any> =>
    request.delete(`/institution/${institutionId}/subjects/${institutionSubjectId}`)
};

const InstitutionSelfEvaluation = {
  list: (id: number): Promise<IInstitutionSelfEvaluation[]> =>
    request.get(`/institution/${id}/selfEvaluation`),
  update: (
    id: number,
    data: IInstitutionSelfEvaluationRequest
  ): Promise<IInstitutionSelfEvaluation> => request.post(`/institution/${id}/selfEvaluation`, data),
  delete: (institutionId: number, selfEvaluationId: number): Promise<any> =>
    request.delete(`/institution/${institutionId}/selfEvaluation/${selfEvaluationId}`),
  saveResponse: (
    id: number,
    data: ISendRequestSelfEvaluationResponse
  ): Promise<ISelfEvaluationResponse> => request.post(`/institution/${id}/responses`, data),
  listResponse: (
    institutionId: number,
    selfEvaluationId: number,
    subFieldId: number
  ): Promise<ISelfEvaluationResponse[]> =>
    request.get(
      `/institution/${institutionId}/responses?selfEvaluationId=${selfEvaluationId}&subFieldId=${subFieldId}`
    )
};

const Fields = {
  list: (): Promise<IField[]> => request.get('/field'),
  listFieldSubfields: (id: number): Promise<ISubFieldComplete[]> =>
    request.get(`/field/${id}/subfield/`)
};

const Municipality = {
  list: (): Promise<IMunicipality[]> => request.get('/municipality/')
};

const County = {
  list: (): Promise<ICounty[]> => request.get('/county/')
};

const Subject = {
  get: (id: number): Promise<ISubject> => request.get(`/subject/${id}`),
  list: (): Promise<ISubject[]> => request.get('/subject/'),
  add: (subject: ISubjectSendRequest): Promise<ISubject> => request.post('/subject/', subject),
  update: (id: number, subject: ISubjectSendRequest) => request.put(`/subject/${id}`, subject),
  delete: (id: number): Promise<any> => request.delete(`subject/${id}`)
};

const EvaluationPeriod = {
  get: (id: number): Promise<IEvaluationPeriod> => request.get(`/evaluationPeriods/${id}`),
  list: (): Promise<IEvaluationPeriod[]> => request.get('/evaluationPeriods'),
  update: (id: number, evaluationPeriod: IEvaluationPeriodSendRequest) =>
    request.put(`/evaluationPeriods/${id}`, evaluationPeriod),
  add: (evaluationPeriod: IEvaluationPeriodSendRequest): Promise<IEvaluationPeriod> =>
    request.post('/evaluationPeriods/', evaluationPeriod),
  delete: (id: number): Promise<any> => request.delete(`/evaluationPeriods/${id}`)
};

const Document = {
  list: (): Promise<IDocument[]> => request.get('/document/'),
  download: (id: number): Promise<AxiosResponse> =>
    request.downloadDocument(`/document/${id}/download`),
  upload: (file: string | Blob, config: AxiosRequestConfig): Promise<IDocument> =>
    request.uploadDocument('/document/upload/', file, config)
};

const EvidenceTemplate = {
  list: (): Promise<IEvidenceTemplate[]> => request.get('/evidenceTemplate/'),
  addEvidenceTemplate: (evidence: IEvidenceTemplateSendRequest): Promise<IEvidenceTemplate> =>
    request.post('/evidenceTemplate/', evidence),
  updateEvidenceTemplate: (
    id: number,
    evidence: IEvidenceTemplateUpdateRequest
  ): Promise<IEvidenceTemplate> => request.put(`/evidenceTemplate/${id}`, evidence),
  deleteEvidenceTemplate: (id: number) => request.delete(`/evidenceTemplate/${id}`)
};

const Evidence = {
  list: (institutionId: number): Promise<IEvidence[]> =>
    request.get(`institution/${institutionId}/evidences`),
  add: (institutionId: number, evidence: IEvidenceSendRequest): Promise<IEvidence> =>
    request.post(`institution/${institutionId}/evidences`, evidence),
  update: (
    institutionId: number,
    evidenceId: number,
    evidence: IEvidenceSendRequest
  ): Promise<IEvidence> =>
    request.put(`institution/${institutionId}/evidences/${evidenceId}`, evidence),
  delete: (institutionId: number, evidenceId: number): Promise<any> =>
    request.delete(`institution/${institutionId}/evidences/${evidenceId}`)
};

const SummaryTemplate = {
  list: (): Promise<ISummaryTemplate[]> => request.get('/finalReportTemplate/'),
  addOrUpdateSummaryTemplate: (template: ISummaryTemplateSendRequest): Promise<ISummaryTemplate> =>
    request.post('/finalReportTemplate/', template)
};

const Summary = {
  list: (institutionId: number): Promise<ISummary> =>
    request.get(`institution/${institutionId}/finalReport`),
  add: (institutionId: number, summary: ISummarySendRequest): Promise<ISummary> =>
    request.post(`institution/${institutionId}/finalReport`, summary),
  update: (
    institutionId: number,
    summaryId: number,
    summary: ISummaryUpdateRequest
  ): Promise<ISummary> =>
    request.put(`institution/${institutionId}/finalReport/${summaryId}`, summary),
  delete: (institutionId: number, summaryId: number): Promise<any> =>
    request.delete(`institution/${institutionId}/evidences/${summaryId}`)
};

const Property = {
  list: (): Promise<IProperty[]> => request.get('/properties/')
};

const Statistic = {
  updateInstitutionStatistics: (
    id: number,
    statistics: IInstitutionStatistics
  ): Promise<IInstitutionStatistics> => request.post(`/institution/${id}/statistics`, statistics),
  fetchInstitutionStatistics: (institutionId: number): Promise<IInstitutionStatistics[]> =>
    request.get(`/institution/${institutionId}/statistics`)
};

const GraphicalData = {
  listInstitutionSubfieldPercentageCompleted: (
    id: number
  ): Promise<ISubfieldPercentageCompleted[]> =>
    request.get(`/institution/${id}/responses/percentage`),
  listInstitutionSubfieldQuantityCompleted: (id: number): Promise<ISubfieldQuantityCompleted[]> =>
    request.get(`/institution/${id}/responses/quantity`)
};

const Report = {
  listFieldsData: (
    institutionId: number,
    selEvaluationId: number
  ): Promise<IFieldExtractReport[]> =>
    request.get(`/institution/${institutionId}/selfEvaluation/${selEvaluationId}/generateReport/`),
  getStatistics: (intitutionType: number): Promise<IStatisticsDataReport> =>
    request.get(`/reports/statistics?institutionTypeId=${intitutionType}`),
  getSummary: (evaluationPeriod: number): Promise<IResultSummaryReport> =>
    request.get(`/reports/performanceSummary?evaluationPeriodId=${evaluationPeriod}`),
  getPerformanceByFields: (
    evaluationPeriodId: number,
    subFieldId: number | undefined
  ): Promise<IResultByFieldsReport> =>
    request.get('/reports/performanceByFields', { evaluationPeriodId, subFieldId }),
  getPerformanceByYears: (): Promise<IResultByYearsReport> =>
    request.get('/reports/performanceByYears'),
  getProgress: (evaluationPeriod: number): Promise<IProgressReport> =>
    request.get(`/reports/progress?evaluationPeriodId=${evaluationPeriod}`),
  getProgressExcel: (): Promise<IProgressReportExcel> =>
    request.get(`/reports/excelProgressReport`),
  getStatisticsExcel: (): Promise<IStatisticsReportExcel> =>
    request.get(`/reports/excelStatisticsReport`)
};

export default {
  AxiosInitiation,
  Auth,
  User,
  Role,
  Sector,
  Municipality,
  County,
  Institution,
  Fields,
  Subject,
  EvaluationPeriod,
  Document,
  Property,
  Statistic,
  InstitutionSelfEvaluation,
  GraphicalData,
  Report,
  Evidence,
  Summary,
  EvidenceTemplate,
  SummaryTemplate
};
