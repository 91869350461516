import { IError } from '../../api/models/error';
import { IField, ISubFieldComplete } from '../../api/models/field';

export const FETCH_FIELDS = 'FETCH_FIELDS';
export const FETCH_FIELDS_SUCCESS = 'FETCH_FIELDS_SUCCESS';
export const FETCH_FIELDS_FAIL = 'FETCH_FIELDS_FAIL';
export const FETCH_SUBFIELDS = 'FETCH_SUBFIELDS';
export const FETCH_SUBFIELDS_SUCCESS = 'FETCH_SUBFIELDS_SUCCESS';
export const FETCH_SUBFIELDS_FAIL = 'FETCH_SUBFIELDS_FAIL';

export interface IFieldsState {
  fields: IField[];
  subFields: ISubFieldComplete[];
  fetchingFields: boolean;
  fetchingSubFields: boolean;
  errors: IError | null;
}

/** ************************** Fetch fields *************************** */
interface IFetchFields {
  type: typeof FETCH_FIELDS;
}

interface IFetchFieldsSuccess {
  type: typeof FETCH_FIELDS_SUCCESS;
  payload: {
    fields: IField[];
  };
}

interface IFetchFieldsFail {
  type: typeof FETCH_FIELDS_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Fetch field subfields *************************** */
interface IFetchFieldSubfields {
  type: typeof FETCH_SUBFIELDS;
}

interface IFetchFieldSubfieldsSuccess {
  type: typeof FETCH_SUBFIELDS_SUCCESS;
  payload: {
    subFields: ISubFieldComplete[];
  };
}

interface IFetchFieldSubfieldsFail {
  type: typeof FETCH_SUBFIELDS_FAIL;
  payload: {
    errors: IError;
  };
}

export type FieldActionTypes =
  | IFetchFields
  | IFetchFieldsSuccess
  | IFetchFieldsFail
  | IFetchFieldSubfields
  | IFetchFieldSubfieldsSuccess
  | IFetchFieldSubfieldsFail;
