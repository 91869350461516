import React, { useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { IDocument } from '../../../api/models/document';
import { SUMMARY_TEMPLATE_NAME } from '../../../api/models/summaryTemplate';
import { addSummary, updateSummary } from '../../../redux/actions/summary';
import UploadDocument from '../../layout/UploadDocument';

export interface ISummaryData {
  summaryId?: number;
  summaryDocument?: IDocument;
  evaluationPeriodId: number;
  templateId: number;
  institutionId: number;
}

interface IProps {
  visible: boolean;
  summaryData?: ISummaryData;
  onClose: () => void;
}

const SummaryModal: React.FC<IProps> = ({ visible, onClose, summaryData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [document, setDocument] = useState<IDocument | undefined>(summaryData?.summaryDocument);
  const [uploading, setUploading] = useState<boolean>(false);
  const onSuccessDocumentUpload = (doc: IDocument | null) => {
    if (doc) {
      setDocument(doc);
    }
  };
  const onDocumentUploading = (flag: boolean) => {
    setUploading(flag);
  };

  return (
    <Modal
      title={SUMMARY_TEMPLATE_NAME}
      visible={visible}
      destroyOnClose
      getContainer={false}
      confirmLoading={uploading}
      okButtonProps={{ disabled: document === undefined }}
      onOk={async () => {
        if (!summaryData) {
          onClose();
          return;
        }

        if (summaryData.summaryId) {
          dispatch(
            updateSummary(
              summaryData.institutionId,
              summaryData.summaryId,
              {
                documentId: document!.id
              },
              t('feedback_message.evidence_successfully_updated')
            )
          );
        } else {
          dispatch(
            addSummary(
              summaryData.institutionId,
              {
                documentId: document!.id,
                finalReportTemplateId: summaryData.templateId,
                evaluationPeriodId: summaryData.evaluationPeriodId
              },
              t('feedback_message.evidence_successfully_added')
            )
          );
        }
        onClose();
      }}
      onCancel={onClose}
      okText={t('general.add')}
      cancelText={t('general.cancel')}
    >
      <UploadDocument
        documentId={summaryData?.summaryDocument?.id ?? null}
        upload={onSuccessDocumentUpload}
        uploading={onDocumentUploading}
      />
    </Modal>
  );
};

export default SummaryModal;
