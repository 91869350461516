import {
  TOGGLE_COLLAPSED_NAV,
  TOGGLE_MOBILE_NAV,
  SELECT_LANGUAGE,
  ThemeActionTypes
} from '../constants/theme';

export const toggleCollapsedNav = (navCollapsed: boolean): ThemeActionTypes => {
  return {
    type: TOGGLE_COLLAPSED_NAV,
    payload: { navCollapsed }
  };
};

export const onMobileNavToggle = (mobileNav: boolean): ThemeActionTypes => {
  return {
    type: TOGGLE_MOBILE_NAV,
    payload: { mobileNav }
  };
};

export const selectLanguage = (language: string): ThemeActionTypes => {
  return {
    type: SELECT_LANGUAGE,
    payload: { language }
  };
};
