import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { FETCH_ROLES, FETCH_ROLES_FAIL, FETCH_ROLES_SUCCESS } from '../constants/roles';
import agent from '../../api/agent';
import { RootState } from '../reducers/index';
import { IError } from '../../api/models/error';

const fetchRoles = (): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch
) => {
  dispatch({
    type: FETCH_ROLES
  });

  try {
    const roles = await agent.Role.list();

    dispatch({
      type: FETCH_ROLES_SUCCESS,
      payload: {
        roles
      }
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: FETCH_ROLES_FAIL,
      payload: {
        errorsFetchingRoles: errors
      }
    });
  }
};

export default fetchRoles;
