import { IError } from '../../api/models/error';
import { ISummaryTemplate } from '../../api/models/summaryTemplate';

export const FETCH_SUMMARY_TEMPLATE = 'FETCH_SUMMARY_TEMPLATE';
export const FETCH_SUMMARY_TEMPLATE_SUCCESS = 'FETCH_SUMMARY_TEMPLATE_SUCCESS';
export const FETCH_SUMMARY_TEMPLATE_FAIL = 'FETCH_SUMMARY_TEMPLATE_FAIL';
export const ADD_OR_UPDATE_SUMMARY_TEMPLATE = 'ADD_SUMMARY_TEMPLATE';
export const ADD_OR_UPDATE_SUMMARY_TEMPLATE_SUCCESS = 'ADD_SUMMARY_TEMPLATE_SUCCESS';
export const ADD_OR_UPDATE_SUMMARY_TEMPLATE_FAIL = 'ADD_SUMMARY_TEMPLATE_FAIL';

export interface ISummaryTemplateState {
  summaryTemplate?: ISummaryTemplate;
  fetchingSummaryTemplate: boolean;
  addingOrUpdatingSummaryTemplate: boolean;
  errors: IError | null;
}

/** ************************** Fetch summary templates *************************** */
interface IFetchSummaryTemplate {
  type: typeof FETCH_SUMMARY_TEMPLATE;
}

interface IFetchSummaryTemplateSuccess {
  type: typeof FETCH_SUMMARY_TEMPLATE_SUCCESS;
  payload: {
    summaryTemplates: ISummaryTemplate[];
  };
}

interface IFetchSummaryTemplateFail {
  type: typeof FETCH_SUMMARY_TEMPLATE_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Add summary template *************************** */
interface IAddOrUpdateSummaryTemplate {
  type: typeof ADD_OR_UPDATE_SUMMARY_TEMPLATE;
}

interface IAddOrUpdateSummaryTemplateSuccess {
  type: typeof ADD_OR_UPDATE_SUMMARY_TEMPLATE_SUCCESS;
  payload: {
    summaryTemplate: ISummaryTemplate;
  };
}

interface IAddOrUpdateSummaryTemplateFail {
  type: typeof ADD_OR_UPDATE_SUMMARY_TEMPLATE_FAIL;
  payload: {
    errors: IError;
  };
}

export type SummaryTemplateActionTypes =
  | IFetchSummaryTemplate
  | IFetchSummaryTemplateSuccess
  | IFetchSummaryTemplateFail
  | IAddOrUpdateSummaryTemplate
  | IAddOrUpdateSummaryTemplateSuccess
  | IAddOrUpdateSummaryTemplateFail;
