import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { IReportImage } from '../../../api/models/report';

const generatePDFReport = (images: IReportImage[], title: string, filename: string) => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const data = images.map((image, index) => {
    return [
      {
        text: image.title,
        margin: index === 0 ? [0, 0, 0, 0] : [0, 20, 0, 0],
        pageBreak: index === 2 ? 'before' : undefined,
        bold: true
      },
      {
        image: image.url,
        width: 500
        // height: 300
      }
    ];
  });

  const docDefinition: any = {
    content: [
      {
        text: title,
        style: 'header'
      },
      {
        text: moment().format('DD/MM/YYYY'),
        style: 'date',
        margin: [0, 0, 0, 30]
      },
      data
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true
      },
      date: {
        italics: true,
        fontSize: 10,
        color: 'grey'
      }
    }
  };

  pdfMake.createPdf(docDefinition).download(`${filename}.pdf`);
};

export default generatePDFReport;
