import { IError } from '../../api/models/error';
import { IProperty } from '../../api/models/property';

export const FETCH_PROPERTIES = 'FETCH_PROPERTIES';
export const FETCH_PROPERTIES_SUCCESS = 'FETCH_PROPERTIES_SUCCESS';
export const FETCH_PROPERTIES_FAIL = 'FETCH_PROPERTIES_FAIL';

export interface IPropertyState {
  properties: IProperty[];
  fetchingProperties: boolean;
  errors: IError | null;
}

/** ************************** Fetch properties *************************** */
interface IFetchProperties {
  type: typeof FETCH_PROPERTIES;
}

interface IFetchPropertiesSuccess {
  type: typeof FETCH_PROPERTIES_SUCCESS;
  payload: {
    properties: IProperty[];
  };
}

interface IFetchPropertiesFail {
  type: typeof FETCH_PROPERTIES_FAIL;
  payload: {
    errors: IError;
  };
}

export type PropertyActionTypes = IFetchProperties | IFetchPropertiesSuccess | IFetchPropertiesFail;
