import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, useStore } from 'react-redux';
import jwtDecode from 'jwt-decode';
import Layout from './views/index';
import Login from './views/auth/Login';
import ResetPassword from './views/auth/ResetPassword';
import PrivateRoute from './routing/PrivateRoute';
import FirstTimeLoginChangePassword from './views/auth/FirstTimeLoginChangePassword';
import NotFound from './views/404';
import ServerError from './views/500';
import agent from './api/agent';
import { history } from './App';
import { RootState } from './redux/reducers';
import { IToken } from './api/models/auth';
import { fetchUser } from './redux/actions/auth';

const Home = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = useStore();

  const firstTimeLogin = useSelector((state: RootState) => state.auth.firstLogin);
  const token = useSelector((state: RootState) => state.auth.token);

  let decodedToken: IToken | null = null;

  if (token) decodedToken = jwtDecode(token);

  useEffect(() => {
    agent.AxiosInitiation(store, history, t);
  }, [store, t]);

  useEffect(() => {
    if (decodedToken && !firstTimeLogin) {
      dispatch(fetchUser(decodedToken?.id, decodedToken?.role, decodedToken?.email));
    }
  }, [dispatch, decodedToken, firstTimeLogin]);

  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/platforma" />
      </Route>
      <Route path="/login" component={Login} />
      <Route path="/rivendos-fjalekalimin" component={ResetPassword} />
      <Route path="/ndrysho-fjalekalimin" component={FirstTimeLoginChangePassword} />
      <Route path="/500" component={ServerError} />
      <Route path="/404" component={NotFound} />
      <PrivateRoute path="/platforma" component={Layout} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Home;
