import { ILoginResponse } from '../../api/models/auth';
import { IError } from '../../api/models/error';
import { IUser } from '../../api/models/user';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const TOGGLE_APP_LOADING = 'TOGGLE_APP_LOADING';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';
export const CHANGE_PASSWORD_FIRST_TIME = 'CHANGE_PASSWORD_FIRST_TIME';
export const CHANGE_PASSWORD_FIRST_TIME_FAIL = 'CHANGE_PASSWORD_FIRST_TIME_FAIL';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const UPDATE_CURRENT_USER_SUCCESS = 'UPDATE_CURRENT_USER_SUCCESS';
export const UPDATE_CURRENT_USER_FAIL = 'UPDATE_CURRENT_USER_FAIL';
export const LOGOUT = 'LOGOUT';
export const RESET_AUTH_ERRORS = 'RESET_AUTH_ERRORS';

export const RESET_STATE_ACTION = [LOGIN_FAIL, CHANGE_PASSWORD, CHANGE_PASSWORD_FIRST_TIME];

interface ILoginSuccess {
  type: typeof LOGIN_SUCCESS;
  payload: ILoginResponse;
}

interface ILoginFail {
  type: typeof LOGIN_FAIL;
  payload: {
    errors: IError;
  };
}

interface IFetchCurrentUserSuccess {
  type: typeof FETCH_USER_SUCCESS;
  payload: {
    user: IUser;
    role: string;
    email: string;
  };
}

interface IFetchCurrentUserFail {
  type: typeof FETCH_USER_FAIL;
  payload: {
    fetchingCurrentUserErrors: IError;
  };
}

interface IChangePassword {
  type: typeof CHANGE_PASSWORD;
}

interface IChangePasswordFail {
  type: typeof CHANGE_PASSWORD_FAIL;
  payload: {
    errors: IError;
  };
}

interface IChangePasswordFirstTime {
  type: typeof CHANGE_PASSWORD_FIRST_TIME;
}

interface IChangePasswordFirstTimeFail {
  type: typeof CHANGE_PASSWORD_FIRST_TIME_FAIL;
  payload: {
    errors: IError;
  };
}

interface IUpdateCurrentUser {
  type: typeof UPDATE_CURRENT_USER;
}

interface IUpdateCurrentUserSuccess {
  type: typeof UPDATE_CURRENT_USER_SUCCESS;
  payload: {
    user: IUser;
  };
}

interface IUpdateCurrentUserFail {
  type: typeof UPDATE_CURRENT_USER_FAIL;
  payload: {
    errors: IError;
  };
}

interface ILogout {
  type: typeof LOGOUT;
}

interface IAppLoading {
  type: typeof TOGGLE_APP_LOADING;
  payload: {
    loading: boolean;
  };
}

/** ************************** Reset auth errors *************************** */
interface IResetAuthErrors {
  type: typeof RESET_AUTH_ERRORS;
}

export interface IAuthState {
  token: string | null;
  isAuthenticated: boolean;
  role: string | null;
  user: IUser | null;
  email: string | null;
  firstLogin: boolean;
  fetchingCurrentUser: boolean;
  fetchingCurrentUserErrors: IError | null;
  errors: IError | null;
  updatingCurrentUser: boolean;
  updateCurrentUserErrors: IError | null;
  appLoading: boolean;
}

export type AuthActionTypes =
  | ILoginSuccess
  | ILoginFail
  | ILogout
  | IFetchCurrentUserSuccess
  | IFetchCurrentUserFail
  | IChangePassword
  | IChangePasswordFail
  | IChangePasswordFirstTime
  | IChangePasswordFirstTimeFail
  | IUpdateCurrentUser
  | IUpdateCurrentUserSuccess
  | IUpdateCurrentUserFail
  | IAppLoading
  | IResetAuthErrors;
