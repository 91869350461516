import React from 'react';
import { Form, Button, Input, Card, Col, Row, Select } from 'antd';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PageHeaderAlt from '../../layout/PageHeaderAlt';
import Flex from '../../layout/shared/Flex';
import { RootState } from '../../../redux/reducers';
import Alert from '../../layout/shared/Alert';
import { ISubject } from '../../../api/models/subject';
import { addSubject, updateSubject } from '../../../redux/actions/subjects';
import AppBreadCrumb from '../../layout/AppBreadCrumb';
import { InstitutionTypeName } from '../../../constants/theme';

const { Option } = Select;

interface IProps {
  subject?: ISubject;
}

const SubjectsForm: React.FC<IProps> = ({ subject = null }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const institutionTypes = useSelector((state: RootState) =>
    state.institution.institutionTypes.filter(
      (institutionType) =>
        institutionType.name !== InstitutionTypeName.AGJENSIA_KOMBETARE_ARSIMIT &&
        institutionType.name !== InstitutionTypeName.AGJENSIA_KOMBETARE_PUNESIMIT_AFTESIVE
    )
  );

  const updatingSubject = useSelector((state: RootState) => state.subject.updatingSubject);
  const addingSubject = useSelector((state: RootState) => state.subject.addingSubject);
  const addSubjectErrors = useSelector((state: RootState) => state.subject.errorsAddingSubject);
  const updateSubjectErrors = useSelector(
    (state: RootState) => state.subject.errorsUpdatingSubject
  );

  const displayErrors = () => {
    if (subject && updateSubjectErrors) return <Alert message={updateSubjectErrors.message} />;
    if (addSubjectErrors) return <Alert message={addSubjectErrors.message} />;

    return null;
  };

  const onFinish = (values: any) => {
    const { name, institutionTypeId } = values;

    if (subject) {
      dispatch(
        updateSubject(
          subject.id,
          {
            name,
            institutionTypeId
          },
          t('feedback_message.subject_successfully_updated')
        )
      );
    } else {
      dispatch(
        addSubject(
          {
            name,
            institutionTypeId
          },
          t('feedback_message.subject_successfully_added')
        )
      );

      form.resetFields();
    }
  };

  return (
    <Form
      layout="vertical"
      form={form}
      className="ant-advanced-search-form"
      initialValues={subject ? { ...subject, institutionTypes } : {}}
      onFinish={onFinish}
    >
      <PageHeaderAlt className="bg-white border-bottom">
        <Flex className="py-2" mobileFlex={false} justifyContent="between" alignItems="center">
          <h2>{subject ? t('general.change_subject') : t('general.add_subject')}</h2>
          <div>
            <Button
              type="primary"
              htmlType="submit"
              loading={subject ? updatingSubject : addingSubject}
            >
              {subject ? t('general.save') : t('general.add')}
            </Button>
          </div>
        </Flex>
        <AppBreadCrumb
          items={[
            { title: t('sidemenu.subjects'), path: '/platforma/admin/drejtime' },
            { title: subject ? t('general.change_subject') : t('general.add_subject'), path: null }
          ]}
        />
      </PageHeaderAlt>

      <Card style={{ marginTop: '25px' }}>
        <Flex justifyContent="between" mobileFlex={false}>
          <Row gutter={16} style={{ width: '100%', marginLeft: '0', marginRight: '0' }}>
            <Col xs={24}>
              <Form.Item
                label={t('general.subject_name')}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t('validation_messages.enter_subject_name')
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="institutionTypeId"
                label={t('general.institution_type')}
                rules={[
                  { required: true, message: t('validation_messages.select_institution_type') }
                ]}
              >
                <Select placeholder={t('placeholders.select_institution_type')}>
                  {institutionTypes.map((institutionType, index) => (
                    <Option key={index.toString()} value={institutionType.id}>
                      {institutionType.description}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>{displayErrors()}</Col>
          </Row>
        </Flex>
      </Card>
    </Form>
  );
};

export default SubjectsForm;
