import {
  FETCH_EVIDENCE_TEMPLATES,
  FETCH_EVIDENCE_TEMPLATES_SUCCESS,
  FETCH_EVIDENCE_TEMPLATES_FAIL,
  ADD_EVIDENCE_TEMPLATE,
  ADD_EVIDENCE_TEMPLATE_SUCCESS,
  ADD_EVIDENCE_TEMPLATE_FAIL,
  UPDATE_EVIDENCE_TEMPLATE,
  UPDATE_EVIDENCE_TEMPLATE_SUCCESS,
  UPDATE_EVIDENCE_TEMPLATE_FAIL,
  DELETE_EVIDENCE_TEMPLATE,
  DELETE_EVIDENCE_TEMPLATE_SUCCESS,
  DELETE_EVIDENCE_TEMPLATE_FAILED,
  RESET_EVIDENCE_TEMPLATE_ERRORS,
  IEvidenceTemplateState,
  EvidenceTemplateActionTypes
} from '../constants/evidenceTemplate';

const initialState: IEvidenceTemplateState = {
  evidenceTemplates: [],
  fetchingEvidenceTemplates: false,
  addingEvidenceTemplate: false,
  updatingEvidenceTemplate: false,
  deletingEvidenceTemplate: false,
  errors: null
};

const evidenceTemplateReducer = (
  state = initialState,
  action: EvidenceTemplateActionTypes
): IEvidenceTemplateState => {
  switch (action.type) {
    case FETCH_EVIDENCE_TEMPLATES:
      return {
        ...state,
        fetchingEvidenceTemplates: true
      };
    case FETCH_EVIDENCE_TEMPLATES_SUCCESS:
      return {
        ...state,
        evidenceTemplates: action.payload.evidenceTemplates,
        fetchingEvidenceTemplates: false
      };
    case FETCH_EVIDENCE_TEMPLATES_FAIL:
      return {
        ...state,
        evidenceTemplates: [],
        fetchingEvidenceTemplates: false,
        errors: action.payload.errors
      };
    case ADD_EVIDENCE_TEMPLATE:
      return {
        ...state,
        addingEvidenceTemplate: true
      };
    case ADD_EVIDENCE_TEMPLATE_SUCCESS:
      return {
        ...state,
        evidenceTemplates: [...state.evidenceTemplates, action.payload.evidenceTemplate],
        addingEvidenceTemplate: false,
        errors: null
      };
    case ADD_EVIDENCE_TEMPLATE_FAIL:
      return {
        ...state,
        addingEvidenceTemplate: false,
        errors: action.payload.errors
      };
    case UPDATE_EVIDENCE_TEMPLATE:
      return {
        ...state,
        updatingEvidenceTemplate: true
      };
    case UPDATE_EVIDENCE_TEMPLATE_SUCCESS:
      return {
        ...state,
        updatingEvidenceTemplate: false,
        evidenceTemplates: state.evidenceTemplates.map((evidenceTemplate) =>
          evidenceTemplate.id === action.payload.evidenceTemplate.id
            ? action.payload.evidenceTemplate
            : evidenceTemplate
        ),
        errors: null
      };
    case UPDATE_EVIDENCE_TEMPLATE_FAIL:
      return {
        ...state,
        updatingEvidenceTemplate: false,
        errors: action.payload.errors
      };
    case DELETE_EVIDENCE_TEMPLATE:
      return {
        ...state,
        deletingEvidenceTemplate: true
      };
    case DELETE_EVIDENCE_TEMPLATE_SUCCESS:
      return {
        ...state,
        deletingEvidenceTemplate: false,
        evidenceTemplates: state.evidenceTemplates.filter(
          (evidenceTemplate) => evidenceTemplate.id !== action.payload.id
        )
      };
    case DELETE_EVIDENCE_TEMPLATE_FAILED:
      return {
        ...state,
        deletingEvidenceTemplate: false,
        errors: action.payload.errors
      };
    case RESET_EVIDENCE_TEMPLATE_ERRORS:
      return {
        ...state,
        errors: null
      };
    default:
      return state;
  }
};

export default evidenceTemplateReducer;
