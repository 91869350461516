import {
  FETCH_SUBFIELD_PERCENTAGE_COMPLETED,
  FETCH_SUBFIELD_PERCENTAGE_COMPLETED_SUCCESS,
  FETCH_SUBFIELD_PERCENTAGE_COMPLETED_FAIL,
  IGraphicalDataState,
  GraphicalDataActionTypes
} from '../constants/graphicalData';

const initialState: IGraphicalDataState = {
  subFieldsPercentageCompleted: [],
  fetchingSubFieldPercentageCompleted: false,
  errors: null
};

const graphicalDataReducer = (
  state = initialState,
  action: GraphicalDataActionTypes
): IGraphicalDataState => {
  switch (action.type) {
    case FETCH_SUBFIELD_PERCENTAGE_COMPLETED:
      return {
        ...state,
        fetchingSubFieldPercentageCompleted: true
      };
    case FETCH_SUBFIELD_PERCENTAGE_COMPLETED_SUCCESS:
      return {
        ...state,
        subFieldsPercentageCompleted: action.payload.subfieldsPercentageCompleted,
        errors: null,
        fetchingSubFieldPercentageCompleted: false
      };
    case FETCH_SUBFIELD_PERCENTAGE_COMPLETED_FAIL:
      return {
        ...state,
        subFieldsPercentageCompleted: [],
        errors: action.payload.errors,
        fetchingSubFieldPercentageCompleted: false
      };
    default:
      return state;
  }
};

export default graphicalDataReducer;
