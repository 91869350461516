import React from 'react';
import { Form, Button, Input, Row, Col, Card, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from '../../components/layout/shared/PageHeader';
import { RootState } from '../../redux/reducers';
import { IUserSendRequest } from '../../api/models/user';
import { updateCurrentUser } from '../../redux/actions/auth';
import Alert from '../../components/layout/shared/Alert';

const { Option } = Select;

const EditProfile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.auth.user);
  const updatingCurrentUser = useSelector((state: RootState) => state.auth.updatingCurrentUser);
  const editProfileErrors = useSelector((state: RootState) => state.auth.updateCurrentUserErrors);

  const getInitialValues = () => {
    if (user) {
      const { firstName, lastName, address, email, institution, role, phone } = user;

      return {
        firstName,
        email,
        lastName,
        role: role.id,
        address,
        institution: institution.id,
        phone
      };
    }

    return undefined;
  };

  const onFinish = async (values: any) => {
    const { firstName, lastName, address, email, role, institution, phone } = values;

    const userData: IUserSendRequest = {
      firstName,
      lastName,
      address,
      email,
      institutionId: institution,
      roleId: role,
      phone
    };

    if (user) {
      dispatch(
        updateCurrentUser(user.id, userData, t('feedback_message.profile_successfully_updated'))
      );
    }
  };

  return user ? (
    <>
      <PageHeader display title={t('navigation_profile.edit_profile')} />
      <Card>
        <div className="mt-4">
          <Form
            onFinish={onFinish}
            name="userProfile"
            layout="vertical"
            initialValues={getInitialValues()}
          >
            <Row>
              <Col xs={24} sm={24} md={24} lg={16}>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      label={t('general.firstName')}
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: t('validation_messages.enter_first_name')
                        }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      label={t('general.lastName')}
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: t('validation_messages.enter_last_name')
                        }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item name="role" label={t('general.role')}>
                      <Select placeholder={t('placeholders.select_role')} disabled>
                        <Option key={user.role.id} value={user.role.id}>
                          {user.role.description}
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item name="institution" label={t('general.institution')}>
                      <Select placeholder={t('placeholders.select_institution')} disabled>
                        <Option key={user?.institution.id} value={user?.institution.id}>
                          {user?.institution.name}
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <Form.Item label={t('general.address')} name="address">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      label={t('general.email')}
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: t('validation_messages.required_email')
                        }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item label={t('general.phone')} name="phone">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                {editProfileErrors && <Alert message={editProfileErrors.message} />}
                <Button type="primary" htmlType="submit" loading={updatingCurrentUser}>
                  {t('general.save_changes')}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
    </>
  ) : null;
};

export default EditProfile;
