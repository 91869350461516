import React from 'react';
import { Route, Redirect, RouteProps, RouteComponentProps, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/reducers/index';
import { UserRole } from '../constants/theme';

interface IProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>>;
  exact: boolean;
  path: string;
}

const AdminPrivateRoute: React.FC<IProps> = ({ component: Component, exact = false, path }) => {
  const userRole = useSelector((state: RootState) => state.auth.role);

  const routeMatch = useRouteMatch();

  const renderComponent = (props: any) => {
    const { history, location, match } = props;

    if (userRole !== UserRole.Admin) return <Redirect to={`${routeMatch.path}/admin/kreu`} />;
    return <Component history={history} location={location} match={match} />;
  };

  return (
    <>
      <Route exact={exact} path={path} render={(props) => renderComponent(props)} />
    </>
  );
};

export default AdminPrivateRoute;
