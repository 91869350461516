import React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import EvidenceTemplateForm from '../../../../../components/admin/evidence-template/EvidenceTemplateForm';
import { RootState } from '../../../../../redux/reducers';

interface MatchParams {
  id: string;
}

interface IProps extends RouteComponentProps<MatchParams> {}

const EditEvidenceTemplate: React.FC<IProps> = ({ match }) => {
  const evidenceTemplate = useSelector((state: RootState) =>
    state.evidenceTemplate.evidenceTemplates.find((el) => el.id === +match.params.id)
  );

  return <EvidenceTemplateForm evidenceTemplate={evidenceTemplate} />;
};

export default EditEvidenceTemplate;
