import { IError } from '../../api/models/error';
import { IEvidenceTemplate } from '../../api/models/evidenceTemplate';

export const FETCH_EVIDENCE_TEMPLATES = 'FETCH_EVIDENCE_TEMPLATES';
export const FETCH_EVIDENCE_TEMPLATES_SUCCESS = 'FETCH_EVIDENCE_TEMPLATES_SUCCESS';
export const FETCH_EVIDENCE_TEMPLATES_FAIL = 'FETCH_EVIDENCE_TEMPLATES_FAIL';
export const ADD_EVIDENCE_TEMPLATE = 'ADD_EVIDENCE_TEMPLATE';
export const ADD_EVIDENCE_TEMPLATE_SUCCESS = 'ADD_EVIDENCE_TEMPLATE_SUCCESS';
export const ADD_EVIDENCE_TEMPLATE_FAIL = 'ADD_EVIDENCE_TEMPLATE_FAIL';
export const UPDATE_EVIDENCE_TEMPLATE = 'UPDATE_EVIDENCE_TEMPLATE';
export const UPDATE_EVIDENCE_TEMPLATE_SUCCESS = 'UPDATE_EVIDENCE_TEMPLATE_SUCCESS';
export const UPDATE_EVIDENCE_TEMPLATE_FAIL = 'UPDATE_EVIDENCE_TEMPLATE_FAIL';
export const DELETE_EVIDENCE_TEMPLATE = 'DELETE_EVIDENCE_TEMPLATE';
export const DELETE_EVIDENCE_TEMPLATE_SUCCESS = 'DELETE_EVIDENCE_TEMPLATE_SUCCESS';
export const DELETE_EVIDENCE_TEMPLATE_FAILED = 'DELETE_EVIDENCE_TEMPLATE_FAIL';
export const RESET_EVIDENCE_TEMPLATE_ERRORS = 'RESET_EVIDENCE_TEMPLATE_ERRORS';

export interface IEvidenceTemplateState {
  evidenceTemplates: IEvidenceTemplate[];
  fetchingEvidenceTemplates: boolean;
  addingEvidenceTemplate: boolean;
  updatingEvidenceTemplate: boolean;
  deletingEvidenceTemplate: boolean;
  errors: IError | null;
}

/** ************************** Fetch evidence templates *************************** */
interface IFetchEvidenceTemplates {
  type: typeof FETCH_EVIDENCE_TEMPLATES;
}

interface IFetchEvidenceTemplatesSuccess {
  type: typeof FETCH_EVIDENCE_TEMPLATES_SUCCESS;
  payload: {
    evidenceTemplates: IEvidenceTemplate[];
  };
}

interface IFetchEvidencesTemplateFail {
  type: typeof FETCH_EVIDENCE_TEMPLATES_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Add evidence template *************************** */
interface IAddEvidenceTemplate {
  type: typeof ADD_EVIDENCE_TEMPLATE;
}

interface IAddEvidenceTemplateSuccess {
  type: typeof ADD_EVIDENCE_TEMPLATE_SUCCESS;
  payload: {
    evidenceTemplate: IEvidenceTemplate;
  };
}

interface IAddEvidenceTemplateFail {
  type: typeof ADD_EVIDENCE_TEMPLATE_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Update evidence template *************************** */
interface IUpdateEvidenceTemplate {
  type: typeof UPDATE_EVIDENCE_TEMPLATE;
}

interface IUpdateEvidenceTemplateSuccess {
  type: typeof UPDATE_EVIDENCE_TEMPLATE_SUCCESS;
  payload: {
    evidenceTemplate: IEvidenceTemplate;
  };
}

interface IUpdateEvidenceTemplateFail {
  type: typeof UPDATE_EVIDENCE_TEMPLATE_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Delete evidence template *************************** */

interface IDeleteEvidenceTemplate {
  type: typeof DELETE_EVIDENCE_TEMPLATE;
}

interface IDeleteEvidenceTemplateSuccess {
  type: typeof DELETE_EVIDENCE_TEMPLATE_SUCCESS;
  payload: {
    id: number;
  };
}

interface IDeleteEvidenceTemplateFailed {
  type: typeof DELETE_EVIDENCE_TEMPLATE_FAILED;
  payload: {
    errors: IError;
  };
}

/** ************************** Reset evidence template errors *************************** */
interface IResetEvidenceTemplateErrors {
  type: typeof RESET_EVIDENCE_TEMPLATE_ERRORS;
}

export type EvidenceTemplateActionTypes =
  | IFetchEvidenceTemplates
  | IFetchEvidenceTemplatesSuccess
  | IFetchEvidencesTemplateFail
  | IAddEvidenceTemplate
  | IAddEvidenceTemplateSuccess
  | IAddEvidenceTemplateFail
  | IUpdateEvidenceTemplate
  | IUpdateEvidenceTemplateSuccess
  | IUpdateEvidenceTemplateFail
  | IDeleteEvidenceTemplate
  | IDeleteEvidenceTemplateSuccess
  | IDeleteEvidenceTemplateFailed
  | IResetEvidenceTemplateErrors;
