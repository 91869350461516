import React from 'react';
import { Table } from 'antd';
import { TableProps } from 'antd/lib/table';
import utils from '../../utils/index';
import { IUserDisplay } from '../../api/models/user';
import { IInstitutionDisplay, IInstitutionSubjectDisplay } from '../../api/models/institution';
import { ISubjectsDisplay } from '../../api/models/subject';
import { IEvaluationPeriodDisplay } from '../../api/models/evaluationPeriod';
import { IInstitutionSelfEvaluationDisplay } from '../../api/models/selfEvaluation';
import { IEvidenceTemplateDisplay } from '../../api/models/evidenceTemplate';
import { IEvidenceDisplay } from '../../api/models/evidence';

interface IProps {
  tableColumns: any;
  dataSource:
    | IUserDisplay[]
    | IInstitutionDisplay[]
    | ISubjectsDisplay[]
    | IEvaluationPeriodDisplay[]
    | IInstitutionSubjectDisplay[]
    | IInstitutionSelfEvaluationDisplay[]
    | IEvidenceTemplateDisplay[]
    | IEvidenceDisplay[];
  search: string | null;
  key?: string;
}

const SearchTable: React.FC<IProps & TableProps<any>> = ({
  tableColumns,
  dataSource,
  search,
  key = 'id'
}) => {
  const onSearch = () => {
    if (!search) return dataSource;

    return utils.wildCardSearch(dataSource, search);
  };

  return (
    <Table
      className="table-responsive"
      columns={tableColumns}
      dataSource={onSearch()}
      rowKey={key}
    />
  );
};

export default SearchTable;
