import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { IError } from '../../api/models/error';
import agent from '../../api/agent';
import Alert from '../layout/shared/Alert';
import { IResetPasswordEmail } from '../../api/models/auth';

interface IProps {
  setLogin: (flag: boolean) => void;
}

const ForgotPasswordForm: React.FC<IProps> = ({ setLogin }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [errors, setErrors] = useState<IError | null>(null);

  const { t } = useTranslation();

  const onSend = async (values: IResetPasswordEmail) => {
    try {
      setLoading(true);

      await agent.Auth.resetPasswordRequest(values);

      setEmailSent(true);
      setLoading(false);
    } catch (error) {
      const err: IError = error?.data;
      setErrors(err);

      setLoading(false);
      setEmailSent(false);
    }
  };

  const displayMessage = () => {
    if (errors) return <Alert message={errors.message} />;
    if (emailSent)
      return <Alert message={t('feedback_message.reset_password_request')} type="success" />;

    return null;
  };

  return (
    <Form form={form} layout="vertical" name="forget-password" onFinish={onSend}>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: t('validation_messages.required_email')
          },
          {
            type: 'email',
            message: t('validation_messages.valid_email')
          }
        ]}
      >
        <Input
          placeholder={t('general.email')}
          onChange={() => {
            setLoading(false);
            setEmailSent(false);
            setErrors(null);
          }}
          prefix={<MailOutlined className="text-primary" />}
        />
      </Form.Item>
      {displayMessage()}
      <Button loading={loading} type="primary" htmlType="submit" block>
        {t('general.send')}
      </Button>
      <div className="d-flex justify-content-end w-100">
        <span
          className="cursor-pointer font-size-sm font-weight-normal text-muted"
          role="link"
          style={{ marginTop: '5px' }}
          onKeyDown={() => {}}
          onClick={() => setLogin(true)}
          tabIndex={0}
        >
          {t('general.back_to_login')}
        </span>
      </div>
    </Form>
  );
};

export default ForgotPasswordForm;
