import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { message } from 'antd';
import { FormInstance } from 'antd/lib/form';
import {
  FETCH_EVIDENCE_TEMPLATES,
  FETCH_EVIDENCE_TEMPLATES_SUCCESS,
  FETCH_EVIDENCE_TEMPLATES_FAIL,
  ADD_EVIDENCE_TEMPLATE,
  ADD_EVIDENCE_TEMPLATE_SUCCESS,
  ADD_EVIDENCE_TEMPLATE_FAIL,
  UPDATE_EVIDENCE_TEMPLATE,
  UPDATE_EVIDENCE_TEMPLATE_SUCCESS,
  UPDATE_EVIDENCE_TEMPLATE_FAIL,
  DELETE_EVIDENCE_TEMPLATE,
  DELETE_EVIDENCE_TEMPLATE_SUCCESS,
  DELETE_EVIDENCE_TEMPLATE_FAILED,
  RESET_EVIDENCE_TEMPLATE_ERRORS,
  EvidenceTemplateActionTypes
} from '../constants/evidenceTemplate';
import agent from '../../api/agent';
import { RootState } from '../reducers/index';
import { IError } from '../../api/models/error';
import {
  IEvidenceTemplateSendRequest,
  IEvidenceTemplateUpdateRequest
} from '../../api/models/evidenceTemplate';

export const fetchEvidenceTemplates = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async (dispatch) => {
  dispatch({
    type: FETCH_EVIDENCE_TEMPLATES
  });

  try {
    const evidenceTemplates = await agent.EvidenceTemplate.list();

    dispatch({
      type: FETCH_EVIDENCE_TEMPLATES_SUCCESS,
      payload: {
        evidenceTemplates
      }
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: FETCH_EVIDENCE_TEMPLATES_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const addEvidenceTemplate = (
  evidenceTemplate: IEvidenceTemplateSendRequest,
  form: FormInstance<any>,
  successMessage: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: ADD_EVIDENCE_TEMPLATE
  });

  try {
    const addedEvidenceTemplate = await agent.EvidenceTemplate.addEvidenceTemplate(
      evidenceTemplate
    );

    dispatch({
      type: ADD_EVIDENCE_TEMPLATE_SUCCESS,
      payload: {
        evidenceTemplate: addedEvidenceTemplate
      }
    });

    form.resetFields();

    message.success({
      content: successMessage,
      duration: 4
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: ADD_EVIDENCE_TEMPLATE_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const updateEvidenceTemplate = (
  id: number,
  evidenceTemplate: IEvidenceTemplateUpdateRequest,
  successMessage: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: UPDATE_EVIDENCE_TEMPLATE
  });

  try {
    const updatedTemplate = await agent.EvidenceTemplate.updateEvidenceTemplate(
      id,
      evidenceTemplate
    );

    dispatch({
      type: UPDATE_EVIDENCE_TEMPLATE_SUCCESS,
      payload: {
        evidenceTemplate: updatedTemplate
      }
    });

    message.success({
      content: successMessage,
      duration: 4
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: UPDATE_EVIDENCE_TEMPLATE_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const deleteEvidenceTemplate = (
  id: number,
  successMessage: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: DELETE_EVIDENCE_TEMPLATE
  });

  try {
    await agent.EvidenceTemplate.deleteEvidenceTemplate(id);

    dispatch({
      type: DELETE_EVIDENCE_TEMPLATE_SUCCESS,
      payload: {
        id
      }
    });

    message.success({
      content: successMessage,
      duration: 4
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: DELETE_EVIDENCE_TEMPLATE_FAILED,
      payload: {
        errors
      }
    });

    message.error({ content: errors?.message, duration: 4 });
  }
};

export const resetEvidenceTemplateErrors = (): EvidenceTemplateActionTypes => {
  return {
    type: RESET_EVIDENCE_TEMPLATE_ERRORS
  };
};
