import { IError } from '../../api/models/error';
import { IEvidence } from '../../api/models/evidence';

export const FETCH_INSTITUTION_EVIDENCE = 'FETCH_INSTITUTION_EVIDENCE';
export const FETCH_INSTITUTION_EVIDENCE_SUCCESS = 'FETCH_INSTITUTION_EVIDENCE_SUCCESS';
export const FETCH_INSTITUTION_EVIDENCE_FAIL = 'FETCH_INSTITUTION_EVIDENCE_FAIL';
export const ADD_INSTITUTION_EVIDENCE = 'ADD_INSTITUTION_EVIDENCE';
export const ADD_INSTITUTION_EVIDENCE_SUCCESS = 'ADD_INSTITUTION_EVIDENCE_SUCCESS';
export const ADD_INSTITUTION_EVIDENCE_FAIL = 'ADD_INSTITUTION_EVIDENCE_FAIL';
export const UPDATE_INSTITUTION_EVIDENCE = 'UPDATE_INSTITUTION_EVIDENCE';
export const UPDATE_INSTITUTION_EVIDENCE_SUCCESS = 'UPDATE_INSTITUTION_EVIDENCE_SUCCESS';
export const UPDATE_INSTITUTION_EVIDENCE_FAIL = 'UPDATE_INSTITUTION_EVIDENCE_FAIL';
export const DELETE_INSTITUTION_EVIDENCE = 'DELETE_INSTITUTION_EVIDENCE';
export const DELETE_INSTITUTION_EVIDENCE_SUCCESS = 'DELETE_INSTITUTION_EVIDENCE_SUCCESS';
export const DELETE_INSTITUTION_EVIDENCE_FAIL = 'DELETE_INSTITUTION_EVIDENCE_FAIL';

export interface IEvidenceState {
  evidences: IEvidence[];
  fetchingEvidences: boolean;
  addingEvidence: boolean;
  updatingEvidence: boolean;
  deletingEvidence: boolean;
  errors: IError | null;
}

/** ************************** Fetch institution evidence *************************** */
interface IFetchInstitutionEvidence {
  type: typeof FETCH_INSTITUTION_EVIDENCE;
}

interface IFetchInstitutionEvidenceSuccess {
  type: typeof FETCH_INSTITUTION_EVIDENCE_SUCCESS;
  payload: {
    evidences: IEvidence[];
  };
}

interface IFetchMyInstitutionEvidenceFail {
  type: typeof FETCH_INSTITUTION_EVIDENCE_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Add institution evidence *************************** */
interface IAddInstitutionEvidence {
  type: typeof ADD_INSTITUTION_EVIDENCE;
}

interface IAddInstitutionEvidenceSuccess {
  type: typeof ADD_INSTITUTION_EVIDENCE_SUCCESS;
  payload: {
    evidence: IEvidence;
  };
}

interface IAddInstitutionEvidenceFail {
  type: typeof ADD_INSTITUTION_EVIDENCE_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Update institution evidence *************************** */
interface IUpdateInstitutionEvidence {
  type: typeof UPDATE_INSTITUTION_EVIDENCE;
}

interface IUpdateInstitutionEvidenceSuccess {
  type: typeof UPDATE_INSTITUTION_EVIDENCE_SUCCESS;
  payload: {
    evidence: IEvidence;
  };
}

interface IUpdateInstitutionEvidenceFail {
  type: typeof UPDATE_INSTITUTION_EVIDENCE_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Delete institution evidence *************************** */
interface IDeleteInstitutionEvidence {
  type: typeof DELETE_INSTITUTION_EVIDENCE;
}

interface IDeleteInstitutionEvidenceSuccess {
  type: typeof DELETE_INSTITUTION_EVIDENCE_SUCCESS;
  payload: {
    id: number;
  };
}

interface IDeleteInstitutionEvidenceFail {
  type: typeof DELETE_INSTITUTION_EVIDENCE_FAIL;
  payload: {
    errors: IError;
  };
}

export type EvidenceActionTypes =
  | IFetchInstitutionEvidence
  | IFetchInstitutionEvidenceSuccess
  | IFetchMyInstitutionEvidenceFail
  | IAddInstitutionEvidence
  | IAddInstitutionEvidenceSuccess
  | IAddInstitutionEvidenceFail
  | IUpdateInstitutionEvidence
  | IUpdateInstitutionEvidenceSuccess
  | IUpdateInstitutionEvidenceFail
  | IDeleteInstitutionEvidence
  | IDeleteInstitutionEvidenceSuccess
  | IDeleteInstitutionEvidenceFail;
