import {
  FETCH_EVALUATION_PERIODS,
  FETCH_EVALUATION_PERIODS_SUCCESS,
  FETCH_EVALUATION_PERIODS_FAIL,
  ADD_EVALUATION_PERIOD,
  ADD_EVALUATION_PERIOD_SUCCESS,
  ADD_EVALUATION_PERIOD_FAIL,
  UPDATE_EVALUATION_PERIOD,
  UPDATE_EVALUATION_PERIOD_SUCCESS,
  UPDATE_EVALUATION_PERIOD_FAIL,
  DELETE_EVALUATION_PERIOD,
  DELETE_EVALUATION_PERIOD_SUCCESS,
  DELETE_EVALUATION_PERIOD_FAIL,
  RESET_EVALUATION_PERIOD_ERRORS,
  IEvaluationPeriods,
  EvaluationPeriodsActionTypes
} from '../constants/evaluationPeriods';

const initialState: IEvaluationPeriods = {
  evaluationPeriods: [],
  fetchingEvaluationPeriods: false,
  addingEvaluationPeriod: false,
  updatingEvaluationPeriod: false,
  deletingEvaluationPeriod: false,
  errors: null
};

const evaluationPeriodsReducer = (
  state = initialState,
  action: EvaluationPeriodsActionTypes
): IEvaluationPeriods => {
  switch (action.type) {
    case FETCH_EVALUATION_PERIODS:
      return {
        ...state,
        fetchingEvaluationPeriods: true
      };
    case FETCH_EVALUATION_PERIODS_SUCCESS:
      return {
        ...state,
        evaluationPeriods: action.payload.evaluationPeriods,
        errors: null,
        fetchingEvaluationPeriods: false
      };
    case FETCH_EVALUATION_PERIODS_FAIL:
      return {
        ...state,
        evaluationPeriods: [],
        errors: action.payload.errors,
        fetchingEvaluationPeriods: false
      };
    case ADD_EVALUATION_PERIOD:
      return {
        ...state,
        addingEvaluationPeriod: true
      };
    case ADD_EVALUATION_PERIOD_SUCCESS:
      return {
        ...state,
        evaluationPeriods: [...state.evaluationPeriods, action.payload.evaluationPeriod],
        errors: null,
        addingEvaluationPeriod: false
      };
    case ADD_EVALUATION_PERIOD_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        addingEvaluationPeriod: false
      };
    case UPDATE_EVALUATION_PERIOD:
      return {
        ...state,
        updatingEvaluationPeriod: true
      };
    case UPDATE_EVALUATION_PERIOD_SUCCESS:
      return {
        ...state,
        evaluationPeriods: state.evaluationPeriods.map((evaluationPeriod) =>
          evaluationPeriod.id === action.payload.evaluationPeriod.id
            ? action.payload.evaluationPeriod
            : evaluationPeriod
        ),
        errors: null,
        updatingEvaluationPeriod: false
      };
    case UPDATE_EVALUATION_PERIOD_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        updatingEvaluationPeriod: false
      };
    case DELETE_EVALUATION_PERIOD:
      return {
        ...state,
        deletingEvaluationPeriod: true
      };
    case DELETE_EVALUATION_PERIOD_SUCCESS:
      return {
        ...state,
        evaluationPeriods: state.evaluationPeriods.filter(
          (evaluationPeriod) => evaluationPeriod.id !== action.payload.id
        ),
        errors: null,
        deletingEvaluationPeriod: false
      };
    case DELETE_EVALUATION_PERIOD_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        deletingEvaluationPeriod: false
      };
    case RESET_EVALUATION_PERIOD_ERRORS:
      return {
        ...state,
        errors: null
      };
    default:
      return state;
  }
};

export default evaluationPeriodsReducer;
