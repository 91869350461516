import {
  ADD_OR_UPDATE_SUMMARY_TEMPLATE,
  ADD_OR_UPDATE_SUMMARY_TEMPLATE_FAIL,
  ADD_OR_UPDATE_SUMMARY_TEMPLATE_SUCCESS,
  FETCH_SUMMARY_TEMPLATE,
  FETCH_SUMMARY_TEMPLATE_FAIL,
  FETCH_SUMMARY_TEMPLATE_SUCCESS,
  ISummaryTemplateState,
  SummaryTemplateActionTypes
} from '../constants/summaryTemplate';

const initialState: ISummaryTemplateState = {
  summaryTemplate: undefined,
  fetchingSummaryTemplate: false,
  addingOrUpdatingSummaryTemplate: false,
  errors: null
};

const summaryTemplateReducer = (
  state = initialState,
  action: SummaryTemplateActionTypes
): ISummaryTemplateState => {
  switch (action.type) {
    case FETCH_SUMMARY_TEMPLATE:
      return {
        ...state,
        fetchingSummaryTemplate: true
      };
    case FETCH_SUMMARY_TEMPLATE_SUCCESS:
      return {
        ...state,
        summaryTemplate: action.payload.summaryTemplates[0],
        fetchingSummaryTemplate: false
      };
    case FETCH_SUMMARY_TEMPLATE_FAIL:
      return {
        ...state,
        summaryTemplate: undefined,
        fetchingSummaryTemplate: false,
        errors: action.payload.errors
      };
    case ADD_OR_UPDATE_SUMMARY_TEMPLATE:
      return {
        ...state,
        addingOrUpdatingSummaryTemplate: true
      };
    case ADD_OR_UPDATE_SUMMARY_TEMPLATE_SUCCESS:
      return {
        ...state,
        summaryTemplate: action.payload.summaryTemplate,
        addingOrUpdatingSummaryTemplate: false,
        errors: null
      };
    case ADD_OR_UPDATE_SUMMARY_TEMPLATE_FAIL:
      return {
        ...state,
        addingOrUpdatingSummaryTemplate: false,
        errors: action.payload.errors
      };
    default:
      return state;
  }
};

export default summaryTemplateReducer;
