import React, { useEffect } from 'react';
import { Card, Col, Divider, Form, Input, Radio, Row, Tabs } from 'antd';
import Title from 'antd/lib/typography/Title';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  ICriteria,
  ISelfEvaluationResponse,
  ISubFieldComplete
} from '../../../../../../api/models/field';
import { IIndicator } from '../../../../../../api/models/indicator';
import { IInstitutionSelfEvaluation } from '../../../../../../api/models/selfEvaluation';
import { RootState } from '../../../../../../redux/reducers';
import Utils from '../../../../../../utils';
import { UserRole } from '../../../../../../constants/theme';

interface IProps {
  subField: ISubFieldComplete;
  responses: ISelfEvaluationResponse[];
  selfEvaluation: IInstitutionSelfEvaluation;
  currentTab: number;
  setCurrentTab: (value: number) => void;
  progress: (value: number) => void;
}

const Indicators: React.FC<IProps> = ({
  subField,
  responses,
  selfEvaluation,
  progress,
  currentTab,
  setCurrentTab
}) => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;
  const { TextArea } = Input;

  const userRole = useSelector((state: RootState) => state.auth.role);

  const { indicators } = subField;

  useEffect(() => {
    progress(
      responses.length === 0
        ? 0
        : Math.floor(
            (responses.filter((response) => response.rating !== null).length / responses.length) *
              100
          )
    );
  }, [progress, subField, responses]);

  const getReadOnlyRating = (rating?: number | null): string => {
    switch (rating) {
      case 0:
        return t('general.no_evaluation');
      case 1:
        return t('general.very_good');
      case 2:
        return t('general.good');
      case 3:
        return t('general.enough');
      case 4:
        return t('general.poorly');
      default:
        return '';
    }
  };

  const formItems = (indicator: IIndicator, criteria: ICriteria) => {
    return (
      <>
        <Col xs={24}>
          <Form.Item name={`rating_${indicator.id}_${criteria.id}`}>
            <Radio.Group>
              <Radio value={1}>{t('general.very_good')}</Radio>
              <Radio value={2}>{t('general.good')}</Radio>
              <Radio value={3}>{t('general.enough')}</Radio>
              <Radio value={4}>{t('general.poorly')}</Radio>
              <Radio value={0}>{t('general.no_evaluation')}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Title level={4}>{t('general.comment_evidence')}</Title>
        </Col>
        <Col xs={24}>
          <Form.Item
            name={`description_${indicator.id}_${criteria.id}`}
            // rules={[
            //   ({ getFieldValue }) => ({
            //     validator(_, value) {
            //       if (value && getFieldValue(`rating_${indicator.id}_${criteria.id}`) === null) {
            //         return Promise.reject(t('validation_messages.select_value'));
            //       }
            //       return Promise.resolve();
            //     }
            //   })
            // ]}
          >
            <TextArea rows={2} maxLength={500} />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Title level={4}>{t('general.evidence')}</Title>
        </Col>
        <Col xs={24}>
          <Form.Item name={`evidence_${indicator.id}_${criteria.id}`}>
            <TextArea rows={2} maxLength={250} placeholder={t('general.evidence_hint')} />
          </Form.Item>
        </Col>
      </>
    );
  };

  const readOnlyFormItems = (criteria: ICriteria) => {
    const currentResponse = responses.filter(
      (response) =>
        response.criteriaId === criteria.id && response.indicatorId === criteria.indicatorId
    )[0];

    return (
      <Col xs={24}>
        <Row gutter={16}>
          <Col xs={24}>
            <div style={{ display: 'flex' }}>
              <span className="ant-descriptions-item-label">{t('general.value')}</span>
              <span className="ant-descriptions-item-content">
                {getReadOnlyRating(currentResponse?.rating)}
              </span>
            </div>
          </Col>
          <Col xs={24}>
            <div style={{ display: 'flex' }}>
              <span className="ant-descriptions-item-label">{t('general.description')}</span>
              <span className="ant-descriptions-item-content">{currentResponse?.description}</span>
            </div>
          </Col>
          <Col xs={24}>
            <div style={{ display: 'flex' }}>
              <span className="ant-descriptions-item-label">{t('general.evidence')}</span>
              <span className="ant-descriptions-item-content">{currentResponse?.evidence}</span>
            </div>
          </Col>
        </Row>
      </Col>
    );
  };

  return (
    <Card className="ant-card-default-border">
      <Tabs
        activeKey={currentTab.toString()}
        onChange={(tabActiveKey) => {
          setCurrentTab(+tabActiveKey);
        }}
      >
        {indicators.map((indicator, index) => (
          <TabPane tab={index + 1} key={+index.toString() + 1}>
            <Row gutter={16}>
              <Col>
                <h1>{t('general.indicator')}</h1>
                <p style={{ fontSize: '20px', color: '#455560' }}>{indicator.description}</p>
                <Divider />
                <h4>{t('general.criteria')}</h4>
                {indicator.criteria.map((criteria, criteriaIndex) => (
                  <div key={criteria.id}>
                    <Row gutter={16}>
                      <Col xs={24} md={11}>
                        <span style={{ fontWeight: 'bold' }}>
                          {`1.${index + 1}.${criteriaIndex + 1} `}
                        </span>
                        {criteria.question}
                      </Col>
                      <Col xs={24} md={13}>
                        {userRole === UserRole.InstitutionAdmin &&
                        Utils.isDateInRange(
                          moment(selfEvaluation.evaluationPeriod.startDate),
                          moment(selfEvaluation.evaluationPeriod.endDate)
                        )
                          ? formItems(indicator, criteria)
                          : readOnlyFormItems(criteria)}
                      </Col>
                    </Row>
                    {criteriaIndex !== indicator.criteria.length - 1 && <Divider />}
                  </div>
                ))}
              </Col>
            </Row>
          </TabPane>
        ))}
      </Tabs>
    </Card>
  );
};

export default Indicators;
