import React, { useState } from 'react';
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';
import { Card, Input, Button, Popconfirm, Tooltip } from 'antd';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Flex from '../../layout/shared/Flex';
import utils from '../../../utils/index';
import { IUser, IUserDisplay } from '../../../api/models/user';
import { RootState } from '../../../redux/reducers';
import { deleteUser } from '../../../redux/actions/users';
import SearchTable from '../../layout/SearchTable';
import Spinner from '../../layout/shared/Spinner';
import { UserRole } from '../../../constants/theme';

const UsersList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { t } = useTranslation();

  const user = useSelector((state: RootState) => state.auth.user);
  const users = useSelector((state: RootState) => state.user.users);
  const deletingUserLoading = useSelector((state: RootState) => state.user.deletingUser);

  const userDisplay: IUserDisplay[] = users.map((el) => {
    return {
      ...el,
      institutionId: el.institution?.id,
      institutionDescription: el.institution?.name,
      roleId: el.role?.id,
      roleDescription: el.role?.description
    };
  });

  const [search, setSearch] = useState<string | null>(null);

  const addProduct = () => {
    history.push(`${match.url}/shto-perdorues`);
  };

  const onHandleDelete = (id: number) => {
    dispatch(deleteUser(id, t('feedback_message.user_successfully_deleted')));
  };

  const tableColumns: any = [
    {
      title: t('general.fullName'),
      dataIndex: 'firstName',
      render: (_: any, record: IUser) => (
        <Flex className="d-flex">{`${record.firstName} ${record.lastName}`}</Flex>
      ),
      sorter: (a: string, b: string) => utils.antdTableSorter(a, b, 'firstName')
    },
    {
      title: t('general.email'),
      dataIndex: 'email',
      sorter: (a: string, b: string) => utils.antdTableSorter(a, b, 'email')
    },
    // {
    //   title: 'Address',
    //   dataIndex: 'address',
    //   sorter: (a: string, b: string) => utils.antdTableSorter(a, b, 'address')
    // },
    // {
    //   title: 'Phone',
    //   dataIndex: 'phone',
    //   sorter: (a: string, b: string) => utils.antdTableSorter(a, b, 'phone')
    // },
    {
      title: t('general.role'),
      dataIndex: ['role', 'description'],
      sorter: (a: string, b: string) => utils.antdTableSorter(a, b, 'role')
    },
    {
      title: t('general.institution'),
      dataIndex: ['institution', 'name'],
      sorter: (a: string, b: string) => utils.antdTableSorter(a, b, 'institution.name')
    },
    {
      title: '',
      dataIndex: 'actions',
      render: (_: any, row: IUser) => {
        if (row.id === user?.id || user?.role.name !== UserRole.Admin) return null;

        return (
          <Flex justifyContent="center">
            <Tooltip title={t('general.edit')} placement="bottom">
              <Link to={`${match.url}/ndrysho-perdorues/${row.id}`}>
                <Button type="primary" className="mr-2" icon={<EditOutlined />} size="small" />
              </Link>
            </Tooltip>

            <Tooltip title={t('general.delete')} placement="bottom">
              <Popconfirm
                title={t('feedback_message.are_you_sure')}
                okText={t('general.yes')}
                cancelText={t('general.no')}
                onConfirm={() => onHandleDelete(row.id)}
              >
                <Button danger icon={<DeleteOutlined />} size="small" />
              </Popconfirm>
            </Tooltip>
          </Flex>
        );
      }
    }
  ];

  if (deletingUserLoading) return <Spinner />;

  return (
    <Card>
      <Flex justifyContent="between" mobileFlex={false}>
        <Flex className="mb-1" mobileFlex={false}>
          <Flex className="mr-md-3 mb-3">
            <Input
              placeholder={t('general.search')}
              prefix={<SearchOutlined />}
              onChange={(e) => setSearch(e.currentTarget.value)}
            />
          </Flex>
        </Flex>
        <Flex>
          {user?.role.name === UserRole.Admin && (
            <Button onClick={addProduct} type="primary" icon={<PlusCircleOutlined />} block>
              {t('general.add_user')}
            </Button>
          )}
        </Flex>
      </Flex>
      <SearchTable tableColumns={tableColumns} dataSource={userDisplay} search={search} />
    </Card>
  );
};

export default UsersList;
