import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_DOCUMENTS,
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENTS_FAIL,
  UPLOAD_DOCUMENT,
  DocumentsActionTypes,
  RESET_DOCUMENT_ERRORS
} from '../constants/documents';
import agent from '../../api/agent';
import { RootState } from '../reducers/index';
import { IError } from '../../api/models/error';
import { IDocument } from '../../api/models/document';

export const fetchDocuments = (): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch
) => {
  dispatch({
    type: FETCH_DOCUMENTS
  });

  try {
    const documents = await agent.Document.list();

    dispatch({
      type: FETCH_DOCUMENTS_SUCCESS,
      payload: {
        documents
      }
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: FETCH_DOCUMENTS_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const uploadDocument = (document: IDocument): DocumentsActionTypes => {
  return {
    type: UPLOAD_DOCUMENT,
    payload: { document }
  };
};

export const resetDocumentErrors = (): DocumentsActionTypes => {
  return {
    type: RESET_DOCUMENT_ERRORS
  };
};
