import React, { useEffect } from 'react';
import { Col, Menu, Row } from 'antd';
import { HomeOutlined, BankOutlined, TeamOutlined } from '@ant-design/icons';
import { Link, Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InnerAppLayout from '../../layout/InnerAppLayout';
import {
  fetchMyInstitution,
  fetchMyInstitutionSelfEvaluation,
  fetchMyInstitutionStatistics,
  fetchMyInstitutionSubjects,
  fetchMyInstitutionUsers
} from '../../../redux/actions/myInstitution';
import fetchProperties from '../../../redux/actions/property';
import SelfEvaluation from '../../../views/institution/self-evaluation';
import HomeInfo from '../../institutions/home';
import { RootState } from '../../../redux/reducers';
import Spinner from '../../layout/shared/Spinner';
import AddSelfEvaluation from '../../../views/institution/self-evaluation/add-self-evaluation-period';
import FieldsOverview from '../../institutions/self-evaluation/fields-overview/FieldsOverview';
import SubFieldEvaluation from '../../institutions/self-evaluation/fields-overview/subfields/subfield-evaluation/SubFieldEvaluation';
import EditYearlyStatisticalData from '../../../views/institution/my-institution/edit-statistical-yearly-data';
import YearlyStatisticalData from '../../institutions/my-institution/YearlyStatisticalData';
import AppBreadCrumb from '../../layout/AppBreadCrumb';
import fetchSubfieldPercentageCompleted from '../../../redux/actions/graphicalData';
import { fetchSummaries } from '../../../redux/actions/summary';
import { fetchEvidences } from '../../../redux/actions/evidence';

interface IProps {
  id: number;
}

const InstitutionMenuOption = () => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { pathname } = useLocation();

  return (
    <Menu defaultSelectedKeys={[`${url}/home`]} mode="inline" selectedKeys={[pathname]}>
      <Menu.Item key={`${url}/home`}>
        <HomeOutlined />
        <span>{t('sidemenu.home')}</span>
        <Link to={`${url}/home`} />
      </Menu.Item>
      <Menu.Item key={`${url}/vetevleresimi`}>
        <TeamOutlined />
        <span>{t('sidemenu.selfevaluation')}</span>
        <Link to={`${url}/vetevleresimi`} />
      </Menu.Item>
      <Menu.Item key={`${url}/institucioni-im`}>
        <BankOutlined />
        <span>{t('sidemenu.myinstitution')}</span>
        <Link to={`${url}/institucioni-im`} />
      </Menu.Item>
    </Menu>
  );
};

const InstitutionContent = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Redirect exact from={path} to={`${path}/home`} />
      <Route exact path={`${path}/home`} component={HomeInfo} />
      <Route exact path={`${path}/vetevleresimi`} component={SelfEvaluation} />
      <Route exact path={`${path}/vetevleresimi/shto-periudhe`} component={AddSelfEvaluation} />
      <Route exact path={`${path}/vetevleresimi/:selfEvaluationId`} component={FieldsOverview} />
      <Route
        exact
        path={`${path}/vetevleresimi/:selfEvaluationId/nenfusha/:subFieldId`}
        component={SubFieldEvaluation}
      />
      <Route exact path={`${path}/institucioni-im`} component={YearlyStatisticalData} />
      <Route
        exact
        path={`${path}/institucioni-im/ndrysho-statistikat/:id`}
        component={EditYearlyStatisticalData}
      />
    </Switch>
  );
};

const InstitutionOverview: React.FC<IProps> = ({ id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const fetchingMyInstitution = useSelector(
    (state: RootState) => state.myInstitution.fetchingMyInstitution
  );
  const fetchingMyInstitutionUsers = useSelector(
    (state: RootState) => state.myInstitution.fetchingMyInstitutionUsers
  );
  const fetchingMyInstitutionSubjects = useSelector(
    (state: RootState) => state.myInstitution.fetchingMyInstitutionSubjects
  );
  const fetchingProperties = useSelector((state: RootState) => state.property.fetchingProperties);
  const fetchingInstitutionStatistics = useSelector(
    (state: RootState) => state.myInstitution.fetchingMyInstitutionStatistics
  );
  const fetchingMyInstitutionSelfEvaluation = useSelector(
    (state: RootState) => state.myInstitution.fetchingMyInstitutionSelfEvaluation
  );

  const institutionId = id;

  useEffect(() => {
    dispatch(fetchMyInstitution(institutionId));
    dispatch(fetchProperties());
    dispatch(fetchMyInstitutionUsers(institutionId));
    dispatch(fetchMyInstitutionSubjects(institutionId));
    dispatch(fetchMyInstitutionStatistics(institutionId));
    dispatch(fetchMyInstitutionSelfEvaluation(institutionId));
    dispatch(fetchSubfieldPercentageCompleted(institutionId));
    dispatch(fetchSummaries(institutionId));
    dispatch(fetchEvidences(institutionId));
  }, [dispatch, institutionId]);

  if (
    fetchingMyInstitution ||
    fetchingProperties ||
    fetchingMyInstitutionUsers ||
    fetchingMyInstitutionSubjects ||
    fetchingMyInstitutionSelfEvaluation ||
    fetchingInstitutionStatistics
  )
    return <Spinner />;

  return (
    <Row>
      <AppBreadCrumb
        items={[
          { title: t('sidemenu.institutions'), path: '/platforma/admin/institucione' },
          { title: t('general.institution_overview'), path: null }
        ]}
      />
      <Col xs={24} style={{ marginTop: '25px' }}>
        <InnerAppLayout
          sideContent={<InstitutionMenuOption />}
          mainContent={<InstitutionContent />}
          pageHeader
          sideContentGutter
        />
      </Col>
    </Row>
  );
};

export default InstitutionOverview;
