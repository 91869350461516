import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button, Card, Col, Row, DatePicker } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  IEvaluationPeriod,
  IEvaluationPeriodSendRequest
} from '../../../api/models/evaluationPeriod';

import PageHeaderAlt from '../../layout/PageHeaderAlt';
import Flex from '../../layout/shared/Flex';
import UploadDocument from '../../layout/UploadDocument';
import { IDocument } from '../../../api/models/document';
import {
  addEvaluationPeriod,
  updateEvaluationPeriod
} from '../../../redux/actions/evaluationPeriods';
import { RootState } from '../../../redux/reducers';
import Alert from '../../layout/shared/Alert';
import AppBreadCrumb from '../../layout/AppBreadCrumb';

interface IProps {
  evaluationPeriod?: IEvaluationPeriod;
}

const EvaluationPeriodForm: React.FC<IProps> = ({ evaluationPeriod = null }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();
  const [document, setDocument] = useState<IDocument | null>();
  const [uploading, setUploading] = useState<boolean>(false);

  const savingEvaluationPeriod = useSelector(
    (state: RootState) => state.evaluationPeriod.addingEvaluationPeriod
  );
  const updatingEvaluationPeriod = useSelector(
    (state: RootState) => state.evaluationPeriod.updatingEvaluationPeriod
  );
  const editEvaluationPeriodErrors = useSelector(
    (state: RootState) => state.evaluationPeriod.errors
  );

  const onSuccessDocumentUpload = (doc: IDocument | null) => {
    setDocument(doc);
  };

  const onDocumentUploading = (flag: boolean) => {
    setUploading(flag);
  };

  const initialDocument =
    useSelector((state: RootState) => state.document.documents).filter(
      (doc) => doc.id === evaluationPeriod?.documentId
    )[0] ?? null;

  useEffect(() => {
    setDocument(initialDocument);
  }, [initialDocument]);

  const onFinish = (values: any) => {
    const { period, year } = values;

    const evaluationPeriodData: IEvaluationPeriodSendRequest = {
      period: +moment(year).format('YYYY'),
      documentId: document?.id ?? null,
      startDate: new Date(period[0]),
      endDate: new Date(period[1])
    };

    if (evaluationPeriod) {
      dispatch(
        updateEvaluationPeriod(
          evaluationPeriod.id,
          evaluationPeriodData,
          t('feedback_message.evaluation_period_successfully_updated')
        )
      );
    } else {
      dispatch(
        addEvaluationPeriod(
          evaluationPeriodData,
          form,
          t('feedback_message.evaluation_period_successfully_added')
        )
      );
    }
  };

  return (
    <Form
      layout="vertical"
      form={form}
      className="ant-advanced-search-form"
      initialValues={
        evaluationPeriod
          ? {
              ...evaluationPeriod,
              year: moment(
                `${moment().month() + 1}-${moment().date()}-${evaluationPeriod.period}`,
                'MM-DD-YYYY'
              ),
              period: [moment(evaluationPeriod.startDate), moment(evaluationPeriod.endDate)]
            }
          : {}
      }
      onFinish={onFinish}
    >
      <PageHeaderAlt className="bg-white border-bottom">
        <Flex className="py-2" mobileFlex={false} justifyContent="between" alignItems="center">
          <h2>
            {evaluationPeriod
              ? t('general.change_evaluation_period')
              : t('general.add_evaluation_period')}
          </h2>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!!uploading}
            loading={evaluationPeriod ? updatingEvaluationPeriod : savingEvaluationPeriod}
          >
            {evaluationPeriod ? t('general.save') : t('general.add')}
          </Button>
        </Flex>
        <AppBreadCrumb
          items={[
            { title: t('sidemenu.evaluation_periods'), path: '/platforma/admin/periudha' },
            {
              title: evaluationPeriod
                ? t('general.change_evaluation_period')
                : t('general.add_evaluation_period'),
              path: null
            }
          ]}
        />
      </PageHeaderAlt>

      <Row gutter={16}>
        <Col xs={24} xl={18}>
          <Card style={{ marginTop: '25px' }}>
            <Flex justifyContent="between" mobileFlex={false}>
              <Row gutter={16} style={{ width: '100%', marginLeft: '0', marginRight: '0' }}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label={t('general.year')}
                    name="year"
                    rules={[
                      {
                        required: true,
                        message: t('validation_messages.enter_period')
                      }
                    ]}
                  >
                    <DatePicker picker="year" className="w-100" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label={t('general.periods')}
                    name="period"
                    rules={[
                      {
                        required: true,
                        message: t('validation_messages.select_start_end_date')
                      }
                    ]}
                  >
                    <RangePicker className="w-100" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  {editEvaluationPeriodErrors ? (
                    <Alert message={editEvaluationPeriodErrors.message} />
                  ) : null}
                </Col>
              </Row>
            </Flex>
          </Card>
        </Col>
        <Col xs={24} xl={6}>
          <Card style={{ marginTop: '25px' }}>
            <Form.Item label={t('general.calendar')} name="calendar">
              <UploadDocument
                documentId={evaluationPeriod?.documentId ?? null}
                upload={onSuccessDocumentUpload}
                uploading={onDocumentUploading}
              />
            </Form.Item>
          </Card>
        </Col>
      </Row>
    </Form>
  );
};

export default EvaluationPeriodForm;
