import React, { useEffect, useState } from 'react';
import { Button, Card, Input, Popconfirm } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  IInstitution,
  IInstitutionSubject,
  IInstitutionSubjectDisplay
} from '../../../api/models/institution';
import utils from '../../../utils';
import {
  addInstitutionSubject,
  closeInstitutionSubjectForm,
  deleteInstitutionSubject,
  fetchInstitutionSubjects,
  showInstitutionSubjectForm,
  updateInstitutionSubject
} from '../../../redux/actions/institutions';
import Flex from '../../layout/shared/Flex';
import { RootState } from '../../../redux/reducers';
import SearchTable from '../../layout/SearchTable';
import InstitutionSubjectsForm, { InstitutionSubjectFormValues } from './InstitutionSubjectsForm';

import Alert from '../../layout/shared/Alert';
import Spinner from '../../layout/shared/Spinner';
import { getEvaluationPeriodBasedOnInstitutionType } from '../../../api/models/evaluationPeriod';
import { UserRole } from '../../../constants/theme';

interface IProps {
  institution: IInstitution;
}

const InstitutionSubjectsList: React.FC<IProps> = ({ institution }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userRole = useSelector((state: RootState) => state.auth.user?.role.name);
  const institutionSubjects = useSelector((state: RootState) =>
    state.institution.institutionSubjects.filter(
      (institutionSubject) => institutionSubject.institutionId === institution.id
    )
  );
  const addingInstitutionSubject = useSelector(
    (state: RootState) => state.institution.addingInstitutionSubject
  );
  const updatingInstitutionSubject = useSelector(
    (state: RootState) => state.institution.updatingInstitutionSubject
  );
  const addInstitutionSubjectErrors = useSelector(
    (state: RootState) => state.institution.errorsAddingInstitutionSubject
  );
  const updateInstitutionSubjectErrors = useSelector(
    (state: RootState) => state.institution.errorsUpdatingInstitutionSubject
  );
  const fetchingInstitutionSubjects = useSelector(
    (state: RootState) => state.institution.fetchingInstitutionSubjects
  );
  const currentInstitutionSubject = useSelector(
    (state: RootState) => state.institution.currentInstitutionSubject
  );
  const showingForm = useSelector(
    (state: RootState) => state.institution.showingInstitutionSubjectsForm
  );

  const loading = currentInstitutionSubject ? updatingInstitutionSubject : addingInstitutionSubject;

  const errors = () => {
    if (currentInstitutionSubject && updateInstitutionSubjectErrors) {
      return <Alert message={updateInstitutionSubjectErrors.message} />;
    }
    if (addInstitutionSubjectErrors) {
      return <Alert message={addInstitutionSubjectErrors.message} />;
    }

    return null;
  };

  const institutionSubjectsDisplay: IInstitutionSubjectDisplay[] = institutionSubjects.map(
    (institutionSubject) => ({
      ...institutionSubject,
      subjectName: institutionSubject.subject.name,
      evaluationPeriodDescription: getEvaluationPeriodBasedOnInstitutionType(
        institutionSubject.evaluationPeriod.period,
        institution.institutionType.name
      ).toString()
    })
  );

  const subjects = useSelector((state: RootState) => state.subject.subjects).filter((subject) => {
    return subject.institutionTypeId === institution.institutionType.id;
  });

  useEffect(() => {
    dispatch(fetchInstitutionSubjects(institution.id));
  }, [dispatch, institution]);

  const [search, setSearch] = useState<string | null>(null);

  const onDeleteInstitutionSubject = (institutionSubject: IInstitutionSubject) => {
    dispatch(
      deleteInstitutionSubject(
        institutionSubject.institutionId,
        institutionSubject.id,
        t('feedback_message.institution_subject_successfully_deleted')
      )
    );
  };

  const onFinishInstitutionSubject = (values: InstitutionSubjectFormValues) => {
    switch (values.mode) {
      case 'create':
        dispatch(
          addInstitutionSubject(
            institution.id,
            {
              evaluationPeriodId: values.evaluationPeriodIds,
              subjectId: values.subjectId
            },
            t('feedback_message.institution_subject_successfully_added')
          )
        );
        break;
      case 'edit':
        dispatch(
          updateInstitutionSubject(
            institution.id,
            values.id,
            {
              evaluationPeriodId: values.evaluationPeriodId,
              subjectId: values.subjectId
            },
            t('feedback_message.institution_subject_successfully_updated')
          )
        );
        break;
      default:
        break;
    }
  };

  const handleAddInstitutitonSubject = () => {
    dispatch(showInstitutionSubjectForm());
  };

  const handleEditInstitutionSubject = (institutionSubject: IInstitutionSubject) => {
    dispatch(showInstitutionSubjectForm(institutionSubject.id));
  };

  const tableColumns = [
    {
      title: t('general.subject'),
      dataIndex: 'subjectName',
      width: '40%',
      sorter: (a: string, b: string) => utils.antdTableSorter(a, b, 'subjectName')
    },
    {
      title: t('general.evaluation_period'),
      dataIndex: 'evaluationPeriodDescription',
      width: '30%',
      sorter: (a: string, b: string) => utils.antdTableSorter(a, b, 'evaluationPeriodDescription')
    },
    {
      title: '',
      dataIndex: 'actions',
      width: '30%',
      render: (_: any, row: IInstitutionSubjectDisplay) => {
        // Only Admin can edit
        if (userRole !== UserRole.Admin) return null;

        // Check if Institution Subject has statistics before showing edit/delete buttons
        if (row.hasStatistics) return null;

        return (
          <Flex justifyContent="center">
            <Button
              type="primary"
              className="mr-2"
              icon={<EditOutlined />}
              size="small"
              onClick={() => handleEditInstitutionSubject(row)}
            />

            <Popconfirm
              title={t('feedback_message.are_you_sure')}
              okText={t('general.yes')}
              cancelText={t('general.no')}
              onConfirm={() => onDeleteInstitutionSubject(row)}
            >
              <Button danger icon={<DeleteOutlined />} size="small" />
            </Popconfirm>
          </Flex>
        );
      }
    }
  ];

  const resetForm = () => {
    dispatch(closeInstitutionSubjectForm());
  };

  if (fetchingInstitutionSubjects) {
    return <Spinner />;
  }

  return (
    <Card>
      <Flex justifyContent="between" mobileFlex={false}>
        <Flex className="mb-1" mobileFlex={false}>
          <Flex className="mr-md-3 mb-3">
            <Input
              placeholder={t('general.search')}
              prefix={<SearchOutlined />}
              onChange={(e) => setSearch(e.currentTarget.value)}
            />
          </Flex>
        </Flex>
        <Flex>
          {userRole === UserRole.Admin && (
            <Button
              onClick={handleAddInstitutitonSubject}
              disabled={subjects.length === 0}
              type="primary"
              icon={<PlusCircleOutlined />}
              block
            >
              {t('general.add_institution_subject')}
            </Button>
          )}
        </Flex>
      </Flex>

      <SearchTable
        tableColumns={tableColumns}
        dataSource={institutionSubjectsDisplay}
        search={search}
      />

      <InstitutionSubjectsForm
        visible={showingForm}
        loading={loading}
        institution={institution}
        subjects={subjects}
        institutionSubject={currentInstitutionSubject}
        errors={errors()}
        onCancel={resetForm}
        onSubmit={onFinishInstitutionSubject}
      />
    </Card>
  );
};

export default InstitutionSubjectsList;
