import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { message } from 'antd';
import {
  ADD_SUBJECT,
  ADD_SUBJECT_FAIL,
  ADD_SUBJECT_SUCCESS,
  DELETE_SUBJECT,
  DELETE_SUBJECT_FAIL,
  DELETE_SUBJECT_SUCCESS,
  FETCH_SUBJECTS,
  FETCH_SUBJECTS_FAIL,
  FETCH_SUBJECTS_SUCCESS,
  RESET_SUBJECT_ERRORS,
  UPDATE_SUBJECT,
  UPDATE_SUBJECT_FAIL,
  UPDATE_SUBJECT_SUCCESS,
  SubjectActionTypes
} from '../constants/subjects';
import agent from '../../api/agent';
import { RootState } from '../reducers/index';
import { IError } from '../../api/models/error';
import { ISubjectSendRequest } from '../../api/models/subject';

export const fetchSubjects = (): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch
) => {
  dispatch({
    type: FETCH_SUBJECTS
  });

  try {
    const subjects = await agent.Subject.list();

    dispatch({
      type: FETCH_SUBJECTS_SUCCESS,
      payload: {
        subjects
      }
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: FETCH_SUBJECTS_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const addSubject = (
  subjectSendRequest: ISubjectSendRequest,
  successMessage: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: ADD_SUBJECT
  });

  try {
    const subject = await agent.Subject.add(subjectSendRequest);

    dispatch({
      type: ADD_SUBJECT_SUCCESS,
      payload: {
        subject
      }
    });

    message.success({
      content: successMessage,
      duration: 4
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: ADD_SUBJECT_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const updateSubject = (
  id: number,
  subject: ISubjectSendRequest,
  successMessage: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: UPDATE_SUBJECT
  });

  try {
    const updatedSubject = await agent.Subject.update(id, subject);

    dispatch({
      type: UPDATE_SUBJECT_SUCCESS,
      payload: {
        subject: updatedSubject
      }
    });

    message.success({
      content: successMessage,
      duration: 4
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: UPDATE_SUBJECT_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const deleteSubject = (
  id: number,
  successMessage: String
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: DELETE_SUBJECT
  });

  try {
    await agent.Subject.delete(id);

    dispatch({
      type: DELETE_SUBJECT_SUCCESS,
      payload: {
        id
      }
    });

    message.success({
      content: successMessage,
      duration: 4
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: DELETE_SUBJECT_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const resetSubjectErrors = (): SubjectActionTypes => {
  return {
    type: RESET_SUBJECT_ERRORS
  };
};
