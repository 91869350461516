import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, message, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { RightOutlined, LeftOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getEvaluationPeriodBasedOnInstitutionType } from '../../../../../../api/models/evaluationPeriod';
import {
  ISendRequestIndicator,
  ISendRequestSelfEvaluationResponse
} from '../../../../../../api/models/field';
import { fetchFieldSubfields } from '../../../../../../redux/actions/field';
import {
  fetchMyInstitutionCurrentResponse,
  updateMyInstitutionSelfEvaluationResponse
} from '../../../../../../redux/actions/myInstitution';
import { RootState } from '../../../../../../redux/reducers';
import Utils from '../../../../../../utils';
import NotFound from '../../../../../../views/404';
import AppBreadCrumb from '../../../../../layout/AppBreadCrumb';
import PageHeaderAlt from '../../../../../layout/PageHeaderAlt';
import Alert from '../../../../../layout/shared/Alert';
import Flex from '../../../../../layout/shared/Flex';
import Spinner from '../../../../../layout/shared/Spinner';
import Indicators from './Indicators';
import { UserRole } from '../../../../../../constants/theme';

interface MatchParams {
  selfEvaluationId: string;
  subFieldId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {}

const SubFieldEvaluation: React.FC<IProps> = ({ match }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const userRole = useSelector((state: RootState) => state.auth.role);
  const institution = useSelector((state: RootState) => state.myInstitution.institution);
  const subField = useSelector((state: RootState) => state.field.subFields).filter(
    (el) => el.id === +match.params.subFieldId
  )[0];
  const selfEvaluation = useSelector(
    (state: RootState) => state.myInstitution.selfEvaluation
  ).filter((el) => el.id === +match.params.selfEvaluationId)[0];

  const fetchingSubfields = useSelector((state: RootState) => state.field.fetchingSubFields);
  const fetchingCurrentResponse = useSelector(
    (state: RootState) => state.myInstitution.fetchingMyInstitutionCurrentResponse
  );
  const updatingSelfEvaluationResponses = useSelector(
    (state: RootState) => state.myInstitution.updatingMyInstitutionSelfEvaluationResponse
  );
  const fetchingSubFieldPercentageCompleted = useSelector(
    (state: RootState) => state.graphs.fetchingSubFieldPercentageCompleted
  );
  const fetchingMyInstitutionSelfEvaluation = useSelector(
    (state: RootState) => state.myInstitution.fetchingMyInstitutionSelfEvaluation
  );

  const currentResponse = useSelector((state: RootState) => state.myInstitution.currentResponse);
  const errors = useSelector((state: RootState) => state.myInstitution.errors);

  const setTitle = () => {
    return `${selfEvaluation && selfEvaluation.field.name} (${
      selfEvaluation &&
      institution &&
      getEvaluationPeriodBasedOnInstitutionType(
        selfEvaluation.evaluationPeriod.period,
        institution.institutionType.name
      )
    })`;
  };

  const onFinishFailed = ({ errorFields }: any) => {
    if (errorFields.length > 0)
      message.error({ content: t('validation_messages.incorrect_fields_try_again'), duration: 4 });
  };

  const getInitialValues = () => {
    const result = {};

    // if (subField) {
    //   const { indicators } = subField;

    //   // Set initial values to 0 for radio button and empty string for description
    //   indicators.forEach((indicator) => {
    //     indicator.criteria.forEach((criteria) => {
    //       Utils.setProperty(result, `description_${criteria.indicatorId}_${criteria.id}`, null);

    //       Utils.setProperty(result, `rating_${criteria.indicatorId}_${criteria.id}`, null);
    //     });
    //   });
    // }

    if (currentResponse) {
      // Update initial values with data coming from db
      currentResponse.forEach((response) => {
        Utils.setProperty(
          result,
          `description_${response.indicatorId}_${response.criteriaId}`,
          response.description ?? null
        );

        Utils.setProperty(
          result,
          `rating_${response.indicatorId}_${response.criteriaId}`,
          response.rating
        );

        Utils.setProperty(
          result,
          `evidence_${response.indicatorId}_${response.criteriaId}`,
          response.evidence ?? null
        );
      });
    }

    return result;
  };

  const onFinish = (values: any) => {
    const indicators: ISendRequestIndicator[] = [];

    // Create initial response object - If response has properties map its properties as initial data
    // otherwise initialize object with rating & description null
    if (Object.keys(currentResponse).length > 0) {
      currentResponse.forEach(({ indicatorId, criteriaId, rating, description, evidence }) => {
        const item: ISendRequestIndicator = { id: indicatorId, criteria: [] };

        item.criteria.push({ id: criteriaId, rating, description, evidence });
      });
    } else {
      subField.indicators.forEach((indicator) => {
        const item: ISendRequestIndicator = { id: indicator.id, criteria: [] };

        indicator.criteria.forEach((criteria) => {
          item.criteria.push({ id: criteria.id, rating: null, description: null, evidence: null });
        });

        indicators.push(item);
      });
    }

    // Update response object created above with actual data coming from the form
    Object.keys(values).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(values, key)) {
        const parts = key.split('_');
        const type = parts[0];
        const indicatorId = +parts[1];
        const criteriaId = +parts[2];

        let description: string | null = null;
        let rating: number | null = null;
        let evidence: string | null = null;

        if (type === 'description') description = values[key] ?? null;
        if (type === 'evidence') evidence = values[key] ?? null;
        if (type === 'rating') rating = values[key] !== null ? +values[key] : null;

        let indicatorExists = false;

        indicators.forEach((indicator) => {
          if (indicator.id === indicatorId) {
            indicatorExists = true;

            let criteriaExists = false;

            indicator.criteria.forEach((criteria) => {
              const tempCriteria = criteria;

              if (tempCriteria.id === criteriaId) {
                criteriaExists = true;

                switch (type) {
                  case 'description':
                    tempCriteria.description = description;
                    break;
                  case 'evidence':
                    tempCriteria.evidence = evidence;
                    break;
                  case 'rating':
                    tempCriteria.rating = rating;
                    break;
                  default:
                    break;
                }
              }
            });

            if (!criteriaExists) {
              indicator.criteria.push({
                id: criteriaId,
                rating,
                description,
                evidence
              });
            }
          }
        });

        if (!indicatorExists) {
          const indicator: ISendRequestIndicator = { id: indicatorId, criteria: [] };

          indicator.criteria.push({
            id: criteriaId,
            rating,
            description,
            evidence
          });

          indicators.push(indicator);
        }
      }
    });

    const result: ISendRequestSelfEvaluationResponse = {
      selfEvaluationId: +match.params.selfEvaluationId,
      subFieldId: +match.params.subFieldId,
      indicator: indicators
    };

    if (institution)
      dispatch(
        updateMyInstitutionSelfEvaluationResponse(
          institution?.id,
          result,
          t('feedback_message.institution_self_evaluation_response_successfully_updated'),
          history
        )
      );
  };

  useEffect(() => {
    if (selfEvaluation) dispatch(fetchFieldSubfields(selfEvaluation.field.id));

    if (institution)
      dispatch(
        fetchMyInstitutionCurrentResponse(
          institution.id,
          +match.params.selfEvaluationId,
          +match.params.subFieldId
        )
      );
  }, [
    dispatch,
    selfEvaluation,
    institution,
    match.params.selfEvaluationId,
    match.params.subFieldId
  ]);

  // useEffect(() => form.resetFields(), [getInitialValues()]);

  const [progress, setProgress] = useState<number>(0);
  const [currentTab, setCurrentTab] = useState<number>(1);

  if (
    fetchingSubfields ||
    updatingSelfEvaluationResponses ||
    fetchingCurrentResponse ||
    fetchingSubFieldPercentageCompleted ||
    fetchingMyInstitutionSelfEvaluation
  )
    return <Spinner />;

  if (!selfEvaluation || !subField) return <NotFound />;

  const firstTab = Math.min(1, subField.indicators.length);
  const lastTab = subField.indicators.length;
  const isOnFirstTab = currentTab === firstTab;
  const isOnLastTab = currentTab === lastTab;

  const canEdit =
    userRole === UserRole.InstitutionAdmin &&
    Utils.isDateInRange(
      moment(selfEvaluation.evaluationPeriod.startDate),
      moment(selfEvaluation.evaluationPeriod.endDate)
    );

  return (
    <Form
      layout="vertical"
      form={form}
      className="ant-advanced-search-form"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={getInitialValues()}
    >
      <PageHeaderAlt className="bg-white border-bottom">
        <Flex className="py-2" mobileFlex={false} justifyContent="between" alignItems="center">
          <h2>{`${subField.name} (${progress} %)`}</h2>
          {canEdit && (
            <Button type="primary" htmlType="submit">
              {t('general.save')}
            </Button>
          )}
        </Flex>
        <AppBreadCrumb
          items={[
            { title: t('sidemenu.selfevaluation'), path: '../../../vetevleresimi' },
            {
              title: setTitle(),
              path: `../../../vetevleresimi/${+match.params.selfEvaluationId}`
            },
            { title: `${subField.name}`, path: null }
          ]}
        />
      </PageHeaderAlt>

      {errors && (
        <div style={{ marginTop: '25px' }}>
          <Alert message={errors.message} />
        </div>
      )}

      <Card style={{ marginTop: '25px' }} title={t('general.description')}>
        {subField?.description}
      </Card>

      <Indicators
        subField={subField}
        responses={currentResponse}
        progress={(value: number) => setProgress(value)}
        currentTab={currentTab}
        setCurrentTab={(value) => setCurrentTab(value)}
        selfEvaluation={selfEvaluation}
      />

      <Row className="w-100" align="middle" justify="space-between">
        {userRole === UserRole.InstitutionAdmin && (
          <Col sm={12}>
            {canEdit ? (
              <Button type="primary" htmlType="submit" icon={<ArrowLeftOutlined />}>
                {t('general.save_and_return')}
              </Button>
            ) : (
              <Button type="primary" icon={<ArrowLeftOutlined />} onClick={() => history.goBack()}>
                {t('general.go_back')}
              </Button>
            )}
          </Col>
        )}
        <Col sm={12}>
          <Flex justifyContent="end">
            <Button
              type="primary"
              icon={<LeftOutlined />}
              size="small"
              disabled={isOnFirstTab}
              onClick={() => setCurrentTab(currentTab - 1)}
            />
            <Button
              type="primary"
              icon={<RightOutlined />}
              size="small"
              className="ml-2"
              disabled={isOnLastTab}
              onClick={() => setCurrentTab(currentTab + 1)}
            />
          </Flex>
        </Col>
      </Row>
    </Form>
  );
};

export default SubFieldEvaluation;
