import {
  FETCH_INSTITUTIONS,
  FETCH_INSTITUTIONS_SUCCESS,
  FETCH_INSTITUTIONS_FAIL,
  FETCH_INSTITUTION_TYPES,
  FETCH_INSTITUTION_TYPES_SUCCESS,
  FETCH_INSTITUTION_TYPES_FAIL,
  FETCH_INSTITUTION_SUBJECTS,
  FETCH_INSTITUTION_SUBJECTS_SUCCESS,
  FETCH_INSTITUTION_SUBJECTS_FAIL,
  ADD_INSTITUTION_SUBJECT,
  ADD_INSTITUTION_SUBJECT_SUCCESS,
  ADD_INSTITUTION_SUBJECT_FAIL,
  UPDATE_INSTITUTION_SUBJECT,
  UPDATE_INSTITUTION_SUBJECT_SUCCESS,
  UPDATE_INSTITUTION_SUBJECT_FAIL,
  DELETE_INSTITUTION_SUBJECT,
  DELETE_INSTITUTION_SUBJECT_SUCCESS,
  DELETE_INSTITUTION_SUBJECT_FAIL,
  ADD_INSTITUTION,
  ADD_INSTITUTION_SUCCESS,
  ADD_INSTITUTION_FAIL,
  UPDATE_INSTITUTION,
  UPDATE_INSTITUTION_SUCCESS,
  UPDATE_INSTITUTION_FAIL,
  DELETE_INSTITUTION,
  DELETE_INSTITUTION_SUCCESS,
  DELETE_INSTITUTION_FAIL,
  SHOW_INSTITUTION_SUBJECT_FORM,
  CLOSE_INSTITUTION_SUBJECT_FORM,
  RESET_INSTITUTION_ERRORS,
  IInstitutionState,
  InstitutionActionTypes
} from '../constants/institutions';

const initialState: IInstitutionState = {
  institutions: [],
  fetchingInstitutions: false,
  errorsFetchingInstitutions: null,
  institutionSubjects: [],
  addingInstitutionSubject: false,
  showingInstitutionSubjectsForm: false,
  currentInstitutionSubject: null,
  errorsAddingInstitutionSubject: null,
  updatingInstitutionSubject: false,
  errorsUpdatingInstitutionSubject: null,
  deletingInstitutionSubject: true,
  errorsDeletingInstitutionSubject: null,
  fetchingInstitutionSubjects: false,
  errorsFetchingInstitutionSubjects: null,
  addingInstitution: false,
  errorsAddingInstitution: null,
  updatingInstitution: false,
  errorsUpdatingInstitution: null,
  deletingInstitution: false,
  errorsDeletingInstitution: null,
  institutionTypes: [],
  fetchingInstitutionTypes: false,
  errorsFetchingInstitutionTypes: null
};

const institutionReducer = (
  state = initialState,
  action: InstitutionActionTypes
): IInstitutionState => {
  switch (action.type) {
    case FETCH_INSTITUTIONS: {
      return {
        ...state,
        fetchingInstitutions: true
      };
    }
    case FETCH_INSTITUTIONS_SUCCESS: {
      return {
        ...state,
        institutions: action.payload.institutions,
        errorsFetchingInstitutions: null,
        fetchingInstitutions: false
      };
    }
    case FETCH_INSTITUTIONS_FAIL: {
      return {
        ...state,
        institutions: [],
        errorsFetchingInstitutions: action.payload.errors,
        fetchingInstitutions: false
      };
    }
    case FETCH_INSTITUTION_SUBJECTS: {
      return {
        ...state,
        fetchingInstitutionSubjects: true
      };
    }
    case FETCH_INSTITUTION_SUBJECTS_SUCCESS: {
      const { institutionId } = action;
      const currentInstitutionSubjects = action.payload.institutionSubjects;
      const otherInstitutionSubjects = state.institutionSubjects.filter(
        (institutionSubject) => institutionSubject.institutionId !== institutionId
      );
      return {
        ...state,
        institutionSubjects: [...currentInstitutionSubjects, ...otherInstitutionSubjects],
        fetchingInstitutionSubjects: false,
        errorsFetchingInstitutionSubjects: null
      };
    }
    case FETCH_INSTITUTION_SUBJECTS_FAIL: {
      return {
        ...state,
        fetchingInstitutionSubjects: false,
        errorsFetchingInstitutionSubjects: action.payload.errors
      };
    }
    case SHOW_INSTITUTION_SUBJECT_FORM: {
      const currentInstitutionSubject = state.institutionSubjects.find(
        (institutionSubject) => institutionSubject.id === action.id
      );
      return {
        ...state,
        showingInstitutionSubjectsForm: true,
        currentInstitutionSubject: currentInstitutionSubject || null
      };
    }
    case CLOSE_INSTITUTION_SUBJECT_FORM: {
      return {
        ...state,
        showingInstitutionSubjectsForm: false,
        currentInstitutionSubject: null,
        errorsAddingInstitutionSubject: null,
        errorsUpdatingInstitutionSubject: null
      };
    }
    case ADD_INSTITUTION_SUBJECT: {
      return {
        ...state,
        addingInstitutionSubject: true
      };
    }
    case ADD_INSTITUTION_SUBJECT_SUCCESS: {
      const { institutionId } = action;
      const currentInstitutionSubjects = action.payload.institutionSubjects;
      const otherInstitutionSubjects = state.institutionSubjects.filter(
        (institutionSubject) => institutionSubject.institutionId !== institutionId
      );
      return {
        ...state,
        institutionSubjects: [...currentInstitutionSubjects, ...otherInstitutionSubjects],
        addingInstitutionSubject: false,
        showingInstitutionSubjectsForm: false,
        currentInstitutionSubject: null
      };
    }
    case ADD_INSTITUTION_SUBJECT_FAIL: {
      return {
        ...state,
        addingInstitutionSubject: false,
        errorsAddingInstitutionSubject: action.payload.errors
      };
    }
    case UPDATE_INSTITUTION_SUBJECT: {
      return {
        ...state,
        updatingInstitutionSubject: true
      };
    }
    case UPDATE_INSTITUTION_SUBJECT_SUCCESS: {
      return {
        ...state,
        updatingInstitutionSubject: false,
        errorsUpdatingInstitutionSubject: null,
        institutionSubjects: state.institutionSubjects.map((institutionSubject) =>
          institutionSubject.id === action.payload.institutionSubject.id
            ? action.payload.institutionSubject
            : institutionSubject
        ),
        showingInstitutionSubjectsForm: false,
        currentInstitutionSubject: null
      };
    }
    case UPDATE_INSTITUTION_SUBJECT_FAIL: {
      return {
        ...state,
        updatingInstitutionSubject: false,
        errorsUpdatingInstitutionSubject: action.payload.errors
      };
    }
    case DELETE_INSTITUTION_SUBJECT: {
      return {
        ...state,
        deletingInstitutionSubject: true
      };
    }
    case DELETE_INSTITUTION_SUBJECT_SUCCESS: {
      return {
        ...state,
        deletingInstitutionSubject: false,
        errorsDeletingInstitutionSubject: null,
        institutionSubjects: state.institutionSubjects.filter(
          (institutionSubject) => institutionSubject.id !== action.payload.institutionSubjectId
        )
      };
    }
    case DELETE_INSTITUTION_SUBJECT_FAIL: {
      return {
        ...state,
        deletingInstitutionSubject: false,
        errorsDeletingInstitutionSubject: action.payload.errors
      };
    }
    case ADD_INSTITUTION: {
      return {
        ...state,
        addingInstitution: true
      };
    }
    case ADD_INSTITUTION_SUCCESS: {
      return {
        ...state,
        institutions: [...state.institutions, action.payload.institution],
        addingInstitution: false,
        errorsAddingInstitution: null
      };
    }
    case ADD_INSTITUTION_FAIL: {
      return {
        ...state,
        addingInstitution: false,
        errorsAddingInstitution: action.payload.errors
      };
    }
    case UPDATE_INSTITUTION: {
      return {
        ...state,
        updatingInstitution: true,
        errorsUpdatingInstitution: null
      };
    }
    case UPDATE_INSTITUTION_SUCCESS: {
      return {
        ...state,
        institutions: state.institutions.map((institution) =>
          institution.id === action.payload.institution.id
            ? action.payload.institution
            : institution
        ),
        updatingInstitution: false,
        errorsUpdatingInstitution: null
      };
    }
    case UPDATE_INSTITUTION_FAIL: {
      return {
        ...state,
        updatingInstitution: false,
        errorsUpdatingInstitution: action.payload.errors
      };
    }
    case DELETE_INSTITUTION: {
      return {
        ...state,
        deletingInstitution: true
      };
    }
    case DELETE_INSTITUTION_SUCCESS: {
      return {
        ...state,
        institutions: state.institutions.filter(
          (institution) => institution.id !== action.payload.id
        ),
        deletingInstitution: false,
        errorsDeletingInstitution: null
      };
    }
    case DELETE_INSTITUTION_FAIL: {
      return {
        ...state,
        deletingInstitution: false,
        errorsDeletingInstitution: action.payload.errors
      };
    }
    case FETCH_INSTITUTION_TYPES: {
      return {
        ...state,
        fetchingInstitutionTypes: true
      };
    }
    case FETCH_INSTITUTION_TYPES_SUCCESS: {
      return {
        ...state,
        institutionTypes: action.payload.institutionTypes,
        errorsFetchingInstitutionTypes: null,
        fetchingInstitutionTypes: false
      };
    }
    case FETCH_INSTITUTION_TYPES_FAIL: {
      return {
        ...state,
        institutions: [],
        errorsFetchingInstitutionTypes: action.payload.errors,
        fetchingInstitutionTypes: false
      };
    }
    case RESET_INSTITUTION_ERRORS:
      return {
        ...state,
        errorsFetchingInstitutions: null,
        errorsAddingInstitutionSubject: null,
        errorsUpdatingInstitutionSubject: null,
        errorsDeletingInstitutionSubject: null,
        errorsFetchingInstitutionSubjects: null,
        errorsAddingInstitution: null,
        errorsUpdatingInstitution: null,
        errorsDeletingInstitution: null,
        errorsFetchingInstitutionTypes: null
      };
    default:
      return state;
  }
};

export default institutionReducer;
