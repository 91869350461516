import {
  FETCH_SECTORS,
  FETCH_SECTORS_SUCCESS,
  FETCH_SECTORS_FAIL,
  ISectorsState,
  SectorsActionTypes
} from '../constants/sectors';

const initialState: ISectorsState = {
  sectors: [],
  fetchingSectors: false,
  errors: null
};

const sectorReducer = (state = initialState, action: SectorsActionTypes): ISectorsState => {
  switch (action.type) {
    case FETCH_SECTORS:
      return {
        ...state,
        fetchingSectors: true
      };
    case FETCH_SECTORS_SUCCESS:
      return {
        ...state,
        sectors: action.payload.sectors,
        errors: null,
        fetchingSectors: false
      };
    case FETCH_SECTORS_FAIL:
      return {
        ...state,
        sectors: [],
        errors: action.payload.errors,
        fetchingSectors: false
      };
    default:
      return state;
  }
};

export default sectorReducer;
