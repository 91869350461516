import React, { useState } from 'react';
import { Upload, message, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { uploadDocument } from '../../redux/actions/documents';
import agent from '../../api/agent';
import { IError } from '../../api/models/error';
import { IDocument } from '../../api/models/document';
import { RootState } from '../../redux/reducers';

interface IProps {
  upload: (doc: IDocument | null) => void;
  uploading: (flag: boolean) => void;
  documentId: number | null;
}

const UploadDocument: React.FC<IProps> = ({ upload, uploading, documentId = null }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const documents = useSelector((state: RootState) => state.document.documents);

  const initialDocument: IDocument | null = documentId
    ? documents.filter((document) => document.id === documentId)[0]
    : null;

  const [documentList, setdocumentList] = useState<any>(
    initialDocument
      ? [
          {
            uid: initialDocument.id,
            name: initialDocument.originalDocumentName,
            status: 'done'
            // url: initialDocument?.document
          }
        ]
      : null
  );

  const props: any = {
    name: 'file',
    onChange(info: { fileList: any; file: { status?: string; name: string } }) {
      let fileList = [...info.fileList];

      fileList = fileList.slice(-1);

      // fileList = fileList.map((file) => {
      //   if (file.response) {
      //     file.url = file.response.url;
      //   }
      //   return file;
      // });

      setdocumentList(fileList);

      if (info.file.status === 'uploading') {
        uploading(true);
      }
      if (info.file.status === 'done') {
        uploading(false);
        message.success({
          content: `${info.file.name} ${t('feedback_message.uploaded_successfully')}`,
          duration: 4
        });
      } else if (info.file.status === 'error') {
        uploading(false);
        message.error({ content: t('feedback_message.upload_failed'), duration: 4 });
      }
    },
    onRemove() {
      upload(null);
    },
    beforeUpload(file: { type: string; size: number }) {
      const mimeTypes = [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'text/csv',
        'application/pdf',
        'image/jpeg',
        'image/png'
      ];

      const { size, type } = file;

      let allowedFileFormat = true;

      if (!mimeTypes.includes(type)) {
        allowedFileFormat = false;
        message.error(t('feedback_message.file_format_not_allowed'));
      }

      const allowedFileSize = size / 1024 / 1024 < 20;

      if (!allowedFileSize) {
        message.error(t('feedback_message.image_20Mb'));
      }

      return allowedFileFormat && allowedFileSize;
    }
  };

  const uploadFile = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;

    const config = {
      onUploadProgress: (event: { loaded: number; total: number }) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      }
    };

    try {
      const document = await agent.Document.upload(file, config);
      dispatch(uploadDocument(document));

      upload(document);

      onSuccess(document);
    } catch (error) {
      const errors: IError = error?.data;

      onError({ errors });
    }
  };

  const { name, onChange, onRemove, beforeUpload } = props;

  return (
    <Upload
      name={name}
      onChange={onChange}
      onRemove={onRemove}
      beforeUpload={beforeUpload}
      customRequest={uploadFile}
      fileList={documentList}
    >
      <Button icon={<UploadOutlined />}>{t('general.upload')}</Button>
    </Upload>
  );
};

export default UploadDocument;
