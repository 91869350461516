import { IError } from '../../api/models/error';
import { IInstitution, IInstitutionSubject, IInstitutionType } from '../../api/models/institution';

export const FETCH_INSTITUTIONS = 'FETCH_INSTITUTIONS';
export const FETCH_INSTITUTIONS_SUCCESS = 'FETCH_INSTITUTIONS_SUCCESS';
export const FETCH_INSTITUTIONS_FAIL = 'FETCH_INSTITUTIONS_FAIL';
export const FETCH_INSTITUTION_SUBJECTS = 'FETCH_INSTITUTION_SUBJECTS';
export const FETCH_INSTITUTION_SUBJECTS_SUCCESS = 'FETCH_INSTITUTION_SUBJECTS_SUCCESS';
export const FETCH_INSTITUTION_SUBJECTS_FAIL = 'FETCH_INSTITUTION_SUBJECTS_FAIL';
export const ADD_INSTITUTION_SUBJECT = 'ADD_INSTITUTION_SUBJECT';
export const ADD_INSTITUTION_SUBJECT_SUCCESS = 'ADD_INSTITUTION_SUBJECT_SUCCESS';
export const ADD_INSTITUTION_SUBJECT_FAIL = 'ADD_INSTITUTION_SUBJECT_FAIL';
export const UPDATE_INSTITUTION_SUBJECT = 'UPDATE_INSTITUTION_SUBJECT';
export const UPDATE_INSTITUTION_SUBJECT_SUCCESS = 'UPDATE_INSTITUTION_SUBJECT_SUCCESS';
export const UPDATE_INSTITUTION_SUBJECT_FAIL = 'UPDATE_INSTITUTION_SUBJECT_FAIL';
export const DELETE_INSTITUTION_SUBJECT = 'DELETE_INSTITUTION_SUBJECT';
export const DELETE_INSTITUTION_SUBJECT_SUCCESS = 'DELETE_INSTITUTION_SUBJECT_SUCCESS';
export const DELETE_INSTITUTION_SUBJECT_FAIL = 'DELETE_INSTITUTION_SUBJECT_FAIL';
export const SHOW_INSTITUTION_SUBJECT_FORM = 'SHOW_INSTITUTION_SUBJECT_FORM';
export const CLOSE_INSTITUTION_SUBJECT_FORM = 'CLOSE_INSTITUTION_SUBJECT_FORM';
export const ADD_INSTITUTION = 'ADD_INSTITUTION';
export const ADD_INSTITUTION_SUCCESS = 'ADD_INSTITUTION_SUCCESS';
export const ADD_INSTITUTION_FAIL = 'ADD_INSTITUTION_FAIL';
export const UPDATE_INSTITUTION = 'UPDATE_INSTITUTION';
export const UPDATE_INSTITUTION_SUCCESS = 'UPDATE_INSTITUTION_SUCCESS';
export const UPDATE_INSTITUTION_FAIL = 'UPDATE_INSTITUTION_FAIL';
export const FETCH_INSTITUTION_TYPES = 'FETCH_INSTITUFETCH_INSTITUTION_TYPESTIONS';
export const FETCH_INSTITUTION_TYPES_SUCCESS = 'FETCH_INSTITUTION_TYPES_SUCCESS';
export const FETCH_INSTITUTION_TYPES_FAIL = 'FETCH_INSTITUTION_TYPES_FAIL';
export const DELETE_INSTITUTION = 'DELETE_INSTITUTION';
export const DELETE_INSTITUTION_SUCCESS = 'DELETE_INSTITUTION_SUCCESS';
export const DELETE_INSTITUTION_FAIL = 'DELETE_INSTITUTION_FAIL';
export const RESET_INSTITUTION_ERRORS = 'RESET_INSTITUTION_ERRORS';

/** ************************** Fetch institutions *************************** */
interface IFetchInstitutions {
  type: typeof FETCH_INSTITUTIONS;
}

interface IFetchInstitutionsSuccess {
  type: typeof FETCH_INSTITUTIONS_SUCCESS;
  payload: {
    institutions: IInstitution[];
  };
}

interface IFetchInstitutionsFail {
  type: typeof FETCH_INSTITUTIONS_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Fetch institution subjects *************************** */
interface IFetchInstitutionSubjects {
  type: typeof FETCH_INSTITUTION_SUBJECTS;
  institutionId: number;
}

interface IFetchInstitutionSubjectsSuccess {
  type: typeof FETCH_INSTITUTION_SUBJECTS_SUCCESS;
  institutionId: number;
  payload: {
    institutionSubjects: IInstitutionSubject[];
  };
}

interface IFetchInstitutionSubjectsFail {
  type: typeof FETCH_INSTITUTION_SUBJECTS_FAIL;
  institutionId: number;
  payload: {
    errors: IError;
  };
}

/** ************************** Add institution subjects *************************** */
export interface IAddInstitutionSubject {
  type: typeof ADD_INSTITUTION_SUBJECT;
  institutionId: number;
}

export interface IAddInstitutionSubjectSuccess {
  type: typeof ADD_INSTITUTION_SUBJECT_SUCCESS;
  institutionId: number;
  payload: {
    institutionSubjects: IInstitutionSubject[];
  };
}

export interface IAddInstitutionSubjectFail {
  type: typeof ADD_INSTITUTION_SUBJECT_FAIL;
  institutionId: number;
  payload: {
    errors: IError;
  };
}

/** ************************** Update institution subjects *************************** */
interface IUpdateInstitutionSubject {
  type: typeof UPDATE_INSTITUTION_SUBJECT;
  institutionId: number;
  institutionSubjectId: number;
}

interface IUpdateInstitutionSubjectSuccess {
  type: typeof UPDATE_INSTITUTION_SUBJECT_SUCCESS;
  institutionId: number;
  institutionSubjectId: number;
  payload: {
    institutionSubject: IInstitutionSubject;
  };
}

interface IUpdateInstitutionSubjectFail {
  type: typeof UPDATE_INSTITUTION_SUBJECT_FAIL;
  institutionId: number;
  institutionSubjectId: number;
  payload: {
    errors: IError;
  };
}

/** ************************** Delete institution subjects *************************** */
export interface IDeleteInstitutionSubject {
  type: typeof DELETE_INSTITUTION_SUBJECT;
  institutionId: number;
  institutionSubjectId: number;
}

export interface IDeleteInstitutionSubjectSuccess {
  type: typeof DELETE_INSTITUTION_SUBJECT_SUCCESS;
  payload: {
    institutionId: number;
    institutionSubjectId: number;
  };
}

export interface IDeleteInstitutionSubjectFail {
  type: typeof DELETE_INSTITUTION_SUBJECT_FAIL;
  institutionId: number;
  institutionSubjectId: number;
  payload: {
    errors: IError;
  };
}

/** ************************** Institution Subject Form *************************** */
interface IShowInstitutionSubjectForm {
  type: typeof SHOW_INSTITUTION_SUBJECT_FORM;
  id?: number;
}

interface ICloseInstitutionSubjectForm {
  type: typeof CLOSE_INSTITUTION_SUBJECT_FORM;
}

/** ************************** Fetch institution types *************************** */
interface IFetchInstitutionTypes {
  type: typeof FETCH_INSTITUTION_TYPES;
}

interface IFetchInstitutionTypesSuccess {
  type: typeof FETCH_INSTITUTION_TYPES_SUCCESS;
  payload: {
    institutionTypes: IInstitutionType[];
  };
}

interface IFetchInstitutionTypesFail {
  type: typeof FETCH_INSTITUTION_TYPES_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Add institution *************************** */
interface IAddInstitution {
  type: typeof ADD_INSTITUTION;
}

interface IAddInstitutionSuccess {
  type: typeof ADD_INSTITUTION_SUCCESS;
  payload: {
    institution: IInstitution;
  };
}

interface IAddInstitutionFail {
  type: typeof ADD_INSTITUTION_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Update institution *************************** */
interface IUpdateInstitution {
  type: typeof UPDATE_INSTITUTION;
}

interface IUpdateInstitutionSuccess {
  type: typeof UPDATE_INSTITUTION_SUCCESS;
  payload: {
    institution: IInstitution;
  };
}

interface IUpdateInstitutionFail {
  type: typeof UPDATE_INSTITUTION_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Delete institution *************************** */
interface IDeleteInstitution {
  type: typeof DELETE_INSTITUTION;
}

interface IDeleteInstitutionSuccess {
  type: typeof DELETE_INSTITUTION_SUCCESS;
  payload: {
    id: number;
  };
}

interface IDeleteInstitutionFail {
  type: typeof DELETE_INSTITUTION_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Reset institution errors *************************** */
interface IResetInstitutionErrors {
  type: typeof RESET_INSTITUTION_ERRORS;
}

export interface IInstitutionState {
  institutions: IInstitution[];
  fetchingInstitutions: boolean;
  errorsFetchingInstitutions: IError | null;
  institutionSubjects: IInstitutionSubject[];
  showingInstitutionSubjectsForm: boolean;
  currentInstitutionSubject: IInstitutionSubject | null;
  fetchingInstitutionSubjects: boolean;
  errorsFetchingInstitutionSubjects: IError | null;
  addingInstitutionSubject: boolean;
  errorsAddingInstitutionSubject: IError | null;
  updatingInstitutionSubject: boolean;
  errorsUpdatingInstitutionSubject: IError | null;
  deletingInstitutionSubject: boolean;
  errorsDeletingInstitutionSubject: IError | null;
  addingInstitution: boolean;
  errorsAddingInstitution: IError | null;
  updatingInstitution: boolean;
  errorsUpdatingInstitution: IError | null;
  deletingInstitution: boolean;
  errorsDeletingInstitution: IError | null;
  institutionTypes: IInstitutionType[];
  fetchingInstitutionTypes: boolean;
  errorsFetchingInstitutionTypes: IError | null;
}

export type InstitutionActionTypes =
  | IFetchInstitutions
  | IFetchInstitutionsSuccess
  | IFetchInstitutionsFail
  | IFetchInstitutionSubjects
  | IFetchInstitutionSubjectsSuccess
  | IFetchInstitutionSubjectsFail
  | IAddInstitutionSubject
  | IAddInstitutionSubjectSuccess
  | IAddInstitutionSubjectFail
  | IUpdateInstitutionSubject
  | IUpdateInstitutionSubjectSuccess
  | IUpdateInstitutionSubjectFail
  | IDeleteInstitutionSubject
  | IDeleteInstitutionSubjectSuccess
  | IDeleteInstitutionSubjectFail
  | IShowInstitutionSubjectForm
  | ICloseInstitutionSubjectForm
  | IAddInstitution
  | IAddInstitutionSuccess
  | IAddInstitutionFail
  | IUpdateInstitution
  | IUpdateInstitutionSuccess
  | IUpdateInstitutionFail
  | IDeleteInstitution
  | IDeleteInstitutionSuccess
  | IDeleteInstitutionFail
  | IFetchInstitutionTypes
  | IFetchInstitutionTypesSuccess
  | IFetchInstitutionTypesFail
  | IResetInstitutionErrors;
