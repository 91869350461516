import React, { Suspense } from 'react';
import { Router } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { ConfigProvider } from 'antd';
import enUS from 'antd/es/locale/en_US';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';
import { RootState } from './redux/reducers/index';

import './config/i18n';
import alAL from './config/antd_locale/al/al_AL';

import 'moment/locale/sq';
import 'moment/locale/en-gb';
import { ALBANIAN_LANGUAGE } from './constants/theme';
import AppLoading from './components/layout/AppLoading';
import Home from './Home';
import HistoryListener from './components/layout/HistoryListener';

export const history = createBrowserHistory();

const App: React.FC = () => {
  const language = useSelector((state: RootState) => state.theme.language);

  if (language === ALBANIAN_LANGUAGE) {
    moment.locale('sq');
  } else {
    moment.locale('en-GB');
  }

  return (
    <Suspense fallback={<AppLoading />}>
      <ConfigProvider locale={language === ALBANIAN_LANGUAGE ? alAL : enUS}>
        <Router history={history}>
          <HistoryListener>
            <Home />
          </HistoryListener>
        </Router>
      </ConfigProvider>
    </Suspense>
  );
};

export default App;
