import React from 'react';
import { Card } from 'antd';

interface IProps {
  title?: string;
  value?: string | number;
  subtitle?: string;
  prefix?: any;
  style: any;
}

const StatisticWidget: React.FC<IProps> = ({ title, value, subtitle, prefix, style }) => {
  return (
    <Card style={style} className="ant-card-default-border">
      {title && <h4 className="mb-0">{title}</h4>}
      <div className={`${prefix ? 'd-flex' : ''}`}>
        {prefix ? <div className="mr-2">{prefix}</div> : null}
        <div>
          <div className="d-flex align-items-center">
            {value && <h1 className="mb-0 font-weight-bold">{value}</h1>}
          </div>
          {subtitle && <div className="text-gray-light mt-1">{subtitle}</div>}
        </div>
      </div>
    </Card>
  );
};

export default StatisticWidget;
