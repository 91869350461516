import React from 'react';
import { Button, Col, Drawer, List, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ISubject } from '../../../../../api/models/subject';
import Spinner from '../../../../layout/shared/Spinner';

interface IProps {
  loading: boolean;
  onAddSubject: (subjectId: number) => void;
  title: string;
  availableSubjects: ISubject[];
  visible: boolean;
  onClose: () => void;
}

const SubjectsDrawer: React.FC<IProps> = ({
  loading,
  title,
  onAddSubject,
  availableSubjects,
  visible,
  onClose
}) => {
  const renderContent = () => {
    if (loading) {
      return <Spinner />;
    }

    return (
      <List
        dataSource={availableSubjects}
        renderItem={(subject) => (
          <List.Item>
            <Row className="w-100" align="middle">
              <Col xs={24} md={12}>
                {subject.name}
              </Col>
              <Col
                xs={24}
                md={{ span: 8, offset: 4 }}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Button
                  onClick={() => onAddSubject(subject.id)}
                  icon={<PlusOutlined />}
                  size="small"
                />
              </Col>
            </Row>
          </List.Item>
        )}
      />
    );
  };

  return (
    <Drawer
      width={450}
      title={title}
      placement="right"
      closable
      onClose={onClose}
      visible={visible}
    >
      {renderContent()}
    </Drawer>
  );
};

export default SubjectsDrawer;
