import {
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  RESET_USER_ERRORS,
  IUsersState,
  UserActionTypes
} from '../constants/users';

const initialState: IUsersState = {
  users: [],
  fetchingUsers: false,
  errorsFetchingUsers: null,
  updatingUser: false,
  errorsUpdatingUser: null,
  addingUser: false,
  errorsAddingUser: null,
  deletingUser: false,
  errorsDeletingUser: null
};

const userReducer = (state = initialState, action: UserActionTypes): IUsersState => {
  switch (action.type) {
    case FETCH_USERS:
      return {
        ...state,
        fetchingUsers: true
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.users,
        errorsFetchingUsers: null,
        fetchingUsers: false
      };
    case FETCH_USERS_FAIL:
      return {
        ...state,
        users: [],
        errorsFetchingUsers: action.payload.errors,
        fetchingUsers: false
      };
    case UPDATE_USER:
      return {
        ...state,
        updatingUser: true
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map((user) =>
          user.id === action.payload.user.id ? action.payload.user : user
        ),
        errorsUpdatingUser: null,
        updatingUser: false
      };
    case UPDATE_USER_FAIL:
      return {
        ...state,
        errorsUpdatingUser: action.payload.errors,
        updatingUser: false
      };
    case ADD_USER:
      return {
        ...state,
        addingUser: true
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload.user],
        errorsAddingUser: null,
        addingUser: false
      };
    case ADD_USER_FAIL:
      return {
        ...state,
        errorsAddingUser: action.payload.errors,
        addingUser: false
      };
    case DELETE_USER:
      return {
        ...state,
        deletingUser: true
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter((user) => user.id !== action.payload.id),
        errorsDeletingUser: null,
        deletingUser: false
      };
    case DELETE_USER_FAIL:
      return {
        ...state,
        errorsDeletingUser: action.payload.errors,
        deletingUser: false
      };
    case RESET_USER_ERRORS:
      return {
        ...state,
        errorsFetchingUsers: null,
        errorsUpdatingUser: null,
        errorsAddingUser: null,
        errorsDeletingUser: null
      };
    default:
      return state;
  }
};

export default userReducer;
