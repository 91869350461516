import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Button, Card, Col, Row, Select, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import PageHeaderAlt from '../../layout/PageHeaderAlt';
import Flex from '../../layout/shared/Flex';
import { IDocument } from '../../../api/models/document';

import { RootState } from '../../../redux/reducers';
import AppBreadCrumb from '../../layout/AppBreadCrumb';
import UploadDocument from '../../layout/UploadDocument';
import { IEvidenceTemplate } from '../../../api/models/evidenceTemplate';
import {
  addEvidenceTemplate,
  updateEvidenceTemplate
} from '../../../redux/actions/evidenceTemplate';
import Alert from '../../layout/shared/Alert';

const { Option } = Select;

interface IProps {
  evidenceTemplate?: IEvidenceTemplate;
}

const EvidenceTemplateForm: React.FC<IProps> = ({ evidenceTemplate = null }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [document, setDocument] = useState<IDocument | null>(evidenceTemplate?.document ?? null);
  const [uploading, setUploading] = useState<boolean>(false);
  const fields = useSelector((state: RootState) => state.field.fields);

  const addingEvidenceTemplate = useSelector(
    (state: RootState) => state.evidenceTemplate.addingEvidenceTemplate
  );
  const updatingEvidenceTemplate = useSelector(
    (state: RootState) => state.evidenceTemplate.updatingEvidenceTemplate
  );

  const saving = evidenceTemplate ? updatingEvidenceTemplate : addingEvidenceTemplate;

  const errors = useSelector((state: RootState) => state.evidenceTemplate.errors);

  const onFinish = (values: any) => {
    const { name, fieldId } = values;

    if (evidenceTemplate) {
      dispatch(
        updateEvidenceTemplate(
          evidenceTemplate.id,
          {
            name,
            documentId: document?.id ?? null
          },
          t('feedback_message.evidence_successfully_updated')
        )
      );
    } else {
      dispatch(
        addEvidenceTemplate(
          {
            name,
            documentId: document?.id,
            fieldId
          },
          form,
          t('feedback_message.evidence_successfully_added')
        )
      );
    }
  };

  const onSuccessDocumentUpload = (doc: IDocument | null) => {
    setDocument(doc);
  };
  const onDocumentUploading = (flag: boolean) => {
    setUploading(flag);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      className="ant-advanced-search-form"
      onFinish={onFinish}
      initialValues={
        evidenceTemplate
          ? {
              name: evidenceTemplate.name,
              fieldId: evidenceTemplate.field.id
            }
          : {}
      }
    >
      <PageHeaderAlt className="bg-white border-bottom">
        <Flex className="py-2" mobileFlex={false} justifyContent="between" alignItems="center">
          <h2>{evidenceTemplate ? t('general.edit_evidence') : t('general.add_evidence')}</h2>
          <Button type="primary" htmlType="submit" disabled={uploading} loading={saving}>
            {evidenceTemplate ? t('general.edit') : t('general.add')}
          </Button>
        </Flex>
        <AppBreadCrumb
          items={[
            { title: t('sidemenu.evidence'), path: '/platforma/admin/dokumenta/evidenca' },
            {
              title: evidenceTemplate ? t('general.edit_evidence') : t('general.add_evidence'),
              path: null
            }
          ]}
        />
      </PageHeaderAlt>

      <Row gutter={16}>
        <Col xs={24} xl={8}>
          <Card style={{ marginTop: '25px' }}>
            <Form.Item
              label={t('general.document_name')}
              name="name"
              rules={[{ required: true, message: t('validation_messages.enter_document_name') }]}
            >
              <Input />
            </Form.Item>
          </Card>
        </Col>
        <Col xs={24} xl={8}>
          <Card style={{ marginTop: '25px' }}>
            <Form.Item
              name="fieldId"
              label={t('general.field')}
              rules={[{ required: true, message: t('validation_messages.select_field') }]}
            >
              <Select placeholder={t('placeholders.select_field')} disabled={!!evidenceTemplate}>
                {fields.map((field, index) => (
                  <Option key={index.toString()} value={field.id}>
                    {field.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Card>
        </Col>
        <Col xs={24} xl={8}>
          <Card style={{ marginTop: '25px' }}>
            <Form.Item label={t('general.document')} name="document">
              <UploadDocument
                documentId={evidenceTemplate?.document?.id ?? null}
                upload={onSuccessDocumentUpload}
                uploading={onDocumentUploading}
              />
            </Form.Item>
          </Card>
        </Col>
        {errors && (
          <Col span={24}>
            <Alert message={errors.message} />
          </Col>
        )}
      </Row>
    </Form>
  );
};

export default EvidenceTemplateForm;
