import React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import InstitutionsForm from '../../../../components/admin/institutions/InstitutionsForm';
import { RootState } from '../../../../redux/reducers';
import NotFound from '../../../404';

interface MatchParams {
  id: string;
}

interface IProps extends RouteComponentProps<MatchParams> {}

const EditInstitution: React.FC<IProps> = ({ match }) => {
  const institution = useSelector((state: RootState) => state.institution.institutions).find(
    (el) => el.id === +match.params.id
  );

  if (!institution) return <NotFound showContainer={false} />;

  return <InstitutionsForm institution={institution} />;
};

export default EditInstitution;
