import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

interface INavigationItem {
  title: string;
  path: string | null;
}

interface IProps {
  items: INavigationItem[];
}

const AppBreadCrumb: React.FC<IProps> = ({ items }) => {
  const buildBreadcrumb = items.map((item) => {
    return (
      <Breadcrumb.Item key={item.path}>
        {item.path ? <Link to={item.path}>{item.title}</Link> : item.title}
      </Breadcrumb.Item>
    );
  });

  return <Breadcrumb>{buildBreadcrumb}</Breadcrumb>;
};

export default AppBreadCrumb;
