import { InstitutionTypeName } from '../../constants/theme';

export interface IEvaluationPeriodSendRequest {
  period: number;
  startDate: Date;
  endDate: Date;
  documentId: number | null;
}

export interface IEvaluationPeriod extends IEvaluationPeriodSendRequest {
  id: number;
}

export interface IEvaluationPeriodDisplay {
  id: number;
  period: number;
  startDate: string;
  endDate: string;
  documentId: number | null;
  schoolPeriod: string;
  coursePeriod: string;
}

export const getEvaluationPeriodBasedOnInstitutionType = (
  year: number,
  institutionType: string
) => {
  if (institutionType === InstitutionTypeName.SHKOLLE) return `${year - 2}-${year - 1}`;

  return year - 2;
};
