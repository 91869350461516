import { IError } from '../../api/models/error';
import { IUser } from '../../api/models/user';

export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAIL = 'ADD_USER_FAIL';
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';
export const RESET_USER_ERRORS = 'RESET_USER_ERRORS';

export interface IUsersState {
  users: IUser[];
  fetchingUsers: boolean;
  errorsFetchingUsers: IError | null;
  updatingUser: boolean;
  errorsUpdatingUser: IError | null;
  addingUser: boolean;
  errorsAddingUser: IError | null;
  deletingUser: boolean;
  errorsDeletingUser: IError | null;
}

/** ************************** Fetch users *************************** */
interface IFetchUsers {
  type: typeof FETCH_USERS;
}

interface IFetchUsersSuccess {
  type: typeof FETCH_USERS_SUCCESS;
  payload: {
    users: IUser[];
  };
}

interface IFetchUsersFail {
  type: typeof FETCH_USERS_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Add user *************************** */
interface IAddUser {
  type: typeof ADD_USER;
}

interface IAddUserSuccess {
  type: typeof ADD_USER_SUCCESS;
  payload: {
    user: IUser;
  };
}

interface IAddUserFail {
  type: typeof ADD_USER_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Update user *************************** */
interface IUpdateUser {
  type: typeof UPDATE_USER;
}

interface IUpdateUserSuccess {
  type: typeof UPDATE_USER_SUCCESS;
  payload: {
    user: IUser;
  };
}

interface IUpdateUserFail {
  type: typeof UPDATE_USER_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Delete user *************************** */
interface IDeleteUser {
  type: typeof DELETE_USER;
}

interface IDeleteUserSuccess {
  type: typeof DELETE_USER_SUCCESS;
  payload: {
    id: number;
  };
}

interface IDeleteUserFail {
  type: typeof DELETE_USER_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Reset users errors *************************** */
interface IResetUsersErrors {
  type: typeof RESET_USER_ERRORS;
}

export type UserActionTypes =
  | IAddUser
  | IAddUserSuccess
  | IAddUserFail
  | IFetchUsers
  | IFetchUsersSuccess
  | IFetchUsersFail
  | IUpdateUser
  | IUpdateUserSuccess
  | IUpdateUserFail
  | IDeleteUser
  | IDeleteUserSuccess
  | IDeleteUserFail
  | IResetUsersErrors;
