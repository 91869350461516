import React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import YearlyStatisticalDataList from '../../../../components/institutions/my-institution/YearlyStatisticalDataList';
import { RootState } from '../../../../redux/reducers';
import NotFound from '../../../404';

interface MatchParams {
  id: string;
}

interface IProps extends RouteComponentProps<MatchParams> {}

const EditYearlyStatisticalData: React.FC<IProps> = ({ match }) => {
  const evaluationPeriodId = +match.params.id;

  const propertiesList = useSelector((state: RootState) => state.property.properties);
  const myInstitution = useSelector((state: RootState) => state.myInstitution.institution);
  const evaluationPeriods = useSelector(
    (state: RootState) => state.evaluationPeriod.evaluationPeriods
  );
  const allSubjects = useSelector((state: RootState) =>
    state.subject.subjects.filter(
      (item) => item.institutionTypeId === myInstitution?.institutionType.id
    )
  );
  const institutionSubjects = useSelector((state: RootState) =>
    state.myInstitution.subjects.filter(
      (subject) => subject.evaluationPeriod.id === evaluationPeriodId
    )
  );

  const evaluationPeriod = evaluationPeriods.filter((el) => el.id === evaluationPeriodId)[0];

  const properties = propertiesList.filter(
    (property) => property.institutionType.id === myInstitution?.institutionType.id
  );

  const institutionStatistics = useSelector(
    (state: RootState) => state.myInstitution.statistics
  ).filter((statistics) => statistics.evaluationPeriodId === evaluationPeriodId)[0];

  if (!evaluationPeriod) return <NotFound showContainer={false} />;

  return myInstitution ? (
    <YearlyStatisticalDataList
      properties={properties}
      allSubjects={allSubjects}
      institutionSubjects={institutionSubjects}
      myInstitution={myInstitution}
      evaluationPeriodId={evaluationPeriodId}
      institutionStatistics={institutionStatistics}
    />
  ) : null;
};

export default EditYearlyStatisticalData;
