import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from 'antd';
import { SIDE_NAV_WIDTH, SIDE_NAV_COLLAPSED_WIDTH } from '../../constants/theme';
import utils from '../../utils/index';

import { RootState } from '../../redux/reducers/index';

const { useBreakpoint } = Grid;

const getLogoWidthGutter = (navCollapsed: boolean, isMobile: boolean) => {
  if (isMobile) return 0;

  return navCollapsed ? `${SIDE_NAV_COLLAPSED_WIDTH}px` : `${SIDE_NAV_WIDTH}px`;
};

const getLogo = (navCollapsed: boolean) => {
  return navCollapsed ? '/img/logo-akafpk-sm.png' : '/img/logo-akafpk.png';
};

const getLogoDisplay = (isMobile: boolean, mobileLogo: boolean) => {
  if (isMobile && !mobileLogo) {
    return 'd-none';
  }
  return 'logo';
};

interface IProps {
  mobileLogo?: boolean;
}

const Logo: React.FC<IProps> = ({ mobileLogo = false }) => {
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
  const navCollapsed = useSelector((state: RootState) => state.theme.navCollapsed);

  return (
    <div
      className={getLogoDisplay(isMobile, mobileLogo)}
      style={{ width: `${getLogoWidthGutter(navCollapsed, isMobile)}` }}
    >
      <img
        src={getLogo(navCollapsed)}
        alt="osap"
        style={{
          width: '90%',
          height: '90%',
          objectFit: 'scale-down'
        }}
      />
    </div>
  );
};

export default Logo;
