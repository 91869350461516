import React from 'react';
import { Row, Col, Card, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { getEvaluationPeriodBasedOnInstitutionType } from '../../../../api/models/evaluationPeriod';
import { RootState } from '../../../../redux/reducers';
import NotFound from '../../../../views/404';
import AppBreadCrumb from '../../../layout/AppBreadCrumb';
// import EvidenceList from './evidence/EvidenceList';
import SubFieldsList from './subfields/SubFieldsList';

interface MatchParams {
  selfEvaluationId: string;
}

interface IProps extends RouteComponentProps<MatchParams> {}

const FieldsOverview: React.FC<IProps> = ({ match }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const institution = useSelector((state: RootState) => state.myInstitution.institution);
  const selfEvaluations = useSelector((state: RootState) => state.myInstitution.selfEvaluation);

  const selfEvaluation = selfEvaluations.filter(
    (item) => item.id === +match.params.selfEvaluationId
  )[0];

  const setTitle = () => {
    return `${selfEvaluation && selfEvaluation.field.name} (${
      selfEvaluation &&
      institution &&
      getEvaluationPeriodBasedOnInstitutionType(
        selfEvaluation.evaluationPeriod.period,
        institution.institutionType.name
      )
    })`;
  };

  if (!selfEvaluation) return <NotFound />;

  return (
    <>
      <AppBreadCrumb
        items={[
          { title: t('sidemenu.selfevaluation'), path: '../vetevleresimi' },
          { title: setTitle(), path: null }
        ]}
      />
      <Row gutter={16} style={{ marginTop: '25px' }}>
        <Col xs={24}>
          <Card title={setTitle()}>
            {selfEvaluation && (
              <span style={{ whiteSpace: 'pre-line' }}>
                {selfEvaluation.field.description.replace(/\\n/g, '\n').replace(/\\u/g, '-')}
              </span>
            )}
          </Card>
        </Col>
        {selfEvaluation && (
          <>
            <Col xs={24}>
              <SubFieldsList selfEvaluation={selfEvaluation} />
            </Col>
            {/* <Col xs={24} lg={12}>
              <EvidenceList selfEvaluation={selfEvaluation} />
            </Col> */}
          </>
        )}
      </Row>
      <Row className="w-100" align="middle" justify="space-between">
        <Col sm={12}>
          <Button
            type="primary"
            htmlType="submit"
            icon={<ArrowLeftOutlined />}
            onClick={() => history.goBack()}
          >
            {t('general.go_back')}
          </Button>
        </Col>
      </Row>
    </>
  );
};
export default FieldsOverview;
