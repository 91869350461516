import React from 'react';

interface IProps {
  children: any;
  background?: string;
  className: string;
  overlap?: boolean;
}

const PageHeaderAlt: React.FC<IProps> = ({ children, background, className, overlap }) => {
  const getStyle = () => {
    return { backgroundImage: background ? `url(${background})` : 'none' };
  };

  return (
    <div
      className={`page-header-alt ${className || ''} ${overlap && 'overlap'}`}
      style={getStyle()}
    >
      <>{children}</>
    </div>
  );
};

export default PageHeaderAlt;
