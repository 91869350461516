import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import InstitutionOverview from '../../../../components/admin/institutions/InstitutionOverview';

interface MatchParams {
  id: string;
}

interface IProps extends RouteComponentProps<MatchParams> {}

const Overview: React.FC<IProps> = ({ match }) => {
  return <InstitutionOverview id={+match.params.id} />;
};

export default Overview;
