import React, { useState } from 'react';
import { Button, Card, Collapse, List, Row, Col, Grid, message, Badge } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import {
  EditOutlined,
  EyeOutlined,
  UnorderedListOutlined,
  PaperClipOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  UploadOutlined,
  FileExcelOutlined
} from '@ant-design/icons';
import _ from 'lodash';
import moment from 'moment';
import PageHeaderAlt from '../../layout/PageHeaderAlt';
import Flex from '../../layout/shared/Flex';
import { RootState } from '../../../redux/reducers';
import { IInstitutionSelfEvaluation } from '../../../api/models/selfEvaluation';
import { getEvaluationPeriodBasedOnInstitutionType } from '../../../api/models/evaluationPeriod';
import agent from '../../../api/agent';
import Spinner from '../../layout/shared/Spinner';
import { IError } from '../../../api/models/error';
import selfEvaluationReportExcel from './fields-overview/report_template/selfEvaluationReport_excel';
import selfEvaluationReportPDF from './fields-overview/report_template/selfEvaluationReport_pdf';
import {
  ISummaryTemplateDisplay,
  SUMMARY_TEMPLATE_NAME
} from '../../../api/models/summaryTemplate';
import SummaryModal, { ISummaryData } from './SummaryModal';
import Alert from '../../layout/shared/Alert';
import Utils from '../../../utils';
import { UserRole } from '../../../constants/theme';

const { useBreakpoint } = Grid;

interface ISelfEvaluationSection {
  year: number;
  selfEvaluations: IInstitutionSelfEvaluation[];
  summaryTemplate?: ISummaryTemplateDisplay;
}

type ISectionListItem = IInstitutionSelfEvaluation | ISummaryTemplateDisplay;

const isSelfEvaluation = (item: ISectionListItem): item is IInstitutionSelfEvaluation => {
  return (item as IInstitutionSelfEvaluation).field !== undefined;
};

const sectionListItems = (selfEvaluationSection: ISelfEvaluationSection): ISectionListItem[] => {
  const items: ISectionListItem[] = [...selfEvaluationSection.selfEvaluations];
  if (selfEvaluationSection.summaryTemplate) {
    items.push(selfEvaluationSection.summaryTemplate);
  }
  return items;
};

const SelfEvaluationList = () => {
  const { t } = useTranslation();
  const { Panel } = Collapse;
  const match = useRouteMatch();
  const [currentSummary, setCurrentSummary] = useState<ISummaryData | undefined>(undefined);
  const selfEvaluations = useSelector((state: RootState) => state.myInstitution.selfEvaluation);
  const evaluationPeriods = useSelector(
    (state: RootState) => state.evaluationPeriod.evaluationPeriods
  );
  const institution = useSelector((state: RootState) => state.myInstitution.institution);
  const userRole = useSelector((state: RootState) => state.auth.role);
  const fields = useSelector((state: RootState) => state.field.fields);
  const summaryTemplate = useSelector((state: RootState) => state.summaryTemplate.summaryTemplate);
  const summaries = useSelector((state: RootState) => state.summary.summaries);
  const errors = useSelector((state: RootState) => state.summary.errors);

  const isMobile = !Utils.getBreakPoint(useBreakpoint()).includes('sm');

  const [loading, setLoading] = useState<boolean>(false);

  const sections = () => {
    const result: ISelfEvaluationSection[] = [];

    const groupedSelfEvaluations = _.groupBy(selfEvaluations, 'evaluationPeriod.period');

    Object.keys(groupedSelfEvaluations).forEach((property) => {
      const section: ISelfEvaluationSection = { year: +property, selfEvaluations: [] };

      const tempSelfEvaluations = groupedSelfEvaluations[property];

      if (tempSelfEvaluations) {
        section.selfEvaluations = tempSelfEvaluations;
      }

      const sectionEvaluationPeriod = section.selfEvaluations[0]?.evaluationPeriod;

      const summaryForSection = summaries.find(
        (summary) => summary.evaluationPeriodId === sectionEvaluationPeriod.id
      );

      if (sectionEvaluationPeriod && summaryTemplate) {
        section.summaryTemplate = {
          templateId: summaryTemplate.id,
          evaluationPeriod: sectionEvaluationPeriod,
          name: SUMMARY_TEMPLATE_NAME,
          templateDocument: summaryTemplate.document,
          summaryDocument: summaryForSection?.document ?? undefined,
          summaryId: summaryForSection?.id
        };
      }

      result.push(section);
    });

    return result;
  };

  const generateSelfEvaluationReport = async (
    institutionId: number,
    selfEvaluationId: number,
    generateReport: any
  ) => {
    try {
      setLoading(true);

      const data = await agent.Report.listFieldsData(institutionId, selfEvaluationId);

      const selfEvaluation = selfEvaluations.filter((item) => item.id === selfEvaluationId)[0];

      if (institution && selfEvaluation) generateReport(data, selfEvaluation, institution, fields);

      setLoading(false);
    } catch (error) {
      const err: IError = error?.data ?? error;

      message.error({ content: err?.message ?? error, duration: 4 });

      setLoading(false);
    }
  };

  if (loading) return <Spinner />;

  const renderSummaryTemplate = (item: ISummaryTemplateDisplay) => {
    return (
      <List.Item key={item.name}>
        <Row className="w-100" align="middle" style={{ paddingLeft: isMobile ? 0 : '24px' }}>
          <Col xs={24} md={12}>
            <h4>{item.name}</h4>
          </Col>
          <Col
            xs={24}
            md={{ span: 6, offset: 6 }}
            style={{
              display: isMobile ? '' : 'flex',
              justifyContent: isMobile ? 'normal' : 'flex-end'
            }}
          >
            <FileTextOutlined
              style={{ fontSize: '25px', color: '#3e82f7' }}
              onClick={() => {
                const { templateDocument } = item;

                if (templateDocument) Utils.downloadDocument(templateDocument?.id);
              }}
            />
            {item.summaryDocument && (
              <Badge size="small" count={1}>
                <PaperClipOutlined
                  style={{ fontSize: '25px', color: '#3e82f7', marginLeft: '20px' }}
                  onClick={() => {
                    const { summaryDocument } = item;

                    if (summaryDocument) Utils.downloadDocument(summaryDocument?.id);
                  }}
                />
              </Badge>
            )}
            {userRole === UserRole.InstitutionAdmin &&
              Utils.isDateInRange(
                moment(item.evaluationPeriod.startDate),
                moment(item.evaluationPeriod.endDate)
              ) && (
                <UploadOutlined
                  style={{ fontSize: '25px', color: '#3e82f7', marginLeft: '20px' }}
                  onClick={() => {
                    if (institution) {
                      setCurrentSummary({
                        institutionId: institution?.id,
                        templateId: item.templateId,
                        evaluationPeriodId: item.evaluationPeriod.id,
                        summaryId: item.summaryId,
                        summaryDocument: item.summaryDocument
                      });
                    }
                  }}
                />
              )}
          </Col>
        </Row>
      </List.Item>
    );
  };

  const renderSelfEvaluation = (item: IInstitutionSelfEvaluation, index: number) => {
    return (
      <List.Item key={item.id}>
        <Row className="w-100" align="middle" style={{ paddingLeft: isMobile ? 0 : '24px' }}>
          <Col xs={24} md={12}>
            {`${index + 1}.${item.field.name} (${
              item.percentage ? Math.floor(+item.percentage) : 0
            } %)`}
          </Col>
          <Col
            xs={24}
            md={{ span: 6, offset: 6 }}
            style={{
              display: isMobile ? '' : 'flex',
              justifyContent: isMobile ? 'normal' : 'flex-end'
            }}
          >
            <FilePdfOutlined
              style={{ fontSize: '20px', color: '#3e82f7', marginLeft: '20px', marginTop: '6px' }}
              onClick={() => {
                if (institution)
                  generateSelfEvaluationReport(institution?.id, item.id, selfEvaluationReportPDF);
              }}
            />
            <FileExcelOutlined
              style={{ fontSize: '20px', color: '#3e82f7', marginLeft: '20px', marginTop: '6px' }}
              onClick={() => {
                if (institution)
                  generateSelfEvaluationReport(institution?.id, item.id, selfEvaluationReportExcel);
              }}
            />
            <Link to={`${match.url}/${item.id}`}>
              <Button
                style={{ marginTop: isMobile ? '5px' : 0, marginLeft: '20px' }}
                type="primary"
                className="mr-2"
                icon={
                  userRole === UserRole.InstitutionAdmin &&
                  Utils.isDateInRange(
                    moment(item.evaluationPeriod.startDate),
                    moment(item.evaluationPeriod.endDate)
                  ) ? (
                    <EditOutlined />
                  ) : (
                    <EyeOutlined />
                  )
                }
                size="small"
              >
                {userRole === UserRole.InstitutionAdmin &&
                Utils.isDateInRange(
                  moment(item.evaluationPeriod.startDate),
                  moment(item.evaluationPeriod.endDate)
                )
                  ? t('general.edit')
                  : t('general.view')}
              </Button>
            </Link>
          </Col>
        </Row>
      </List.Item>
    );
  };

  const renderListItem = (item: ISectionListItem, index: number) => {
    if (isSelfEvaluation(item)) {
      return renderSelfEvaluation(item, index);
    }
    return renderSummaryTemplate(item);
  };

  return (
    <>
      <PageHeaderAlt className="bg-white border-bottom">
        <Flex className="py-2" mobileFlex={false} justifyContent="between" alignItems="center">
          <h2>{t('sidemenu.selfevaluation')}</h2>
          <Link to={`${match.url}/shto-periudhe`}>
            <Button
              disabled={evaluationPeriods.length === 0}
              type="primary"
              htmlType="submit"
              icon={<UnorderedListOutlined />}
            >
              {/* {institution?.institutionType.name === InstitutionTypeName.SHKOLLE
                ? t('general.academic_year')
                : t('general.calendar_year')} */}
              {t('general.select_field')}
            </Button>
          </Link>
        </Flex>
      </PageHeaderAlt>

      <SummaryModal
        summaryData={currentSummary}
        visible={currentSummary !== undefined}
        onClose={() => {
          setCurrentSummary(undefined);
        }}
      />

      {errors && <Alert message={errors.message} />}

      {sections().map((section, index) => {
        return (
          <Card style={{ marginTop: '25px' }} key={index.toString()}>
            <Collapse bordered={false}>
              <Panel
                header={
                  institution &&
                  getEvaluationPeriodBasedOnInstitutionType(
                    section.year,
                    institution.institutionType.name
                  )
                }
                key={+index.toString() * 31}
              >
                <List dataSource={sectionListItems(section)} renderItem={renderListItem} />
              </Panel>
            </Collapse>
          </Card>
        );
      })}
    </>
  );
};

export default SelfEvaluationList;
