import DatePicker from './datepicker';

const localeValues = {
  locale: 'al',
  DatePicker,
  Table: {
    filterTitle: 'Filtro',
    filterConfirm: 'OK',
    filterReset: 'Rivendos',
    selectAll: 'Zgjidh faqen aktuale',
    selectInvert: 'Përmbys zgjedhjen e faqes aktuale',
    selectionAll: 'Zgjidhni të gjitha të dhënat',
    sortTitle: 'Rendit',
    expand: 'Zgjero vijën',
    collapse: 'Zvogëlo vijën',
    triggerDesc: 'Rendit në rendit zbritës',
    triggerAsc: 'Rendit në rendit rritës',
    cancelSort: 'Anullo'
  },
  Modal: {
    okText: 'OK',
    cancelText: 'Anullo',
    justOkText: 'OK'
  },
  Popconfirm: {
    okText: 'OK',
    cancelText: 'Anullo'
  },
  Transfer: {
    searchPlaceholder: 'Kërko',
    itemUnit: 'element',
    itemsUnit: 'elementë'
  },
  Empty: {
    description: 'Nuk ka të dhëna'
  },
  Upload: {
    uploading: 'Duke ngarkuar...',
    removeFile: 'Fshi skedarin',
    uploadError: 'Gabim në ngarkim',
    previewFile: 'Paraqit skedarin',
    downloadFile: 'Shkarko skedarin'
  },
  Text: {
    edit: 'Ndrysho',
    copy: 'Kopjo',
    copied: 'Kopjuar'
    // expand: 'Développer'
  },
  PageHeader: {
    back: 'Kthehu'
  }
};

export default localeValues;
