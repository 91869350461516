import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { message } from 'antd';
import {
  FETCH_INSTITUTION_SUMMARY,
  FETCH_INSTITUTION_SUMMARY_SUCCESS,
  FETCH_INSTITUTION_SUMMARY_FAIL,
  ADD_INSTITUTION_SUMMARY,
  ADD_INSTITUTION_SUMMARY_SUCCESS,
  ADD_INSTITUTION_SUMMARY_FAIL,
  UPDATE_INSTITUTION_SUMMARY,
  UPDATE_INSTITUTION_SUMMARY_SUCCESS,
  UPDATE_INSTITUTION_SUMMARY_FAIL,
  DELETE_INSTITUTION_SUMMARY,
  DELETE_INSTITUTION_SUMMARY_SUCCESS,
  DELETE_INSTITUTION_SUMMARY_FAIL,
  RESET_INSTITUTION_SUMMARY_ERRORS,
  SummaryActionTypes
} from '../constants/summary';
import agent from '../../api/agent';
import { RootState } from '../reducers/index';
import { IError } from '../../api/models/error';
import { ISummarySendRequest, ISummaryUpdateRequest } from '../../api/models/summary';

export const fetchSummaries = (
  institutionId: number
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: FETCH_INSTITUTION_SUMMARY
  });

  try {
    const summaries = await agent.Summary.list(institutionId);

    dispatch({
      type: FETCH_INSTITUTION_SUMMARY_SUCCESS,
      payload: {
        summaries
      }
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: FETCH_INSTITUTION_SUMMARY_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const addSummary = (
  institutionId: number,
  summary: ISummarySendRequest,
  successMessage: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: ADD_INSTITUTION_SUMMARY
  });

  try {
    const addedSummary = await agent.Summary.add(institutionId, summary);

    dispatch({
      type: ADD_INSTITUTION_SUMMARY_SUCCESS,
      payload: {
        summary: addedSummary
      }
    });

    message.success({
      content: successMessage,
      duration: 4
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: ADD_INSTITUTION_SUMMARY_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const updateSummary = (
  institutionId: number,
  summaryId: number,
  summary: ISummaryUpdateRequest,
  successMessage: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: UPDATE_INSTITUTION_SUMMARY
  });

  try {
    const updatedSummary = await agent.Summary.update(institutionId, summaryId, summary);

    dispatch({
      type: UPDATE_INSTITUTION_SUMMARY_SUCCESS,
      payload: {
        summary: updatedSummary
      }
    });

    message.success({
      content: successMessage,
      duration: 4
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: UPDATE_INSTITUTION_SUMMARY_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const deleteSummary = (
  institutionId: number,
  summaryId: number,
  successMessage: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: DELETE_INSTITUTION_SUMMARY
  });

  try {
    await agent.Evidence.delete(institutionId, summaryId);

    dispatch({
      type: DELETE_INSTITUTION_SUMMARY_SUCCESS,
      payload: {
        id: summaryId
      }
    });

    message.success({
      content: successMessage,
      duration: 4
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: DELETE_INSTITUTION_SUMMARY_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const resetInstitutionSummaryErrors = (): SummaryActionTypes => {
  return {
    type: RESET_INSTITUTION_SUMMARY_ERRORS
  };
};
