import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Card, Col, message, Row } from 'antd';
import html2canvas from 'html2canvas';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Header from '../../../../../components/admin/reports/StatisticsReportHeader';
import HorizontalBarChart from '../../../../../components/admin/charts/HorizontalBarChart';
import LineChart from '../../../../../components/admin/charts/LineChart';
import { IChartSeries, IReportImage, IResultByYearsReport } from '../../../../../api/models/report';
import { IError } from '../../../../../api/models/error';
import agent from '../../../../../api/agent';
import Spinner from '../../../../../components/layout/shared/Spinner';
import generatePDFReport from '../../pdfReport';
import generateExcelReport from '../../excelReport';

const EvaluationPerformanceByYearsReport = () => {
  const { t } = useTranslation();

  const [results, setResults] = useState<IResultByYearsReport | null>(null);
  const [loadingResults, setLoadingResults] = useState<boolean>(true);
  const [institutionProgress, setInstitutionProgress] = useState<IChartSeries[]>([]);
  const [groupedByFields, setGroupedByFields] = useState<IChartSeries[]>([]);
  const [groupedByInstitutions, setGroupedByInstitutions] = useState<IChartSeries[]>([]);
  const [generatingPDF, setGeneratingPDF] = useState<boolean>(false);
  const [generatingExcel, setGeneratingExcel] = useState<boolean>(false);

  const performanceByYearsImage = useRef<HTMLDivElement>(null);

  const getResults = async () => {
    let result = null;

    setLoadingResults(true);

    result = await agent.Report.getPerformanceByYears();

    setLoadingResults(false);

    setResults(result);
  };

  const generatePDF = async () => {
    const images: IReportImage[] = [];
    const reportTitle = `${t('general.self_evaluation_result')}`;

    const image = {
      element: performanceByYearsImage.current
    };

    try {
      setGeneratingPDF(true);
      window.scrollTo(0, 0);

      if (image && image.element) {
        const canvas = await html2canvas(image.element, { scrollX: -7 });

        images.push({ url: canvas.toDataURL('image/png') });

        generatePDFReport(images, reportTitle, 'raport_performance_nder_vite');
        setGeneratingPDF(false);
      }
    } catch (error) {
      setGeneratingPDF(false);
    }
  };

  const generateChartsDataSource = useCallback(async () => {
    if (results) {
      setInstitutionProgress([
        {
          name: 'Institucioni',
          data: results.institutionsResult.map(({ period, rating }) => ({
            x: period,
            y: rating
          }))
        }
      ]);

      setGroupedByFields(
        results.fieldsProgress.map(({ name, periods }) => {
          return {
            name,
            data: periods.map(({ period, rating }) => ({
              x: period,
              y: rating
            }))
          };
        })
      );

      setGroupedByInstitutions(
        results.institutionsProgress.map(({ name, periods }) => {
          return {
            name,
            data: periods.map(({ period, rating }) => ({
              x: period,
              y: rating
            }))
          };
        })
      );
    }
  }, [results]);

  useEffect(() => {
    try {
      getResults();
    } catch (error) {
      const errors: IError = error?.data;
      setLoadingResults(false);
      message.error({ content: errors?.message, duration: 4 });
    }
  }, []);

  useEffect(() => {
    generateChartsDataSource();
  }, [generateChartsDataSource, results]);

  const generateExcel = async () => {
    try {
      setGeneratingExcel(true);
      const result = await agent.Report.getProgressExcel();

      const datasource: any[] = result.data.map(
        ({
          county,
          institution,
          year,
          startdate,
          enddate,
          field,
          subfield,
          indicator,
          criteria,
          rating,
          description,
          evidence
        }) => {
          return [
            county,
            institution,
            year,
            `${moment(startdate).format('DD/MM/YYYY')} - ${moment(enddate).format('DD/MM/YYYY')}`,
            field,
            subfield,
            indicator,
            criteria,
            rating,
            description,
            evidence
          ];
        }
      );

      generateExcelReport(
        'raport_rezultatet_vetevleresimit',
        datasource,
        'evaluation_progress_data_table',
        [
          { name: 'Qark' },
          { name: 'Institucioni Arsimor' },
          { name: 'Viti i vlerësimit' },
          { name: 'Periudha e vlerësimit' },
          { name: 'Fusha' },
          { name: 'Nënfusha' },
          { name: 'Treguesi' },
          { name: 'Kriteri' },
          { name: 'Vlerësimi me pikë' },
          { name: 'Komente' },
          { name: 'Evidenca' }
        ]
      );

      setGeneratingExcel(false);
    } catch (error) {
      const errors: IError = error?.data;
      setGeneratingExcel(false);
      message.error({ content: errors?.message, duration: 4 });
    }
  };

  if (loadingResults) return <Spinner />;

  return (
    <>
      <Header
        generateExcel={generateExcel}
        generatePDF={generatePDF}
        generatingPDF={generatingPDF}
        generatingExcel={generatingExcel}
        title={t('general.self_evaluation_result')}
        subtitle={t('sidemenu.performance_over_years')}
      />

      <Row gutter={16} ref={performanceByYearsImage}>
        <Col xs={24}>
          <Card
            title={t('sidemenu.selfevaluation')}
            style={{ marginTop: '25px' }}
            className="ant-card-default-border"
          >
            <HorizontalBarChart height={300} series={institutionProgress} />
          </Card>
        </Col>

        <Col xs={24}>
          <Card title={t('general.result_by_fields_points')} className="ant-card-default-border">
            <LineChart height={300} series={groupedByFields} />
          </Card>
        </Col>

        <Col xs={24}>
          <Card
            title={t('general.result_by_institutions_points')}
            className="ant-card-default-border"
          >
            <LineChart height={300} series={groupedByInstitutions} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default EvaluationPerformanceByYearsReport;
