import {
  FETCH_MUNICIPALITIES,
  FETCH_MUNICIPALITIES_SUCCESS,
  FETCH_MUNICIPALITIES_FAIL,
  IMunicipalitiesState,
  MunicipalityActionTypes
} from '../constants/municipalities';

const initialState: IMunicipalitiesState = {
  municipalities: [],
  fetchingMunicipalities: false,
  errors: null
};

const municipalityReducer = (
  state = initialState,
  action: MunicipalityActionTypes
): IMunicipalitiesState => {
  switch (action.type) {
    case FETCH_MUNICIPALITIES:
      return {
        ...state,
        fetchingMunicipalities: true
      };
    case FETCH_MUNICIPALITIES_SUCCESS:
      return {
        ...state,
        municipalities: action.payload.municipalities,
        errors: null,
        fetchingMunicipalities: false
      };
    case FETCH_MUNICIPALITIES_FAIL:
      return {
        ...state,
        municipalities: [],
        errors: action.payload.errors,
        fetchingMunicipalities: false
      };
    default:
      return state;
  }
};

export default municipalityReducer;
