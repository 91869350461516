import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  title: string;
  display: boolean;
}

const PageHeader: React.FC<IProps> = ({ title, display }) => {
  const { t } = useTranslation();

  return display ? (
    <div className="app-page-header">
      <h3 className="mb-0 mr-3 font-weight-semibold">{t(title)}</h3>
    </div>
  ) : null;
};

export default PageHeader;
