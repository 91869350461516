import { IError } from '../../api/models/error';
import { ISubject, ISubjectSendRequest } from '../../api/models/subject';

export const FETCH_SUBJECTS = 'FETCH_SUBJECTS';
export const FETCH_SUBJECTS_SUCCESS = 'FETCH_SUBJECTS_SUCCESS';
export const FETCH_SUBJECTS_FAIL = 'FETCH_SUBJECTS_FAIL';
export const ADD_SUBJECT = 'ADD_SUBJECT';
export const ADD_SUBJECT_SUCCESS = 'ADD_SUBJECT_SUCESS';
export const ADD_SUBJECT_FAIL = 'ADD_SUBJECT_FAIL';
export const UPDATE_SUBJECT = 'UPDATE_SUBJECT';
export const UPDATE_SUBJECT_SUCCESS = 'UPDATE_SUBJECT_SUCCESS';
export const UPDATE_SUBJECT_FAIL = 'UPDATE_SUBJECT_FAIL';
export const DELETE_SUBJECT = 'DELETE_SUBJECT';
export const DELETE_SUBJECT_SUCCESS = 'DELETE_SUBJECT_SUCCESS';
export const DELETE_SUBJECT_FAIL = 'DELETE_SUBJECT_FAIL';
export const RESET_SUBJECT_ERRORS = 'RESET_SUBJECT_ERRORS';

/** ************************** Fetch subjects *************************** */
export interface IFetchSubjects {
  type: typeof FETCH_SUBJECTS;
}

export interface IFetchSubjectsSuccess {
  type: typeof FETCH_SUBJECTS_SUCCESS;
  payload: {
    subjects: ISubject[];
  };
}

export interface IFetchSubjectsFail {
  type: typeof FETCH_SUBJECTS_FAIL;
  payload: {
    errors: IError;
  };
}

export interface ISubjectsState {
  subjects: ISubject[];
  fetchingSubjects: boolean;
  errorsFetchingSubjects: IError | null;
  addingSubject: boolean;
  errorsAddingSubject: IError | null;
  updatingSubject: boolean;
  errorsUpdatingSubject: IError | null;
  deletingSubject: boolean;
  errorsDeletingSubject: IError | null;
}

/** ************************** Add subject *************************** */
interface IAddSubject {
  type: typeof ADD_SUBJECT;
  payload: {
    subject: ISubjectSendRequest;
  };
}

interface IAddSubjectSuccess {
  type: typeof ADD_SUBJECT_SUCCESS;
  payload: {
    subject: ISubject;
  };
}

interface IAddSubjectFail {
  type: typeof ADD_SUBJECT_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Update subject *************************** */
interface IUpdateSubject {
  type: typeof UPDATE_SUBJECT;
  payload: {
    subject: ISubjectSendRequest;
  };
}

interface IUpdateSubjectSuccess {
  type: typeof UPDATE_SUBJECT_SUCCESS;
  payload: {
    subject: ISubject;
  };
}

interface IUpdateSubjectFail {
  type: typeof UPDATE_SUBJECT_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Delete subject *************************** */
interface IDeleteSubject {
  type: typeof DELETE_SUBJECT;
}

interface IDeleteSubjectSuccess {
  type: typeof DELETE_SUBJECT_SUCCESS;
  payload: {
    id: number;
  };
}

interface IDeleteSubjectFail {
  type: typeof DELETE_SUBJECT_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Reset subject errors *************************** */
interface IResetSubjectErrors {
  type: typeof RESET_SUBJECT_ERRORS;
}

export type SubjectActionTypes =
  | IFetchSubjects
  | IFetchSubjectsSuccess
  | IFetchSubjectsFail
  | IAddSubject
  | IAddSubjectSuccess
  | IAddSubjectFail
  | IUpdateSubject
  | IUpdateSubjectSuccess
  | IUpdateSubjectFail
  | IDeleteSubject
  | IDeleteSubjectSuccess
  | IDeleteSubjectFail
  | IResetSubjectErrors;
