import { IError } from '../../api/models/error';
import { ISummary } from '../../api/models/summary';

export const FETCH_INSTITUTION_SUMMARY = 'FETCH_INSTITUTION_SUMMARY';
export const FETCH_INSTITUTION_SUMMARY_SUCCESS = 'FETCH_INSTITUTION_SUMMARY_SUCCESS';
export const FETCH_INSTITUTION_SUMMARY_FAIL = 'FETCH_INSTITUTION_SUMMARY_FAIL';
export const ADD_INSTITUTION_SUMMARY = 'ADD_INSTITUTION_SUMMARY';
export const ADD_INSTITUTION_SUMMARY_SUCCESS = 'ADD_INSTITUTION_SUMMARY_SUCCESS';
export const ADD_INSTITUTION_SUMMARY_FAIL = 'ADD_INSTITUTION_SUMMARY_FAIL';
export const UPDATE_INSTITUTION_SUMMARY = 'UPDATE_INSTITUTION_SUMMARY';
export const UPDATE_INSTITUTION_SUMMARY_SUCCESS = 'UPDATE_INSTITUTION_SUMMARY_SUCCESS';
export const UPDATE_INSTITUTION_SUMMARY_FAIL = 'UPDATE_INSTITUTION_SUMMARY_FAIL';
export const DELETE_INSTITUTION_SUMMARY = 'DELETE_INSTITUTION_SUMMARY';
export const DELETE_INSTITUTION_SUMMARY_SUCCESS = 'DELETE_INSTITUTION_SUMMARY_SUCCESS';
export const DELETE_INSTITUTION_SUMMARY_FAIL = 'DELETE_INSTITUTION_SUMMARY_FAIL';
export const RESET_INSTITUTION_SUMMARY_ERRORS = 'RESET_INSTITUTION_SUMMARY_ERRORS';

export interface ISummaryState {
  summaries: ISummary[];
  fetchingSummaries: boolean;
  addingSummary: boolean;
  updatingSummary: boolean;
  deletingSummary: boolean;
  errors: IError | null;
}

/** ************************** Fetch institution summary *************************** */
interface IFetchInstitutionSummary {
  type: typeof FETCH_INSTITUTION_SUMMARY;
}

interface IFetchInstitutionSummarySuccess {
  type: typeof FETCH_INSTITUTION_SUMMARY_SUCCESS;
  payload: {
    summaries: ISummary[];
  };
}

interface IFetchInstitutionSummaryFail {
  type: typeof FETCH_INSTITUTION_SUMMARY_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Add institution summary *************************** */
interface IAddInstitutionSummary {
  type: typeof ADD_INSTITUTION_SUMMARY;
}

interface IAddInstitutionSummarySuccess {
  type: typeof ADD_INSTITUTION_SUMMARY_SUCCESS;
  payload: {
    summary: ISummary;
  };
}

interface IAddInstitutionSummaryFail {
  type: typeof ADD_INSTITUTION_SUMMARY_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Update institution summary *************************** */
interface IUpdateInstitutionSummary {
  type: typeof UPDATE_INSTITUTION_SUMMARY;
}

interface IUpdateInstitutionSummarySuccess {
  type: typeof UPDATE_INSTITUTION_SUMMARY_SUCCESS;
  payload: {
    summary: ISummary;
  };
}

interface IUpdateInstitutionSummaryFail {
  type: typeof UPDATE_INSTITUTION_SUMMARY_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Delete institution summary *************************** */
interface IDeleteInstitutionSummary {
  type: typeof DELETE_INSTITUTION_SUMMARY;
}

interface IDeleteInstitutionSummarySuccess {
  type: typeof DELETE_INSTITUTION_SUMMARY_SUCCESS;
  payload: {
    id: number;
  };
}

interface IDeleteInstitutionSummaryFail {
  type: typeof DELETE_INSTITUTION_SUMMARY_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Reset institution summary errors *************************** */
interface IResetInstitutionSummaryErrors {
  type: typeof RESET_INSTITUTION_SUMMARY_ERRORS;
}

export type SummaryActionTypes =
  | IFetchInstitutionSummary
  | IFetchInstitutionSummarySuccess
  | IFetchInstitutionSummaryFail
  | IAddInstitutionSummary
  | IAddInstitutionSummarySuccess
  | IAddInstitutionSummaryFail
  | IUpdateInstitutionSummary
  | IUpdateInstitutionSummarySuccess
  | IUpdateInstitutionSummaryFail
  | IDeleteInstitutionSummary
  | IDeleteInstitutionSummarySuccess
  | IDeleteInstitutionSummaryFail
  | IResetInstitutionSummaryErrors;
