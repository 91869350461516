import React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { RootState } from '../../../../redux/reducers';
import NotFound from '../../../404';
import EvaluationPeriodForm from '../../../../components/admin/self-evaluation-periods/EvaluationPeriodForm';

interface MatchParams {
  id: string;
}

interface IProps extends RouteComponentProps<MatchParams> {}

const EditEvaluationPeriod: React.FC<IProps> = ({ match }) => {
  const evaluationPeriods = useSelector(
    (state: RootState) => state.evaluationPeriod.evaluationPeriods
  );

  const evaluationPeriod = evaluationPeriods.filter((el) => el.id === +match.params.id)[0];

  if (!evaluationPeriod) return <NotFound showContainer={false} />;

  return <EvaluationPeriodForm evaluationPeriod={evaluationPeriod} />;
};

export default EditEvaluationPeriod;
