import React, { ReactElement, useEffect, useCallback } from 'react';
import { Form, Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IInstitution, IInstitutionSubject } from '../../../api/models/institution';
import { RootState } from '../../../redux/reducers';
import { getEvaluationPeriodBasedOnInstitutionType } from '../../../api/models/evaluationPeriod';
import { ISubject } from '../../../api/models/subject';

export interface EditInstitutionSubjectFormValues {
  mode: 'edit';
  id: number;
  subjectId: number;
  evaluationPeriodId: number;
}
export interface AddInstitutionSubjectFormValues {
  mode: 'create';
  subjectId: number;
  evaluationPeriodIds: number[];
}

export type InstitutionSubjectFormValues =
  | EditInstitutionSubjectFormValues
  | AddInstitutionSubjectFormValues;

export interface IProps {
  onCancel: () => void;
  onSubmit: (values: InstitutionSubjectFormValues) => void;
  subjects: ISubject[];
  errors: ReactElement | null;
  visible: boolean;
  loading: boolean;
  institution: IInstitution;
  institutionSubject: IInstitutionSubject | null;
}

const InstitutionSubjectForm: React.FC<IProps> = ({
  visible,
  loading,
  subjects,
  institution,
  institutionSubject,
  errors,
  onCancel,
  onSubmit
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const evaluationPeriods = useSelector(
    (state: RootState) => state.evaluationPeriod.evaluationPeriods
  );
  const { Option } = Select;

  const getInitialValues = useCallback(() => {
    if (institutionSubject) {
      const { evaluationPeriod, subject } = institutionSubject;

      return {
        subjectId: subject.id,
        evaluationPeriodId: evaluationPeriod.id
      };
    }

    return null;
  }, [institutionSubject]);

  useEffect(() => {
    form.setFieldsValue(getInitialValues());
  }, [form, getInitialValues]);

  return (
    <Modal
      visible={visible}
      confirmLoading={loading}
      destroyOnClose
      afterClose={() => {
        form.resetFields();
      }}
      title={
        institutionSubject
          ? t('general.change_institution_subject')
          : t('general.add_institution_subject')
      }
      okText={institutionSubject ? t('general.save') : t('general.add')}
      cancelText={t('general.cancel')}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      onOk={async () => {
        try {
          const { subjectId, evaluationPeriodId } = await form.validateFields();
          if (institutionSubject) {
            onSubmit({
              mode: 'edit',
              id: institutionSubject.id,
              subjectId,
              evaluationPeriodId
            });
          } else {
            onSubmit({
              mode: 'create',
              subjectId,
              evaluationPeriodIds: evaluationPeriodId
            });
          }
          form.resetFields();
        } catch (error) {
          // NO-OP
        }
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item
          name="subjectId"
          label={t('general.subject')}
          rules={[{ required: true, message: t('validation_messages.select_subject') }]}
        >
          <Select placeholder={t('placeholders.select_subject')}>
            {subjects.map((subject) => (
              <Option key={subject.id} label={subject.name} value={subject.id}>
                {subject.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {!institutionSubject ? (
          <>
            <Form.Item
              name="evaluationPeriodId"
              label={t('general.evaluation_period')}
              rules={[{ required: true, message: t('validation_messages.select_period') }]}
            >
              <Select placeholder={t('placeholders.select_period')} mode="multiple">
                {evaluationPeriods.map((evaluationPeriod) => (
                  <Option
                    key={evaluationPeriod.id}
                    label={getEvaluationPeriodBasedOnInstitutionType(
                      evaluationPeriod.period,
                      institution.institutionType.name
                    )}
                    value={evaluationPeriod.id}
                  >
                    {getEvaluationPeriodBasedOnInstitutionType(
                      evaluationPeriod.period,
                      institution.institutionType.name
                    )}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item
              name="evaluationPeriodId"
              label={t('general.evaluation_period')}
              rules={[{ required: true, message: t('validation_messages.select_period') }]}
            >
              <Select placeholder={t('placeholders.select_period')}>
                {evaluationPeriods.map((evaluationPeriod) => (
                  <Option
                    key={evaluationPeriod.id}
                    label={getEvaluationPeriodBasedOnInstitutionType(
                      evaluationPeriod.period,
                      institution.institutionType.name
                    )}
                    value={evaluationPeriod.id}
                  >
                    {getEvaluationPeriodBasedOnInstitutionType(
                      evaluationPeriod.period,
                      institution.institutionType.name
                    )}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )}
        {errors}
      </Form>
    </Modal>
  );
};
export default InstitutionSubjectForm;
