import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { message } from 'antd';
import { FormInstance } from 'antd/lib/form';
import {
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  RESET_USER_ERRORS,
  UserActionTypes
} from '../constants/users';
import agent from '../../api/agent';
import { RootState } from '../reducers/index';
import { IError } from '../../api/models/error';
import { IUserSendRequest } from '../../api/models/user';

export const fetchUsers = (): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch
) => {
  dispatch({
    type: FETCH_USERS
  });

  try {
    const users = await agent.User.list();

    dispatch({
      type: FETCH_USERS_SUCCESS,
      payload: {
        users
      }
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: FETCH_USERS_FAIL,
      payload: {
        errors
      }
    });

    message.error({ content: errors?.message, duration: 4 });
  }
};

export const updateUser = (
  id: number,
  values: IUserSendRequest,
  successMessage: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: UPDATE_USER
  });

  try {
    const user = await agent.User.update(id, values);

    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: {
        user
      }
    });

    message.success({ content: successMessage, duration: 4 });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: UPDATE_USER_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const addUser = (
  values: IUserSendRequest,
  form: FormInstance<any>,
  successMessage: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: ADD_USER
  });

  try {
    const user = await agent.User.add({ ...values });

    dispatch({
      type: ADD_USER_SUCCESS,
      payload: {
        user
      }
    });

    form.resetFields();

    message.success({ content: successMessage, duration: 4 });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: ADD_USER_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const deleteUser = (
  id: number,
  successMessage: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: DELETE_USER
  });

  try {
    await agent.User.delete(id);

    dispatch({
      type: DELETE_USER_SUCCESS,
      payload: {
        id
      }
    });

    message.success({ content: successMessage, duration: 4 });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: DELETE_USER_FAIL,
      payload: {
        errors
      }
    });

    message.error({ content: errors?.message, duration: 4 });
  }
};

export const resetUserErrors = (): UserActionTypes => {
  return {
    type: RESET_USER_ERRORS
  };
};
