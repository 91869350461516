import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Menu, Layout } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { toggleCollapsedNav, onMobileNavToggle } from '../../redux/actions/theme';
import { SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from '../../constants/theme';
import Logo from './Logo';

import { RootState } from '../../redux/reducers/index';
import NavProfile from './NavProfile';

const { Header } = Layout;
interface IProps {
  isMobile: boolean;
}

const HeaderNav: React.FC<IProps> = ({ isMobile }) => {
  const isNavTop = false;

  const navCollapsed = useSelector((state: RootState) => state.theme.navCollapsed);
  const mobileNav = useSelector((state: RootState) => state.theme.mobileNav);
  const dispatch = useDispatch();

  const onToggle = () => {
    if (!isMobile) {
      dispatch(toggleCollapsedNav(!navCollapsed));
    } else {
      dispatch(onMobileNavToggle(!mobileNav));
    }
  };

  const getNavWidth = () => {
    if (isMobile) return '0px';

    return navCollapsed ? `${SIDE_NAV_COLLAPSED_WIDTH}px` : `${SIDE_NAV_WIDTH}px`;
  };

  return (
    <Header className="app-header">
      <div className="app-header-wrapper">
        <Logo />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <Menu mode="horizontal">
              {isNavTop && !isMobile ? null : (
                <Menu.Item
                  key="0"
                  onClick={() => {
                    onToggle();
                  }}
                >
                  {navCollapsed || isMobile ? (
                    <MenuUnfoldOutlined className="nav-icon" />
                  ) : (
                    <MenuFoldOutlined className="nav-icon" />
                  )}
                </Menu.Item>
              )}
            </Menu>
          </div>
          <div className="nav-right">
            {/* <NavLanguage /> */}
            <NavProfile />
          </div>
        </div>
      </div>
    </Header>
  );
};

export default HeaderNav;
