import React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { RootState } from '../../../../redux/reducers';
import NotFound from '../../../404';
import SubjectsForm from '../../../../components/admin/subjects/SubjectsForm';

interface MatchParams {
  id: string;
}

interface IProps extends RouteComponentProps<MatchParams> {}

const EditSubject: React.FC<IProps> = ({ match }) => {
  const subject = useSelector((state: RootState) =>
    state.subject.subjects.find((el) => el.id === +match.params.id)
  );

  if (!subject) return <NotFound showContainer={false} />;

  return <SubjectsForm subject={subject} />;
};

export default EditSubject;
