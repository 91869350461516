import { PickerLocale } from 'antd/lib/date-picker/generatePicker';

const locale: PickerLocale = {
  lang: {
    locale: 'al',
    placeholder: 'Zgjidh datën',
    yearPlaceholder: 'Zgjidh vitin',
    quarterPlaceholder: 'Zgjidh 4-mujorin',
    monthPlaceholder: 'Zgjidh muajin',
    weekPlaceholder: 'Zgjidh javën',
    rangePlaceholder: ['Data e fillimi', 'Data e mbarimit'],
    today: 'Sot',
    now: 'Tani',
    backToToday: 'Kthehu në ditën e sotme',
    ok: 'Ok',
    clear: 'Fshij',
    month: 'Muaj',
    year: 'Vit',
    timeSelect: 'Zgjidh kohën',
    dateSelect: 'Zfjidh datën',
    monthSelect: 'Zgjidh një muaj',
    yearSelect: 'Zgjidh një vit',
    decadeSelect: 'Zgjidh një dekadë',
    yearFormat: 'YYYY',
    dateFormat: 'D/M/YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'D/M/YYYY HH:mm:ss',
    monthFormat: 'MMMM',
    monthBeforeYear: true,
    previousMonth: 'Muaj i kaluar',
    nextMonth: 'Muaj tjetër',
    previousYear: 'Viti i kaluar',
    nextYear: 'Viti tjetër',
    previousDecade: 'Dekada e kaluar',
    nextDecade: 'Dekada tjetër',
    previousCentury: 'Shekulli i kaluar',
    nextCentury: 'Shekulli tjetër'
  },
  timePickerLocale: {
    placeholder: 'Zgjidh kohën',
    rangePlaceholder: ['Koha e fillimit', 'Koha e mbarimit']
  },
  dateFormat: 'DD-MM-YYYY',
  dateTimeFormat: 'DD-MM-YYYY HH:mm:ss',
  weekFormat: 'wo-YYYY',
  monthFormat: 'MM-YYYY'
};

export default locale;
