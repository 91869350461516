import {
  FETCH_SUBJECTS,
  FETCH_SUBJECTS_SUCCESS,
  FETCH_SUBJECTS_FAIL,
  ADD_SUBJECT,
  ADD_SUBJECT_SUCCESS,
  ADD_SUBJECT_FAIL,
  UPDATE_SUBJECT,
  UPDATE_SUBJECT_SUCCESS,
  UPDATE_SUBJECT_FAIL,
  DELETE_SUBJECT,
  DELETE_SUBJECT_SUCCESS,
  DELETE_SUBJECT_FAIL,
  RESET_SUBJECT_ERRORS,
  ISubjectsState,
  SubjectActionTypes
} from '../constants/subjects';

const initialState: ISubjectsState = {
  subjects: [],
  fetchingSubjects: false,
  errorsFetchingSubjects: null,
  addingSubject: false,
  errorsAddingSubject: null,
  updatingSubject: false,
  errorsUpdatingSubject: null,
  deletingSubject: false,
  errorsDeletingSubject: null
};

const subjectsReducer = (state = initialState, action: SubjectActionTypes): ISubjectsState => {
  switch (action.type) {
    case FETCH_SUBJECTS:
      return {
        ...state,
        fetchingSubjects: true
      };
    case FETCH_SUBJECTS_SUCCESS:
      return {
        ...state,
        subjects: action.payload.subjects,
        errorsFetchingSubjects: null,
        fetchingSubjects: false
      };
    case FETCH_SUBJECTS_FAIL:
      return {
        ...state,
        subjects: [],
        errorsFetchingSubjects: action.payload.errors,
        fetchingSubjects: false
      };
    case ADD_SUBJECT:
      return {
        ...state,
        addingSubject: true
      };
    case ADD_SUBJECT_SUCCESS:
      return {
        ...state,
        subjects: [...state.subjects, action.payload.subject],
        addingSubject: false,
        errorsAddingSubject: null
      };
    case ADD_SUBJECT_FAIL:
      return {
        ...state,
        addingSubject: false,
        errorsAddingSubject: action.payload.errors
      };
    case UPDATE_SUBJECT:
      return {
        ...state,
        updatingSubject: true
      };
    case UPDATE_SUBJECT_SUCCESS:
      return {
        ...state,
        subjects: state.subjects.map((subject) =>
          subject.id === action.payload.subject.id ? action.payload.subject : subject
        ),
        updatingSubject: false,
        errorsUpdatingSubject: null
      };
    case UPDATE_SUBJECT_FAIL:
      return {
        ...state,
        updatingSubject: false,
        errorsUpdatingSubject: action.payload.errors
      };
    case DELETE_SUBJECT:
      return {
        ...state,
        deletingSubject: true
      };
    case DELETE_SUBJECT_SUCCESS:
      return {
        ...state,
        subjects: state.subjects.filter((subject) => subject.id !== action.payload.id),
        deletingSubject: false,
        errorsDeletingSubject: null
      };
    case DELETE_SUBJECT_FAIL:
      return {
        ...state,
        deletingSubject: false,
        errorsDeletingSubject: action.payload.errors
      };
    case RESET_SUBJECT_ERRORS:
      return {
        ...state,
        errorsFetchingSubjects: null,
        errorsAddingSubject: null,
        errorsUpdatingSubject: null,
        errorsDeletingSubject: null
      };
    default:
      return state;
  }
};

export default subjectsReducer;
