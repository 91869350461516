import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { FETCH_SECTORS, FETCH_SECTORS_SUCCESS, FETCH_SECTORS_FAIL } from '../constants/sectors';
import agent from '../../api/agent';
import { RootState } from '../reducers/index';
import { IError } from '../../api/models/error';

const fetchSectors = (): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch
) => {
  dispatch({
    type: FETCH_SECTORS
  });

  try {
    const sectors = await agent.Sector.list();

    dispatch({
      type: FETCH_SECTORS_SUCCESS,
      payload: {
        sectors
      }
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: FETCH_SECTORS_FAIL,
      payload: {
        errors
      }
    });
  }
};

export default fetchSectors;
