import {
  FETCH_COUNTIES,
  FETCH_COUNTIES_SUCCESS,
  FETCH_COUNTIES_FAIL,
  ICountiesState,
  CountyActionTypes
} from '../constants/counties';

const initialState: ICountiesState = {
  counties: [],
  fetchingCounties: false,
  errors: null
};

const countyReducer = (state = initialState, action: CountyActionTypes): ICountiesState => {
  switch (action.type) {
    case FETCH_COUNTIES:
      return {
        ...state,
        fetchingCounties: true
      };
    case FETCH_COUNTIES_SUCCESS:
      return {
        ...state,
        counties: action.payload.counties,
        errors: null,
        fetchingCounties: false
      };
    case FETCH_COUNTIES_FAIL:
      return {
        ...state,
        counties: [],
        errors: action.payload.errors,
        fetchingCounties: false
      };
    default:
      return state;
  }
};

export default countyReducer;
