import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/reducers/index';

interface IProps extends RouteProps {
  component: React.ComponentClass<Pick<IProps, never>, any>;
  path: string;
}

const PrivateRoute: React.FC<IProps> = ({ component: Component, path }) => {
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const firstTimeLogin = useSelector((state: RootState) => state.auth.firstLogin);

  const renderComponent = (props: any) => {
    if (firstTimeLogin) {
      return <Redirect to="/ndrysho-fjalekalimin" />;
    }

    if (!isAuthenticated) {
      return (
        <>
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
          ;
        </>
      );
    }

    return <Component />;
  };

  return (
    <>
      <Route path={path} render={(props) => renderComponent(props)} />
    </>
  );
};

export default PrivateRoute;
