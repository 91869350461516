import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { message } from 'antd';
import {
  FETCH_INSTITUTIONS,
  FETCH_INSTITUTIONS_FAIL,
  FETCH_INSTITUTIONS_SUCCESS,
  FETCH_INSTITUTION_SUBJECTS,
  FETCH_INSTITUTION_SUBJECTS_SUCCESS,
  FETCH_INSTITUTION_SUBJECTS_FAIL,
  FETCH_INSTITUTION_TYPES,
  FETCH_INSTITUTION_TYPES_SUCCESS,
  FETCH_INSTITUTION_TYPES_FAIL,
  ADD_INSTITUTION,
  ADD_INSTITUTION_FAIL,
  ADD_INSTITUTION_SUCCESS,
  UPDATE_INSTITUTION,
  UPDATE_INSTITUTION_SUCCESS,
  UPDATE_INSTITUTION_FAIL,
  DELETE_INSTITUTION,
  DELETE_INSTITUTION_SUCCESS,
  DELETE_INSTITUTION_FAIL,
  ADD_INSTITUTION_SUBJECT,
  ADD_INSTITUTION_SUBJECT_FAIL,
  ADD_INSTITUTION_SUBJECT_SUCCESS,
  UPDATE_INSTITUTION_SUBJECT,
  UPDATE_INSTITUTION_SUBJECT_SUCCESS,
  UPDATE_INSTITUTION_SUBJECT_FAIL,
  DELETE_INSTITUTION_SUBJECT,
  DELETE_INSTITUTION_SUBJECT_FAIL,
  DELETE_INSTITUTION_SUBJECT_SUCCESS,
  SHOW_INSTITUTION_SUBJECT_FORM,
  CLOSE_INSTITUTION_SUBJECT_FORM,
  RESET_INSTITUTION_ERRORS,
  InstitutionActionTypes
} from '../constants/institutions';
import agent from '../../api/agent';
import { RootState } from '../reducers/index';
import { IError } from '../../api/models/error';
import {
  IInstitutionSendRequest,
  IInstitutionSubjectSendRequest
} from '../../api/models/institution';

export const fetchInstitutions = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async (dispatch) => {
  dispatch({
    type: FETCH_INSTITUTIONS
  });

  try {
    const institutions = await agent.Institution.list();

    dispatch({
      type: FETCH_INSTITUTIONS_SUCCESS,
      payload: {
        institutions
      }
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: FETCH_INSTITUTIONS_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const fetchInstitutionSubjects = (
  institutionId: number
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: FETCH_INSTITUTION_SUBJECTS,
    value: institutionId
  });

  try {
    const institutionSubjects = (
      await agent.Institution.listSubjectsForInstitution(institutionId)
    ).map((institutionSubjectResponse) => ({
      ...institutionSubjectResponse,
      institutionId
    }));

    dispatch({
      type: FETCH_INSTITUTION_SUBJECTS_SUCCESS,
      institutionId,
      payload: {
        institutionSubjects
      }
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: FETCH_INSTITUTION_SUBJECTS_FAIL,
      institutionId,
      payload: {
        errors
      }
    });
  }
};

export const addInstitutionSubject = (
  institutionId: number,
  institutionSubjectSendRequest: IInstitutionSubjectSendRequest,
  successMessage: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: ADD_INSTITUTION_SUBJECT,
    value: institutionId
  });

  try {
    const institutionSubjects = (
      await agent.Institution.addSubjects(institutionId, institutionSubjectSendRequest)
    ).map((institutionSubject) => ({
      ...institutionSubject,
      institutionId
    }));

    dispatch({
      type: ADD_INSTITUTION_SUBJECT_SUCCESS,
      institutionId,
      payload: {
        institutionSubjects
      }
    });

    message.success({
      content: successMessage,
      duration: 4
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: ADD_INSTITUTION_SUBJECT_FAIL,
      value: institutionId,
      payload: {
        errors
      }
    });
  }
};

export const showInstitutionSubjectForm = (id?: number): InstitutionActionTypes => {
  return {
    type: SHOW_INSTITUTION_SUBJECT_FORM,
    id
  };
};

export const closeInstitutionSubjectForm = (): InstitutionActionTypes => {
  return {
    type: CLOSE_INSTITUTION_SUBJECT_FORM
  };
};

export const updateInstitutionSubject = (
  institutionId: number,
  institutionSubjectId: number,
  institutionSubjectSendRequest: IInstitutionSubjectSendRequest,
  successMessage: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: UPDATE_INSTITUTION_SUBJECT,
    value: institutionId
  });

  try {
    const institutionSubject = {
      ...(await agent.Institution.updateSubject(
        institutionId,
        institutionSubjectId,
        institutionSubjectSendRequest
      )),
      institutionId
    };

    dispatch({
      type: UPDATE_INSTITUTION_SUBJECT_SUCCESS,
      value: institutionId,
      payload: {
        institutionSubject
      }
    });

    message.success({
      content: successMessage,
      duration: 4
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: UPDATE_INSTITUTION_SUBJECT_FAIL,
      value: institutionId,
      payload: {
        errors
      }
    });
  }
};

export const deleteInstitutionSubject = (
  institutionId: number,
  institutionSubjectId: number,
  successMessage: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: DELETE_INSTITUTION_SUBJECT
  });

  try {
    await agent.Institution.deleteSubject(institutionId, institutionSubjectId);

    dispatch({
      type: DELETE_INSTITUTION_SUBJECT_SUCCESS,
      payload: {
        institutionSubjectId
      }
    });

    message.success({
      content: successMessage,
      duration: 4
    });
  } catch (error) {
    const errors: IError = error?.data;
    dispatch({
      type: DELETE_INSTITUTION_SUBJECT_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const addInstitution = (
  institutionSendRequest: IInstitutionSendRequest,
  successMessage: string,
  history: any
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: ADD_INSTITUTION
  });

  try {
    const institution = await agent.Institution.add(institutionSendRequest);

    dispatch({
      type: ADD_INSTITUTION_SUCCESS,
      payload: {
        institution
      }
    });

    message.success({
      content: successMessage,
      duration: 4
    });

    // Go to edit immediately after save so user can insert institution subjects
    history.replace(`./ndrysho-institucion/${institution.id}`);
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: ADD_INSTITUTION_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const updateInstitution = (
  id: number,
  institution: IInstitutionSendRequest,
  successMessage: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: UPDATE_INSTITUTION
  });

  try {
    const updatedInstitution = await agent.Institution.update(id, institution);

    dispatch({
      type: UPDATE_INSTITUTION_SUCCESS,
      payload: {
        institution: updatedInstitution
      }
    });

    message.success({
      content: successMessage,
      duration: 4
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: UPDATE_INSTITUTION_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const deleteInstitution = (
  id: number,
  successMessage: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: DELETE_INSTITUTION
  });

  try {
    await agent.Institution.delete(id);

    dispatch({
      type: DELETE_INSTITUTION_SUCCESS,
      payload: {
        id
      }
    });

    message.success({
      content: successMessage,
      duration: 4
    });
  } catch (error) {
    const errors: IError = error?.data;
    dispatch({
      type: DELETE_INSTITUTION_FAIL,
      payload: {
        errors
      }
    });

    message.error({ content: errors?.message, duration: 4 });
  }
};

export const fetchInstitutionTypes = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async (dispatch) => {
  dispatch({
    type: FETCH_INSTITUTION_TYPES
  });

  try {
    const institutionTypes = await agent.Institution.listTypes();

    dispatch({
      type: FETCH_INSTITUTION_TYPES_SUCCESS,
      payload: {
        institutionTypes
      }
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: FETCH_INSTITUTION_TYPES_FAIL,
      payload: {
        errors
      }
    });
  }
};

export const resetInstitutionErrors = (): InstitutionActionTypes => {
  return {
    type: RESET_INSTITUTION_ERRORS
  };
};
