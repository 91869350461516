import React from 'react';
import { useSelector } from 'react-redux';
import SummaryTemplateForm from '../../../../components/admin/summary-template/SummaryTemplateForm';
import { RootState } from '../../../../redux/reducers';

const SummaryTemplate: React.FC = () => {
  const summaryTemplate = useSelector((state: RootState) => state.summaryTemplate.summaryTemplate);

  return <SummaryTemplateForm summaryTemplate={summaryTemplate} />;
};

export default SummaryTemplate;
