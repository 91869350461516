import React from 'react';
import { Card, Col, Row, List, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { RootState } from '../../../redux/reducers';
import { getEvaluationPeriodBasedOnInstitutionType } from '../../../api/models/evaluationPeriod';
import { InstitutionTypeName, UserRole } from '../../../constants/theme';

const YearlyStatisticalData = () => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const institution = useSelector((state: RootState) => state.myInstitution.institution);
  const userRole = useSelector((state: RootState) => state.auth.role);

  const evaluationPeriods = useSelector(
    (state: RootState) => state.evaluationPeriod.evaluationPeriods
  );

  return (
    <Row gutter={16}>
      <Col xs={24}>
        <Card
          title={
            institution?.institutionType.name === InstitutionTypeName.SHKOLLE
              ? t('general.academic_year')
              : t('general.calendar_year')
          }
        >
          <List
            itemLayout="horizontal"
            dataSource={evaluationPeriods}
            pagination={{
              pageSize: 10,
              showSizeChanger: false
            }}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Link to={`${match.url}/ndrysho-statistikat/${item.id}`}>
                    <Button
                      type="primary"
                      className="mr-2"
                      icon={
                        userRole === UserRole.InstitutionAdmin ? <EditOutlined /> : <EyeOutlined />
                      }
                      size="small"
                    >
                      {t(userRole === UserRole.InstitutionAdmin ? 'general.edit' : 'general.view')}
                    </Button>
                  </Link>
                ]}
              >
                <List.Item.Meta
                  title={
                    institution &&
                    getEvaluationPeriodBasedOnInstitutionType(
                      item.period,
                      institution.institutionType.name
                    )
                  }
                />
              </List.Item>
            )}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default YearlyStatisticalData;
