import { IError } from '../../api/models/error';
import { IDocument } from '../../api/models/document';

export const FETCH_DOCUMENTS = 'FETCH_DOCUMENTS';
export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS';
export const FETCH_DOCUMENTS_FAIL = 'FETCH_DOCUMENTS_FAIL';
export const UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT';
export const RESET_DOCUMENT_ERRORS = 'RESET_DOCUMENT_ERRORS';
export interface IDocumentsState {
  documents: IDocument[];
  fetchingDocuments: boolean;
  errors: IError | null;
}

/** ************************** Fetch documents *************************** */
interface IFetchDocuments {
  type: typeof FETCH_DOCUMENTS;
}

interface IFetchDocumentsSuccess {
  type: typeof FETCH_DOCUMENTS_SUCCESS;
  payload: {
    documents: IDocument[];
  };
}

interface IFetchDocumentsFail {
  type: typeof FETCH_DOCUMENTS_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Upload document *************************** */
interface IUploadDocument {
  type: typeof UPLOAD_DOCUMENT;
  payload: {
    document: IDocument;
  };
}

/** ************************** Reset documents errors *************************** */
interface IResetDocumentErrors {
  type: typeof RESET_DOCUMENT_ERRORS;
}

export type DocumentsActionTypes =
  | IFetchDocuments
  | IFetchDocumentsSuccess
  | IFetchDocumentsFail
  | IUploadDocument
  | IResetDocumentErrors;
