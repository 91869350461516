import React, { useState } from 'react';
import { Form, Button, Input, message } from 'antd';

import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Alert from '../layout/shared/Alert';
import { IResetPasswordRequest } from '../../api/models/auth';
import { IError } from '../../api/models/error';
import agent from '../../api/agent';

const ResetPasswordForm = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const history = useHistory();
  const [errors, setErrors] = useState<IError | null>(null);

  const query = () => {
    return new URLSearchParams(location.search);
  };

  const onFinish = async (values: any) => {
    const result: IResetPasswordRequest = {
      ...values,
      token: query().get('token'),
      email: query().get('email')
    };

    try {
      await agent.Auth.resetPasswordConfirm(result);

      message.success({
        content: t('feedback_message.password_successfully_changed'),
        duration: 4
      });

      history.push('/');
    } catch (error) {
      const err: IError = error?.data;
      setErrors(err);
    }
  };

  return (
    <>
      <Form name="resetPasswordForm" layout="vertical" onFinish={onFinish}>
        <Form.Item
          label={t('general.new_password')}
          name="new_password1"
          rules={[
            {
              required: true,
              message: t('validation_messages.enter_new_password')
            }
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label={t('general.confirm_password')}
          name="new_password2"
          rules={[
            {
              required: true,
              message: t('validation_messages.confirm_new_password')
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('new_password1') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(t('validation_messages.password_not_matched'));
              }
            })
          ]}
        >
          <Input.Password />
        </Form.Item>
        {errors && <Alert message={errors.message} />}
        <Button type="primary" htmlType="submit" className="w-100">
          {t('navigation_profile.change_password')}
        </Button>
      </Form>
    </>
  );
};

export default ResetPasswordForm;
