import React from 'react';
import { Row, Col, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../components/layout/shared/PageHeader';
import ChangePasswordForm from '../../components/auth/ChangePasswordForm';

const ChangePassword = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeader display title={t('navigation_profile.change_password')} />
      <Card>
        <Row>
          <Col xs={24} sm={24} md={24} lg={8}>
            <ChangePasswordForm />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default ChangePassword;
