import {
  FETCH_PROPERTIES,
  FETCH_PROPERTIES_SUCCESS,
  FETCH_PROPERTIES_FAIL,
  IPropertyState,
  PropertyActionTypes
} from '../constants/property';

const initialState: IPropertyState = {
  properties: [],
  fetchingProperties: false,
  errors: null
};

const propertyReducer = (state = initialState, action: PropertyActionTypes): IPropertyState => {
  switch (action.type) {
    case FETCH_PROPERTIES:
      return {
        ...state,
        fetchingProperties: true
      };
    case FETCH_PROPERTIES_SUCCESS:
      return {
        ...state,
        properties: action.payload.properties,
        errors: null,
        fetchingProperties: false
      };
    case FETCH_PROPERTIES_FAIL:
      return {
        ...state,
        properties: [],
        errors: action.payload.errors,
        fetchingProperties: false
      };
    default:
      return state;
  }
};

export default propertyReducer;
