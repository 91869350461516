import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { FETCH_COUNTIES, FETCH_COUNTIES_SUCCESS, FETCH_COUNTIES_FAIL } from '../constants/counties';
import agent from '../../api/agent';
import { RootState } from '../reducers/index';
import { IError } from '../../api/models/error';

const fetchCounties = (): ThunkAction<void, RootState, unknown, Action<string>> => async (
  dispatch
) => {
  dispatch({
    type: FETCH_COUNTIES
  });

  try {
    const counties = await agent.County.list();

    dispatch({
      type: FETCH_COUNTIES_SUCCESS,
      payload: {
        counties
      }
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: FETCH_COUNTIES_FAIL,
      payload: {
        errors
      }
    });
  }
};

export default fetchCounties;
