import { IError } from '../../api/models/error';
import { ISubfieldPercentageCompleted } from '../../api/models/graphicalData';

export const FETCH_SUBFIELD_PERCENTAGE_COMPLETED = 'FETCH_SUBFIELD_PERCENTAGE_COMPLETED';
export const FETCH_SUBFIELD_PERCENTAGE_COMPLETED_SUCCESS =
  'FETCH_SUBFIELD_PERCENTAGE_COMPLETED_SUCCESS';
export const FETCH_SUBFIELD_PERCENTAGE_COMPLETED_FAIL = 'FETCH_SUBFIELD_PERCENTAGE_COMPLETED_FAIL';

export interface IGraphicalDataState {
  subFieldsPercentageCompleted: ISubfieldPercentageCompleted[];
  fetchingSubFieldPercentageCompleted: boolean;
  errors: IError | null;
}

/** ************************** Fetch field subfields *************************** */
interface IFetchFieldSubfieldPercentageCompleted {
  type: typeof FETCH_SUBFIELD_PERCENTAGE_COMPLETED;
}

interface IFetchFieldSubfieldPercentageCompletedSuccess {
  type: typeof FETCH_SUBFIELD_PERCENTAGE_COMPLETED_SUCCESS;
  payload: {
    subfieldsPercentageCompleted: ISubfieldPercentageCompleted[];
  };
}

interface IFetchFieldSubfieldPercentageCompletedFail {
  type: typeof FETCH_SUBFIELD_PERCENTAGE_COMPLETED_FAIL;
  payload: {
    errors: IError;
  };
}

export type GraphicalDataActionTypes =
  | IFetchFieldSubfieldPercentageCompleted
  | IFetchFieldSubfieldPercentageCompletedSuccess
  | IFetchFieldSubfieldPercentageCompletedFail;
