import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_SUBFIELD_PERCENTAGE_COMPLETED,
  FETCH_SUBFIELD_PERCENTAGE_COMPLETED_SUCCESS,
  FETCH_SUBFIELD_PERCENTAGE_COMPLETED_FAIL
} from '../constants/graphicalData';
import agent from '../../api/agent';
import { RootState } from '../reducers/index';
import { IError } from '../../api/models/error';

const fetchSubfieldPercentageCompleted = (
  id: number
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  dispatch({
    type: FETCH_SUBFIELD_PERCENTAGE_COMPLETED
  });

  try {
    const subfieldsPercentageCompleted = await agent.GraphicalData.listInstitutionSubfieldPercentageCompleted(
      id
    );

    dispatch({
      type: FETCH_SUBFIELD_PERCENTAGE_COMPLETED_SUCCESS,
      payload: {
        subfieldsPercentageCompleted
      }
    });
  } catch (error) {
    const errors: IError = error?.data;

    dispatch({
      type: FETCH_SUBFIELD_PERCENTAGE_COMPLETED_FAIL,
      payload: {
        errors
      }
    });
  }
};

export default fetchSubfieldPercentageCompleted;
