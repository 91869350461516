export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSED_NAV';
export const TOGGLE_MOBILE_NAV = 'TOGGLE_MOBILE_NAV';
export const SELECT_LANGUAGE = 'SELECT_LANGUAGE';

interface IToggleCollapsedNav {
  type: typeof TOGGLE_COLLAPSED_NAV;
  payload: {
    navCollapsed: boolean;
  };
}

interface IMobileNavToggle {
  type: typeof TOGGLE_MOBILE_NAV;
  payload: {
    mobileNav: boolean;
  };
}

interface ILanguageSelect {
  type: typeof SELECT_LANGUAGE;
  payload: {
    language: string;
  };
}

export interface IThemeState {
  navCollapsed: boolean;
  mobileNav: boolean;
  language: string;
}

export type ThemeActionTypes = IToggleCollapsedNav | IMobileNavToggle | ILanguageSelect;
