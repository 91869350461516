/* eslint-disable no-param-reassign */
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

interface ITableHeader {
  name: string;
}

const startingRow = 1;
const startingColumn = 'A';

const createTable = (
  worksheet: ExcelJS.Worksheet,
  result: any,
  tableName: string,
  columns: ITableHeader[]
) => {
  const table = worksheet.addTable({
    name: tableName,
    ref: `${startingColumn}${startingRow}`,
    columns,
    rows: [...result]
  });

  return table;
};

const generateExcelReport = (
  fileName: string,
  data: any[],
  tableName: string,
  tableHeader: ITableHeader[]
) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet');

  if (data.length > 0) createTable(worksheet, data, tableName, tableHeader);

  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
  });
};

export default generateExcelReport;
