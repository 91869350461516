import React from 'react';
import Chart from 'react-apexcharts';
import { IChartSeries } from '../../../api/models/report';

interface IProps {
  series: IChartSeries[];
  height?: number;
  percentage?: boolean;
}

const VerticalBarChart: React.FC<IProps> = ({ series, height = 350, percentage = false }) => {
  const options = {
    grid: {
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: false
        }
      }
    },
    chart: {
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'top'
        }
      }
    },
    dataLabels: {
      enabled: true,
      offsetX: -10,
      formatter: (value: number) => {
        if (value === 0) {
          return '';
        }

        if (percentage) {
          return value < 5 ? '' : value;
        }

        return value;
      }
    },
    tooltip: {
      y: {
        formatter: (value: number) => {
          return percentage ? `${value} %` : value;
        }
      }
    },
    xaxis: {
      tickPlacement: 'between'
      // tickAmount: 5,
      // min: 0,
      // max: 100
      // categories
    },
    yaxis: {
      labels: {
        show: true,
        align: 'right',
        minWidth: 0,
        maxWidth: 200
      },
      tickAmount: percentage ? 5 : undefined,
      min: percentage ? 0 : undefined,
      max: percentage ? 100 : undefined
    }
  };

  return <Chart options={options} series={series} type="bar" height={height} />;
};

export default VerticalBarChart;
