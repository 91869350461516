import React from 'react';
import { Button, Grid, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { FilePdfOutlined, FileExcelOutlined } from '@ant-design/icons';
import utils from '../../../utils';
import PageHeaderAlt from '../../layout/PageHeaderAlt';

interface IProps {
  element?: any;
  title: string;
  subtitle?: string;
  generatePDF: () => void;
  generateExcel: () => void;
  generatingPDF?: boolean;
  generatingExcel?: boolean;
}

const StatisticsReportHeader: React.FC<IProps> = ({
  element = null,
  title,
  generatePDF,
  generateExcel,
  generatingPDF = false,
  generatingExcel = false,
  subtitle
}) => {
  const { t } = useTranslation();

  const { useBreakpoint } = Grid;
  const isMedium = !utils.getBreakPoint(useBreakpoint()).includes('md');
  const isSmall = !utils.getBreakPoint(useBreakpoint()).includes('sm');

  return (
    <div>
      <PageHeaderAlt className="bg-white border-bottom">
        <Row gutter={16}>
          {subtitle ? (
            <Col xs={24} md={12}>
              <Row gutter={16}>
                <Col xs={24}>
                  <h2 style={{ marginBottom: 0 }}>{title}</h2>
                </Col>
                <Col xs={24}>
                  <p style={{ marginBottom: isSmall ? '15px' : 0 }}>{subtitle}</p>
                </Col>
              </Row>
            </Col>
          ) : (
            <Col xs={24} md={12}>
              <h2>{title}</h2>
            </Col>
          )}
          <Col xs={24} md={6} style={{ margin: isMedium ? '15px 0' : 0 }}>
            {element}
          </Col>
          <Col xs={24} md={6}>
            <Row gutter={16}>
              <Col xs={12}>
                <Button
                  type="primary"
                  loading={generatingPDF}
                  className="w-100"
                  onClick={() => generatePDF()}
                >
                  {t('general.pdf')}
                  <FilePdfOutlined />
                </Button>
              </Col>
              <Col xs={12}>
                <Button
                  type="primary"
                  loading={generatingExcel}
                  className="w-100"
                  onClick={() => generateExcel()}
                >
                  {t('general.excel')}
                  <FileExcelOutlined />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </PageHeaderAlt>
    </div>
  );
};

export default StatisticsReportHeader;
