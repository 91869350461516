import {
  FETCH_INSTITUTION_SUMMARY,
  FETCH_INSTITUTION_SUMMARY_SUCCESS,
  FETCH_INSTITUTION_SUMMARY_FAIL,
  ADD_INSTITUTION_SUMMARY,
  ADD_INSTITUTION_SUMMARY_SUCCESS,
  ADD_INSTITUTION_SUMMARY_FAIL,
  UPDATE_INSTITUTION_SUMMARY,
  UPDATE_INSTITUTION_SUMMARY_SUCCESS,
  UPDATE_INSTITUTION_SUMMARY_FAIL,
  DELETE_INSTITUTION_SUMMARY,
  DELETE_INSTITUTION_SUMMARY_SUCCESS,
  DELETE_INSTITUTION_SUMMARY_FAIL,
  RESET_INSTITUTION_SUMMARY_ERRORS,
  ISummaryState,
  SummaryActionTypes
} from '../constants/summary';

const initialState: ISummaryState = {
  summaries: [],
  fetchingSummaries: false,
  addingSummary: false,
  updatingSummary: false,
  deletingSummary: false,
  errors: null
};

const summaryReducer = (state = initialState, action: SummaryActionTypes): ISummaryState => {
  switch (action.type) {
    case FETCH_INSTITUTION_SUMMARY:
      return {
        ...state,
        fetchingSummaries: true
      };
    case FETCH_INSTITUTION_SUMMARY_SUCCESS:
      return {
        ...state,
        summaries: action.payload.summaries,
        errors: null,
        fetchingSummaries: false
      };
    case FETCH_INSTITUTION_SUMMARY_FAIL:
      return {
        ...state,
        summaries: [],
        fetchingSummaries: false,
        errors: action.payload.errors
      };
    case ADD_INSTITUTION_SUMMARY:
      return {
        ...state,
        addingSummary: true
      };
    case ADD_INSTITUTION_SUMMARY_SUCCESS:
      return {
        ...state,
        summaries: [
          ...state.summaries.filter((summary) => summary.id !== action.payload.summary.id),
          action.payload.summary
        ],
        addingSummary: false,
        errors: null
      };
    case ADD_INSTITUTION_SUMMARY_FAIL:
      return {
        ...state,
        addingSummary: false,
        errors: action.payload.errors
      };
    case UPDATE_INSTITUTION_SUMMARY:
      return {
        ...state,
        updatingSummary: true
      };
    case UPDATE_INSTITUTION_SUMMARY_SUCCESS:
      return {
        ...state,
        updatingSummary: false,
        summaries: state.summaries.map((summary) =>
          summary.id === action.payload.summary.id ? action.payload.summary : summary
        ),
        errors: null
      };
    case UPDATE_INSTITUTION_SUMMARY_FAIL:
      return {
        ...state,
        updatingSummary: false,
        errors: action.payload.errors
      };
    case DELETE_INSTITUTION_SUMMARY:
      return {
        ...state,
        deletingSummary: true
      };
    case DELETE_INSTITUTION_SUMMARY_SUCCESS:
      return {
        ...state,
        deletingSummary: false,
        summaries: state.summaries.filter((summary) => summary.id !== action.payload.id),
        errors: null
      };
    case DELETE_INSTITUTION_SUMMARY_FAIL:
      return {
        ...state,
        deletingSummary: false,
        errors: action.payload.errors
      };
    case RESET_INSTITUTION_SUMMARY_ERRORS:
      return {
        ...state,
        errors: null
      };
    default:
      return state;
  }
};

export default summaryReducer;
