import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Card, Col, message, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import html2canvas from 'html2canvas';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/reducers';
import Header from '../../../../../components/admin/reports/StatisticsReportHeader';
import StatisticalData from '../../../../../components/admin/reports/StatisticalData';
import {
  IChartSeries,
  IReportImage,
  IStatisticalDataWidget,
  IStatisticsDataReport
} from '../../../../../api/models/report';
import LineChart from '../../../../../components/admin/charts/LineChart';
import { InstitutionTypeName } from '../../../../../constants/theme';
import { IInstitutionType } from '../../../../../api/models/institution';
import { IError } from '../../../../../api/models/error';
import agent from '../../../../../api/agent';
import Spinner from '../../../../../components/layout/shared/Spinner';
import { getEvaluationPeriodBasedOnInstitutionType } from '../../../../../api/models/evaluationPeriod';
import generatePDFReport from '../../pdfReport';
import generateExcelReport from '../../excelReport';

const GraduationStatisticsReport = () => {
  const { t } = useTranslation();
  const { Option } = Select;

  const institutionTypes = useSelector(
    (state: RootState) => state.institution.institutionTypes
  ).filter(
    ({ name }) =>
      name === InstitutionTypeName.QENDER_FORMIMI_PROFESIONAL ||
      name === InstitutionTypeName.SHKOLLE
  );

  const [institutionType, setInstitutionType] = useState<null | IInstitutionType>(null);
  const [stats, setStats] = useState<IStatisticsDataReport | null>(null);
  const [loadingStatistics, setLoadingStatistics] = useState<boolean>(true);
  const [groupedByInstitution, setGroupedByInstitution] = useState<IChartSeries[]>([]);
  const [groupedBySubjects, setGroupedBySubjects] = useState<IChartSeries[]>([]);
  // const [studentsData, setStudentsData] = useState<IChartSeries[]>([]);
  const [generatingPDF, setGeneratingPDF] = useState<boolean>(false);
  const [generatingExcel, setGeneratingExcel] = useState<boolean>(false);

  const statisticsGraduationImage1 = useRef<HTMLDivElement>(null);
  const statisticsGraduationImage2 = useRef<HTMLDivElement>(null);

  const getStatistics = useCallback(async () => {
    let result = null;

    if (institutionType) {
      setLoadingStatistics(true);

      result = await agent.Report.getStatistics(institutionType.id);

      setLoadingStatistics(false);
    }

    setStats(result);
  }, [institutionType]);

  const generateChartsDataSource = useCallback(() => {
    if (stats && institutionType) {
      setGroupedByInstitution(
        stats.groupBySchools.map(({ institution, statistics }) => {
          return {
            name: institution,
            data: statistics.map(({ evaluationPeriod, graduation }) => ({
              x: getEvaluationPeriodBasedOnInstitutionType(evaluationPeriod, institutionType.name),
              y: graduation
            }))
          };
        })
      );

      setGroupedBySubjects(
        stats.groupBySubjects.map(({ subject, statistics }) => {
          return {
            name: subject,
            data: statistics.map(({ evaluationPeriod, graduation }) => ({
              x: getEvaluationPeriodBasedOnInstitutionType(evaluationPeriod, institutionType.name),
              y: graduation
            }))
          };
        })
      );

      // setStudentsData([
      //   {
      //     name: 'Nxënës',
      //     data: stats.studentsData.map(({ evaluationPeriod, graduation }) => ({
      //       x: evaluationPeriod,
      //       y: graduation
      //     }))
      //   }
      // ]);
    }
  }, [institutionType, stats]);

  useEffect(() => {
    setInstitutionType(institutionTypes[0]);
  }, [institutionTypes]);

  useEffect(() => {
    try {
      getStatistics();
    } catch (error) {
      const errors: IError = error?.data;
      setLoadingStatistics(false);
      message.error({ content: errors?.message, duration: 4 });
    }
  }, [getStatistics, institutionType]);

  useEffect(() => {
    generateChartsDataSource();
  }, [generateChartsDataSource, stats]);

  const renderHeaderFilter = () => {
    if (institutionType?.id) {
      return (
        <Select
          className="w-100"
          placeholder={t('general.institution_type')}
          style={{ border: '1px solid #3e82f7', borderRadius: '11px' }}
          defaultValue={institutionType.id}
          onChange={(e) => {
            setInstitutionType(institutionTypes.filter((el) => el.id === e)[0]);
          }}
        >
          {institutionTypes.map((type) => (
            <Option key={type.id} value={type.id}>
              {type.description}
            </Option>
          ))}
        </Select>
      );
    }

    return null;
  };

  const renderWidgets = () => {
    let data: IStatisticalDataWidget[] = [];

    if (stats && institutionType?.name) {
      data = stats?.statistics.map(({ description, graduation }) => {
        return {
          title: description,
          value: graduation.toString(),
          subtitle: getEvaluationPeriodBasedOnInstitutionType(
            new Date().getFullYear(),
            institutionType.name
          ).toString()
        };
      });
    }

    return data;
  };

  const generateExcel = async () => {
    try {
      setGeneratingExcel(true);
      const result = await agent.Report.getStatisticsExcel();

      const datasource: any[] = result.data.map(
        ({
          county,
          municipality,
          institution,
          institutiontype: instType,
          property,
          subproperty,
          year,
          registration,
          graduation
        }) => {
          return [
            county,
            municipality,
            institution,
            property,
            subproperty,
            getEvaluationPeriodBasedOnInstitutionType(year, instType),
            registration,
            graduation
          ];
        }
      );

      generateExcelReport(
        'raport_te_dhenat_statistikore',
        datasource,
        'graduation_statistical_data_table',
        [
          { name: 'Qark' },
          { name: 'Bashki' },
          { name: 'Emri i institucionit' },
          { name: 'Tipi i treguesit' },
          { name: 'Treguesi' },
          { name: 'Viti akademik' },
          { name: 'Total' },
          { name: 'Certifikime/Diplomime' }
        ]
      );

      setGeneratingExcel(false);
    } catch (error) {
      const errors: IError = error?.data;
      setGeneratingExcel(false);
      message.error({ content: errors?.message, duration: 4 });
    }
  };

  const generatePDF = async () => {
    const images: IReportImage[] = [];
    const reportTitle =
      institutionType?.name === InstitutionTypeName.SHKOLLE
        ? t('general.graduation_vocational_education')
        : t('general.graduation_course_center');

    const firstImage = {
      element: statisticsGraduationImage1.current
    };

    const secondImage = {
      element: statisticsGraduationImage2.current
    };

    try {
      setGeneratingPDF(true);
      window.scrollTo(0, 0);

      if (firstImage && firstImage.element) {
        const canvas = await html2canvas(firstImage.element, { scrollX: -7 });

        images.push({ url: canvas.toDataURL('image/png') });
      }

      if (secondImage && secondImage.element) {
        const canvas = await html2canvas(secondImage.element, { scrollX: -7 });

        images.push({ url: canvas.toDataURL('image/png') });
      }

      generatePDFReport(images, reportTitle, 'raport_te_dhenat_statistikore_diplomime');
      setGeneratingPDF(false);
    } catch (error) {
      setGeneratingPDF(false);
    }
  };

  if (loadingStatistics) return <Spinner />;

  return (
    <>
      <Header
        generatePDF={generatePDF}
        generateExcel={generateExcel}
        generatingPDF={generatingPDF}
        generatingExcel={generatingExcel}
        element={renderHeaderFilter()}
        title={t('general.graduation')}
      />

      <Row gutter={16} justify="space-between" align="middle">
        <Col xs={24} ref={statisticsGraduationImage1}>
          <Row gutter={16}>
            <StatisticalData data={renderWidgets()} />

            <Col xs={24}>
              <Row gutter={16}>
                {/* <Col xs={24}>
                  <Card title={t('general.graduated_students')}>
                    <HorizontalBarChart series={studentsData} />
                  </Card>
                </Col> */}

                <Col xs={24}>
                  <Card
                    title={t('general.graduated_students_subjects')}
                    className="ant-card-default-border"
                  >
                    <LineChart series={groupedBySubjects} />
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col xs={24} ref={statisticsGraduationImage2}>
          <Card
            title={t('general.graduated_students_institutions')}
            className="ant-card-default-border"
          >
            <LineChart series={groupedByInstitution} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default GraduationStatisticsReport;
