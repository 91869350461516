import React from 'react';
import { Form, Button, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import PageHeaderAlt from '../../layout/PageHeaderAlt';
import Flex from '../../layout/shared/Flex';
import { IProperty } from '../../../api/models/property';
import PropertyDataCard from './statistics-datacard/YearlyStatisticalPropertyDataCard';
import SubjectDataCard from './statistics-datacard/YearlyStatisticalSubjectDataCard';
import { ISubject } from '../../../api/models/subject';
import { IInstitution, IInstitutionSubject } from '../../../api/models/institution';
import { RootState } from '../../../redux/reducers';
import {
  IInstitutionStatistics,
  IPropertyStatistics,
  ISubjectStatistics
} from '../../../api/models/statistic';
import { updateMyInstitutionStatistics } from '../../../redux/actions/myInstitution';
import Alert from '../../layout/shared/Alert';
import Utils from '../../../utils';
import AppBreadCrumb from '../../layout/AppBreadCrumb';
import { UserRole } from '../../../constants/theme';

interface IProps {
  properties: IProperty[];
  allSubjects: ISubject[];
  institutionSubjects: IInstitutionSubject[];
  myInstitution: IInstitution;
  institutionStatistics: IInstitutionStatistics;
  evaluationPeriodId: number;
}

const YearlyStatisticalDataList: React.FC<IProps> = ({
  properties,
  allSubjects,
  institutionSubjects,
  myInstitution,
  evaluationPeriodId,
  institutionStatistics
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [form] = Form.useForm();
  const userRole = useSelector((state: RootState) => state.auth.role);
  const dispatch = useDispatch();

  const updatingMyInstitutionStatistics = useSelector(
    (state: RootState) => state.myInstitution.updatingMyInstitutionStatistics
  );

  const errors = useSelector((state: RootState) => state.myInstitution.errors);

  const onFinish = (values: any) => {
    const tempSubjects: ISubjectStatistics[] = [];
    const tempProperties: IPropertyStatistics[] = [];

    Object.keys(values).forEach((key) => {
      const parts = key.split('_');
      const value = values[key] ? +values[key] : null;
      const id = +parts[0];
      const type = parts[2];

      if (parts[1] === 'subject') {
        let objExists = false;

        tempSubjects.forEach((subject) => {
          const el = subject;

          if (el.id === id) {
            objExists = true;

            if (type === 'graduation') el.graduation = value;
            else el.registration = value;
          }
        });

        if (!objExists) {
          if (type === 'graduation') {
            tempSubjects.push({
              id,
              graduation: value,
              registration: null
            });
          } else {
            tempSubjects.push({
              id,
              graduation: null,
              registration: value
            });
          }
        }
      } else {
        let objExists = false;

        tempProperties.forEach((subject) => {
          const el = subject;

          if (el.subPropertyId === id) {
            objExists = true;

            if (type === 'graduation') el.graduation = value;
            else el.registration = value;
          }
        });

        if (!objExists) {
          if (type === 'graduation') {
            tempProperties.push({
              subPropertyId: id,
              graduation: value,
              registration: null
            });
          } else {
            tempProperties.push({
              subPropertyId: id,
              graduation: null,
              registration: value
            });
          }
        }
      }
    });

    const result: IInstitutionStatistics = {
      evaluationPeriodId,
      subjects: tempSubjects,
      properties: tempProperties
    };

    dispatch(
      updateMyInstitutionStatistics(
        myInstitution.id,
        result,
        t('feedback_message.statistics_successfully_updated'),
        history
      )
    );
  };

  const getInitialValues = () => {
    if (institutionStatistics) {
      const { properties: props, subjects: sub } = institutionStatistics;

      const result = {};

      sub.forEach((subject) => {
        Utils.setProperty(
          result,
          `${+subject.id}_subject_graduation`,
          subject.graduation ? +subject.graduation : null
        );
        Utils.setProperty(
          result,
          `${+subject.id}_subject_registration`,
          subject.registration ? +subject.registration : null
        );
      });

      props.forEach((property) => {
        Utils.setProperty(
          result,
          `${+property.subPropertyId}_property_graduation`,
          property.graduation ? +property.graduation : null
        );
        Utils.setProperty(
          result,
          `${+property.subPropertyId}_property_registration`,
          property.registration ? +property.registration : null
        );
      });

      return result;
    }

    return undefined;
  };

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        className="ant-advanced-search-form"
        onFinish={onFinish}
        initialValues={getInitialValues()}
      >
        <PageHeaderAlt className="bg-white border-bottom">
          <Flex className="py-2" mobileFlex={false} justifyContent="between" alignItems="center">
            <h2>{t('general.statistical_data')}</h2>
            {userRole === UserRole.InstitutionAdmin && (
              <Button type="primary" htmlType="submit" loading={updatingMyInstitutionStatistics}>
                {t('general.save')}
              </Button>
            )}
          </Flex>
          <AppBreadCrumb
            items={[
              { title: t('sidemenu.myinstitution'), path: `../../institucioni-im` },
              { title: t('general.statistical_data'), path: null }
            ]}
          />
        </PageHeaderAlt>

        {errors && (
          <div style={{ marginTop: '20px' }}>
            <Alert message={errors.message} />
          </div>
        )}

        <SubjectDataCard
          evaluationPeriodId={evaluationPeriodId}
          allSubjects={allSubjects}
          institutionSubjects={institutionSubjects}
          institutionStatistics={institutionStatistics}
        />

        {properties.map((property) => (
          <PropertyDataCard
            key={property.id}
            property={property}
            institutionStatistics={institutionStatistics}
          />
        ))}

        <Row className="w-100" align="middle" justify="space-between">
          {userRole === UserRole.InstitutionAdmin && (
            <Col sm={12}>
              <Button type="primary" htmlType="submit" icon={<ArrowLeftOutlined />}>
                {t('general.save_and_return')}
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    </>
  );
};

export default YearlyStatisticalDataList;
