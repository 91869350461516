import React, { useState, useEffect } from 'react';
import { Card, Row, Col } from 'antd';
import { RouteComponentProps, Redirect } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import LoginForm from '../../components/auth/LoginForm';
import ForgotPasswordForm from '../../components/auth/ForgotPasswordForm';
import AppLoading from '../../components/layout/AppLoading';
import { RootState } from '../../redux/reducers';
import { toggleAppLoading } from '../../redux/actions/auth';
import Utils from '../../utils';

const backgroundStyle = {
  backgroundImage: 'url(/img/login-background.jpg)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover'
};

interface IProps extends RouteComponentProps {}

const Login: React.FC<IProps> = ({ location }) => {
  const isMobile = !Utils.getBreakPoint(useBreakpoint()).includes('sm');
  const lastLocation: any = location.state || { from: { pathname: '/' } };
  const [showLogin, toggleShowLogin] = useState<boolean>(true);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const appLoading = useSelector((state: RootState) => state.auth.appLoading);
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated) dispatch(toggleAppLoading(false));
  }, [dispatch, isAuthenticated]);

  const onShowLogin = (flag: boolean) => {
    toggleShowLogin(flag);
  };

  if (appLoading) {
    return <AppLoading />;
  }

  if (isAuthenticated) {
    return <Redirect to={lastLocation.from.pathname} />;
  }

  return (
    <div className="auth-container">
      <div className="h-100" style={backgroundStyle}>
        <div className="container d-flex flex-column justify-content-center h-100">
          <Row justify="center">
            <Col xs={20} sm={20} md={20} lg={7}>
              <Card bordered={false}>
                <div className="my-4">
                  <div className="text-center">
                    <img className="img-fluid mb-4" src="/img/logo-akafpk.png" alt="" />
                    {!showLogin && (
                      <>
                        <h3 className="mt-3 font-weight-bold">{t('general.forget_password')}</h3>
                        <p className="mb-4">{t('general.email_reset_password')}</p>
                      </>
                    )}
                  </div>
                  <Row justify="center">
                    <Col xs={24} sm={24} md={20} lg={20}>
                      {showLogin ? (
                        <LoginForm setLogin={onShowLogin} />
                      ) : (
                        <ForgotPasswordForm setLogin={onShowLogin} />
                      )}
                    </Col>
                  </Row>
                </div>
              </Card>
              <Card bordered={false}>
                {isMobile ? (
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <img style={{ height: '100px' }} src="/img/SDC.png" alt="SDC Logo" />
                    <img style={{ height: '100px' }} src="/img/undp_logo.png" alt="UNDP Logo" />
                  </div>
                ) : (
                  <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <img src="/img/SDC.png" alt="SDC Logo" />
                    <img src="/img/undp_logo.png" alt="UNDP Logo" />
                  </div>
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Login;
