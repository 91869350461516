import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Grid } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Icon from './Icon';
import navigationConfig, { IMenuItem } from '../../config/NavigationConfig';
import utils from '../../utils/index';
import { onMobileNavToggle } from '../../redux/actions/theme';
import { RootState } from '../../redux/reducers';

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setDefaultOpen = (key: any) => {
  const keyList = [];
  let keyString = '';
  if (key) {
    const arr = key.split('-');
    for (let index = 0; index < arr.length; index += 1) {
      const elm = arr[index];

      keyString = index === 0 ? elm : `${keyString}-${elm}`;

      keyList.push(keyString);
    }
  }
  return keyList;
};

interface IProps {
  routeInfo: any;
}

const MenuContent: React.FC<IProps> = ({ routeInfo }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userRole = useSelector((state: RootState) => state.auth.role);
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');

  const navigation: IMenuItem[] = navigationConfig(userRole ?? '');

  const closeMobileNav = () => {
    if (isMobile) {
      dispatch(onMobileNavToggle(false));
    }
  };

  return (
    <Menu
      theme="light"
      mode="inline"
      style={{ height: '100%', borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
    >
      {navigation.map((menu: IMenuItem) =>
        menu.submenu.length > 0 ? (
          <Menu.ItemGroup key={menu.key} title={t(menu.title)}>
            {menu.submenu.map((subMenuFirst: IMenuItem) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  icon={subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                  key={subMenuFirst.key}
                  title={t(subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond: IMenuItem) => (
                    <Menu.Item key={subMenuSecond.key}>
                      {subMenuSecond.icon ? <Icon type={subMenuSecond?.icon} /> : null}
                      <span>{t(subMenuSecond.title)}</span>
                      <Link onClick={() => closeMobileNav()} to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                  <span>{t(subMenuFirst.title)}</span>
                  <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </Menu.ItemGroup>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{menu.title ? t(menu.title) : ''}</span>
            {menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

export default MenuContent;
