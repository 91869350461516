import React from 'react';
import { Button, Form, Input } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/actions/auth';
import { ILoginRequest } from '../../api/models/auth';
import { RootState } from '../../redux/reducers';
import Alert from '../layout/shared/Alert';

interface IProps {
  setLogin: (flag: boolean) => void;
}

const LoginForm: React.FC<IProps> = ({ setLogin }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const loginErrors = useSelector((state: RootState) => state.auth.errors);

  const onFinish = async (values: ILoginRequest) => {
    dispatch(login(values));
  };

  const renderPasswordFormItem = () => {
    return (
      <div className="d-flex justify-content-between w-100 align-items-center">
        <span>{t('general.password')}</span>

        <span
          role="link"
          tabIndex={0}
          onKeyDown={() => {}}
          className="cursor-pointer font-size-sm font-weight-normal text-muted"
          onClick={() => setLogin(false)}
        >
          {`${t('general.forget_password')}?`}
        </span>
      </div>
    );
  };

  return (
    <>
      <Form layout="vertical" name="login-form" onFinish={onFinish}>
        <Form.Item
          name="email"
          label={t('general.email')}
          rules={[
            {
              required: true,
              message: t('validation_messages.required_email')
            },
            {
              type: 'email',
              message: t('validation_messages.valid_email')
            }
          ]}
        >
          <Input prefix={<MailOutlined className="text-primary" />} />
        </Form.Item>
        <Form.Item
          name="password"
          label={renderPasswordFormItem()}
          rules={[
            {
              required: true,
              message: t('validation_messages.required_password')
            }
          ]}
        >
          <Input.Password prefix={<LockOutlined className="text-primary" />} />
        </Form.Item>
        {loginErrors && <Alert message={loginErrors.message} />}
        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={false}>
            {t('general.sign_in')}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default LoginForm;
