import React from 'react';
import { Button, Card, Cascader, Col, Form, Input, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IInstitution } from '../../../api/models/institution';
import { addInstitution, updateInstitution } from '../../../redux/actions/institutions';
import { RootState } from '../../../redux/reducers';
import Alert from '../../layout/shared/Alert';
import Flex from '../../layout/shared/Flex';
import InstitutionSubjectsList from './InstitutionSubjectsList';
import AppBreadCrumb from '../../layout/AppBreadCrumb';
import { InstitutionTypeName } from '../../../constants/theme';

const { Option } = Select;

interface IProps {
  institution?: IInstitution;
}

const InstitutionsForm: React.FC<IProps> = ({ institution = null }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const institutionTypes = useSelector((state: RootState) =>
    state.institution.institutionTypes.filter(
      (el) =>
        el.name !== InstitutionTypeName.AGJENSIA_KOMBETARE_ARSIMIT &&
        el.name !== InstitutionTypeName.AGJENSIA_KOMBETARE_PUNESIMIT_AFTESIVE
    )
  );
  const municipalities = useSelector((state: RootState) => state.municipality.municipalities);
  const counties = useSelector((state: RootState) => state.county.counties);
  const sectors = useSelector((state: RootState) => state.sector.sectors);
  const institutionSubjects = useSelector((state: RootState) =>
    state.institution.institutionSubjects.filter(
      (institutionSubject) => institutionSubject.institutionId === institution?.id
    )
  );

  const addingInstitution = useSelector((state: RootState) => state.institution.addingInstitution);
  const addInstitutionErrors = useSelector(
    (state: RootState) => state.institution.errorsAddingInstitution
  );
  const updatingInstitution = useSelector(
    (state: RootState) => state.institution.updatingInstitution
  );
  const updateInstitutionErrors = useSelector(
    (state: RootState) => state.institution.errorsUpdatingInstitution
  );
  const loadingInstitutionSubjects = useSelector(
    (state: RootState) => state.institution.fetchingInstitutionSubjects
  );

  const countyForMunicipalityId = (municipalityId: number) => {
    const municipality = municipalities.find((el) => el.id === municipalityId);
    const county = counties.find((el) => el.id === municipality?.countyId);
    return county;
  };

  const displayErrors = () => {
    if (institution && updateInstitutionErrors) {
      return <Alert message={updateInstitutionErrors.message} />;
    }

    if (addInstitutionErrors) {
      return <Alert message={addInstitutionErrors.message} />;
    }

    return null;
  };

  const onFinishInstitution = (values: any) => {
    const {
      name,
      phone,
      email,
      institutionTypeId,
      sectorId,
      acronym,
      countyAndMunicipality
    } = values;

    const municipalityId = countyAndMunicipality[1];

    if (institution) {
      dispatch(
        updateInstitution(
          institution.id,
          {
            name,
            phone,
            email,
            municipalityId,
            institutionTypeId,
            sectorId,
            acronym
          },
          t('feedback_message.institution_successfully_updated')
        )
      );
    } else {
      dispatch(
        addInstitution(
          {
            name,
            phone,
            email,
            municipalityId,
            institutionTypeId,
            sectorId,
            acronym
          },
          t('feedback_message.institution_successfully_added'),
          history
        )
      );

      form.resetFields();
    }
  };

  const renderSubmitButton = () => {
    return (
      <Button
        type="primary"
        htmlType="submit"
        loading={institution ? updatingInstitution : addingInstitution}
      >
        {institution ? t('general.save') : t('general.add')}
      </Button>
    );
  };

  return (
    <Row>
      <AppBreadCrumb
        items={[
          { title: t('sidemenu.institutions'), path: '/platforma/admin/institucione' },
          { title: t('general.institution_data'), path: null }
        ]}
      />
      <Col xs={24}>
        <Form
          layout="vertical"
          form={form}
          className="ant-advanced-search-form"
          initialValues={
            institution
              ? {
                  ...institution,
                  institutionTypeId: institution.institutionType.id,
                  sectorId: institution.sector.id,
                  countyAndMunicipality: [
                    countyForMunicipalityId(institution.municipality.id)?.id,
                    institution.municipality.id
                  ]
                }
              : {}
          }
          onFinish={onFinishInstitution}
        >
          <Card
            style={{ marginTop: '25px' }}
            title={t('general.institution_data')}
            extra={renderSubmitButton()}
          >
            <Flex justifyContent="between" mobileFlex={false}>
              <Row gutter={16} style={{ width: '100%', marginLeft: '0', marginRight: '0' }}>
                <Col xs={12}>
                  <Form.Item
                    label={t('general.institution_name')}
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: t('validation_messages.enter_institution_name')
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    label={t('general.acronym')}
                    name="acronym"
                    rules={[
                      {
                        required: true,
                        message: t('validation_messages.enter_institution_acronym')
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    label={t('general.email')}
                    name="email"
                    rules={[
                      {
                        type: 'email',
                        message: t('validation_messages.valid_email')
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label={t('general.phone')} name="phone">
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    name="countyAndMunicipality"
                    label={t('general.county_and_municipality')}
                    rules={[
                      {
                        required: true,
                        message: t('validation_messages.select_county_and_municipality')
                      }
                    ]}
                  >
                    <Cascader
                      placeholder={t('placeholders.select_county_and_municipality')}
                      options={counties.map((county) => ({
                        value: county.id,
                        label: county.name,
                        children: municipalities
                          .filter((municipality) => municipality.countyId === county.id)
                          .map((municipality) => ({
                            value: municipality.id,
                            label: municipality.name
                          }))
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    name="institutionTypeId"
                    label={t('general.institution_type')}
                    rules={[
                      { required: true, message: t('validation_messages.select_institution_type') }
                    ]}
                  >
                    <Select
                      placeholder={t('placeholders.select_institution_type')}
                      disabled={
                        institution !== null &&
                        (institutionSubjects.length > 0 || loadingInstitutionSubjects)
                      }
                    >
                      {institutionTypes.map((institutionType, index) => (
                        <Option key={index.toString()} value={institutionType.id}>
                          {institutionType.description}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    name="sectorId"
                    label={t('general.sector')}
                    rules={[{ required: true, message: t('validation_messages.select_sector') }]}
                  >
                    <Select placeholder={t('placeholders.select_sector')}>
                      {sectors.map((sector, index) => (
                        <Option key={index.toString()} value={sector.id}>
                          {sector.description}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>{displayErrors()}</Col>
              </Row>
            </Flex>
          </Card>
        </Form>
      </Col>
      {institution && (
        <Col xs={24}>
          <InstitutionSubjectsList institution={institution} />
        </Col>
      )}
    </Row>
  );
};

export default InstitutionsForm;
