import { IError } from '../../api/models/error';
import { ISector } from '../../api/models/sector';

export const FETCH_SECTORS = 'FETCH_SECTORS';
export const FETCH_SECTORS_SUCCESS = 'FETCH_SECTORS_SUCCESS';
export const FETCH_SECTORS_FAIL = 'FETCH_SECTORS_FAIL';

interface IFetchSectors {
  type: typeof FETCH_SECTORS;
}

interface IFetchSectorsSuccess {
  type: typeof FETCH_SECTORS_SUCCESS;
  payload: {
    sectors: ISector[];
  };
}

interface IFetchSectorsFail {
  type: typeof FETCH_SECTORS_FAIL;
  payload: {
    errors: IError;
  };
}

export interface ISectorsState {
  sectors: ISector[];
  fetchingSectors: boolean;
  errors: IError | null;
}

export type SectorsActionTypes = IFetchSectors | IFetchSectorsSuccess | IFetchSectorsFail;
