import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import NotFound from '../404';
import ChangePassword from '../auth/ChangePassword';
import EditProfile from '../auth/EditProfile';
import Home from './home';
import MyInstitution from './my-institution';
import EditYearlyStatisticalData from './my-institution/edit-statistical-yearly-data/index';
import SelfEvaluation from './self-evaluation/index';
import AddSelfEvaluation from './self-evaluation/add-self-evaluation-period';
import FieldsOverview from '../../components/institutions/self-evaluation/fields-overview/FieldsOverview';
import SubFieldEvaluation from '../../components/institutions/self-evaluation/fields-overview/subfields/subfield-evaluation/SubFieldEvaluation';

const InstitutionRoutes: React.FC = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}`}>
        <Redirect to={`${match.url}/kreu`} />
      </Route>
      <Route exact path={`${match.url}/kreu`} component={Home} />
      <Route exact path={`${match.url}/vetevleresimi`} component={SelfEvaluation} />
      <Route
        exact
        path={`${match.url}/vetevleresimi/shto-periudhe`}
        component={AddSelfEvaluation}
      />
      <Route
        exact
        path={`${match.url}/vetevleresimi/:selfEvaluationId`}
        component={FieldsOverview}
      />
      <Route
        exact
        path={`${match.url}/vetevleresimi/:selfEvaluationId/nenfusha/:subFieldId`}
        component={SubFieldEvaluation}
      />
      <Route exact path={`${match.url}/institucioni-im`} component={MyInstitution} />
      <Route
        exact
        path={`${match.url}/institucioni-im/ndrysho-statistikat/:id`}
        component={EditYearlyStatisticalData}
      />
      <Route exact path={`${match.url}/modifiko-profilin`} component={EditProfile} />
      <Route exact path={`${match.url}/ndrysho-fjalekalimin`} component={ChangePassword} />
      <Route component={() => <NotFound showContainer={false} />} />
    </Switch>
  );
};

export default InstitutionRoutes;
