import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Card, Col, message, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import html2canvas from 'html2canvas';
import moment from 'moment';
import { RootState } from '../../../../../redux/reducers';
import Header from '../../../../../components/admin/reports/StatisticsReportHeader';
import StatisticalData from '../../../../../components/admin/reports/StatisticalData';
import {
  IChartSeries,
  IReportImage,
  IResultByFieldsReport,
  IStatisticalDataWidget
} from '../../../../../api/models/report';
import HorizontalBarChart from '../../../../../components/admin/charts/HorizontalBarChart';
import { IError } from '../../../../../api/models/error';
import agent from '../../../../../api/agent';
import { IEvaluationPeriod } from '../../../../../api/models/evaluationPeriod';
import Spinner from '../../../../../components/layout/shared/Spinner';
import { ISubField } from '../../../../../api/models/field';
import generatePDFReport from '../../pdfReport';
import generateExcelReport from '../../excelReport';

const EvaluationPerformanceByFieldsReport = () => {
  const { t } = useTranslation();
  const { Option } = Select;

  const evaluationPeriods = useSelector(
    (state: RootState) => state.evaluationPeriod.evaluationPeriods
  );
  const fields = useSelector((state: RootState) => state.field.fields);

  const [evaluationPeriod, setEvaluationPeriod] = useState<null | IEvaluationPeriod>(null);
  const [subField, setSubField] = useState<undefined | number>(undefined);
  const [loadingResults, setLoadingResults] = useState<boolean>(true);
  const [results, setResults] = useState<IResultByFieldsReport | null>(null);
  const [statistics, setStatistics] = useState<IStatisticalDataWidget[]>([]);
  const [resultByFields, setResultByFields] = useState<IChartSeries[]>([]);
  const [resultByInstitutions, setResultByInstitutions] = useState<any>([]);
  const [resultBySubfields, setResultBySubfields] = useState<IChartSeries[]>([]);
  const [subFields, setSubFields] = useState<ISubField[]>([]);
  const [generatingPDF, setGeneratingPDF] = useState<boolean>(false);
  const [generatingExcel, setGeneratingExcel] = useState<boolean>(false);

  const resultPerformanceByFieldsImage = useRef<HTMLDivElement>(null);
  const resultPerformanceBySubFieldsImage = useRef<HTMLDivElement>(null);

  const getResults = useCallback(async () => {
    let result = null;

    if (evaluationPeriod) {
      setLoadingResults(true);

      result = await agent.Report.getPerformanceByFields(evaluationPeriod.id, subField);

      setLoadingResults(false);
    }

    setResults(result);
  }, [evaluationPeriod, subField]);

  const generateChartsDataSource = useCallback(async () => {
    if (results) {
      setStatistics(results.statistics);

      setResultByFields([
        {
          name: 'Fusha',
          data: results.resultByFields.map(({ name, rating }) => ({
            x: name,
            y: rating
          }))
        }
      ]);

      setResultBySubfields([
        {
          name: 'Institucioni',
          data: results.resultBySubFields.map(({ name, rating }) => ({
            x: name,
            y: rating
          }))
        }
      ]);

      setResultByInstitutions(
        results.resultByInstitutions.map((el) => ({
          name: el.name,
          institutions: [
            {
              name: el.name,
              data: el.institutions.map(({ institution, rating }) => ({
                x: institution,
                y: rating
              }))
            }
          ]
        }))
      );
    }
  }, [results]);

  const selectEvaluationPeriod = useCallback(() => {
    if (evaluationPeriods.length > 0) {
      const currentPeriod = evaluationPeriods.filter(
        (el) => el.period === new Date().getFullYear()
      )[0];

      if (currentPeriod) return currentPeriod;

      return evaluationPeriods[0];
    }

    return null;
  }, [evaluationPeriods]);

  useEffect(() => {
    setEvaluationPeriod(selectEvaluationPeriod());

    const mappedSubFields: any = fields.map((field) => field.subFields);

    setSubFields([].concat(...mappedSubFields));
  }, [evaluationPeriods, fields, selectEvaluationPeriod]);

  useEffect(() => {
    try {
      getResults();
    } catch (error) {
      const errors: IError = error?.data;
      setLoadingResults(false);
      message.error({ content: errors?.message, duration: 4 });
    }
  }, [evaluationPeriod, getResults, subField]);

  useEffect(() => {
    generateChartsDataSource();
  }, [generateChartsDataSource, results]);

  const renderHeaderFilter = () => {
    if (evaluationPeriod) {
      return (
        <Select
          className="w-100"
          placeholder={t('general.evaluation_periods_ptc')}
          style={{ border: '1px solid #3e82f7', borderRadius: '11px' }}
          defaultValue={evaluationPeriod.id}
          onChange={(e) => {
            setEvaluationPeriod(evaluationPeriods.filter((el) => el.id === e)[0]);
          }}
        >
          {evaluationPeriods.map((period) => (
            <Option key={period.id} value={period.id}>
              {period.period}
            </Option>
          ))}
        </Select>
      );
    }

    return null;
  };

  const renderSubFieldsFilter = () => {
    return (
      <Col xs={24} md={12}>
        <Select
          showSearch
          optionFilterProp="children"
          className="w-100"
          placeholder={t('general.subfield')}
          style={{ border: '1px solid #3e82f7', borderRadius: '11px' }}
          defaultValue={subField}
          onChange={(el) => setSubField(+el)}
        >
          {subFields.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
      </Col>
    );
  };

  const generateExcel = async () => {
    try {
      if (evaluationPeriod) {
        setGeneratingExcel(true);
        const result = await agent.Report.getProgressExcel();

        const datasource: any[] = result.data
          .filter((el) => el.year === evaluationPeriod.period)
          .map(
            ({
              county,
              institution,
              year,
              startdate,
              enddate,
              field,
              subfield,
              indicator,
              criteria,
              rating,
              description,
              evidence
            }) => {
              return [
                county,
                institution,
                year,
                `${moment(startdate).format('DD/MM/YYYY')} - ${moment(enddate).format(
                  'DD/MM/YYYY'
                )}`,
                field,
                subfield,
                indicator,
                criteria,
                rating,
                description,
                evidence
              ];
            }
          );

        generateExcelReport(
          'raport_rezultatet_vetevleresimit',
          datasource,
          'evaluation_progress_data_table',
          [
            { name: 'Qark' },
            { name: 'Institucioni Arsimor' },
            { name: 'Viti i vlerësimit' },
            { name: 'Periudha e vlerësimit' },
            { name: 'Fusha' },
            { name: 'Nënfusha' },
            { name: 'Treguesi' },
            { name: 'Kriteri' },
            { name: 'Vlerësimi me pikë' },
            { name: 'Komente' },
            { name: 'Evidenca' }
          ]
        );

        setGeneratingExcel(false);
      }
    } catch (error) {
      const errors: IError = error?.data;
      setGeneratingExcel(false);
      message.error({ content: errors?.message, duration: 4 });
    }
  };

  const generatePDF = async () => {
    const images: IReportImage[] = [];
    const reportTitle = `${t('general.process_performance_fields')} ${evaluationPeriod?.period}`;

    const firstImage = {
      element: resultPerformanceByFieldsImage.current
    };

    const secondImage = {
      element: resultPerformanceBySubFieldsImage.current
    };

    try {
      setGeneratingPDF(true);
      window.scrollTo(0, 0);

      if (firstImage && firstImage.element) {
        const canvas = await html2canvas(firstImage.element, { scrollX: -7 });

        images.push({ url: canvas.toDataURL('image/png') });
      }

      if (secondImage && secondImage.element) {
        const canvas = await html2canvas(secondImage.element, { scrollX: -7 });

        images.push({ url: canvas.toDataURL('image/png') });
      }

      generatePDFReport(images, reportTitle, 'raport_performace_sipas_fushave');
      setGeneratingPDF(false);
    } catch (error) {
      setGeneratingPDF(false);
    }
  };

  if (!evaluationPeriod) return <h3>{t('general.no_evaluation_period')}</h3>;

  if (loadingResults) return <Spinner />;

  return (
    <>
      <Header
        element={renderHeaderFilter()}
        generateExcel={generateExcel}
        generatePDF={generatePDF}
        generatingPDF={generatingPDF}
        generatingExcel={generatingExcel}
        title={t('general.self_evaluation_result')}
        subtitle={t('sidemenu.performance_by_fields')}
      />

      <Row gutter={16}>
        <Col xs={24} ref={resultPerformanceByFieldsImage}>
          <Row gutter={16} justify="space-between" align="middle">
            <StatisticalData data={statistics} />
            <Col xs={24}>
              <Card
                title={t('general.result_by_fields_points')}
                className="ant-card-default-border"
              >
                <HorizontalBarChart height={250} series={resultByFields} />
              </Card>
            </Col>

            <Col xs={24}>
              <Card
                title={t('general.result_by_subfields_points')}
                className="ant-card-default-border"
              >
                {renderSubFieldsFilter()}
                <HorizontalBarChart height={250} series={resultBySubfields} />
              </Card>
            </Col>
          </Row>
        </Col>

        <Col xs={24} ref={resultPerformanceBySubFieldsImage}>
          {resultByInstitutions.map((el: any, index: any) => {
            return (
              <Card
                key={index.toString()}
                title={t('general.field_result_by_institutions', {
                  field: `"${el.name}"`
                })}
                className="ant-card-default-border"
              >
                <HorizontalBarChart height={250} series={el.institutions} />
              </Card>
            );
          })}
        </Col>
      </Row>
    </>
  );
};

export default EvaluationPerformanceByFieldsReport;
