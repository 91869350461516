import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAIL,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_FIRST_TIME,
  CHANGE_PASSWORD_FIRST_TIME_FAIL,
  UPDATE_CURRENT_USER,
  UPDATE_CURRENT_USER_SUCCESS,
  UPDATE_CURRENT_USER_FAIL,
  IAuthState,
  TOGGLE_APP_LOADING,
  RESET_AUTH_ERRORS,
  AuthActionTypes
} from '../constants/auth';

const initialState: IAuthState = {
  token: localStorage.getItem('token'),
  isAuthenticated: false,
  user: null,
  errors: null,
  role: null,
  email: null,
  firstLogin: false,
  updatingCurrentUser: false,
  updateCurrentUserErrors: null,
  fetchingCurrentUser: true,
  fetchingCurrentUserErrors: null,
  appLoading: false
};

const authReducer = (state = initialState, action: AuthActionTypes): IAuthState => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
        role: action.payload.role,
        email: action.payload.email,
        firstLogin: action.payload.firstLogin,
        errors: null
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        errors: action.payload.errors,
        role: null,
        user: null,
        email: null,
        firstLogin: false,
        token: null
      };
    case CHANGE_PASSWORD_FIRST_TIME_FAIL:
      return {
        ...state,
        errors: action.payload.errors
      };
    case TOGGLE_APP_LOADING:
      return {
        ...state,
        appLoading: action.payload.loading
      };
    case LOGOUT:
    case CHANGE_PASSWORD:
    case CHANGE_PASSWORD_FIRST_TIME:
      return {
        ...state,
        isAuthenticated: false,
        role: null,
        user: null,
        token: null,
        errors: null,
        email: null,
        firstLogin: false
      };
    case CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        errors: action.payload.errors
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        role: action.payload.role,
        email: action.payload.email,
        fetchingCurrentUser: false,
        fetchingCurrentUserErrors: null
      };
    case FETCH_USER_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        role: null,
        email: null,
        fetchingCurrentUser: false,
        fetchingCurrentUserErrors: action.payload.fetchingCurrentUserErrors
        // appLoading: false
      };
    case UPDATE_CURRENT_USER:
      return {
        ...state,
        updatingCurrentUser: true
      };
    case UPDATE_CURRENT_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        updateCurrentUserErrors: null,
        updatingCurrentUser: false
      };
    case UPDATE_CURRENT_USER_FAIL:
      return {
        ...state,
        updateCurrentUserErrors: action.payload.errors,
        updatingCurrentUser: false
      };
    case RESET_AUTH_ERRORS:
      return {
        ...state,
        updateCurrentUserErrors: null,
        fetchingCurrentUserErrors: null
      };
    default:
      return state;
  }
};

export default authReducer;
