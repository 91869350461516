import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { getEvaluationPeriodBasedOnInstitutionType } from '../../../../../api/models/evaluationPeriod';
import { IInstitution } from '../../../../../api/models/institution';
import { IFieldExtractReport } from '../../../../../api/models/report';
import { IInstitutionSelfEvaluation } from '../../../../../api/models/selfEvaluation';
import { IField } from '../../../../../api/models/field';
import {
  evaluateSelfEvaluationRating,
  generateSelfEvaluationReportResult,
  getSelEvalutationAverageRating
} from './selfEvaluationReport_helper';

const fieldExtractPDFReport = (
  // fileName: string,
  data: IFieldExtractReport[],
  selfEvaluation: IInstitutionSelfEvaluation,
  institution: IInstitution,
  fields: IField[]
) => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const currentField = fields.filter((field) => field.id === data[0].fieldId)[0];
  const result = generateSelfEvaluationReportResult(currentField, data);

  // Build  header for 'Vleresimi sipas treguesve' table
  const vleresimiSipasTreguesve: any[] = [
    [
      { text: 'Nënfusha', style: 'tableHeader' },
      { text: 'Treguesi', style: 'tableHeader' },
      { text: 'Nivelet e vlerësimit', style: 'tableHeader' },
      { text: 'Pikët', style: 'tableHeader' }
    ]
  ];

  // Build body for 'Vleresimi sipas treguesve' table
  result.subFields.forEach((subField, indexOfSubField) => {
    subField.indicators.forEach((indicator, indexOfIndicator) => {
      vleresimiSipasTreguesve.push([
        {
          text: `${indexOfSubField + 1}. ${subField.name}`,
          style: 'tableField',
          rowSpan: subField.indicators.length
        },
        {
          text: `${indexOfSubField + 1}.${indexOfIndicator + 1}. ${indicator.name}`,
          style: 'tableField'
        },
        { text: evaluateSelfEvaluationRating(indicator.rating), style: 'tableField' },
        { text: indicator?.rating?.toFixed(1) ?? '', alignment: 'right', style: 'tableField' }
      ]);
    });
  });

  // Build  header for 'Vleresimi sipas kritereve' table
  const vleresimiSipasKritereve: any[] = [
    [
      { text: 'Nënfusha', style: 'tableHeader' },
      { text: 'Treguesi', style: 'tableHeader' },
      { text: 'Kritere', style: 'tableHeader' },
      { text: 'Nivelet e vlerësimit', style: 'tableHeader' },
      { text: 'Pikët', style: 'tableHeader' },
      { text: 'Komente', style: 'tableHeader' },
      { text: 'Evidenca', style: 'tableHeader' }
    ]
  ];

  // Build body for 'Vleresimi sipas kritereve' table
  result.subFields.forEach((subField, indexOfSubField) => {
    const subFieldRowSpan = data.filter((item) => item.subFieldId === subField.id).length;

    subField.indicators.forEach((indicator, indexOfIndicator) => {
      indicator.criteria.forEach((criteria, indexOfCriteria) => {
        vleresimiSipasKritereve.push([
          {
            text: `${indexOfSubField + 1}. ${subField.name}`,
            style: 'tableField_1',
            rowSpan: subFieldRowSpan
          },
          {
            text: `${indexOfSubField + 1}.${indexOfIndicator + 1}. ${indicator.name}`,
            style: 'tableField_1',
            rowSpan: indicator.criteria.length
          },
          {
            text: `${indexOfSubField + 1}.${indexOfIndicator + 1}.${indexOfCriteria + 1}. ${
              criteria.name
            }`,
            style: 'tableField_1'
          },
          { text: evaluateSelfEvaluationRating(criteria.rating), style: 'tableField_1' },
          { text: criteria.rating?.toFixed(1) ?? '', alignment: 'right', style: 'tableField_1' },
          { text: criteria.description, style: 'tableField_1' },
          { text: criteria.evidence, style: 'tableField_1' }
        ]);
      });
    });
  });

  const totalRating = getSelEvalutationAverageRating(data);

  const docDefinition: any = {
    pageOrientation: 'landscape',
    content: [
      { text: `Vetëvlerësimi ${selfEvaluation.evaluationPeriod.period}`, style: 'mainheader' },
      { text: `${institution.name}`, style: 'subheader' },
      { text: `Fusha: ${data[0].fieldName}`, style: 'subheader' },
      {
        text: `${getEvaluationPeriodBasedOnInstitutionType(
          selfEvaluation.evaluationPeriod.period,
          institution.institutionType.name
        )}`,
        style: 'subheader'
      },
      {
        table: {
          body: [
            [
              {
                border: [false, false, false, true],
                style: 'subheader_1',
                text: 'Performanca e vetëvlerësimit'
              }
            ],
            [
              {
                border: [false, false, false, false],
                margin: [0, 5, 0, 0],
                text: `${evaluateSelfEvaluationRating(totalRating)} ${totalRating.toFixed(1)}`,
                fontSize: 12,
                bold: true
              }
            ]
          ]
        },
        layout: {
          paddingLeft() {
            return 0;
          }
        }
      },
      {
        text: 'Vetëvlerësimi sipas nënfushave',
        style: 'subheader_1',
        margin: [0, 0, 0, 10],
        pageBreak: 'before'
      },
      {
        table: {
          widths: ['*', 250, 100],
          headerRows: 1,
          body: [
            [
              { text: 'Nënfusha', style: 'tableHeader' },
              { text: 'Nivelet e vlerësimit', style: 'tableHeader' },
              { text: 'Pikët', style: 'tableHeader' }
            ],
            ...result.subFields.map(({ name, rating }, index) => {
              return [
                { text: `${index + 1}. ${name}`, style: 'tableField' },
                { text: evaluateSelfEvaluationRating(rating), style: 'tableField' },
                { text: rating?.toFixed(1) ?? '', alignment: 'right', style: 'tableField' }
              ];
            })
          ]
        }
      },
      {
        text: 'Vetëvleresimi sipas treguesve',
        style: 'subheader_1',
        margin: [0, 0, 0, 10],
        pageBreak: 'before'
      },
      {
        table: {
          widths: [200, '*', 100, 50],
          headerRows: 1,
          body: [...vleresimiSipasTreguesve]
        }
      },
      {
        text: 'Vetëvlerësimi sipas kritereve',
        style: 'subheader_1',
        margin: [0, 0, 0, 0, 10],
        pageBreak: 'before'
      },
      {
        table: {
          widths: [75, '*', '*', 100, 35, '*', '*'],
          headerRows: 1,
          body: [...vleresimiSipasKritereve]
        }
      }
    ],
    pageBreakBefore(currentNode: any, followingNodesOnPage: any) {
      return currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0;
    },
    footer(currentPage: any, pageCount: any) {
      return [
        {
          text: `${currentPage.toString()} nga ${pageCount}`,
          alignment: 'right',
          margin: [0, 0, 40, 0],
          fontSize: 8
        }
      ];
    },
    styles: {
      mainheader: {
        fontSize: 25,
        bold: true,
        margin: [0, 100, 0, 0],
        alignment: 'center'
      },
      subheader: {
        fontSize: 18,
        bold: true,
        margin: [0, 50, 0, 0],
        alignment: 'center'
      },
      subheader_1: {
        fontSize: 12,
        bold: true,
        margin: [0, 50, 0, 0]
      },
      tableHeader: {
        fillColor: 'black',
        color: 'white',
        alignment: 'center',
        fontSize: 12,
        bold: true
      },
      tableField: {
        fontSize: 10
      },
      tableField_1: {
        fontSize: 8
      }
    },
    defaultStyle: {
      // alignment: 'justify'
    }
  };

  pdfMake.createPdf(docDefinition).download('raporti_vetëvlerësimit.pdf');
};

export default fieldExtractPDFReport;
