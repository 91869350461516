import React from 'react';
import { Form, Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// import moment from 'moment';
import { RootState } from '../../../redux/reducers';
import {
  closeInstitutionSelfEvaluationForm,
  updateMyInstitutionSelfEvaluation
} from '../../../redux/actions/myInstitution';
import Alert from '../../layout/shared/Alert';
import { IEvaluationPeriod } from '../../../api/models/evaluationPeriod';

// import { getEvaluationPeriodBasedOnInstitutionType } from '../../../api/models/evaluationPeriod';
// import utils from '../../../utils';

export interface IProps {
  visible: boolean;
}

const getIdOfLatestEvaluationPeriod = (
  evaluationPeriods: IEvaluationPeriod[]
): number | undefined => {
  const sortedByEndDate = evaluationPeriods.sort(
    (first, second) => new Date(first.endDate).valueOf() - new Date(second.endDate).valueOf()
  );

  return sortedByEndDate[sortedByEndDate.length - 1]?.id;
};

const SelfEvaluationPeriodForm: React.FC<IProps> = ({ visible }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { Option } = Select;

  const fields = useSelector((state: RootState) => state.field.fields);
  const institution = useSelector((state: RootState) => state.myInstitution.institution);
  const evaluationPeriods = useSelector(
    (state: RootState) => state.evaluationPeriod.evaluationPeriods
  );
  const addingMyInstitutionSelfEvaluationPeriod = useSelector(
    (state: RootState) => state.myInstitution.addingMyInstitutionSelfEvaluation
  );
  const errors = useSelector((state: RootState) => state.myInstitution.errors);

  return (
    <Modal
      visible={visible}
      confirmLoading={addingMyInstitutionSelfEvaluationPeriod}
      afterClose={() => {
        form.resetFields();
      }}
      title={t('general.add_field')}
      okText={t('general.add')}
      cancelText={t('general.cancel')}
      onCancel={() => {
        dispatch(closeInstitutionSelfEvaluationForm());
        form.resetFields();
      }}
      onOk={async () => {
        try {
          const { fieldId } = await form.validateFields();

          const evaluationPeriodId = getIdOfLatestEvaluationPeriod(evaluationPeriods);

          if (institution && evaluationPeriodId)
            dispatch(
              updateMyInstitutionSelfEvaluation(
                institution.id,
                { fieldId, evaluationPeriodId },
                t('feedback_message.institution_self_evaluation_period_successfully_added')
              )
            );
        } catch (error) {
          // NO-OP
        }
      }}
    >
      <Form form={form} layout="vertical" name="selfEvaluation_modal">
        <Form.Item
          name="fieldId"
          label={t('general.field')}
          rules={[{ required: true, message: t('validation_messages.select_field') }]}
        >
          <Select placeholder={t('placeholders.select_field')}>
            {fields.map((field) => (
              <Option key={field.id} value={field.id}>
                {field.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {/* {institution && (
          <Form.Item
            name="evaluationPeriodId"
            label={t('general.evaluation_period')}
            rules={[{ required: true, message: t('validation_messages.select_period') }]}
          >
            <Select placeholder={t('placeholders.select_period')}>
              {evaluationPeriods
                
                .filter((evaluationPeriod) => utils.isDateBefore(moment(evaluationPeriod.endDate)))
                .map((evaluationPeriod) => (
                  <Option
                    key={evaluationPeriod.id}
                    label={getEvaluationPeriodBasedOnInstitutionType(
                      evaluationPeriod.period,
                      institution.institutionType.name
                    )}
                    value={evaluationPeriod.id}
                  >
                    {getEvaluationPeriodBasedOnInstitutionType(
                      evaluationPeriod.period,
                      institution.institutionType.name
                    )}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        )} */}
        {errors && <Alert message={errors.message} />}
      </Form>
    </Modal>
  );
};

export default SelfEvaluationPeriodForm;
