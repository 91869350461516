import React from 'react';
import { Col, Grid } from 'antd';
import { ColProps } from 'antd/lib/grid';
import StatisticWidget from '../../layout/StatisticWidget';
import { IStatisticalDataWidget } from '../../../api/models/report';
import utils from '../../../utils';

interface IProps {
  data: IStatisticalDataWidget[];
}

const StatisticalData: React.FC<IProps> = ({ data }) => {
  const { useBreakpoint } = Grid;
  const isXs = utils.getBreakPoint(useBreakpoint()).includes('xs');

  let colProps: ColProps = {};
  if (!isXs) {
    colProps = {
      flex: `${100 / data.length}%`
    };
  } else {
    colProps = {
      xs: 12
    };
  }

  const renderWidgets = () => {
    if (data.length === 0) return null;

    return data.map((el, index) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Col key={index.toString()} {...colProps}>
        <StatisticWidget
          title={el.title}
          value={el.value}
          subtitle={el.subtitle}
          style={{ marginTop: '25px' }}
        />
      </Col>
    ));
  };

  return <>{renderWidgets()}</>;
};

export default StatisticalData;
