import { IError } from '../../api/models/error';
import { ICounty } from '../../api/models/county';

export const FETCH_COUNTIES = 'FETCH_COUNTIES';
export const FETCH_COUNTIES_SUCCESS = 'FETCH_COUNTIES_SUCCESS';
export const FETCH_COUNTIES_FAIL = 'FETCH_COUNTIES_FAIL';

interface IFetchCounties {
  type: typeof FETCH_COUNTIES;
}

interface IFetchCountiesSuccess {
  type: typeof FETCH_COUNTIES_SUCCESS;
  payload: {
    counties: ICounty[];
  };
}

interface IFetchCountiesFail {
  type: typeof FETCH_COUNTIES_FAIL;
  payload: {
    errors: IError;
  };
}

export interface ICountiesState {
  counties: ICounty[];
  fetchingCounties: boolean;
  errors: IError | null;
}

export type CountyActionTypes = IFetchCounties | IFetchCountiesSuccess | IFetchCountiesFail;
