import { combineReducers } from 'redux';
import themeReducer from './theme';
import authReducer from './auth';
import roleReducer from './roles';
import userReducer from './users';
import institutionReducer from './institutions';
import sectorReducer from './sectors';
import municipalityReducer from './municipalities';
import countyReducer from './counties';
import subjectReducer from './subjects';
import evaluationPeriodsReducer from './evaluationPeriods';
import { RESET_STATE_ACTION, AuthActionTypes } from '../constants/auth';
import documentReducer from './documents';
import myInstitutionReducer from './myInstitution';
import propertyReducer from './property';
import fieldReducer from './fields';
import graphicalDataReducer from './graphicalData';
import evidenceTemplateReducer from './evidenceTemplate';
import summaryTemplateReducer from './summaryTemplate';
import evidenceReducer from './evidence';
import summaryReducer from './summary';

const appReducer = combineReducers({
  theme: themeReducer,
  auth: authReducer,
  role: roleReducer,
  institution: institutionReducer,
  user: userReducer,
  sector: sectorReducer,
  municipality: municipalityReducer,
  county: countyReducer,
  subject: subjectReducer,
  evaluationPeriod: evaluationPeriodsReducer,
  document: documentReducer,
  myInstitution: myInstitutionReducer,
  property: propertyReducer,
  field: fieldReducer,
  graphs: graphicalDataReducer,
  evidenceTemplate: evidenceTemplateReducer,
  evidence: evidenceReducer,
  summary: summaryReducer,
  summaryTemplate: summaryTemplateReducer
});

export const rootReducer = (state: any, action: AuthActionTypes) => {
  let updatedState = state;

  if (RESET_STATE_ACTION.includes(action.type)) {
    updatedState = undefined;
  }

  return appReducer(updatedState, action);
};

export type RootState = ReturnType<typeof rootReducer>;
