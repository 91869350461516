import React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import UsersForm from '../../../../components/admin/users/UsersForm';
import { RootState } from '../../../../redux/reducers';
import NotFound from '../../../404';

interface MatchParams {
  id: string;
}

interface IProps extends RouteComponentProps<MatchParams> {}

const EditUser: React.FC<IProps> = ({ match }) => {
  const users = useSelector((state: RootState) => state.user.users);
  const user = users.filter((el) => el.id === +match.params.id)[0];

  if (!user) return <NotFound showContainer={false} />;

  return <UsersForm user={user} />;
};

export default EditUser;
