import React from 'react';
import { Form, Button, Input, Card, Col, Row, Cascader } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PageHeaderAlt from '../../layout/PageHeaderAlt';
import Flex from '../../layout/shared/Flex';
import { RootState } from '../../../redux/reducers';
import { IUser, IUserSendRequest } from '../../../api/models/user';
import { addUser, updateUser } from '../../../redux/actions/users';
import Alert from '../../layout/shared/Alert';
import { IInstitution } from '../../../api/models/institution';
import AppBreadCrumb from '../../layout/AppBreadCrumb';
import { InstitutionTypeName, UserRole } from '../../../constants/theme';

interface IProps {
  user?: IUser;
}

const UsersForm: React.FC<IProps> = ({ user = null }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const roles = useSelector((state: RootState) => state.role.roles);
  const institutions = useSelector((state: RootState) => state.institution.institutions);
  const institutionTypes = useSelector((state: RootState) => state.institution.institutionTypes);
  const updatingUser = useSelector((state: RootState) => state.user.updatingUser);
  const savingUser = useSelector((state: RootState) => state.user.addingUser);
  const addUserErrors = useSelector((state: RootState) => state.user.errorsAddingUser);
  const updateUserErrors = useSelector((state: RootState) => state.user.errorsUpdatingUser);

  const institutionTypeFor = (institution: IInstitution) =>
    institutionTypes.find(
      (institutionType) => institution.institutionType.id === institutionType.id
    );

  const displayErrors = () => {
    if (user && updateUserErrors) return <Alert message={updateUserErrors.message} />;
    if (addUserErrors) return <Alert message={addUserErrors.message} />;

    return null;
  };

  const onFinish = (values: any) => {
    const { firstName, lastName, address, email, phone, institutionAndRole } = values;

    const userData: IUserSendRequest = {
      firstName,
      lastName,
      address,
      email,
      phone,
      institutionId: institutionAndRole[0],
      roleId: institutionAndRole[1]
    };

    if (user)
      dispatch(updateUser(user.id, userData, t('feedback_message.user_successfully_updated')));
    else dispatch(addUser(userData, form, t('feedback_message.user_successfully_added')));
  };

  return (
    <Form
      layout="vertical"
      form={form}
      className="ant-advanced-search-form"
      initialValues={
        user ? { ...user, institutionAndRole: [user.institution.id, user.role.id] } : {}
      }
      onFinish={onFinish}
    >
      <PageHeaderAlt className="bg-white border-bottom">
        <Flex className="py-2" mobileFlex={false} justifyContent="between" alignItems="center">
          <h2>{user ? t('general.change_user') : t('general.add_user')}</h2>
          <Button type="primary" htmlType="submit" loading={user ? updatingUser : savingUser}>
            {user ? t('general.save') : t('general.add')}
          </Button>
        </Flex>
        <AppBreadCrumb
          items={[
            { title: t('sidemenu.users'), path: '/platforma/admin/perdorues' },
            { title: user ? t('general.change_user') : t('general.add_user'), path: null }
          ]}
        />
      </PageHeaderAlt>

      <Card style={{ marginTop: '25px' }}>
        <Flex justifyContent="between" mobileFlex={false}>
          <Row gutter={16} style={{ width: '100%', marginLeft: '0', marginRight: '0' }}>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label={t('general.firstName')}
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: t('validation_messages.enter_first_name')
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label={t('general.lastName')}
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: t('validation_messages.enter_last_name')
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="institutionAndRole"
                label={t('general.institution_and_role')}
                rules={[
                  { required: true, message: t('validation_messages.select_institution_and_role') }
                ]}
              >
                <Cascader
                  placeholder={t('placeholders.select_institution_and_role')}
                  options={institutions.map((institution) => ({
                    value: institution.id,
                    label: institution.name,
                    children: roles
                      .filter((role) => {
                        switch (institutionTypeFor(institution)?.name) {
                          case InstitutionTypeName.AGJENSIA_KOMBETARE_ARSIMIT:
                            return role.name === UserRole.Admin || role.name === UserRole.Director;
                          case InstitutionTypeName.AGJENSIA_KOMBETARE_PUNESIMIT_AFTESIVE:
                            return role.name === UserRole.Director;
                          case InstitutionTypeName.QENDER_FORMIMI_PROFESIONAL:
                          case InstitutionTypeName.SHKOLLE:
                            return (
                              role.name === UserRole.InstitutionAdmin ||
                              role.name === UserRole.InstitutionDirector
                            );
                          default:
                            return false;
                        }
                      })
                      .map((role) => ({
                        value: role.id,
                        label: role.description
                      }))
                  }))}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label={t('general.email')}
                name="email"
                rules={[
                  {
                    required: true,
                    message: t('validation_messages.required_email')
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item label={t('general.phone')} name="phone">
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={t('general.address')} name="address">
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>{displayErrors()}</Col>
          </Row>
        </Flex>
      </Card>
    </Form>
  );
};

export default UsersForm;
