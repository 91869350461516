import React, { useState } from 'react';
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  EyeOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { Button, Card, Input, Popconfirm, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { IInstitution, IInstitutionDisplay } from '../../../api/models/institution';
import { deleteInstitution } from '../../../redux/actions/institutions';
import { RootState } from '../../../redux/reducers';
import utils from '../../../utils/index';
import SearchTable from '../../layout/SearchTable';
import Flex from '../../layout/shared/Flex';
import { InstitutionTypeName, UserRole } from '../../../constants/theme';

const InstitutionsList: React.FC = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.auth.user);
  const institutions = useSelector((state: RootState) => state.institution.institutions);
  const municipalities = useSelector((state: RootState) => state.municipality.municipalities);
  const counties = useSelector((state: RootState) => state.county.counties);

  const [search, setSearch] = useState<string | null>(null);

  if (!user) return <Redirect to="/login" />;

  const institutionsDisplay: IInstitutionDisplay[] = institutions.map((institution) => {
    return {
      ...institution,
      email: institution.email ?? '',
      phone: institution.phone ?? '',
      municipalityDescription: institution.municipality.name,
      institutionTypeDescription: institution.institutionType.description,
      sectorDescription: institution.sector.description,
      countyDescription: counties.filter(
        (county) =>
          county.id ===
          municipalities.filter(
            (municipality) => municipality.id === institution.municipality.id
          )[0]?.countyId
      )[0]?.name
    };
  });

  const addInstitution = () => {
    history.push(`${match.url}/shto-institucion`);
  };

  const onHandleDelete = (id: number) => {
    dispatch(deleteInstitution(id, t('feedback_message.institution_successfully_deleted')));
  };

  const tableColumns: any = [
    {
      title: t('general.fullName'),
      dataIndex: 'name',
      // width: '15%',
      sorter: (a: string, b: string) => utils.antdTableSorter(a, b, 'name')
    },
    {
      title: t('general.email'),
      dataIndex: 'email',
      // ellipsis: {
      //   showTitle: false
      // },
      // width: '15%',
      // render: (email: string) => (
      //   <Tooltip placement="topLeft" title={email}>
      //     {email}
      //   </Tooltip>
      // ),
      sorter: (a: string, b: string) => utils.antdTableSorter(a, b, 'email')
    },
    {
      title: t('general.acronym'),
      dataIndex: 'acronym',
      sorter: (a: string, b: string) => utils.antdTableSorter(a, b, 'acronym')
    },
    {
      title: t('general.phone'),
      dataIndex: 'phone',
      // ellipsis: {
      //   showTitle: false
      // },
      // render: (phone: string) => (
      //   <Tooltip placement="topLeft" title={phone}>
      //     {phone}
      //   </Tooltip>
      // ),
      sorter: (a: string, b: string) => utils.antdTableSorter(a, b, 'phone')
    },
    {
      title: t('general.county'),
      dataIndex: 'countyDescription',
      sorter: (a: string, b: string) => utils.antdTableSorter(a, b, 'countyDescription')
    },
    {
      title: t('general.municipality'),
      dataIndex: 'municipalityDescription',
      sorter: (a: string, b: string) => utils.antdTableSorter(a, b, 'municipalityDescription')
    },
    {
      title: t('general.type'),
      dataIndex: 'institutionTypeDescription',
      sorter: (a: string, b: string) => utils.antdTableSorter(a, b, 'institutionTypeDescription')
    },
    {
      title: t('general.sector'),
      dataIndex: 'sectorDescription',
      sorter: (a: string, b: string) => utils.antdTableSorter(a, b, 'sectorDescription')
    },
    {
      title: '',
      // width: '15%',
      dataIndex: 'actions',
      render: (_: any, row: IInstitution) => {
        // Admin cannot edit/delete his own institution since it is seeded
        if (
          row.institutionType.name === InstitutionTypeName.AGJENSIA_KOMBETARE_ARSIMIT ||
          row.institutionType.name === InstitutionTypeName.AGJENSIA_KOMBETARE_PUNESIMIT_AFTESIVE
        ) {
          return null;
        }

        // Only Admin can edit
        const canEdit = user.role.name === UserRole.Admin;

        return (
          <Flex justifyContent="center">
            <Tooltip title={t('general.view')} placement="bottom">
              <Link to={`${match.url}/shiko-institucion/${row.id}`}>
                <Button type="primary" className="mr-2" icon={<EyeOutlined />} size="small" />
              </Link>
            </Tooltip>

            {canEdit && (
              <Tooltip title={t('general.edit')} placement="bottom">
                <Link to={`${match.url}/ndrysho-institucion/${row.id}`}>
                  <Button type="primary" className="mr-2" icon={<EditOutlined />} size="small" />
                </Link>
              </Tooltip>
            )}

            {canEdit && (
              <Tooltip title={t('general.delete')} placement="bottom">
                <Popconfirm
                  title={t('feedback_message.are_you_sure')}
                  okText={t('general.yes')}
                  cancelText={t('general.no')}
                  onConfirm={() => onHandleDelete(row.id)}
                >
                  <Button danger icon={<DeleteOutlined />} size="small" />
                </Popconfirm>
              </Tooltip>
            )}
          </Flex>
        );
      }
    }
  ];

  return (
    <Card>
      <Flex justifyContent="between" mobileFlex={false}>
        <Flex className="mb-1" mobileFlex={false}>
          <Flex className="mr-md-3 mb-3">
            <Input
              placeholder={t('general.search')}
              prefix={<SearchOutlined />}
              onChange={(e) => setSearch(e.currentTarget.value)}
            />
          </Flex>
        </Flex>
        <Flex>
          {user.role.name === UserRole.Admin && (
            <Button onClick={addInstitution} type="primary" icon={<PlusCircleOutlined />} block>
              {t('general.add_institution')}
            </Button>
          )}
        </Flex>
      </Flex>

      <SearchTable tableColumns={tableColumns} dataSource={institutionsDisplay} search={search} />
    </Card>
  );
};

export default InstitutionsList;
