import React from 'react';
import Chart from 'react-apexcharts';
import { IChartSeries } from '../../../api/models/report';

interface IProps {
  series: IChartSeries[];
  height?: number;
  legendPosition?: string;
}

const LineChart: React.FC<IProps> = ({ series, height = 300, legendPosition = 'right' }) => {
  const options = {
    chart: {
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 2
    },
    legend: {
      position: legendPosition
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6
      }
    },
    // xaxis: {
    //   categories
    // },
    grid: {
      borderColor: '#f1f1f1'
    }
  };

  return <Chart options={options} series={series} type="line" height={height} />;
};

export default LineChart;
