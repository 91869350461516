import { IDocument } from './document';
import { IEvaluationPeriod } from './evaluationPeriod';

export const SUMMARY_TEMPLATE_NAME = 'Raporti i vetëvlerësimit';

export interface ISummaryTemplateSendRequest {
  documentId: number;
}

export interface ISummaryTemplate {
  id: number;
  document: IDocument;
}

export interface ISummaryTemplateDisplay {
  templateId: number;
  summaryId?: number;
  templateDocument: IDocument;
  summaryDocument?: IDocument;
  evaluationPeriod: IEvaluationPeriod;
  name: string;
}
