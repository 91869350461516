import { IError } from '../../api/models/error';
import { ISelfEvaluationResponse } from '../../api/models/field';
import { IInstitution, IInstitutionSubject } from '../../api/models/institution';
import { IInstitutionSelfEvaluation } from '../../api/models/selfEvaluation';
import { IInstitutionStatistics } from '../../api/models/statistic';
import { IUser } from '../../api/models/user';
import {
  IAddInstitutionSubject,
  IAddInstitutionSubjectFail,
  IAddInstitutionSubjectSuccess,
  IDeleteInstitutionSubject,
  IDeleteInstitutionSubjectFail,
  IDeleteInstitutionSubjectSuccess
} from './institutions';

export const FETCH_MY_INSTITUTION = 'FETCH_MY_INSTITUTION';
export const FETCH_MY_INSTITUTION_SUCCESS = 'FETCH_MY_INSTITUTION_SUCCESS';
export const FETCH_MY_INSTITUTION_FAIL = 'FETCH_MY_INSTITUTION_FAIL';
export const FETCH_MY_INSTITUTION_USERS = 'FETCH_MY_INSTITUTION_USERS';
export const FETCH_MY_INSTITUTION_USERS_SUCCESS = 'FETCH_MY_INSTITUTION_USERS_SUCCESS';
export const FETCH_MY_INSTITUTION_USERS_FAIL = 'FETCH_MY_INSTITUTION_USERS_FAIL';
export const FETCH_MY_INSTITUTION_SUBJECTS = 'FETCH_MY_INSTITUTION_SUBJECTS';
export const FETCH_MY_INSTITUTION_SUBJECTS_SUCCESS = 'FETCH_MY_INSTITUTION_SUBJECTS_SUCCESS';
export const FETCH_MY_INSTITUTION_SUBJECTS_FAIL = 'FETCH_MY_INSTITUTION_SUBJECTS_FAIL';
export const FETCH_MY_INSTITUTION_STATISTICS = 'FETCH_MY_INSTITUTION_STATISTICS';
export const FETCH_MY_INSTITUTION_STATISTICS_SUCCESS = 'FETCH_MY_INSTITUTION_STATISTICS_SUCCESS';
export const FETCH_MY_INSTITUTION_STATISTICS_FAIL = 'FETCH_MY_INSTITUTION_STATISTICS_FAIL';
export const UPDATE_MY_INSTITUTION_STATISTICS = 'UPDATE_MY_INSTITUTION_STATISTICS';
export const UPDATE_MY_INSTITUTION_STATISTICS_SUCCESS = 'UPDATE_MY_INSTITUTION_STATISTICS_SUCCESS';
export const UPDATE_MY_INSTITUTION_STATISTICS_FAIL = 'UPDATE_MY_INSTITUTION_STATISTICS_FAIL';
export const FETCH_MY_INSTITUTION_SELF_EVALUATION = 'FETCH_MY_INSTITUTION_SELF_EVALUATION';
export const FETCH_MY_INSTITITUTION_SELF_EVALUATION_SUCCESS =
  'FETCH_MY_INSTITUTION_SELF_EVALUATION_SUCCESS';
export const FETCH_MY_INSTITITUTION_SELF_EVALUATION_FAIL =
  'FETCH_MY_INSTITUTION_SELF_EVALUATION_FAIL';
export const UPDATE_MY_INSTITUTION_SELF_EVALUATION = 'UPDATE_MY_INSTITUTION_SELF_EVALUATION';
export const UPDATE_MY_INSTITUTION_SELF_EVALUATION_SUCCESS =
  'UPDATE_MY_INSTITUTION_SELF_EVALUATION_SUCCESS';
export const UPDATE_MY_INSTITUTION_SELF_EVALUATION_FAIL =
  'UPDATE_MY_INSTITUTION_SELF_EVALUATION_FAIL';
export const DELETE_MY_INSTITUTION_SELF_EVALUATION = 'DELETE_MY_INSTITUTION_SELF_EVALUATION';
export const DELETE_MY_INSTITUTION_SELF_EVALUATION_SUCCESS =
  'DELETE_MY_INSTITUTION_SELF_EVALUATION_SUCCESS';
export const DELETE_MY_INSTITUTION_SELF_EVALUATION_FAIL =
  'DELETE_MY_INSTITUTION_SELF_EVALUATION_FAIL';
export const SHOW_MY_INSTITUTION_SELF_EVALUATION_FORM = 'SHOW_MY_INSTITUTION_SELF_EVALUATION_FORM';
export const CLOSE_MY_INSTITUTION_SELF_EVALUATION_FORM =
  'CLOSE_MY_INSTITUTION_SELF_EVALUATION_FORM';
export const UPDATE_MY_INSTITUTION_SELF_EVALUATION_RESPONSE =
  'UPDATE_MY_INSTITUTION_SELF_EVALUATION_RESPONSE';
export const UPDATE_MY_INSTITUTION_SELF_EVALUATION_RESPONSE_SUCCESS =
  'UPDATE_MY_INSTITUTION_SELF_EVALUATION_RESPONSE_SUCCESS';
export const UPDATE_MY_INSTITUTION_SELF_EVALUATION_RESPONSE_FAIL =
  'UPDATE_MY_INSTITUTION_SELF_EVALUATION_RESPONSE_FAIL';
export const FETCH_MY_INSTITUTION_CURRENT_RESPONSE = 'FETCH_MY_INSTITUTION_CURRENT_RESPONSE';
export const FETCH_MY_INSTITUTION_CURRENT_RESPONSE_SUCCESS =
  'FETCH_MY_INSTITUTION_CURRENT_RESPONSE_SUCCESS';
export const FETCH_MY_INSTITUTION_CURRENT_RESPONSE_FAIL =
  'FETCH_MY_INSTITUTION_CURRENT_RESPONSE_FAIL';
export const RESET_MY_INSTITUTION_ERRORS = 'RESET_MY_INSTITUTION_ERRORS';

export interface IMyInstitutionState {
  institution: IInstitution | null;
  users: IUser[];
  subjects: IInstitutionSubject[];
  statistics: IInstitutionStatistics[];
  selfEvaluation: IInstitutionSelfEvaluation[];
  currentResponse: ISelfEvaluationResponse[];
  fetchingMyInstitution: boolean;
  fetchingMyInstitutionUsers: boolean;
  fetchingMyInstitutionSubjects: boolean;
  fetchingMyInstitutionStatistics: boolean;
  fetchingMyInstitutionSelfEvaluation: boolean;
  fetchingMyInstitutionCurrentResponse: boolean;
  updatingMyInstitutionSubjects: boolean;
  updatingMyInstitutionStatistics: boolean;
  addingMyInstitutionSelfEvaluation: boolean;
  deletingMyInstitutionSelfEvaluation: boolean;
  showMyInstitutionSelfEvaluationForm: boolean;
  updatingMyInstitutionSelfEvaluationResponse: boolean;
  errors: IError | null;
}

/** ************************** Fetch my institution *************************** */
interface IFetchMyInstitution {
  type: typeof FETCH_MY_INSTITUTION;
}

interface IFetchMyInstitutionSuccess {
  type: typeof FETCH_MY_INSTITUTION_SUCCESS;
  payload: {
    institution: IInstitution;
  };
}

interface IFetchMyInstitutionFail {
  type: typeof FETCH_MY_INSTITUTION_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Fetch institution users *************************** */
interface IFetchMyInstitutionUsers {
  type: typeof FETCH_MY_INSTITUTION_USERS;
}

interface IFetchMyInstitutionUsersSuccess {
  type: typeof FETCH_MY_INSTITUTION_USERS_SUCCESS;
  payload: {
    users: IUser[];
  };
}

interface IFetchMyInstitutionUsersFail {
  type: typeof FETCH_MY_INSTITUTION_USERS_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Fetch institution subjects *************************** */
interface IFetchMyInstitutionSubjects {
  type: typeof FETCH_MY_INSTITUTION_SUBJECTS;
}

interface IFetchMyInstitutionSubjectsSuccess {
  type: typeof FETCH_MY_INSTITUTION_SUBJECTS_SUCCESS;
  payload: {
    subjects: IInstitutionSubject[];
  };
}

interface IFetchMyInstitutionSubjectsFail {
  type: typeof FETCH_MY_INSTITUTION_SUBJECTS_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Fetch institution statistics *************************** */
interface IFetchMyInstitutionStatistics {
  type: typeof FETCH_MY_INSTITUTION_STATISTICS;
}

interface IFetchMyInstitutionStatisticsSuccess {
  type: typeof FETCH_MY_INSTITUTION_STATISTICS_SUCCESS;
  payload: {
    statistics: IInstitutionStatistics[];
  };
}

interface IFetchMyInstitutionStatisticsFail {
  type: typeof FETCH_MY_INSTITUTION_STATISTICS_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Update institution statistics *************************** */
interface IUpdateMyInstitutionStatistics {
  type: typeof UPDATE_MY_INSTITUTION_STATISTICS;
}

interface IUpdateMyInstitutionStatisticsSuccess {
  type: typeof UPDATE_MY_INSTITUTION_STATISTICS_SUCCESS;
  payload: {
    statistics: IInstitutionStatistics;
  };
}

interface IUpdateMyInstitutionStatisticsFail {
  type: typeof UPDATE_MY_INSTITUTION_STATISTICS_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Fetch institution self evaluation *************************** */
interface IFetchMyInstitutionSelfEvaluation {
  type: typeof FETCH_MY_INSTITUTION_SELF_EVALUATION;
}

interface IFetchMyInstitutionSelfEvaluationSuccess {
  type: typeof FETCH_MY_INSTITITUTION_SELF_EVALUATION_SUCCESS;
  payload: {
    selfEvaluation: IInstitutionSelfEvaluation[];
  };
}

interface IFetchMyInstitutionSelfEvaluationFail {
  type: typeof FETCH_MY_INSTITITUTION_SELF_EVALUATION_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Update institution self evaluation *************************** */
interface IUpdateMyInstitutionSelfEvaluation {
  type: typeof UPDATE_MY_INSTITUTION_SELF_EVALUATION;
}

interface IUpdateMyInstitutionSelfEvaluationSuccess {
  type: typeof UPDATE_MY_INSTITUTION_SELF_EVALUATION_SUCCESS;
  payload: {
    selfEvaluation: IInstitutionSelfEvaluation;
  };
}

interface IUpdateMyInstitutionSelfEvaluationFail {
  type: typeof UPDATE_MY_INSTITUTION_SELF_EVALUATION_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Delete institution self evaluation *************************** */
interface IDeleteMyInstitutionSelfEvaluation {
  type: typeof DELETE_MY_INSTITUTION_SELF_EVALUATION;
}

interface IDeleteMyInstitutionSelfEvaluationSuccess {
  type: typeof DELETE_MY_INSTITUTION_SELF_EVALUATION_SUCCESS;
  payload: {
    id: number;
  };
}

interface IDeleteMyInstitutionSelfEvaluationFail {
  type: typeof DELETE_MY_INSTITUTION_SELF_EVALUATION_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Show institution self evaluation form *************************** */
interface IShowMyInstitutionSelfEvaluationForm {
  type: typeof SHOW_MY_INSTITUTION_SELF_EVALUATION_FORM;
}

interface IHideMyInstitutionSelfEvaluationForm {
  type: typeof CLOSE_MY_INSTITUTION_SELF_EVALUATION_FORM;
}

/** ************************** Update institution self evaluation response *************************** */
interface IUpdateMyInstitutionSelfEvaluationResponse {
  type: typeof UPDATE_MY_INSTITUTION_SELF_EVALUATION_RESPONSE;
}

interface IUpdateMyInstitutionSelfEvaluationResponseSuccess {
  type: typeof UPDATE_MY_INSTITUTION_SELF_EVALUATION_RESPONSE_SUCCESS;
  payload: {
    currentResponse: ISelfEvaluationResponse[];
  };
}

interface IUpdateMyInstitutionSelfEvaluationResponseFail {
  type: typeof UPDATE_MY_INSTITUTION_SELF_EVALUATION_RESPONSE_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Fetch institution current response *************************** */
interface IFetchMyInstitutionCurrentResponse {
  type: typeof FETCH_MY_INSTITUTION_CURRENT_RESPONSE;
}

interface IFetchMyInstitutionCurrentResponseSuccess {
  type: typeof FETCH_MY_INSTITUTION_CURRENT_RESPONSE_SUCCESS;
  payload: {
    currentResponse: ISelfEvaluationResponse[];
  };
}

interface IFetchMyInstitutionCurrentResponseFail {
  type: typeof FETCH_MY_INSTITUTION_CURRENT_RESPONSE_FAIL;
  payload: {
    errors: IError;
  };
}

/** ************************** Reset institution errors *************************** */
interface IResetMyInstitutionErrors {
  type: typeof RESET_MY_INSTITUTION_ERRORS;
}

export type MyInstitutionActionTypes =
  | IFetchMyInstitution
  | IFetchMyInstitutionSuccess
  | IFetchMyInstitutionFail
  | IFetchMyInstitutionUsers
  | IFetchMyInstitutionUsersSuccess
  | IFetchMyInstitutionUsersFail
  | IFetchMyInstitutionSubjects
  | IFetchMyInstitutionSubjectsSuccess
  | IFetchMyInstitutionSubjectsFail
  | IAddInstitutionSubject
  | IAddInstitutionSubjectSuccess
  | IAddInstitutionSubjectFail
  | IDeleteInstitutionSubject
  | IDeleteInstitutionSubjectSuccess
  | IDeleteInstitutionSubjectFail
  | IFetchMyInstitutionStatistics
  | IFetchMyInstitutionStatisticsSuccess
  | IFetchMyInstitutionStatisticsFail
  | IUpdateMyInstitutionStatistics
  | IUpdateMyInstitutionStatisticsSuccess
  | IUpdateMyInstitutionStatisticsFail
  | IFetchMyInstitutionSelfEvaluation
  | IFetchMyInstitutionSelfEvaluationSuccess
  | IFetchMyInstitutionSelfEvaluationFail
  | IUpdateMyInstitutionSelfEvaluation
  | IUpdateMyInstitutionSelfEvaluationSuccess
  | IUpdateMyInstitutionSelfEvaluationFail
  | IShowMyInstitutionSelfEvaluationForm
  | IHideMyInstitutionSelfEvaluationForm
  | IDeleteMyInstitutionSelfEvaluation
  | IDeleteMyInstitutionSelfEvaluationSuccess
  | IDeleteMyInstitutionSelfEvaluationFail
  | IUpdateMyInstitutionSelfEvaluationResponse
  | IUpdateMyInstitutionSelfEvaluationResponseSuccess
  | IUpdateMyInstitutionSelfEvaluationResponseFail
  | IFetchMyInstitutionCurrentResponse
  | IFetchMyInstitutionCurrentResponseSuccess
  | IFetchMyInstitutionCurrentResponseFail
  | IResetMyInstitutionErrors;
